import React, { useCallback, useMemo } from 'react';
import { Dialog, DialogContent, Stack, Typography, useTheme } from "@mui/material";

import useTranslations from "helpers/hooks/useTranslations";
import useIsMobile from "helpers/hooks/useIsMobile";

import { IContainerDetail } from "helpers/interfaces";
import useOrderData from "../useOrderData";
import CustomDataGridPro from "components/Common/CustomDataGridPro";
import { GridCallbackDetails, GridRowIdGetter, GridRowModel } from "@mui/x-data-grid-pro";
import DataGridColumnGenerator from "components/Common/DataGridColumnGenerator";
import Format from "helpers/fv.format";
import useFormatHelper from "helpers/hooks/useFormatHelper";
import useRowsPerPage from "helpers/hooks/useRowsPerPage";

interface IProps {
    dialogVisible: boolean;
    onClose: () => void;
}

interface IContainerDetailRow extends IContainerDetail {
    orderItem: string,
    scheduleUnit: string,
    formattedPart: string,
    formattedSize: string,
}

const ContainerDetail: React.FC<IProps> = ({ dialogVisible, onClose }: IProps) => {

    const tm = useTranslations();
    const theme = useTheme();
    const isMobile = useIsMobile();
    const formatMethods = useFormatHelper();
    const rowsPerPage = useRowsPerPage("ContainerDetail");
    const { order, containerDetail } = useOrderData();

    const containerDetailRows = useMemo(() => {
        const details: IContainerDetailRow[] = [];

        if (order && containerDetail?.details) {
            containerDetail.details.forEach((cd) => {
                const props: IContainerDetailRow = {
                    ...cd,
                    orderItem: `${cd.orderNumber} - ${Format.FormatLineItem(cd.lineItem, cd.subLineItem)}`,
                    scheduleUnit: `${cd.schedID}-${cd.unitID}`,
                    formattedPart: `${Format.FormatPartDescription(cd.partNo, cd.partNoSuffix, cd.description)}`,
                    formattedSize: `${formatMethods.FormatDimensions(cd.width, cd.height, cd.thickness, order.engineeringUnitSetID)}`,
                };
                details.push(props);
            });

            return details;

        } else {
            return null;
        }

    }, [containerDetail?.details, order, formatMethods]);

    const getOrderUri = useCallback((row: IContainerDetailRow): string => {

        if (row.userCanAccessOrder) {
            return `/orders`;
        }

        return "";
    }, []);

    const getOrderParams = useCallback((row: IContainerDetailRow) => {
        if (row.userCanAccessOrder) {
            return {
                oKey: row.oKey
            }
        }
    }, []);

    const generator = useMemo(() => {
        const columns = new DataGridColumnGenerator(tm, containerDetailRows, theme, isMobile);
        columns.TryAddNumericColumn("slot", "Slot", undefined, { hideInMobile: false });
        columns.TryAddHyperlinkColumn("orderItem", "Order-Item", getOrderUri, getOrderParams, { hideInMobile: false });
        columns.TryAddTextColumn("scheduleUnit", "Schedule-Unit", { hideInMobile: true });
        columns.TryAddNumericColumn("containerQty", "Quantity", undefined, { hideInMobile: true });
        columns.TryAddTextColumn("formattedPart", "Part", { hideInMobile: false });
        columns.TryAddTextColumn("formattedSize", "Size", { hideInMobile: false });
        return columns;
    }, [tm, containerDetailRows, theme, isMobile, getOrderUri, getOrderParams]);

    const getRowId: GridRowIdGetter = (row: GridRowModel) => {
        const container = row as IContainerDetail;
        return container.slot;
    };

    const onPageSizeChange = (pageSize: number, details: GridCallbackDetails) => rowsPerPage.setPageSize(pageSize);

    return <>
        <Dialog
            open={dialogVisible}
            onClose={onClose}
            fullWidth={true}
            maxWidth={"lg"}
        >
            <DialogContent sx={{ padding: 1 }}>
                <Stack direction="column" gap={1}>
                    <Typography variant="h5">
                        {tm.Get("Shipping Container Details")}
                    </Typography>
                    <Typography>
                        {tm.Get("Shipping Container:")} {containerDetail?.container.containerKey}
                    </Typography>
                    {containerDetail &&
                        <CustomDataGridPro
                            getRowId={getRowId}
                            columns={generator.GetColumns()}
                            rows={containerDetailRows}
                            onPageSizeChange={onPageSizeChange}
                            pageSize={rowsPerPage.pageSize}
                            rowsPerPageOptions={rowsPerPage.pageSizeOptions}
                        />
                    }
                </Stack>
            </DialogContent>
        </Dialog>
    </>;

}

export default ContainerDetail;