import { useEffect, useMemo } from 'react';

import QuotesAPI from "components/Quotes/QuotesAPI";
import OrdersAPI from "components/Orders/OrdersAPI";

import useTranslations from "helpers/hooks/useTranslations";

import { ExportTypeEnum } from "helpers/enums";
import WebResponse from "helpers/WebResponse";
import useSelectionValuesData from "helpers/context/SelectionValues/useSelectionValuesData";
import useSelectionValuesActions from "helpers/context/SelectionValues/useSelectionValuesActions";

export type ReportID =
    "OrderAck" |
    "OrderAckWOPricing" |
    "OrderAckWOLineItemPricing" |
    "OrderList" |
    "OrderSelling" |
    "OrderAccounting" |
    number;

export type FormatType =
    "PDF" |
    "Word" |
    "Excel";

export interface IReportSelection {
    reportID: ReportID,
    menuCaption: string,
}

function useReports(app: "core" | "web", reportType: "pickup" | "order" | "quote") {

    const selectionValues = useSelectionValuesData();
    const actions = useSelectionValuesActions();
    const reports = selectionValues.reports;
    const tm = useTranslations();

    const isExpired = false;

    // Load custom reports from API (stored in context)
    useEffect(() => {

        if (reportType) {
            if (!reports.has(reportType) || isExpired) {
                actions.LoadReportsAsync(app, reportType);
            }
        }

    }, [actions, app, reportType, reports, isExpired]);

    // Base reports are hard-coded (each has their own API call)
    const baseReports = useMemo((): IReportSelection[] => {
        switch (reportType) {
            case "quote":
                return [
                    { menuCaption: tm.Get("Quote"), reportID: "OrderAck" },
                    { menuCaption: tm.Get("Quote w/o Pricing"), reportID: "OrderAckWOPricing" },
                    { menuCaption: tm.Get("Quote w/o Line Item Pricing"), reportID: "OrderAckWOLineItemPricing" },
                    { menuCaption: tm.Get("Quote List"), reportID: "OrderList" },
                    { menuCaption: tm.Get("Quote Selling"), reportID: "OrderSelling" },
                    { menuCaption: tm.Get("Quote Accounting"), reportID: "OrderAccounting" },
                ];
            case "order":
                return [
                    { menuCaption: tm.Get("Order"), reportID: "OrderAck" },
                ];
            case "pickup":
                return [
                    { menuCaption: tm.Get("Pickup"), reportID: "OrderAck" },
                ];
        }

    }, [reportType, tm]);

    const customReports = useMemo(() => {
        return reports.get(reportType);
    }, [reports, reportType]);

    const reportSelections = useMemo(() => {
        if (baseReports && customReports) {
            var allReports: IReportSelection[] = [];
            allReports = allReports.concat(baseReports);
            customReports?.forEach((sr) => {
                allReports.push({
                    menuCaption: tm.Get(sr.menuCaption),
                    reportID: sr.reportID,
                })
            });
            return allReports;
        }

        return [];
    }, [baseReports, customReports, tm]);

    const getReportDownloadAPI = (oKey: number, reportID: ReportID, formatType: FormatType): Promise<WebResponse<Blob>> => {

        let exportType: ExportTypeEnum;
        switch (formatType) {
            case "Word":
                exportType = ExportTypeEnum.Word;
                break;
            case "Excel":
                exportType = ExportTypeEnum.Excel;
                break;
            case "PDF":
                exportType = ExportTypeEnum.PDF;
                break;
        }

        if (app === "web") {
            switch (reportID) {
                case "OrderAck":
                    return QuotesAPI.GetOrderAcknowledgement(oKey, exportType);
                case "OrderAckWOPricing":
                    return QuotesAPI.GetOrderAcknowledgementWOPricing(oKey, exportType);
                case "OrderAckWOLineItemPricing":
                    return QuotesAPI.GetOrderAcknowledgementWOLineItemPricing(oKey, exportType);
                case "OrderList":
                    return QuotesAPI.GetOrderList(oKey, exportType);
                case "OrderSelling":
                    return QuotesAPI.GetOrderSelling(oKey, exportType);
                case "OrderAccounting":
                    return QuotesAPI.GetOrderAccounting(oKey, exportType);
                default:
                    return QuotesAPI.GetCustomReport(oKey, reportID, exportType);
            }
        }
        else {
            switch (reportID) {
                case "OrderAck":
                    return OrdersAPI.GetOrderAcknowledgement(oKey);
                case "OrderAckWOPricing":
                case "OrderAckWOLineItemPricing":
                case "OrderList":
                case "OrderSelling":
                case "OrderAccounting":
                    throw new Error(`ReportID ${reportID} not implemented for orders.`)
                default:
                    return OrdersAPI.GetCustomReport(oKey, reportID);
            }
        }
    };

    return { reportSelections, getReportDownloadAPI };
}

export default useReports;

