import React, { useCallback } from 'react';
import { Stack, Autocomplete, TextField, Button } from "@mui/material";

import { IShapeEditorInfo, IShapeDisplayInfo } from "helpers/interfaces";
import useTranslations from "helpers/hooks/useTranslations";
import useIsMobile from "helpers/hooks/useIsMobile";
import useWizardState, { useWizardStateActions } from "components/OptionsWizard/WebDesigner/useWizardState";
import { WizardViewEnum } from "components/OptionsWizard/WizardContext";

interface IProps {
    shapeEditor: IShapeEditorInfo,
    handleShapeChange: (e: any, data: IShapeDisplayInfo) => void
}

const ShapeDropdown: React.FC<any> = ({shapeEditor, handleShapeChange}: IProps) => {
    const tm = useTranslations();
    const isMobile = useIsMobile();
    const wizardActions = useWizardStateActions();
    const wizardState = useWizardState();

    const handleShapeLibraryClick = useCallback(() => {
        wizardActions?.SwitchToView(WizardViewEnum.ShapeLibrary);
    }, [wizardActions]);

    const selectedShape = React.useCallback(() => {
        if (shapeEditor.availableShapes.length > 0) {
            const item = shapeEditor.availableShapes.find(s => s.shapeNumber === shapeEditor.selectedShapeNumber);
            return item ?? undefined;
        }
        return undefined;
    }, [shapeEditor]);

    return <Stack direction="row" alignItems="center" justifyContent="center">
        <Autocomplete
            autoComplete
            disableClearable
            autoSelect
            autoHighlight
            selectOnFocus
            handleHomeEndKeys
            options={shapeEditor.availableShapes}
            getOptionLabel={(s: IShapeDisplayInfo) => s.shapeCodeAndDescription}
            isOptionEqualToValue={(s: IShapeDisplayInfo, value: IShapeDisplayInfo) => s.shapeNumber === value.shapeNumber}
            style={{width: isMobile ? "100%" : "60%", margin: 3}} 
            renderInput={(params) => (
                <TextField 
                    {...params}
                    size="small" 
                    label={tm.Get("Shape")}
                    InputProps={{
                        ...params.InputProps,
                    }}
                    inputProps={{
                        ...params.inputProps,
                        inputMode: wizardState.userPreferences.enableMobileKeyboard ? "text" : "none"
                    }}
                />
            )}
            onChange={handleShapeChange}
            value={selectedShape()}
        />
        <Button
            variant="contained"
            color="primary"
            value="..."
            title={tm.Get("Shape Library")}
            onClick={handleShapeLibraryClick}
            >
            {"..."}
        </Button>
    </Stack>

};

export default ShapeDropdown;