import { useMemo } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { Error } from "@mui/icons-material";

import { ILineItem } from "models/IQuote";
import { ThemeColorEnum } from "helpers/enums";
import useTranslations from "helpers/hooks/useTranslations";

interface IProps {
    lineItem: ILineItem,
    validationExceptionsOnly: boolean,
    onClick: (lineItem: ILineItem) => void,
}

const LineItemExceptionIcon: React.FC<IProps> = ({ lineItem, validationExceptionsOnly, onClick }: IProps) => {

    // Prevent any of the links in this section from acting as "submit"
    const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();
    const tm = useTranslations();

    const tooltip = useMemo(() => {
        return tm.Get("View Line Item Exceptions")
    }, [tm]);

    const showIcon = useMemo(() => {
        return validationExceptionsOnly ? lineItem.hasValidationExceptions : lineItem.hasExceptions;
    }, [validationExceptionsOnly, lineItem]);

    if (showIcon) {
        return <div onClick={preventDefault}>
            <Tooltip title={tooltip}>
                <IconButton sx={{ padding: 0 }} color={ThemeColorEnum.Primary} onClick={(_) => onClick(lineItem)}>
                    <Error />
                </IconButton>
            </Tooltip>
        </div>;
    }
    else {
        return <></>;
    }

};

export default LineItemExceptionIcon;


