import useTranslations from "helpers/hooks/useTranslations";

interface IProps {
    children?: string;
    parameters?: string[];
}

const Translation = (props: IProps) => {

    const tm = useTranslations();

    if (!props.children) return null;

    let translated: string;

    if (props.parameters) {
        translated = tm.GetWithParams(props.children, ...props.parameters);
    } else {
        translated = tm.Get(props.children);
    }

    return <>
        {translated}
    </>;

};

export default Translation;


