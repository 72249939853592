import { useCallback, useEffect, useMemo, useState } from "react";
import { Container, Dialog, DialogContent, useTheme } from "@mui/material";
import { GridCallbackDetails, GridRowIdGetter, GridRowModel } from "@mui/x-data-grid-pro";

import { AddColumns } from "components/Quotes/QuoteEntry/TransLog/TransLogColumns";

import useQuoteData from "components/Quotes/useQuoteData";
import useActionButtons from "helpers/context/Page/useActionButtons";
import useQuoteActions from "components/Quotes/useQuoteActions";
import useTranslations from "helpers/hooks/useTranslations";
import useWindowTitle from "helpers/context/Title/useWindowTitle";

import { IActionButton } from "helpers/context/Page/PageContext";
import { ThemeColorEnum } from "helpers/enums";
import CustomDataGridPro from "components/Common/CustomDataGridPro";
import DataGridColumnGenerator from "components/Common/DataGridColumnGenerator";
import useIsMobile from "helpers/hooks/useIsMobile";
import { ITransLogDiff, ITransLogHeader } from "helpers/interfaces";
import TransLogDetails from "./TransLogDetails";
import DialogTitleWithClose from "components/Dialog/DialogTitleWithClose";
import useRowsPerPage from "helpers/hooks/useRowsPerPage";
import { useNavigate } from "react-router-dom";
import QuoteNavigation from "components/Quotes/QuoteNavigation";
import { TransLogHeaderTypeEnum } from "models/ITransLogHeader";

const TransLog: React.FC<any> = () => {

    const theme = useTheme();
    const isMobile = useIsMobile();
    const tm = useTranslations();
    const { quote } = useQuoteData();
    const quoteActions = useQuoteActions();
    const navigate = useNavigate();
    const actionButtons = useActionButtons();
    const rowsPerPage = useRowsPerPage("TransLog");
    const [transLogData, setTransLogData] = useState<ITransLogHeader[] | undefined>(undefined);
    const [isDetailsDialogVisible, setIsDetailsDialogVisible] = useState<boolean>(false);
    const [selectedTransLogHeader, setSelectedTransLogHeader] = useState<ITransLogHeader | undefined>(undefined);
    const [selectedTransLogDiffs, setSelectedTransLogDiffs] = useState<ITransLogDiff[] | undefined>(undefined);

    const generator = new DataGridColumnGenerator(tm, transLogData, theme, isMobile);

    const onViewDetails = useCallback((transLogHeader: ITransLogHeader) => {
        if (quote?.oKey) {
            setSelectedTransLogHeader(transLogHeader);
            quoteActions.GetQuoteHistoryDetailAsync(quote.oKey, transLogHeader.odKey, transLogHeader.question, transLogHeader.code, transLogHeader.tableName, transLogHeader.rowNumber).then((td) => {
                setSelectedTransLogDiffs(td);
                setIsDetailsDialogVisible(true);
            });
        }
    }, [quote, quoteActions]);

    AddColumns(generator, isMobile, onViewDetails);


    const getRowId: GridRowIdGetter = (row: GridRowModel) => {
        const transLogHeader = row as ITransLogHeader;
        return `${transLogHeader.odKey}|${transLogHeader.question ?? ""}|${transLogHeader.code ?? ""}|${transLogHeader.tableName}|${transLogHeader.rowNumber}`;
    };

    useWindowTitle(`${tm.Get("Transactions")} - #${quote?.orderNumber}`);

    useEffect(() => {

        const okButton: IActionButton = {
            text: tm.Get("OK"),
            color: ThemeColorEnum.Secondary,
            onClick: () => { navigate(QuoteNavigation.QuoteEntryURL(quote?.oKey ?? 0)); }
        };
        actionButtons.Set(0, okButton);

    }, [actionButtons, quoteActions, tm, navigate, quote]);

    useEffect(() => {
        if (!transLogData && quote?.oKey) {
            quoteActions.GetQuoteHistoryAsync(quote.oKey).then((td) => {
                setTransLogData(td);
            });
        }

    }, [transLogData, quoteActions, quote]);

    const onPageSizeChange = (pageSize: number, details: GridCallbackDetails) => rowsPerPage.setPageSize(pageSize);

    const headerText = useMemo(() => {
        if (selectedTransLogHeader) {
            switch (selectedTransLogHeader.type) {
                case TransLogHeaderTypeEnum.Quote:
                    return selectedTransLogHeader.quoteNumber;
                case TransLogHeaderTypeEnum.LineItem:
                    return `${selectedTransLogHeader.quoteNumber}-${selectedTransLogHeader.lineItem}`;
                case TransLogHeaderTypeEnum.LineItemOption:
                    return `${selectedTransLogHeader.quoteNumber}-${selectedTransLogHeader.lineItem}: ${selectedTransLogHeader.question} [${selectedTransLogHeader.code}]`;
                default:
                    return selectedTransLogHeader.quoteNumber;
            }
        }
        return ""
    }, [selectedTransLogHeader]);

    return <>
        <Container>
            <CustomDataGridPro
                getRowId={getRowId}
                columns={generator.GetColumns()}
                rows={transLogData}
                style={{ minHeight: 456, marginTop: theme.spacing(1) }}
                onPageSizeChange={onPageSizeChange}
                pageSize={rowsPerPage.pageSize}
                rowsPerPageOptions={rowsPerPage.pageSizeOptions}
            />
        </Container>

        <Dialog
            open={isDetailsDialogVisible}
            onClose={() => setIsDetailsDialogVisible(false)}
        >
            <DialogTitleWithClose id="customized-dialog-title" onClose={() => setIsDetailsDialogVisible(false)}>
                {headerText}
            </DialogTitleWithClose>
            <DialogContent>
                {selectedTransLogHeader && selectedTransLogDiffs &&
                    <TransLogDetails
                        diffs={selectedTransLogDiffs}
                        user={selectedTransLogHeader.user}
                        date={selectedTransLogHeader.date}
                    />
                }

            </DialogContent>
        </Dialog>
    </>
};

export default TransLog;

