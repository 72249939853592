import { Dialog, DialogTitle } from "@mui/material";
import { ILineItemInfo, IWebDesigner } from "components/OptionsWizard/interfaces";
import ItemProperties, { IItemInformation, IItemProperties } from "components/OptionsWizard/ItemProperties/ItemProperties";
import { PartDefaults } from "helpers/context/Parts/usePartDefaults";
import useMeasurementTypes from "helpers/context/SelectionValues/useMeaurementTypes";
import { useEffectOnLoad } from "helpers/hooks/useEffectOnLoad";
import useIsMobile from "helpers/hooks/useIsMobile";
import useTranslations from "helpers/hooks/useTranslations";
import { IPartCallSizePresets } from "helpers/interfaces";
import PartsAPI from "helpers/PartsAPI";
import React from 'react';

interface IProps {
    isSubLineItem: boolean;
    itemInfo: ILineItemInfo;
    webDesigner: IWebDesigner | null;
    dialogVisible: boolean;
    onCancel: () => void;
    onSubmit: (originalState: IItemProperties, newState: IItemProperties) => void;
}

const ItemPropertiesDialog: React.FC<IProps> = ({itemInfo, webDesigner, dialogVisible, onCancel, onSubmit}: IProps) => {

    const measurementTypes = useMeasurementTypes();
    const isMobile = useIsMobile();
    const tm = useTranslations();

    const quoteMeasurementType = React.useCallback(() => {
        if (itemInfo && measurementTypes) {
            return measurementTypes.find((m) => m.setID === itemInfo.unitSet);
        }
    }, [itemInfo, measurementTypes]);

    const measurementType = quoteMeasurementType();


    const [partDefaults, setPartDefaults] = React.useState<PartDefaults | undefined>(undefined);
    const [partCallSizes, setPartCallSizes] = React.useState<IPartCallSizePresets | undefined>(undefined);


    useEffectOnLoad(() => {
        /*TODO: Cache this */
        const partDefPromise = PartsAPI.GetPartDefaults(itemInfo.partNo, itemInfo.partNoSuffix, itemInfo.shortcutName);
        const callSizesPromise = PartsAPI.GetPartCallSizes(itemInfo.partNo, itemInfo.partNoSuffix);

        Promise.all([partDefPromise, callSizesPromise]).then(([partDefaults, callSizes]) => {
            setPartCallSizes(callSizes);
            setPartDefaults(new PartDefaults(partDefaults));
        });

    });



    const dialogInfo = React.useMemo(() => {

        if (partCallSizes && partDefaults && measurementType) {

            const initialState: IItemProperties = {
                qty: itemInfo.qty,
                height: itemInfo.height,
                width: itemInfo.width,
                thickness: itemInfo.thickness,
                callSize: itemInfo.callSize,
                comment: itemInfo.comment
            };

            const dialogItemInfo: IItemInformation = {
                existingItem: true,
                isSubLineItem: (webDesigner && webDesigner.currentSection !== 0) ?? false,
                callSizes: partCallSizes,
                defaults: partDefaults
            };

            return { initialState, itemInfo: dialogItemInfo };

        }
        //If any itemInfo props change, we need to update this.
    }, [partCallSizes, partDefaults, measurementType, webDesigner, itemInfo])


    if (measurementType && dialogInfo?.initialState) {

        return <>
            <Dialog
                open={dialogVisible}
                onClose={onCancel}
                fullScreen={isMobile}
            >
                <DialogTitle>{tm.Get("Item Properties")}</DialogTitle>
                <ItemProperties
                    newItem={false}
                    initialState={dialogInfo.initialState}
                    itemInfo={dialogInfo.itemInfo}
                    measurementType={measurementType}
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                />
            </Dialog>
        </>;
    }

    return null

}

export default ItemPropertiesDialog;