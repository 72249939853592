import { PropsWithChildren } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useTranslations from "helpers/hooks/useTranslations";
import OKCancelControl from "./OKCancelControl";

interface IProps {
    open: boolean
    handleClose: (result: boolean) => void;
    headerText: string;
    message?: string;
    noText?: string;
    yesText?: string;
    defaultToYes?: boolean;
}

const AlertDialog: React.FC<PropsWithChildren<IProps>> = ({ open, handleClose, headerText, message, noText, yesText, defaultToYes, children }: PropsWithChildren<IProps>) => {

    const tm = useTranslations();

    if (!noText)
        noText = tm.Get("No");

    if (!yesText)
        yesText = tm.Get("Yes");

    const handleClickOff = () => {
        handleClose(false);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClickOff}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {headerText}
            </DialogTitle>
            <DialogContent>
                {children ??
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                }
            </DialogContent>
            <DialogActions>
                <OKCancelControl okCommand={() => handleClose(true)} cancelCommand={() => handleClose(false)}
                    okText={yesText} cancelText={noText} defaultToYes={defaultToYes}
                />
            </DialogActions>
        </Dialog >
    );
}

export default AlertDialog;
