import TranslationsAPI from "helpers/context/Translations/TranslationsAPI";
import { IConfiguredLanguage } from "models/Translations/IConfiguredLanguage";
import { ILocaleTranslations } from "models/Translations/ILocaleTranslations";
import { ITMConfig } from "models/Translations/ITMConfig";
import { LocalCacheHelper } from "helpers/LocalCacheHelper";
import React, { Reducer } from "react";
import { useEffectOnLoad } from "helpers/hooks/useEffectOnLoad";
import { IBaseContent } from "models/IBaseContent";

type GetMethod = (content: string | IBaseContent) => string;
type GetWithParamsMethod = (content: string, ...params: string[]) => string;

interface ITranslationContext {

    languages: Map<string, IConfiguredLanguage>;
    currentLanguage: IConfiguredLanguage;
    translations: ILocaleTranslations | null;

    config: ITMConfig | null;

    missingContent: Set<string>;

    TM: {
        Get: GetMethod,
        GetWithParams: GetWithParamsMethod,
    }

};

const INITIAL_TMGET = (content: string | IBaseContent) : string => {
    if (typeof content === 'string') {
        return content;
    }
    else {
        if (content.params) {
            return content.message.fvformat(...content.params)
        }
        else {
            return content.message;
        }
    }
}

const INITIAL_CONTEXT: ITranslationContext = {
    languages: new Map<string, IConfiguredLanguage>(),
    currentLanguage: {
        cultureInfo: "en",
        enabledForEmployees: false,
        enabledForUserTranslation: false,
        textIsLeftToRight: true,
    },
    missingContent: new Set<string>(),
    translations: null, // INITIAL_TRANSLATIONS,
    config: null,
    TM: {
        Get: INITIAL_TMGET,
        GetWithParams: (content: string, ...params: string[]) => content.fvformat(...params),
    }

}


type TranslationAction = { type: "SET_LANGUAGES", value: Map<string, IConfiguredLanguage> }
    | { type: "SET_TRANSLATIONS", value: ILocaleTranslations }
    | { type: "SET_GETS", value: [GetMethod, GetWithParamsMethod] }
    | { type: "SET_CURRENT", value: string }
    | { type: "ADD_MISSING", value: string }
    | { type: "SET_CONFIG", value: ITMConfig | null }
    | { type: "RESET_CACHE" }
    ;

const TranslationReducer: Reducer<ITranslationContext, TranslationAction> = (initialState, action) => {

    switch (action.type) {
        case "SET_LANGUAGES":
            return { ...initialState, languages: action.value };
        case "SET_TRANSLATIONS":
            return { ...initialState, translations: action.value };
        case "SET_CONFIG":
            return { ...initialState, config: action.value };
        case "SET_GETS":
            return { ...initialState, TM: { Get: action.value[0], GetWithParams: action.value[1] } };
        case "SET_CURRENT":
            let currentLanguage: IConfiguredLanguage | undefined = initialState.languages.get(action.value);
            if (currentLanguage) {
                return { ...initialState, currentLanguage };
            }
            break;
        case "ADD_MISSING":
            if (!initialState.missingContent.has(action.value)) {
                return { ...initialState, missingContent: new Set<string>([...initialState.missingContent, action.value]) }
            }
            break;
        case "RESET_CACHE":
            return INITIAL_CONTEXT;

    }

    return initialState;

};

class TranslationActionsAPI {

    #dispatch: React.Dispatch<TranslationAction>;

    constructor(dispatch: React.Dispatch<TranslationAction>) {
        this.#dispatch = dispatch;
    }

    public ResetContext = () => {
        this.#dispatch({ type: "RESET_CACHE" });
        QueryConfigAndLanguages(this.#dispatch);
    }

}


export const TranslationContext = React.createContext<ITranslationContext>(INITIAL_CONTEXT);
export const TranslationActionsAPIContext = React.createContext<TranslationActionsAPI>({} as any);

const GetTranslatedContent = (baseContent: string, originalBaseContent: string, hasColon: boolean, translationCache: ILocaleTranslations | null, textIsLeftToRight: boolean, contentPrefix: string, missingPrefix: string, onContentMissing: (content: string) => void) => {

    if (translationCache) {
        let translation = translationCache.content.get(baseContent);

        if (translation) {

            if (translation.localeCompare(baseContent, undefined, { sensitivity: "base" }) === 0) {
                //remove any capitalization issues that are maintained in the translation DB
                translation = baseContent;
            }

            if (hasColon) {
                if (textIsLeftToRight)
                    translation = translation + ":";
                else
                    translation = ":" + translation;
            }

            return contentPrefix + translation;

        } else {
            //No translation exists, submit the scrubbed translation as new base content
            onContentMissing(baseContent);
        }
    }

    return missingPrefix + contentPrefix + originalBaseContent;

}


const QueryConfigAndLanguages = (dispatch: React.Dispatch<TranslationAction>) => {
    const configPromise = TranslationsAPI.QueryTMConfig();
    const languagesPromise = TranslationsAPI.QueryConfiguredLanguages();

    Promise.all([configPromise, languagesPromise]).then(([config, languageInfo]) => {
        dispatch({ type: "SET_CONFIG", value: config });
        dispatch({ type: "SET_LANGUAGES", value: languageInfo.languageMap });
        dispatch({ type: "SET_CURRENT", value: languageInfo.defaultCultureInfo });
    });
}

export const TranslationContextProvider: React.FC<React.PropsWithChildren<any>> = (props: React.PropsWithChildren<any>) => {

    const [state, dispatch] = React.useReducer(TranslationReducer, INITIAL_CONTEXT);

    const translationAPI = React.useMemo(() => {
        return new TranslationActionsAPI(dispatch);
    }, [dispatch])

    const onContentMissing = React.useCallback((content: string) => {
        if (state.config && state.config.missingContentAction !== "DoNotAutoSubmit") {
            // Note: dispatching will cause a state update that will generate some console warnings and possible render issues
            // But these will go away once the content is submitted properly (you may need to clear your cache in the preferences menu)
            dispatch({ type: "ADD_MISSING", value: content });
            if (!state.missingContent.has(content)) {
                TranslationsAPI.PostMissingContent(content);
            }
        }
    }, [state.missingContent, state.config]);

    const locale = state.translations?.locale;

    React.useEffect(() => {

        const contentPrefix = state.config?.contentPrefix ?? "";
        const missingPrefix = state.config?.missingPrefix ?? "";

        const tmGet = (content: string | IBaseContent) : string => {

            if (typeof content === 'string') {
                let hasColon: boolean = false;
                let baseContent: string = content;
                if (content.endsWith(":")) {
                    hasColon = true;
                    baseContent = content.substring(0, content.length - 1);
                }
    
                return GetTranslatedContent(baseContent, content, hasColon, state.translations, state.currentLanguage.textIsLeftToRight, contentPrefix, missingPrefix, onContentMissing);
            }
            else {
                // IBaseContent
                const translated = tmGet(content.message);
                if (content.params) {
                    if (content.translateParams) {
                        let translatedParams = translateParams(content.params, content.translateParams);
                        return translated.fvformat(...translatedParams);
                    }
                    return translated.fvformat(...content.params);
                }
                else {
                    return translated;
                }
            }

        }

        const tmGetWithParams = (content: string, ...params: string[]) => {
            var baseContent : IBaseContent = { message: content, params: params };
            return tmGet(baseContent);
        }

        const translateParams = (params: string[], translateArgs: boolean[]) => {
            return params.map((param, index) => translateArgs[index] ? tmGet(param) : param);
        }

        dispatch({ type: "SET_GETS", value: [tmGet, tmGetWithParams] })

    }, [state.config, state.currentLanguage.cultureInfo, locale, onContentMissing, state.translations, state.currentLanguage.textIsLeftToRight]);

    const handleBrowserLanguageChange = () => {
        LocalCacheHelper.ClearLocalCache();
        translationAPI.ResetContext();
    }

    useEffectOnLoad(() => {
        QueryConfigAndLanguages(dispatch);
    });

    useEffectOnLoad(() => {
        window.addEventListener("languagechange", handleBrowserLanguageChange);
        return () => {
            window.removeEventListener('languagechange', handleBrowserLanguageChange);
        }
    });

    React.useEffect(() => {
        if (state.currentLanguage && state.currentLanguage.cultureInfo !== "en") {
            TranslationsAPI.QueryLocaleTranslations(state.currentLanguage.cultureInfo).then(tr => {
                dispatch({ type: "SET_TRANSLATIONS", value: tr });
            });
        }
    }, [state.currentLanguage, state.currentLanguage.cultureInfo]);

    return <TranslationContext.Provider value={state}>
        <TranslationActionsAPIContext.Provider value={translationAPI}>
            {state.translations && props.children}
        </TranslationActionsAPIContext.Provider>
    </TranslationContext.Provider>

};
