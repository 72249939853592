import React from 'react';
import { Grid, TextField, InputAdornment, IconButton, Alert, Typography, MenuItem, Select, FormControl, InputLabel, Container } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import useTranslations from 'helpers/hooks/useTranslations';
import { AuthorizationTypeEnum } from "helpers/enums";
import useIsMobile from 'helpers/hooks/useIsMobile';
import useLoadingForm from "helpers/hooks/useLoadingForm";
import LoadingForm from 'components/Common/LoadingForm';
import useAuthHandler from "helpers/context/Authentication/useAuthHandler";
import { useSearchParams } from "react-router-dom";

interface IFuncProps {
    csrMode: boolean;
}

const handleMousePassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
}

enum AuthenticationTypeEnum {
    FeneTech = "FeneTech",
    Windows = "Windows",
}

const Login: React.FC<IFuncProps> = (props: IFuncProps) => {

    const [authenticationType, setAuthenticationType] = React.useState<AuthenticationTypeEnum>(AuthenticationTypeEnum.FeneTech);
    const [username, setUserName] = React.useState<string>("");
    const [password, setPassword] = React.useState<string>("");
    const [domain, setDomain] = React.useState<string>("");
    const [errorMessage, setErrorMessage] = React.useState<string>("");
    const [showPassword, setShowPassword] = React.useState<boolean>(false);

    const [query, setQuery] = useSearchParams();
    const remoteID = query.get("RemoteID") ?? undefined;
    const userName = query.get("UserName") ?? undefined;

    const tm = useTranslations();
    const isMobile = useIsMobile();
    const authHandler = useAuthHandler();

    React.useEffect(() => {
        // If the query string contains a RemoteID and UserName, it means an external system has already authenticated with the WCSS API
        // Use this info to sign the user in instead of showing them the user name and password prompt.
        // Example of what we are expecting:  https://www.consoso.com/WEB/quotes?oKey=69&UserName=tomjones&RemoteID=39dcaba7-262a-417a-8b57-b785f7186136
        if (remoteID && userName) {
            authHandler.SignInWithRemoteIDAsync(remoteID, userName).then((success) => {
                if (success) {
                    // Remove the login info from the query string. The React Router will direct them where they want to go (anywhere but the login page should work)
                    // Example: https://www.consoso.com/WEB/quotes?oKey=69
                    query.delete("RemoteID");
                    query.delete("UserName");
                    setQuery(query);
                }
            });
        }
    }, [remoteID, userName, authHandler, setQuery, query]);

    const submitHandler = async (form: HTMLFormElement) => {

        setErrorMessage("");

        let loginType: AuthorizationTypeEnum = AuthorizationTypeEnum.Dealer;
        let apiUsername = username;

        if (props.csrMode) {
            loginType = AuthorizationTypeEnum.CSR;

            if (authenticationType === AuthenticationTypeEnum.Windows && domain) {
                apiUsername = `${domain}\\${username}`;
            }
        }

        const success = await authHandler.SignInAsync(apiUsername, password, loginType);

        if (success) {
            setErrorMessage("");
        } else {
            setErrorMessage(tm.Get("Sign in failed. Please try again."));
        }

    }

    const loadingFormState = useLoadingForm(submitHandler);

    const handleShowPassword = async () => {
        setShowPassword(!showPassword);
    }

    if (remoteID && userName) {
        return <></>;
    }

    return <Container disableGutters>
        <LoadingForm {...loadingFormState}>
            <Grid container justifyContent="center" padding="10px" spacing={3} >
                <Grid item justifyContent="center" alignItems='stretch' md={6} xs={11} >

                    {props.csrMode && <>
                        <Grid item textAlign="center" >
                            <Typography variant="h4" >
                                {tm.Get("WEB CSR Sign In")}
                            </Typography>
                        </Grid>

                        <Grid item alignContent="center" margin={1} >
                            <FormControl fullWidth>
                                <InputLabel>{tm.Get("Authentication Type")}</InputLabel>
                                <Select
                                    label={tm.Get("Authentication Type")}
                                    variant="outlined"
                                    value={authenticationType}
                                    onChange={(e) => setAuthenticationType(e.target.value as AuthenticationTypeEnum)}
                                >
                                    <MenuItem key={AuthenticationTypeEnum.FeneTech} value={AuthenticationTypeEnum.FeneTech}>{tm.Get("FeneTech Authentication")}</MenuItem>
                                    <MenuItem key={AuthenticationTypeEnum.Windows} value={AuthenticationTypeEnum.Windows}>{tm.Get("Windows Authentication")}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </>}

                    <Grid item alignContent="center" margin={1} >
                        <TextField fullWidth variant="outlined" value={username} label={tm.Get("User Name")} onChange={(e) => setUserName(e.target.value)} />
                    </Grid>

                    <Grid item margin={1}>
                        <TextField fullWidth variant="outlined" value={password} label={tm.Get("Password")} onChange={(e) => setPassword(e.target.value)}
                            type={showPassword ? "text" : "password"}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end" >
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleShowPassword}
                                            onMouseDown={handleMousePassword}
                                            onMouseUp={handleMousePassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                            }}

                        />
                    </Grid>

                    {props.csrMode && authenticationType === AuthenticationTypeEnum.Windows && <>
                        <Grid item alignContent="center" margin={1} >
                            <TextField fullWidth variant="outlined" value={domain} label={tm.Get("Domain")} onChange={(e) => setDomain(e.target.value)} />
                        </Grid>
                    </>}

                    <Grid item margin={1}>
                        <Grid container justifyContent="flex-end">
                            <LoadingButton variant="contained" color="primary" type="submit"
                                loading={loadingFormState.submitting}
                                disabled={!(username && password)}
                                sx={{ minWidth: isMobile ? 1 : 1 / 4 }}
                            >
                                {tm.Get("Sign In")}
                            </LoadingButton>
                        </Grid>
                    </Grid>

                    <Grid item margin={1}>
                        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                    </Grid>

                </Grid>
            </Grid >
        </LoadingForm>
    </Container>
}


export default Login;
