import React, { useCallback, useState } from 'react';
import "./topheader.scss";
import { Grid, IconButton, DialogContent, Popover, AppBar, Button } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';
import useIsMobile from 'helpers/hooks/useIsMobile';
import useUserInfo from '../../helpers/context/User/useUserInfo';
import UserMenu from "./UserMenu";
import UserMenuMobile from "./UserMenuMobile";
import UserAvatar from './UserAvatar';
import { RoleEnum } from 'helpers/enums';
import useFeneVisionTheme from 'helpers/context/Theme/useFeneVisionTheme';
import useAuthHandler from "helpers/context/Authentication/useAuthHandler";
import useAuthInfo from "helpers/context/Authentication/useAuthInfo";
import ThemeSelector from "./ThemeSelector";
import useHomeAddress from "helpers/context/HomeAddress/useHomeAddress";

interface IProps {
}

const TopHeader: React.FC<IProps> = (props: IProps) => {

    const isMobile: boolean = useIsMobile();
    const userInfo = useUserInfo();
    const navigate = useNavigate();
    const authHandler = useAuthHandler();
    const authInfo = useAuthInfo();
    const [isThemeSelectorOpen, setIsThemeSelectorOpen] = useState<boolean>(false);
    const homeAddress = useHomeAddress();

    const signOut = useCallback(() => {
        authHandler.SignOutAsync().then(() => {
            if (userInfo.HasRole(RoleEnum.CSR)) {
                navigate("/login/csr");
            }
            else {
                navigate("/login");
            }
        });
    }, [authHandler, userInfo, navigate]);

    const [menuOpen, setMenuOpen] = React.useState<boolean>(false);

    const navigateToProfile = () => {
        setMenuOpen(false);
        navigate("/user/profile");
    }

    const navigateToPreferences = () => {
        setMenuOpen(false);
        navigate("/user/preferences");
    }

    const openThemeSelector = () => {
        setMenuOpen(false);
        setIsThemeSelectorOpen(true);
    }

    const closeThemeSelector = () => {
        setIsThemeSelectorOpen(false);
    }

    const [themeContext] = useFeneVisionTheme();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        if (event.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
    }

    return <>

        {isMobile ? <UserMenuMobile
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            signOut={signOut}
            navigateToProfile={navigateToProfile}
            navigateToPreferences={navigateToPreferences}
            openThemeSelector={openThemeSelector}
            anchorElement={anchorEl}

        />
            : <UserMenu
                menuOpen={menuOpen}
                setMenuOpen={setMenuOpen}
                signOut={signOut}
                navigateToProfile={navigateToProfile}
                navigateToPreferences={navigateToPreferences}
                openThemeSelector={openThemeSelector}
                anchorElement={anchorEl}
            />}

        <AppBar position="relative">
            <Grid container direction="row" display="flex"
                justifyContent="space-between"
                alignContent="center"
                alignItems="center"
                className="header">

                <Grid item className="logo" >
                    <img
                        src={`images/${themeContext.selectedThemeInfo?.logoFileName}`}
                        alt="Logo" style={{cursor: authInfo.signedIn ? "pointer" : "default"}}
                        onClick={(() => authInfo.signedIn ? navigate("/dashboard") : null)}
                    />
                </Grid>

                <Grid container item xs alignItems="center" justifyContent="flex-end">
                    {authInfo.signedIn &&
                        <IconButton onClick={(e) => { setMenuOpen(true); handleClick(e); }} >
                            <UserAvatar />
                        </IconButton>
                    }
                    {homeAddress !== "" && !userInfo.isAccessedExternal && <>
                        <Button variant="contained" component="a" href={homeAddress} sx={{minWidth: 0, marginLeft: 1}} size="small">
                            <HomeIcon />
                        </Button>
                    </>}
                </Grid>
            </Grid>
        </AppBar>

        <Popover
            open={isThemeSelectorOpen}
            onClose={closeThemeSelector}
            anchorOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <DialogContent>
                <ThemeSelector />
            </DialogContent>
        </Popover>
    </>;

}





export default TopHeader;

