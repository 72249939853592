import { useMemo } from "react";
import { Typography, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import useOrderData from "components/Orders/useOrderData";
import useTranslations from "helpers/hooks/useTranslations";

function RelatedQuoteLink() {

    const { order } = useOrderData();
    const tm = useTranslations();

    const quoteLink = useMemo(() : {label: string, url: string} => {
        
        let label = "";
        let url = "";
        if (order && order.remoteOrderNumber && order.remoteOKey)
        {
            label = tm.Get("Quote");
            label += ": " + order.remoteOrderNumber;
            url = `/quotes?oKey=${order.remoteOKey}`;
        }

        return {label, url};

    }, [order, tm]);

    return <>
        {(quoteLink.label && quoteLink.url) ?
            <Link component={RouterLink} to={quoteLink.url} >
                {quoteLink.label}
            </Link>
        :
            <>
            <Typography>
                {quoteLink.label}
            </Typography>
            </>
        }
    </>;

}

export default RelatedQuoteLink;