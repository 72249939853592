import React, { useState, useCallback, useMemo } from "react";
import { TextField, MenuItem, Stack, IconButton, Tooltip, Autocomplete } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FileCopy from '@mui/icons-material/FileCopy';

import useIsMobile from "helpers/hooks/useIsMobile";
import useTranslations from "helpers/hooks/useTranslations";
import { PricingMethodEnum } from "helpers/enums"
import { ICategoryListItem, ISimpleListItem } from "helpers/interfaces";
import AddTable from "./AddTable";
import EditTable from "./EditTable";
import CopyTable from "./CopyTable";
import useMessageBox from "helpers/context/Page/useMessageBox";

interface IProps {
    requery: (i: any) => void,
    categories: ICategoryListItem[],
    tables: ISimpleListItem[],
    tableType: PricingMethodEnum,
    selectedCategory: number,
    selectedTable: string,
    setTableType: React.Dispatch<React.SetStateAction<PricingMethodEnum>>,
    handleTableChange: (newTable: string) => void,
    setSelectedCategory: React.Dispatch<React.SetStateAction<number>>,
    addHandler: (name: string, category: number) => void,
    editHandler: (name: string) => void,
    deleteHandler: () => void,
    copyHandler: (name: string, targetTable: number) => void,
}

const PricingTablesControls = ({requery, categories, tables, tableType, selectedCategory, selectedTable,
                                setTableType, handleTableChange, setSelectedCategory, addHandler, editHandler, deleteHandler, copyHandler} : IProps) => {

    const isMobile = useIsMobile();
    const tm = useTranslations();
    const messageBox = useMessageBox();

    const [addClicked, setAddClicked] = useState<boolean>(false);
    const [editClicked, setEditClicked] = useState<boolean>(false);
    const [copyClicked, setCopyClicked] = useState<boolean>(false);
    
    const handleTableTypeChange = useCallback((e : React.ChangeEvent<HTMLInputElement>) => {
        setTableType(parseInt(e.target.value));
    }, [setTableType]);

    const handleCategoryChange = useCallback((categoryID: number) => {
        setSelectedCategory(categoryID);
        requery(categoryID);
    }, [setSelectedCategory, requery]);

    const tableSelectorLabel = useMemo(() => {
        switch (tableType) {
            case PricingMethodEnum.Discount:
                return tm.Get("Discount Table");
            case PricingMethodEnum.Markup:
                return tm.Get("Markup Table");
            case PricingMethodEnum.Margin:
                return tm.Get("Margin Table");
        }
    }, [tableType, tm]);

    const isTableSelected = useMemo(() => {
        return selectedTable !== "";
    }, [selectedTable]);

    const selectedTableName = useMemo(() => {
        if (!isTableSelected) return "";
        return tables.find((t) => t.id.toString() === selectedTable.toString())?.description ?? "";
    }, [isTableSelected, tables, selectedTable]);

    const deletePrompt = useCallback(() => {
        messageBox.Show({
            message: tm.Get("Are you sure you want to delete this table?"), 
            title: tm.GetWithParams("Delete {0}", tm.Get("Price Table")),
            yesNoPrompt: true,
            callback: (result) => {
                if (result) deleteHandler();
            }
        });
    }, [messageBox, tm, deleteHandler]);

    const categoryList = useMemo(() => {
        return [{categoryID: -1, description: tm.Get("{All Categories}")}].concat(categories);
    }, [categories, tm]);

    const category = useMemo(() => {
        return categoryList.find((c) => c.categoryID === selectedCategory) ?? categoryList[0];
    }, [categoryList, selectedCategory]);

    const table = useMemo(() => {
        return tables.find((t) => t.id === parseInt(selectedTable)) ?? {id: 0, description: ""};
    }, [tables, selectedTable]);

    return <>
        <Stack direction="column" alignItems="flex-start" justifyItems="stretch" spacing={1} width={isMobile ? 1 : (1 / 3)}>
            <TextField size="small" label={tm.Get("Table Type")} value={tableType} onChange={handleTableTypeChange} fullWidth select>
                <MenuItem value={PricingMethodEnum.Discount}>{tm.Get("Discount")}</MenuItem>
                <MenuItem value={PricingMethodEnum.Markup}>{tm.Get("Markup")}</MenuItem>
                <MenuItem value={PricingMethodEnum.Margin}>{tm.Get("Margin")}</MenuItem>
            </TextField>
            <Autocomplete
                id="select-category"
                autoComplete
                autoHighlight
                autoSelect
                selectOnFocus
                disableClearable
                handleHomeEndKeys
                options={categoryList}
                fullWidth
                size="small"
                getOptionLabel={(option : ICategoryListItem) => option.description}
                renderInput={(params) => <TextField
                    label={tm.Get("Pricing Category")}
                    {...params}
                />}
                value={category}
                onChange={(_, data) => handleCategoryChange(data.categoryID)}
            />

            <Stack direction="row" spacing={1} width={1}>
                <Autocomplete
                    id="select-table"
                    autoComplete
                    autoHighlight
                    autoSelect
                    selectOnFocus
                    disableClearable
                    handleHomeEndKeys
                    options={tables}
                    fullWidth
                    size="small"
                    getOptionLabel={(option : ISimpleListItem) => option.description}
                    renderInput={(params) => <TextField
                        label={tableSelectorLabel}
                        {...params}
                    />}
                    value={table}
                    onChange={(_, data) => handleTableChange(data.id.toString())}
                />

                <Tooltip title={tm.GetWithParams("Add {0}", tm.Get("Table"))}>
                    <IconButton onClick={() => setAddClicked(true)}>
                        <AddIcon fontSize="small" color="primary"/>
                    </IconButton>
                </Tooltip>
                {isTableSelected && <>
                    <Tooltip title={tm.GetWithParams("Edit {0}", tm.Get("Table Name"))}>
                        <IconButton onClick={() => setEditClicked(true)}>
                            <EditIcon fontSize="small" color="primary"/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={tm.GetWithParams("Delete {0}", tm.Get("Table"))}>
                        <IconButton onClick={deletePrompt}>
                            <DeleteIcon fontSize="small" color="primary"/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={tm.GetWithParams("Copy {0}", tm.Get("Table"))}>
                        <IconButton onClick={() => setCopyClicked(true)}>
                            <FileCopy fontSize="small" color="primary"/>
                        </IconButton>
                    </Tooltip>
                </>}
            </Stack>
        </Stack>

        {addClicked && 
            <AddTable 
                categories={categories}
                defaultCategory={selectedCategory}
                open={addClicked}
                close={() => setAddClicked(false)}
                handleAdd={(name: string, category: number) => {
                    setAddClicked(false);
                    addHandler(name, category);
                }}
            />   
        }   

        {editClicked && 
            <EditTable 
                oldName={selectedTableName}
                open={editClicked}
                close={() => setEditClicked(false)}
                handleEdit={(name: string) => {
                    setEditClicked(false);
                    editHandler(name);
                }}
            />   
        }  

        {copyClicked && 
            <CopyTable 
                tables={tables}
                sourceTable={selectedTable}
                open={copyClicked}
                close={() => setCopyClicked(false)}
                handleCopy={(name: string, targetTable: number) => {
                    setCopyClicked(false);
                    copyHandler(name, targetTable);
                }}
            />   
        }  
    </>
}

export default PricingTablesControls;