import { useMemo } from "react";
import { Autocomplete, TextField } from "@mui/material";

import { IQuestionSelection } from "models/GlobalOptions/IGlobalOptionsSelections";
import useTranslations from "helpers/hooks/useTranslations";
import useGlobalOptions from "../useGlobalOptions";

const QuestionSelection: React.FC<any> = () => {

    const tm = useTranslations();
    const { selections, onQuestionChange } = useGlobalOptions();

    const selectedQuestion = useMemo(() => {
        let selection = selections.questionSelections?.find((q) => q.value === selections.selectedQuestion);

        if (!selection) {
            selection = { description: "", value: "" };
        }
        return selection;

    }, [selections]);

    return <>
        <Autocomplete
            autoComplete
            disableClearable
            autoSelect
            autoHighlight
            selectOnFocus
            handleHomeEndKeys
            options={selections.questionSelections}
            getOptionLabel={(s: IQuestionSelection) => s.description}
            renderInput={(params) => (
                <TextField
                    {...params}
                    size="small"
                    label={tm.Get("Question")}
                    InputProps={{
                        ...params.InputProps,
                    }}
                />
            )}
            onChange={(_, value) => onQuestionChange(value.value)}
            value={selectedQuestion}
        />
    </>;
};


export default QuestionSelection;
