import { Dialog, DialogContent, DialogActions, Typography } from "@mui/material";
import PageContext, { IPageInfo } from 'helpers/context/Page/PageContext';
import useMessageBox from "helpers/context/Page/useMessageBox";
import useTranslations from "helpers/hooks/useTranslations";
import DialogTitleWithClose from 'components/Dialog/DialogTitleWithClose'
import { Box } from "@mui/system";
import OKCancelControl from "components/Common/OKCancelControl";

function MessageBox() {

    const messageBox = useMessageBox();
    const tm = useTranslations();

    const handleNoClick = (pageInfo: IPageInfo) => {
        messageBox.Hide();
        if (pageInfo.dialog?.callback) {
            pageInfo.dialog.callback(false);
        }
    }

    const handleYesClick = (pageInfo: IPageInfo) => {
        messageBox.Hide();
        if (pageInfo.dialog?.callback) {
            pageInfo.dialog.callback(true);
        }
    }

    const handleDialogClose = (pageInfo: IPageInfo) => {
        messageBox.Hide();
        if (pageInfo.dialog?.callback) {
            pageInfo.dialog.callback();
        }
    }    
    
    return <>
        <PageContext.Consumer>
            {pageInfo => (
                <Dialog fullWidth
                    onClose={() => handleDialogClose(pageInfo)}
                    open={pageInfo.dialog?.visible ?? false}
                >
                    <DialogTitleWithClose id="customized-dialog-title" onClose={() => handleDialogClose(pageInfo)}>
                        {pageInfo.dialog?.title}
                    </DialogTitleWithClose>
                    <DialogContent>
                        {pageInfo.dialog?.imageSource &&
                            <Box display="flex" flexDirection="row" justifyContent="center">
                                <img alt="" src={pageInfo.dialog.imageSource} />
                            </Box>
                        }
                        <Typography whiteSpace="pre-wrap">
                            {pageInfo.dialog?.message}
                        </Typography>
                    </DialogContent>
                    {pageInfo.dialog?.yesNoPrompt &&
                        <DialogActions>
                            <OKCancelControl okCommand={() => handleYesClick(pageInfo)} cancelCommand={() => handleNoClick(pageInfo)} 
                                okText={tm.Get("Yes")} cancelText={tm.Get("No")} defaultToYes />
                        </DialogActions>
                    }
                </Dialog>
            )
            }
        </PageContext.Consumer>
    </>

}

export default MessageBox;

