import { CircularProgress, Autocomplete, TextField } from "@mui/material";
import useTranslations from "helpers/hooks/useTranslations";
import { useMemo } from 'react';
import { useController, useFormContext } from "react-hook-form";
import LabeledText from "./LabeledText";

interface IProps {
    dataSource: any
    fieldName: string
    valueField: string
    defaultValue: any
    displayField: string
    translateDisplayField: boolean
    label: string
    readOnly: boolean
    size?: "small" | "medium" 
}

const AutocompleteForm = ({ dataSource, fieldName, valueField, defaultValue, displayField, translateDisplayField, label, readOnly, size, ...rest }: IProps) => {

    const tm = useTranslations();
    const { control } = useFormContext();

    const { field } = useController({
        name: fieldName,
        control,
        rules: { required: false },
    });

    const selectedValue = useMemo(() => {
        return dataSource?.find((item: any) => field.value && item[valueField] === field.value) ?? null
    }, [dataSource, field.value, valueField]);

    if (readOnly) {
        return <LabeledText
            label={label}
            text={selectedValue ? selectedValue[displayField] : ""}
            isLoading={!dataSource}
            showSkeletonWhenEmpty={true}
        />
    }

    return (
        <Autocomplete
            id={fieldName}
            autoComplete
            autoSelect
            autoHighlight
            selectOnFocus
            handleHomeEndKeys
            options={dataSource ?? []}
            disabled={readOnly}
            isOptionEqualToValue={(option, value) => option === value}
            getOptionLabel={(option) => option ? (translateDisplayField ? tm.Get(option[displayField]) : option[displayField]) : ""}
            renderInput={(params) => (
                <TextField
                    variant="outlined"
                    {...params}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {(dataSource === undefined || !dataSource) && <CircularProgress color="inherit" size={20} />}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
            onChange={(_, data) => { field.onChange(data ? data[valueField] : defaultValue) }}
            value={selectedValue}
            ref={field.ref}
            size={size}
            {...rest}
        />
    );
}

export default AutocompleteForm;