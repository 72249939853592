import { useEffect } from "react";
import { useLocation, Route, Routes } from "react-router-dom";

import OrderLineItemPriceBreakdown from "components/LineItemPriceBreakdown/OrderLineItemPriceBreakdown";
import useOrderActions from "components/Orders/useOrderActions";
import OrderView from "components/Orders/OrderView/OrderView";
import useOrderData from "../useOrderData";
import LoadingLinearProgress from "components/Common/LoadingLinearProgress";
import NotFound from "components/Layout/NotFound";

const OrderViewMain: React.FC<any> = () => {

    const query = new URLSearchParams(useLocation().search);
    const oKeyString = query.get("oKey") ?? undefined;
    const orderActions = useOrderActions();
    const { isLoading } = useOrderData();

    const oKey = oKeyString ? parseInt(oKeyString) : 0;

    useEffect(() => {
        if (oKey) {
            orderActions.LoadOrderAsync(oKey);
        }
    }, [oKey, orderActions]);

    if (!oKey) {
        return <NotFound />
    }

    if (isLoading) {
        return <LoadingLinearProgress />
    }

    return <Routes>
        <Route path="" element={<OrderView oKey={oKey} />} />
        <Route path="pricebreakdown" element={<OrderLineItemPriceBreakdown />} />
        <Route path="*" element={<NotFound />} />
    </Routes>

};

export default OrderViewMain;