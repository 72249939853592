import { useMemo } from "react";
import { Autocomplete, TextField } from "@mui/material";

import useTranslations from "helpers/hooks/useTranslations";
import { IGlobalItem } from "helpers/interfaces";

interface IProps {
    globalItem: IGlobalItem;
    newValue: string,
    setNewValue: (newValue: string) => void,
}

const ListOptionValueInput: React.FC<IProps> = ({ globalItem, newValue, setNewValue }: IProps) => {

    const tm = useTranslations();

    const selectedNewOption = useMemo(() => {
        return globalItem.selectItems?.find((si) => si.value === newValue);
    }, [newValue, globalItem.selectItems]);

    return <>
        <Autocomplete
            autoComplete
            disableClearable
            fullWidth
            autoSelect
            autoHighlight
            selectOnFocus
            handleHomeEndKeys
            options={globalItem.selectItems}
            getOptionLabel={(si) => si.description}
            renderInput={(params) => (
                <TextField
                    {...params}
                    size="small"
                    label={tm.Get("Value")}
                    InputProps={{
                        ...params.InputProps,
                    }}
                />
            )}
            onChange={(_, v) => setNewValue(v?.value ?? "")}
            value={selectedNewOption}
        />
    </>;
};

export default ListOptionValueInput;

