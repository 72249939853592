import React from 'react';
import { Accordion, AccordionDetails, Grid, Typography } from "@mui/material"
import { useFormContext } from "react-hook-form";
import AccordionSummarySlim from "components/Common/AccordionSummarySlim";
import useTranslations from "helpers/hooks/useTranslations";
import SelectForm from "components/Common/SelectForm";
import useQuoteClassifications from "helpers/context/SelectionValues/useQuoteClassifications";
import { CustomerRefOptionEnum, PONumberOptionEnum } from "helpers/enums";
import { GetEnumAsListOfKeyValuePairs } from "helpers/objects";
import Constants from "helpers/constants";

interface IProps {
    readOnly: boolean
}

const CompanySettings: React.FC<IProps> = (props: IProps) => {

    const tm = useTranslations();
    const { getValues } = useFormContext();
    const appClassifications = useQuoteClassifications();

    const [isExpanded, setIsEpanded] = React.useState<boolean>(true);

    const getPONumberOptionString = (value: PONumberOptionEnum): string => {
        switch(value) {
            case PONumberOptionEnum.Default:
                return getValues('defaultParentPONumber');
            case PONumberOptionEnum.Order:
                return tm.Get("Order");
            case PONumberOptionEnum.PO:
                return tm.Get("PO");
            case PONumberOptionEnum.Blank:
                return tm.Get("Blank (Override)");
            default:
                throw new Error("Programming error: Invalid PO Number Option supplied");
        }
    }

    const getCustomerRefOptionString = (value: CustomerRefOptionEnum): string => {
        switch(value) {
            case CustomerRefOptionEnum.Default:
                return getValues('defaultParentCustomerRef')
            case CustomerRefOptionEnum.Customer:
                return tm.Get("Customer");
            case CustomerRefOptionEnum.OrderCustomer:
                return tm.Get("Order-Customer");
            case CustomerRefOptionEnum.CustomerRef:
                return tm.Get("Customer Ref");
            case CustomerRefOptionEnum.Blank:
                return tm.Get("Blank (Override)");
            default:
                throw new Error("Programming error: Invalid Customer Ref Option supplied");
        }
    }

    return <>
        <Accordion expanded={isExpanded} onChange={() => setIsEpanded((prev) => !prev)} disableGutters >
            <AccordionSummarySlim>
                <Typography>
                    {tm.Get("Settings")}
                </Typography>
            </AccordionSummarySlim>
            <AccordionDetails>
                <Grid container spacing={2} padding={0}>
                    <Grid item xs={12} sm={6}>
                        <SelectForm
                            fieldName={"parentPONumber"}
                            dataSource={GetEnumAsListOfKeyValuePairs(Object.values(PONumberOptionEnum))}
                            keyField="value"
                            valueField="value"
                            displayField="key"
                            translateDisplayField={false}
                            label={tm.Get("Default PO Number")}
                            readOnly={props.readOnly}
                            renderDisplayText={getPONumberOptionString}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SelectForm
                            fieldName={"parentCustomerRef"}
                            dataSource={GetEnumAsListOfKeyValuePairs(Object.values(CustomerRefOptionEnum))}
                            keyField="value"
                            valueField="value"
                            displayField="key"
                            translateDisplayField={false}
                            label={tm.Get("Default Customer Ref")}
                            readOnly={props.readOnly}
                            renderDisplayText={getCustomerRefOptionString}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SelectForm
                            fieldName="defaultClassification"
                            dataSource={appClassifications?.GetQuoteClassificationsBeforeUpload()}
                            translateDisplayField={(description: string) => Constants.Strings.Classifications.includes(description)}
                            keyField="classificationID"
                            valueField="classificationID"
                            displayField="description"
                            label={tm.Get("Default Quote Status")}
                            readOnly={props.readOnly}
                        />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    </>;
}

export default CompanySettings;