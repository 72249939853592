import useApplicationInfo from "helpers/context/Application/useApplicationInfo";
import Format from "helpers/fv.format";
import useTranslations from "helpers/hooks/useTranslations";
import React from "react";
import useLocaleNumberFormatter from "./useLocaleNumberFormatter";

export default function useFormatHelper():Format {

    const tm = useTranslations();
    const lnf = useLocaleNumberFormatter();
    const appInfo = useApplicationInfo();

    const fvformat = React.useMemo<Format>(() => {
        return new Format(appInfo.engineeringUnits, tm, lnf);
    }, [tm, appInfo.engineeringUnits, lnf])

    return fvformat;
    
}
