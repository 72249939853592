import { useTheme } from "@mui/material";
import { GridCallbackDetails, GridRowIdGetter, GridRowModel } from "@mui/x-data-grid-pro";
import CustomDataGridPro from "components/Common/CustomDataGridPro";
import DataGridColumnGenerator from "components/Common/DataGridColumnGenerator";
import useMessageBox from "helpers/context/Page/useMessageBox";
import useUserInfo from "helpers/context/User/useUserInfo";
import { RoleEnum } from "helpers/enums";
import useIsMobile from "helpers/hooks/useIsMobile";
import useRowsPerPage from "helpers/hooks/useRowsPerPage";
import useTranslations from "helpers/hooks/useTranslations";
import { IUserSummary } from "helpers/interfaces";
import React from 'react';
import { userCommandsColumn, userNameColumn } from "./UserColumns";

interface IProps {
    users: IUserSummary[] | null,
    showUserSite: boolean,
    isDealerCreatingAdminContractor: boolean,
    editUser: (employeeID: string) => void,
    deleteUser: (employeeID: string) => void
}

const UserTable: React.FC<IProps> = (props: IProps) => {

    const tm = useTranslations();
    const theme = useTheme();
    const isMobile = useIsMobile();
    const user = useUserInfo();
    const messageBox = useMessageBox();
    const rowsPerPage = useRowsPerPage("User");

    const generator = new DataGridColumnGenerator(tm, props.users, theme, isMobile);
    if ((props.users?.length ?? 0) > 0) {
        generator.AddColumn(userNameColumn("userName", "User Name", tm, isMobile, theme, messageBox));
        generator.TryAddTextColumn("firstName", "First Name", { hideInMobile: true });
        generator.TryAddTextColumn("lastName", "Last Name", { hideInMobile: true });

        if (props.showUserSite) {
            generator.TryAddTextColumn("site", "Mfg Site", { hideInMobile: true });
        }
        generator.AddColumn(userCommandsColumn(props.editUser, props.deleteUser, props.isDealerCreatingAdminContractor, user.HasRole(RoleEnum.Contractor)));
    }
    const onPageSizeChange = (pageSize: number, details: GridCallbackDetails) => rowsPerPage.setPageSize(pageSize);
    const getRowId: GridRowIdGetter = (row: GridRowModel) => row["employeeID"];

    return <>
        <CustomDataGridPro
            onPageSizeChange={onPageSizeChange}
            pageSize={rowsPerPage.pageSize}
            rowsPerPageOptions={rowsPerPage.pageSizeOptions}
            getRowId={getRowId}
            columns={generator.GetColumns()}
            rows={props.users}
        />
    </>;
}

export default UserTable;