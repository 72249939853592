import { Accordion, AccordionDetails, Grid, Typography } from "@mui/material";
import AccordionSummarySlim from "components/Common/AccordionSummarySlim";
import AutocompleteForm from "components/Common/AutocompleteForm";
import CheckBoxForm from "components/Common/CheckBoxForm";
import TextFieldForm from "components/Common/TextFieldForm";
import Constants from "helpers/constants";
import useShipVias from "helpers/context/SelectionValues/useShipVias";
import useTranslations from "helpers/hooks/useTranslations";
import React from 'react';
import { useFormContext } from "react-hook-form";

interface IProps {
    expanded: boolean,
    onExpandedChange: (event: React.SyntheticEvent, expanded: boolean) => void,
    readOnly: boolean,
    parentCustomerID: string
}

const CustomerShipping: React.FC<IProps> = (props: IProps) => {

    const tm = useTranslations();
    const { watch } = useFormContext();
    const shipViaList = useShipVias(props.parentCustomerID);
    const shippingSameAsBilling = watch("customer.useBillAsShipAddress");

    return <>
        <Accordion expanded={props.expanded} onChange={props.onExpandedChange} disableGutters >
            <AccordionSummarySlim>
                <Typography>
                    {tm.Get("Shipping Address")}
                </Typography>
            </AccordionSummarySlim>
            <AccordionDetails>
                <Grid container spacing={2} padding={0}>
                    <Grid item xs={12} sm={6}>
                        <CheckBoxForm
                            fieldName="customer.useBillAsShipAddress"
                            label={tm.Get("Same as billing address")}
                            id="customer.useBillAsShipAddress"
                            readOnly={props.readOnly}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AutocompleteForm
                            fieldName="customer.shipVia"
                            dataSource={shipViaList}
                            valueField="description"
                            defaultValue=""
                            displayField="description"
                            translateDisplayField={false}
                            label={tm.Get("Ship Via")}
                            readOnly={props.readOnly}
                        />
                    </Grid>
                    {!shippingSameAsBilling && <>
                        <Grid item xs={12} sm={6}>
                            <TextFieldForm
                                fieldName="customer.shippingInfo.address1"
                                readOnly={props.readOnly}
                                variant="standard"
                                id="customer.shipAddress1"
                                inputProps={{ maxLength: Constants.MaxLength.Address1 }}
                                label={tm.Get("Address 1")}
                                placeholder={tm.Get("Address 1")}
                                fullWidth
                                />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextFieldForm
                                fieldName="customer.shippingInfo.address2"
                                readOnly={props.readOnly}
                                variant="standard"
                                id="customer.shipAddress2"
                                inputProps={{ maxLength: Constants.MaxLength.Address2 }}
                                label={tm.Get("Address 2")}
                                placeholder={tm.Get("Address 2")}
                                fullWidth
                                />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextFieldForm
                                fieldName="customer.shippingInfo.city"
                                readOnly={props.readOnly}
                                variant="standard"
                                id="customer.shipCity"
                                inputProps={{ maxLength: Constants.MaxLength.City }}
                                label={tm.Get("City")}
                                placeholder={tm.Get("City")}
                                fullWidth
                                />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextFieldForm
                                fieldName="customer.shippingInfo.state"
                                readOnly={props.readOnly}
                                variant="standard"
                                id="customer.shipState"
                                inputProps={{ maxLength: Constants.MaxLength.State }}
                                label={tm.Get("State")}
                                placeholder={tm.Get("State")}
                                fullWidth
                                />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextFieldForm
                                fieldName="customer.shippingInfo.zipCode"
                                readOnly={props.readOnly}
                                variant="standard"
                                id="customer.shipZipCode"
                                inputProps={{ maxLength: Constants.MaxLength.ZipCode }}
                                label={tm.Get("Zip Code")}
                                placeholder={tm.Get("Zip Code")}
                                fullWidth
                                />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextFieldForm
                                fieldName="customer.shippingInfo.phoneNumber"
                                readOnly={props.readOnly}
                                variant="standard"
                                id="customer.shipPhoneNumber"
                                inputProps={{ maxLength: Constants.MaxLength.Phone }}
                                label={tm.Get("Phone")}
                                placeholder={tm.Get("Phone")}
                                fullWidth
                                />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextFieldForm
                                fieldName="customer.shippingInfo.faxNumber"
                                readOnly={props.readOnly}
                                variant="standard"
                                id="customer.shipFaxNumber"
                                inputProps={{ maxLength: Constants.MaxLength.Fax }}
                                label={tm.Get("Fax")}
                                placeholder={tm.Get("Fax")}
                                fullWidth
                                />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldForm
                                fieldName="customer.shippingInfo.emailAddress"
                                readOnly={props.readOnly}
                                variant="standard"
                                id="customer.shipEmailAddress"
                                inputProps={{ maxLength: Constants.MaxLength.EmailAddress }}
                                label={tm.Get("Email")}
                                placeholder={tm.Get("Email")}
                                fullWidth
                                />
                        </Grid>
                    </>}
                </Grid>
            </AccordionDetails>
        </Accordion>
    </>;
}

export default CustomerShipping;