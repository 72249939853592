import { InputAdornment } from '@mui/material';

import { useFormContext } from 'react-hook-form';
import useTranslations from 'helpers/hooks/useTranslations';
import useApplicationInfo from 'helpers/context/Application/useApplicationInfo';
import TextFieldForm from "components/Common/TextFieldForm";
import Constants from 'helpers/constants';
import { PricingMethodEnum } from 'helpers/enums';
import { IQuotePermissions } from "helpers/interfaces";
import { ResolvePath } from "helpers/objects";
import useLocaleNumberFormatter from "helpers/hooks/useLocaleNumberFormatter";
import { useEffectOnLoad } from "helpers/hooks/useEffectOnLoad";

interface IProps {
    pricingMethod: PricingMethodEnum,
    fieldName: string,
    permissions?: IQuotePermissions,
    permissionName: string,
    [x: string] : any    // this should always be the last prop, to support any other props that will be passed along down the component chain
}

function SelectPricingValue({ pricingMethod, fieldName, permissions, permissionName, ...rest }: IProps) 
{
    const tm = useTranslations();
    const lnf = useLocaleNumberFormatter({style: "decimal", useGrouping: true, minimumFractionDigits: 4, maximumFractionDigits: 4});

    const { setValue, watch, formState: { errors } } = useFormContext();

    const applicationInfo = useApplicationInfo();

    useEffectOnLoad(() => {
        const initialValue = watch(fieldName);
        if (initialValue !== null) {
            setValue(fieldName, lnf.Format(initialValue));
        }
    });    

    const isPricingMethodPercentBased = () : boolean => {
        switch (pricingMethod)
        {
            case PricingMethodEnum.Discount:
                return !applicationInfo.parameters.pricingShowDiscountMultiplierInsteadOfPercent;
            case PricingMethodEnum.Markup:
                return !applicationInfo.parameters.pricingShowMarkupMultiplierInsteadOfPercent;
            case PricingMethodEnum.Markdown:
                return true;
            case PricingMethodEnum.Margin:
                return true;
            default:
                return true;
        }
    }

    const getLabelText = (pricingMethod: PricingMethodEnum) : string => {
        switch (pricingMethod)
        {
            case PricingMethodEnum.Markup:
                return isPricingMethodPercentBased() ? "Markup" : "Markup Mult.";
            case PricingMethodEnum.Discount:
                return isPricingMethodPercentBased() ? "Discount" : "Discount Mult.";
            case PricingMethodEnum.Margin:
                return "Gross Profit Margin";
            case PricingMethodEnum.Markdown:
                return "Markdown";
            default:
                return "";
        }
    }

    const validateValue = (value: string) : string | boolean => {
        
        if (value)
        {
            const floatValue = lnf.Parse(value.toString());
            
            if (isNaN(floatValue))
            {
                const message = tm.Get("Must be a number.");
                return message;
            }
            else
            {
                // THOUGHT: maybe use something like https://github.com/s-yadav/react-number-format ? 
                let minValue : number = 0;
                let maxValue : number = 100;
                switch (pricingMethod)
                {
                    case PricingMethodEnum.Discount:
                        minValue = isPricingMethodPercentBased() ? Constants.Min.DiscountPercent : Constants.Min.DiscountMultiplier;
                        maxValue = isPricingMethodPercentBased() ? Constants.Max.DiscountPercent : Constants.Max.DiscountMultiplier;
                        break;
                    case PricingMethodEnum.Markup:
                        minValue = isPricingMethodPercentBased() ? Constants.Min.MarkupPercent : Constants.Min.MarkupMultiplier;
                        maxValue = isPricingMethodPercentBased() ? Constants.Max.MarkupPercent : Constants.Max.MarkupMultiplier;
                        break;
                    case PricingMethodEnum.Margin:
                        minValue = Constants.Min.MarginPercent;
                        maxValue = Constants.Max.MarginPercent;
                        break;
                    case PricingMethodEnum.Markdown:
                        minValue = Constants.Min.MarkdownPercent;
                        maxValue = Constants.Max.MarkdownPercent;
                        break;      
                }
                if (floatValue > maxValue || floatValue < minValue)
                {
                    const message = tm.GetWithParams("{0} must be between {1} and {2}.", getLabelText(pricingMethod), minValue.toString(), maxValue.toString());
                    return message;                    
                }
            }
        }

        return true;
    }

    function handleOnBlur(e : any) : void {
        if (e.target.value !== "")
        {
            let numericValue = lnf.Parse(e.target.value);
            setValue(fieldName, lnf.Format(numericValue));
        }
        else
        {
            setValue(fieldName, null);
        }
    }    

    const getValidationError = () : any => ResolvePath(fieldName, errors);

    const getIsEnabled = () : boolean => ResolvePath(permissionName, permissions) ?? false;

    return <>
        <TextFieldForm
            variant="standard"
            readOnly={false}
            id="orderDiscount"
            fieldName={fieldName}
            validateValue={validateValue}
            InputProps={{
                endAdornment: 
                isPricingMethodPercentBased() && <InputAdornment position="end">%</InputAdornment>
            }}
            label={tm.Get(getLabelText(pricingMethod))}
            placeholder={tm.Get(getLabelText(pricingMethod))}
            onBlur ={handleOnBlur}
            error={getValidationError() ? true : false}
            helperText={getValidationError()?.message}
            disabled={!getIsEnabled()}
            {...rest}
          />     
    </>

}

export default SelectPricingValue;


