import React from 'react';
import { Autocomplete, TextField, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import DialogTitleWithClose from "components/Dialog/DialogTitleWithClose";
import useTranslations from "../../helpers/hooks/useTranslations";
import { IMfgCustomer } from "helpers/interfaces";
import { useController, useFormContext } from "react-hook-form";
import OKCancelControl from "components/Common/OKCancelControl";
import { CaseInsensitiveCompare } from "helpers/objects";
import TextFieldForm from "components/Common/TextFieldForm";
import Constants from "helpers/constants";

interface IProps {
    isOpen: boolean,
    onClose: () => void,
    mfgSitesVisible: boolean,
    mfgSites: IMfgCustomer[] | null,
    customerNames: string[]
}

const AddCustomerDialog: React.FC<IProps> = ({isOpen, onClose, mfgSitesVisible, mfgSites, customerNames}: IProps) => {

    const tm = useTranslations();
    const {control, reset, formState: {errors}} = useFormContext();

    React.useEffect(() => {
        reset();
    }, [isOpen, reset]);

    const { field } = useController({
        name: "select-mfg-site", 
        control,
        rules: { required: {value: mfgSitesVisible, message: tm.GetWithParams("{0} cannot be blank.", tm.Get("Site ID"))} },
    });

    const selectedMfgSite = React.useMemo(() => {
        return mfgSites?.find((site) => field.value && site.siteID === field.value.siteID) ?? null
    }, [field, mfgSites]);

    return <>
        <Dialog fullWidth open={isOpen} onClose={onClose}>
            <DialogTitleWithClose onClose={onClose}>{tm.Get("Add Customer")}</DialogTitleWithClose>
            <DialogContent>
                {/*Only specify siteID if multiSite, otherwise default to parentSiteID*/}
                {mfgSitesVisible && mfgSites &&
                    <>
                        <DialogContentText>{tm.Get("Mfg Site")}</DialogContentText>
                        <Autocomplete
                            id="select-mfg-site"
                            autoComplete
                            autoSelect
                            autoHighlight
                            selectOnFocus
                            handleHomeEndKeys
                            options={mfgSites}
                            getOptionLabel={(option : IMfgCustomer) => option.siteName}
                            renderInput={(params) => <TextField
                                {...params}
                                error={errors["select-mfg-site"] !== undefined}
                                helperText={errors["select-mfg-site"] ? errors["select-mfg-site"]?.message : ""}
                            />}
                            sx={{marginBottom: 2}}
                            value={selectedMfgSite}
                            onChange={(_, data) => {field.onChange(data)}}
                            ref={field.ref}
                        />
                    </>
                }
                <TextFieldForm
                    size="small"
                    fieldName="customer-name"
                    readOnly={false}
                    id="customer-name"
                    requiredRule={{ value: true, message: tm.GetWithParams("{0} Required", tm.Get("Name")) }}
                    validateValue={{
                        isNotEmpty: (value: string) => new RegExp("^(?!\\s*$).+").test(value) || tm.GetWithParams("{0} cannot be blank.", tm.Get("Name")),
                        isNotTaken: (value: string) => !customerNames.find(name => CaseInsensitiveCompare(name, value.trim())) || tm.GetWithParams("{0} already exists.", tm.Get("Customer"))
                    }}
                    error={errors["customer-name"]?.message ? true : false}
                    helperText={errors["customer-name"]?.message}
                    fullWidth
                    inputProps={{ maxLength: Constants.MaxLength.CustomerName }}
                    label={tm.Get("Name")}
                    inputRef={input => input && input.focus()}
                    sx={{marginTop: 2}}
                />
            </DialogContent>
            <DialogActions>
                <OKCancelControl cancelCommand={onClose} okText={tm.Get("Add")} okButtonType={"submit"} okForm={"add-customer"}/>
            </DialogActions>
        </Dialog>
    </>;
}

export default AddCustomerDialog;