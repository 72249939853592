import React, { useCallback, useEffect, useState } from "react";
import { Dialog, DialogTitle } from "@mui/material";

import useWindowTitle from "helpers/context/Title/useWindowTitle";
import useMeasurementTypes from "helpers/context/SelectionValues/useMeaurementTypes";
import { PartDefaults } from "helpers/context/Parts/usePartDefaults";
import useTranslations from "helpers/hooks/useTranslations";
import useIsMobile from "helpers/hooks/useIsMobile";
import useQuoteData from "components/Quotes/useQuoteData";
import useWait from "helpers/context/Page/useWait";
import useActionButtons from "helpers/context/Page/useActionButtons";
import { IActionButton } from "helpers/context/Page/PageContext";
import { ThemeColorEnum } from "helpers/enums";
import ItemProperties, { IItemInformation, IItemProperties } from "components/OptionsWizard/ItemProperties/ItemProperties";
import { IPartKey } from "helpers/interfaces";
import ProductNavigator from "./ProductNavigator";
import PartsAPI from "helpers/PartsAPI";
import WizardAPI from "components/OptionsWizard/WizardAPI";
import useQuoteActions from "components/Quotes/useQuoteActions";
import { useNavigate } from "react-router-dom";
import QuoteNavigation from "components/Quotes/QuoteNavigation";


const ProductNavigatorWrapper: React.FC<any> = (props) => {

    const { quote, productNavigatorState } = useQuoteData();
    const oKey = quote?.oKey;
    const measurementTypes = useMeasurementTypes();
    const isMobile = useIsMobile();
    const wait = useWait();
    const actionButtons = useActionButtons();
    const quoteActions = useQuoteActions();
    const navigate = useNavigate();


    const [callSize, setCallSize] = useState<string>("");
    const [finalNode, setFinalNode] = useState<string | undefined>(undefined);
    const [selectedPartKey, setSelectedPartKey] = useState<IPartKey | null>(null);
    const [newLineItemDialogVisible, setNewLineItemDialogVisible] = useState<boolean>(false);
    const [newLineItemProperties, setNewLineItemProperties] = useState<IItemProperties | undefined>(undefined);
    const [newLineItemInformation, setNewLineItemInformation] = useState<IItemInformation | undefined>(undefined);

    const tm = useTranslations();
    useWindowTitle(tm.Get("Product Navigator"));

    const quoteMeasurementType = useCallback(() => {
        if (quote && measurementTypes) {
            return measurementTypes.find((m) => m.setID === quote.engineeringUnitSetID);
        }
    }, [quote, measurementTypes]);

    const onCancel = useCallback(() => {
        navigate(QuoteNavigation.QuoteEntryURL(quote?.oKey ?? 0));        
    }, [navigate, quote]);

    const onPartSelected = useCallback((partKey) => {
        setSelectedPartKey(partKey);
    }, []);

    const onFinalNodeChange = useCallback((nodeID) => {
        setFinalNode(nodeID);
    }, []);

    const onCallSizeChange = useCallback((callSize) => {
        setCallSize(callSize);
    }, []);

    useEffect(() => {
        if (selectedPartKey) {
            const partDefPromise = PartsAPI.GetPartDefaults(selectedPartKey.masterPartNo, selectedPartKey.partNoSuffix, selectedPartKey.shortcutName)
            const callSizesPromise = PartsAPI.GetPartCallSizes(selectedPartKey.masterPartNo, selectedPartKey.partNoSuffix)

            Promise.all([partDefPromise, callSizesPromise]).then(([partDefaults, callSizes]) => {

                const newLineItemInfo: IItemInformation = {
                    callSizes: callSizes,
                    defaults: new PartDefaults(partDefaults),
                    existingItem: false,
                    isSubLineItem: false,
                }
                var callSizeObject = callSizes.callSizes.find(e => e.callSize === callSize);
                const newLineItemProps: IItemProperties = {
                    callSize: callSize,
                    comment: newLineItemInfo.defaults.Defaults?.comment ?? "",
                    height: (callSize === "" ? newLineItemInfo.defaults.Defaults?.height : callSizeObject?.height) ?? 0,
                    qty: newLineItemInfo.defaults.Defaults?.quantity ?? 1,
                    thickness: newLineItemInfo.defaults.Defaults?.thickness ?? 0,
                    width: (callSize === "" ? newLineItemInfo.defaults.Defaults?.width : callSizeObject?.width) ?? 0,
                }

                setNewLineItemInformation(newLineItemInfo);
                setNewLineItemProperties(newLineItemProps);
                setNewLineItemDialogVisible(true);
            });
        }
    }, [selectedPartKey, callSize]);

    const handleAddNewLineItemDialogResult = useCallback((result: boolean, newState?: IItemProperties) => {
        setNewLineItemDialogVisible(false);

        if (result && quote && selectedPartKey) {

            const submitParams = {
                OKey: quote?.oKey,
                PartNo: selectedPartKey.masterPartNo,
                PartNoSuffix: selectedPartKey.partNoSuffix,
                ShortcutName: selectedPartKey.shortcutName,
                Quantity: newState?.qty,
                Width: newState?.width,
                Height: newState?.height,
                Thickness: newState?.thickness,
                CallSize: newState?.callSize,
                Comment: newState?.comment,
            };

            wait.Show(true);

            WizardAPI.InitNewLineItemAsync(submitParams).then(() => {
                quoteActions?.SetProductNavigatorState({ cameFromProductNavigator: true, finalNodeID: finalNode ?? "", callSize: callSize });
                navigate(QuoteNavigation.OptionsWizardURL(oKey ?? 0, 0));
            }).finally(() => wait.Show(false));

        }

        setSelectedPartKey(null);

    }, [quote, selectedPartKey, quoteActions, wait, finalNode, callSize, setNewLineItemDialogVisible, navigate, oKey]);

    useEffect(() => {
        const cancelButton: IActionButton = {
            text: tm.Get("Cancel"),
            color: ThemeColorEnum.Primary,
            onClick: onCancel
        };
        actionButtons.Set(0, cancelButton);

    }, [oKey, actionButtons, tm, onCancel]);

    const addNewLineItemDialog = useCallback(() => {

        const measurementType = quoteMeasurementType();

        if (newLineItemProperties && newLineItemInformation && measurementType) {
            return <>
                <Dialog
                    open={newLineItemDialogVisible}
                    onClose={() => handleAddNewLineItemDialogResult(false)}
                    fullScreen={isMobile}
                >
                    <DialogTitle>{tm.Get("Item Properties")}</DialogTitle>
                    <ItemProperties
                        newItem={true}
                        initialState={newLineItemProperties}
                        itemInfo={newLineItemInformation}
                        measurementType={measurementType}
                        onCancel={() => handleAddNewLineItemDialogResult(false)}
                        onSubmit={(_, newState) => handleAddNewLineItemDialogResult(true, newState)}
                    />
                </Dialog>
            </>;
        }

    }, [newLineItemDialogVisible, newLineItemProperties, newLineItemInformation, isMobile, quoteMeasurementType, handleAddNewLineItemDialogResult, tm])

    if (!oKey) {
        return null;
    }

    return <>
        <ProductNavigator
            oKey={oKey}
            initialFinalNodeID={productNavigatorState.finalNodeID}
            initialCallSize={productNavigatorState.callSize}
            partList={null}
            onCancel={onCancel}
            onPartSelected={onPartSelected}
            onFinalNodeChange={onFinalNodeChange}
            onCallSizeChange={onCallSizeChange}
        />
        {addNewLineItemDialog()}
    </>
}

export default ProductNavigatorWrapper;