import React from 'react';
import { Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import useTranslations from "../../helpers/hooks/useTranslations";
import useWindowTitle from "helpers/context/Title/useWindowTitle";
import { useFormContext } from "react-hook-form";
import { useEffectOnLoad } from "helpers/hooks/useEffectOnLoad";
import BoxWithHeader from "components/Common/BoxWithHeader";
import Constants from "helpers/constants";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const ChangePassword: React.FC = () => {

    const tm = useTranslations();
    const { register, reset, formState: { errors } } = useFormContext();
    useWindowTitle(tm.Get("Change Password"));

    const [password, setPassword] = React.useState<string>("");
    const [showPassword, setShowPassword] = React.useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState<boolean>(false);
    const [focused, setFocused] = React.useState<boolean>(false);

    const validation = register("confirmPassword", {
        validate: {
            isNotEmpty: (value: string) => new RegExp("^(?!\\s*$).+").test(value) || tm.GetWithParams("{0} cannot be blank.", tm.Get("New Password")),
            passwordsEqual: (value: string) => value === password || tm.Get("New Passwords must match, try again!")
        },
    });

    useEffectOnLoad(() => {
        reset();
    });

    const handleMousePassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    }

    return <BoxWithHeader caption={tm.Get("Change Password")} sx={{ mt: 2 }}>
        <Grid container padding={1} spacing={2}>
            <Grid item xs={12}>
                <TextField
                    id="password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    inputProps={{ maxLength: Constants.MaxLength.Password }}
                    variant="standard"
                    label={tm.Get("New Password")}
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={(e) => setShowPassword(prev => !prev)}
                                    onMouseDown={handleMousePassword}
                                    onMouseUp={handleMousePassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                    }}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    {...validation}
                    id="confirmPassword"
                    type={showConfirmPassword ? "text" : "password"}
                    error={errors[validation.name] && !focused}
                    helperText={errors[validation.name] && !focused ? errors[validation.name]?.message : ""}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    inputProps={{ maxLength: Constants.MaxLength.Password }}
                    variant="standard"
                    label={tm.Get("Confirm New Password")}
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={(e) => setShowConfirmPassword(prev => !prev)}
                                    onMouseDown={handleMousePassword}
                                    onMouseUp={handleMousePassword}
                                    edge="end"
                                >
                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                    }}
                    fullWidth
                />
            </Grid>
        </Grid>
    </BoxWithHeader>;
}
export default ChangePassword;