import { DateTime } from "luxon";
import DoneIcon from '@mui/icons-material/Done';
import { GridColumns, GridEnrichedColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { ITranslationManager } from "helpers/hooks/useTranslations";
import { Grid, Link, Stack, Theme, Tooltip, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { getIconClassName } from '@uifabric/styling';
import { ICurrencyFormatter } from "helpers/hooks/useCurrencyFormatter";
import CustomerInactiveIcon from "components/Customers/CustomerInactiveIcon";

type TryAddTooltipParams = { tooltip: string | null, tooltipParams?: string[] | null }
type TryAddOptionalParams = { hideInMobile?: boolean, hideable?: boolean, flex?: number };

export default class DataGridColumnGenerator {

  #tm: ITranslationManager;
  #cf: ICurrencyFormatter | undefined;
  #data: any;
  #theme: Theme;
  #isMobile: boolean;

  private _columns: GridEnrichedColDef[] = [];

  #minWidth: number;

  public constructor(tm: ITranslationManager, data: any[] | null | undefined, theme: Theme, isMobile: boolean, cf: ICurrencyFormatter | undefined = undefined) {
    this.#tm = tm;
    this.#cf = cf;
    this.#theme = theme;
    this.#isMobile = isMobile;
    this.#minWidth = isMobile ? 90 : 130;

    if (data && data.length > 0) {
      this.#data = data[0];
    }

  }

  public TryAddStatusColumn = () => {

    if (!this.#data || !this.#data.hasOwnProperty("statusText"))
      return;

    const col = {
      field: "statusText",
      minWidth: 130,
      align: "left",
      headerAlign: "left",
      headerName: this.#tm.Get("Status"),
      renderCell: (params: GridRenderCellParams<any, IStatusProperties, any>) => {



        const statusText = params.row.statusText;
        const statusTooltip = params.row.statusTooltip;
        const statusColor = params.row.statusColor;

        return <Tooltip title={statusTooltip}>
          <Typography variant="body2" style={{ color: statusColor }} >
            {statusText}
          </Typography>
        </Tooltip>


      }
    } as GridEnrichedColDef<IStatusProperties>;

    this._columns.push(col);

  }

  public TryAddRenderCellColumn = (fieldName: string, title: string, renderCell: (params: GridRenderCellParams) => JSX.Element, { hideInMobile, hideable, flex }: TryAddOptionalParams) => {

    const hide = (this.#isMobile && hideInMobile);

    if (!this.#data || !this.#data.hasOwnProperty(fieldName))
      return;

    const col = {
      field: fieldName,
      headerName: this.#tm.Get(title),
      minWidth: this.#minWidth,
      flex: flex ?? 1,
      hide,
      hideable,
      renderCell: renderCell,
    } as GridEnrichedColDef;

    this._columns.push(col);

  };

  public TryAddDocumentNumberColumn = (keyFieldName: string, numberFieldName: string, title: string, uri: string, { hideInMobile, hideable, flex }: TryAddOptionalParams) => {

    const hide = (this.#isMobile && hideInMobile);

    if (!this.#data || !this.#data.hasOwnProperty(numberFieldName))
      return;

    const col = {
      field: numberFieldName,
      headerName: this.#tm.Get(title),
      minWidth: this.#minWidth,
      flex: flex ?? 1,
      hide,
      hideable,
      renderCell: (params: GridRenderCellParams) => {
        const oKey = params.row[keyFieldName];
        return <>
          <Link variant="body2" underline="hover" component={RouterLink} to={{ pathname: `${uri}`, search: `oKey=${oKey}` }} state={{ fromOrder: true }}  >
            {params.value}
          </Link>
        </>;

      }
    } as GridEnrichedColDef;

    this._columns.push(col);

  };

  public TryAddTextColumnWithTooltip = (fieldName: string, title: string, { tooltip, tooltipParams }: TryAddTooltipParams, { hideInMobile, hideable, flex }: TryAddOptionalParams) => {
    const hide = (this.#isMobile && hideInMobile);

    if (!this.#data || !this.#data.hasOwnProperty(fieldName))
      return;

    const col = {
      field: fieldName,
      minWidth: this.#minWidth,
      headerName: this.#tm.Get(title),
      headerAlign: "left",
      align: "left",
      flex: flex ?? 1,
      hide,
      hideable,
      renderCell: (params: GridRenderCellParams) => {

        const translated = this.translateTooltip(this.#tm, params, tooltip, tooltipParams);

        return <Tooltip title={translated}>
          <Typography variant="body2">
            {params.value}
          </Typography>
        </Tooltip>

      },
    } as GridEnrichedColDef

    this._columns.push(col);

  }
  public TryAddTranslatedTextColumn = (fieldName: string, title: string, { hideInMobile, hideable, flex }: TryAddOptionalParams) => {

    const hide = (this.#isMobile && hideInMobile);

    if (!this.#data || !this.#data.hasOwnProperty(fieldName))
      return;

    const col = {
      field: fieldName,
      minWidth: this.#minWidth,
      headerName: this.#tm.Get(title),
      flex: flex ?? 1,
      hide,
      hideable,
      renderCell: (params: GridRenderCellParams) => {
        return <>
          <Typography variant="body2">
            {this.#tm.Get(params.value as string)}
          </Typography>
        </>;
      }

    } as GridEnrichedColDef

    this._columns.push(col)

  }

  public TryAddTextColumn = (fieldName: string, title: string, { hideInMobile, hideable, flex }: TryAddOptionalParams) => {

    this.TryAddFormattedTextColumn(fieldName, title, undefined, { hideInMobile, hideable, flex });

  }

  public TryAddFormattedTextColumn = (fieldName: string, title: string, formatText: undefined | ((value: any) => string), { hideInMobile, hideable, flex }: TryAddOptionalParams) => {

    const hide = (this.#isMobile && hideInMobile);

    if (!this.#data || !this.#data.hasOwnProperty(fieldName))
      return;

    const col = {
      field: fieldName,
      minWidth: this.#minWidth,
      headerName: this.#tm.Get(title),
      flex: flex ?? 1,
      hide,
      hideable,
      renderCell: (params: GridRenderCellParams) => {
        if (formatText) {
          return <>
            <Typography variant="body2">
              {formatText(params.value)}
            </Typography>
          </>;
        }
        return <>
          <Typography variant="body2">
            {params.value}
          </Typography>
        </>;
      },
    } as GridEnrichedColDef

    this._columns.push(col)

  }

  public TryAddDateColumn = (fieldName: string, title: string, { hideInMobile, hideable, flex }: TryAddOptionalParams) => {
    this.TryAddDateTimeColumn(fieldName, title, DateTime.DATE_SHORT, { hideInMobile, hideable, flex });
  }

  public TryAddDateTimeColumn = (fieldName: string, title: string, dateFormat: Intl.DateTimeFormatOptions, { hideInMobile, hideable, flex }: TryAddOptionalParams) => {
    const hide = (this.#isMobile && hideInMobile);
    if (!this.#data || !this.#data.hasOwnProperty(fieldName))
      return;

    const col = {
      field: fieldName,
      headerName: this.#tm.Get(title),
      minWidth: this.#minWidth,
      flex: flex ?? 1,
      hide,
      hideable,
      renderCell: (params: GridRenderCellParams) => {

        if (!params.value)
          return <></>;

        var dt = DateTime.fromISO(params.value as string);
        var dtNull = DateTime.fromISO("1900-01-01T00:00:00");
        if (dt.startOf("day").toMillis() === dtNull.startOf("day").toMillis()) {
          return <></>;
        }
        return <>
          <Typography variant="body2">
            {dt.setLocale(navigator.language).toLocaleString(dateFormat)}
          </Typography>
        </>;

      },
    } as GridEnrichedColDef

    this._columns.push(col);

  }

  public TryAddCheckColumn = (fieldName: string, title: string, { tooltip, tooltipParams }: TryAddTooltipParams, { hideInMobile, hideable, flex }: TryAddOptionalParams) => {
    const hide = (this.#isMobile && hideInMobile);
    if (!this.#data || !this.#data.hasOwnProperty(fieldName))
      return;

    const col = {
      field: fieldName,
      minWidth: this.#minWidth,
      headerName: this.#tm.Get(title),
      flex: flex ?? 1,
      headerAlign: 'center',
      align: 'center',
      hide,
      hideable,
      renderCell: (params: GridRenderCellParams) => {
        if (params && params.value)
          if (tooltip) {

            const translated = this.translateTooltip(this.#tm, params, tooltip, tooltipParams);

            return <Tooltip title={translated}>
              <DoneIcon style={{ color: this.#theme.palette.success.main }} />
            </Tooltip>
          } else {
            return <DoneIcon style={{ color: this.#theme.palette.success.main }} />;
          }
        else
          return <></>;
      },
    } as GridEnrichedColDef

    this._columns.push(col);

  };

  public TryAddNumericColumn = (fieldName: string, title: string, formatNumber: undefined | ((value: number) => string), { hideInMobile, hideable, flex }: TryAddOptionalParams) => {

    const hide = (this.#isMobile && hideInMobile);

    if (!this.#data || !this.#data.hasOwnProperty(fieldName))
      return;

    const col = {
      field: fieldName,
      minWidth: this.#minWidth,
      headerName: this.#tm.Get(title),
      flex: flex ?? 1,
      hide,
      hideable,
      headerAlign: "right",
      align: "right",
      renderCell: (params: GridRenderCellParams) => {

        if (formatNumber) {
          return <>
            <Typography variant="body2">
              {formatNumber(params.value)}
            </Typography>
          </>;
        }
        return <>
          <Typography variant="body2">
            {params.value}
          </Typography>
        </>;
      },
    } as GridEnrichedColDef

    this._columns.push(col)

  }

  public TryAddHyperlinkColumn = (fieldName: string, title: string, uri: string | ((row: any) => string), getParams: ((row: any) => any) | null, { hideInMobile, hideable, flex }: TryAddOptionalParams) => {

    const hide = (this.#isMobile && hideInMobile);

    if (!this.#data || !this.#data.hasOwnProperty(fieldName))
      return;

    const getSearch = (params: any) => {
      return new URLSearchParams(params).toString()
    }

    const getUri = (row: any) => {
      if (typeof uri === 'function') {
        return uri(row);
      }
      return uri;
    }

    const col = {
      field: fieldName,
      headerName: this.#tm.Get(title),
      minWidth: this.#minWidth,
      flex: flex ?? 1,
      hide,
      hideable,
      renderCell: (params: GridRenderCellParams) => {
        const uri = getUri(params.row);
        if (uri && uri !== "") {
          return <>
            <Link variant="body2" underline="hover" component={RouterLink} to={{ pathname: uri, search: getParams ? getSearch(getParams(params.row)) : "" }} >
              {params.value}
            </Link>
          </>;
        }
        else {
          return <>
            {params.value}
          </>
        }


      }
    } as GridEnrichedColDef;

    this._columns.push(col);

  };

  public TryAddLinkButtonColumn = (fieldName: string, title: string, onClick: (row: any) => void, { hideInMobile, hideable, flex }: TryAddOptionalParams) => {

    const hide = (this.#isMobile && hideInMobile);

    if (!this.#data || !this.#data.hasOwnProperty(fieldName))
      return;

    const col = {
      field: fieldName,
      headerName: this.#tm.Get(title),
      minWidth: this.#minWidth,
      flex: flex ?? 1,
      hide,
      hideable,
      renderCell: (params: GridRenderCellParams) => {
        return <>
          <Link variant="body2" component="button" underline="hover" onClick={() => onClick(params.row)} >
            {params.value}
          </Link>
        </>;

      }
    } as GridEnrichedColDef;

    this._columns.push(col);

  };

  public TryAddFileLinkColumn = (fieldName: string, title: string, uri: string | ((row: any) => string), getParams: (row: any) => any, { hideInMobile, hideable, flex }: TryAddOptionalParams) => {

    const hide = (this.#isMobile && hideInMobile);

    if (!this.#data || !this.#data.hasOwnProperty(fieldName))
      return;

    const getSearch = (params: any) => {
      return new URLSearchParams(params).toString()
    }

    const getUri = (row: any) => {
      if (typeof uri === 'function') {
        return uri(row);
      }
      return uri;
    }

    const getExtension = (fileName: string) => {
      if (fileName.includes(".")) {
        return fileName.substring(fileName.lastIndexOf(".") + 1);
      }
      return "";
    }

    const getIconClassNameForItem = (row: any) => {

      const fileName = row[fieldName];
      const extension = getExtension(fileName);
      let className = "";

      switch (extension) {
        case "xls":
        case "xlsx":
          className = "ExcelDocument";
          break;
        case "doc":
        case "docx":
          className = "WordDocument";
          break;
        case "pdf":
          className = "PDF";
          break;
        case "png":
        case "jpg":
        case "gif":
        case "bmp":
          className = "FileImage";
          break;
        case "vis":
        case "visx":
          className = "VisioDocument";
          break;
        case "ppt":
        case "pptx":
          className = "PowerPointDocument";
          break;
        case "txt":
          className = "TextDocument";
          break;
        default:
          className = "Document";
      }

      return getIconClassName(className);
    }

    const col = {
      field: fieldName,
      headerName: this.#tm.Get(title),
      minWidth: this.#minWidth,
      flex: flex ?? 1,
      hide,
      hideable,
      renderCell: (params: GridRenderCellParams) => {
        return <>
          <Grid container columnGap={1} display="flex" flexDirection="row" flexWrap="nowrap">
            <Grid item>
              <i className={getIconClassNameForItem(params.row)} />
            </Grid>
            <Grid item xs>
              <Link download variant="body2" underline="hover" href={`${getUri(params.row)}?${getSearch(getParams(params.row))}`} >
                {params.value}
              </Link>
            </Grid>
          </Grid>
        </>;

      }
    } as GridEnrichedColDef;

    this._columns.push(col);

  };

  public AddHoldStatusColumn = ({ hideInMobile, hideable, flex }: TryAddOptionalParams) => {

    if (!this.#data || !this.#data.hasOwnProperty('holdStatus'))
      return;

    const hide = (this.#isMobile && hideInMobile);
    const col = {
      field: 'holdStatus',
      minWidth: this.#minWidth,
      headerName: this.#tm.Get('On Hold'),
      headerAlign: 'center',
      align: 'center',
      hide,
      hideable,
      renderCell: (params) => {

        switch (params.value) {
          case -1:
            return <DoneIcon style={{ color: this.#theme.palette.warning.main }} />
          case 0:
            return <DoneIcon style={{ color: this.#theme.palette.error.main }} />
          default:
            return <></>
        }

      },
      flex: flex ?? 1,
    } as GridEnrichedColDef

    this._columns.push(col);
  }

  public TryAddTotalPriceColumn = ({ hideInMobile, hideable, flex }: TryAddOptionalParams) => {

    const hide = (this.#isMobile && hideInMobile);

    if (!this.#data || !this.#data.hasOwnProperty("totalPrice"))
      return;

    const col = {
      field: 'totalPrice',
      minWidth: this.GetMinWidth(),
      headerName: this.#tm.Get('Total'),
      headerAlign: 'right',
      align: 'right',
      hide,
      hideable,
      renderCell: (params: GridRenderCellParams) => {
        return (params.value !== undefined && this.#cf !== undefined && <Typography variant="body2">{this.#cf.FormatToCulture(params.value, params.row.currencyCulture)}</Typography>)
      },
      flex: flex ?? 1,

    } as GridEnrichedColDef;

    this._columns.push(col);

  };

  public TryAddWebCustomerColumn = (fieldName: string, inactiveFieldName: string, title: string, { hideInMobile, hideable, flex }: TryAddOptionalParams) => {

    const hide = (this.#isMobile && hideInMobile);

    if (!this.#data || !this.#data.hasOwnProperty(inactiveFieldName))
      return;

    const col = {
      field: fieldName,
      headerName: this.#tm.Get(title),
      minWidth: this.#minWidth,
      flex: flex ?? 1,
      hide,
      hideable,
      renderCell: (params: GridRenderCellParams) => {
        const inactive = params.row[inactiveFieldName];
        return <>
          <Stack direction="row" alignItems={"center"}>
            <Typography variant="body2" color={inactive ? "error" : undefined}>
              {params.value}
            </Typography>
            {inactive &&
              <CustomerInactiveIcon name={params.value} />
            }
          </Stack>
        </>;

      }
    } as GridEnrichedColDef;

    this._columns.push(col);

  };

  public AddColumn = (col: GridEnrichedColDef) => {
    this._columns.push(col);
  }

  public GetMinWidth(): number {
    return this.#minWidth;
  }

  public GetColumns(): GridColumns {
    return this._columns;
  }

  private translateTooltip = (tm: ITranslationManager, params: GridRenderCellParams, tooltip: string | null, tooltipParams?: string[] | null) => {

    if (!tooltip) return "";

    let translated: string
    if (tooltipParams) {
      const toolTipValues = tooltipParams.map(p => params.getValue(params.id, p)?.toString() ?? "");
      translated = tm.GetWithParams(tooltip, ...toolTipValues);
    }
    else
      translated = tm.Get(tooltip);

    return translated;
  };


}

export interface IStatusProperties {
  statusText: string,
  statusColor: string,
  statusTooltip: string,
}

export function AddStatusColumnData<T>(order: T, fallbackStatus: string, theme: Theme, tm: ITranslationManager) {
  /*If the field doesn't exist in the dataset, 'undefined' is returned, which is falsey */

  const pickedUpQty = (order as any)["pickedUpQty"];
  const receivedQty = (order as any)["receivedQty"];
  const totalQty = (order as any)["totalQty"] as number;

  const completed = (order as any)['complete'];
  const shipped = (order as any)['shipped'];
  const inproduction = (order as any)["inProduction"];
  const inProcess = (order as any)["inProcess"];
  const onHold = (order as any)["onHold"];
  let holdStatus = parseInt((order as any)["holdStatus"] as string);


  let statusText: string = "";
  let statusTooltip: string = "";
  let statusColor = theme.palette.text.primary;

  if (holdStatus <= 0) {
    statusText = tm.Get("On Hold");
    if (holdStatus === 0) {
      statusColor = theme.palette.error.main;
    } else {
      statusTooltip = tm.Get("Approval Required");
      statusColor = theme.palette.warning.main;
    }
  } else if (onHold) {
    statusText = tm.Get("On Hold");
    statusColor = theme.palette.warning.main;
  } else if (completed) {
    statusText = tm.Get("Complete");
  }
  else if (receivedQty) {
    statusText = tm.Get("Received");
    statusTooltip = tm.GetWithParams("{0} of {1} received", receivedQty.toString(), totalQty.toString());
  }
  else if (pickedUpQty) {
    statusText = tm.Get("Picked Up");
    statusTooltip = tm.GetWithParams("{0} of {1} picked up", pickedUpQty.toString(), totalQty.toString());
  }
  else if (shipped) {
    switch (parseInt(shipped as string)) {
      case 1:
        statusText = tm.Get("Partially Shipped");
        break;
      case 2:
        statusText = tm.Get("Shipped");
        break;
    }
  }

  else if (inproduction)
    statusText = tm.Get("In Production");
  else if (inProcess)
    statusText = tm.Get("In Process");

  else
    statusText = tm.Get(fallbackStatus);


  let statusOrder: T & IStatusProperties = { ...order, statusText, statusTooltip, statusColor };
  return statusOrder;

}

