import { useEffect, useState, useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Grid, IconButton, Tooltip, Dialog, DialogTitle } from "@mui/material";
import { Explore } from "@mui/icons-material";

import SelectCategory from "components/Quotes/QuoteEntry/AddLineItem/SelectCategory";
import SelectPart from "components/Quotes/QuoteEntry/AddLineItem/SelectPart";

import { IPartListItem, ICategoryListItem } from "helpers/interfaces";
import { FindAllNestedProps } from "helpers/objects";
import useTranslations from "helpers/hooks/useTranslations";
import useMessageBox from "helpers/context/Page/useMessageBox";
import useIsMobile from "helpers/hooks/useIsMobile";
import useApplicationInfo from "helpers/context/Application/useApplicationInfo";
import ItemProperties, { IItemInformation, IItemProperties } from "components/OptionsWizard/ItemProperties/ItemProperties";
import useMeasurementTypes from "helpers/context/SelectionValues/useMeaurementTypes";
import { PartDefaults } from "helpers/context/Parts/usePartDefaults";
import PartsAPI from "helpers/PartsAPI";
import WizardAPI from "components/OptionsWizard/WizardAPI";
import useQuoteData from "components/Quotes/useQuoteData";
import useQuoteActions from "components/Quotes/useQuoteActions";
import useWait from "helpers/context/Page/useWait";
import { MeasurementSystemEnum } from "helpers/fv.format";
import Constants from "helpers/constants";
import { useNavigate } from "react-router-dom";
import QuoteNavigation from "components/Quotes/QuoteNavigation";
import { ThemeColorEnum } from "helpers/enums";


interface ISelections {
    category: ICategoryListItem | null,
    part: IPartListItem | null,
}

const DefaultSelections: ISelections = { category: null, part: null };

const AddLineItem: React.FC = () => {
    const tm = useTranslations();
    const messageBox = useMessageBox();
    const isMobile = useIsMobile();
    const applicationInfo = useApplicationInfo();
    const measurementTypes = useMeasurementTypes()
    const wait = useWait();
    const quoteActions = useQuoteActions();
    const navigate = useNavigate();

    const { quote, defaultCategoryID, defaultPart } = useQuoteData();
    const [newLineItemDialogVisible, setNewLineItemDialogVisible] = useState<boolean>(false);
    const [newLineItemProperties, setNewLineItemProperties] = useState<IItemProperties | undefined>(undefined);
    const [newLineItemInformation, setNewLineItemInformation] = useState<IItemInformation | undefined>(undefined);

    const formMethods = useForm({ mode: "onChange", defaultValues: DefaultSelections });
    const { handleSubmit, setValue, reset, watch } = formMethods;

    const selectedCategory = watch("category");
    const selectedPart = watch("part")

    useEffect(() => {
        const defaults: ISelections = {
            category: (defaultCategoryID === undefined ? null : { categoryID: defaultCategoryID, description: "" }),
            part: (defaultPart === undefined ? null : { partKey: defaultPart, description: "" }),
        };
        reset(defaults);
    }, [defaultCategoryID, defaultPart, reset]);

    const onCategoryChange = useCallback((category: ICategoryListItem | null) => {
        if (!category || category.categoryID !== defaultCategoryID) {
            setValue("part", null);
        }

    }, [defaultCategoryID, setValue]);

    const convertSystemMeasurementTypeToQuoteMeasurementType = useCallback((value: number) => {
        if (applicationInfo.engineeringUnits.systemUnitSet !== quote?.engineeringUnitSetID) {
            if (applicationInfo.engineeringUnits.systemUnitSet === MeasurementSystemEnum.Imperial) {
                return value * Constants.ConversionFactors.InchesToMillimeters;
            } else {
                return value / Constants.ConversionFactors.InchesToMillimeters;
            }
        }
        return value;
    }, [applicationInfo, quote]);

    const onSubmit = (formData: ISelections) => {
        if (formData?.part) {
            const partDefPromise = PartsAPI.GetPartDefaults(formData.part.partKey.masterPartNo, formData.part.partKey.partNoSuffix, formData.part.partKey.shortcutName)
            const callSizesPromise = PartsAPI.GetPartCallSizes(formData.part.partKey.masterPartNo, formData.part.partKey.partNoSuffix)

            Promise.all([partDefPromise, callSizesPromise]).then(([partDefaults, callSizes]) => {
                const newLineItemInfo: IItemInformation = {
                    callSizes: callSizes,
                    defaults: new PartDefaults(partDefaults),
                    existingItem: false,
                    isSubLineItem: false,
                }
                const newLineItemProps: IItemProperties = {
                    callSize: newLineItemInfo.defaults.Defaults?.callSize ?? "",
                    comment: newLineItemInfo.defaults.Defaults?.comment ?? "",
                    height: convertSystemMeasurementTypeToQuoteMeasurementType(newLineItemInfo.defaults.Defaults?.height ?? 0),
                    qty: newLineItemInfo.defaults.Defaults?.quantity ?? 1,
                    thickness: convertSystemMeasurementTypeToQuoteMeasurementType(newLineItemInfo.defaults.Defaults?.thickness ?? 0),
                    width: convertSystemMeasurementTypeToQuoteMeasurementType(newLineItemInfo.defaults.Defaults?.width ?? 0),
                }
                setNewLineItemInformation(newLineItemInfo);
                setNewLineItemProperties(newLineItemProps);
                setNewLineItemDialogVisible(true);
            });
        }
    };

    const onError = (errors: { [x: string]: any }) => {
        const messages = FindAllNestedProps(errors, "message");
        const formattedMessage = messages.join("\n");

        if (formattedMessage) {
            messageBox.Show({ message: formattedMessage, title: tm.Get("Please correct before saving.") });
        }
    }

    const handleProductNavClick = useCallback(() => {
        if (quote) {
            quoteActions.SetProductNavigatorState({ cameFromProductNavigator: false, callSize: "", finalNodeID: "" });
            navigate(QuoteNavigation.ProductNavigatorURL(quote?.oKey ?? 0));
        }
    }, [quote, quoteActions, navigate]);

    const quoteMeasurementType = useCallback(() => {
        if (quote && measurementTypes) {
            return measurementTypes.find((m) => m.setID === quote.engineeringUnitSetID);
        }
    }, [quote, measurementTypes]);

    const handleAddNewLineItemDialogResult = useCallback((result: boolean, newState?: IItemProperties) => {
        setNewLineItemDialogVisible(false);

        if (result && quote && selectedPart) {

            const submitParams = {
                OKey: quote?.oKey,
                PartNo: selectedPart?.partKey.masterPartNo,
                PartNoSuffix: selectedPart?.partKey.partNoSuffix,
                ShortcutName: selectedPart?.partKey.shortcutName,
                Quantity: newState?.qty,
                Width: newState?.width,
                Height: newState?.height,
                Thickness: newState?.thickness,
                CallSize: newState?.callSize,
                Comment: newState?.comment,
            };

            wait.Show(true);

            WizardAPI.InitNewLineItemAsync(submitParams).then(() => {
                navigate(QuoteNavigation.OptionsWizardURL(quote?.oKey ?? 0, 0));
            }).finally(() => wait.Show(false));

        }

    }, [setNewLineItemDialogVisible, quote, selectedPart, wait, navigate]);

    const addNewLineItemDialog = useCallback(() => {

        const measurementType = quoteMeasurementType();

        if (newLineItemProperties && newLineItemInformation && measurementType) {
            return <>
                <Dialog
                    open={newLineItemDialogVisible}
                    onClose={() => handleAddNewLineItemDialogResult(false)}
                    fullScreen={isMobile}
                >
                    <DialogTitle>{tm.Get("Item Properties")}</DialogTitle>
                    <ItemProperties
                        newItem={true}
                        initialState={newLineItemProperties}
                        itemInfo={newLineItemInformation}
                        measurementType={measurementType}
                        onCancel={() => handleAddNewLineItemDialogResult(false)}
                        onSubmit={(_, newState) => handleAddNewLineItemDialogResult(true, newState)}
                    />
                </Dialog>
            </>;
        }

    }, [newLineItemDialogVisible, newLineItemProperties, newLineItemInformation, quoteMeasurementType, handleAddNewLineItemDialogResult, isMobile, tm])

    return <>
        {quote &&
            <FormProvider {...formMethods}>
                <form id="addLineItem" onSubmit={handleSubmit(onSubmit, onError)}>
                    <Grid container direction="row" spacing={1}>
                        {applicationInfo.productNavigatorEnabled &&
                            <Grid item xs="auto" display="flex" flexDirection="row" alignItems="center">
                                <Tooltip title={tm.Get("Product Navigator")}>
                                    <IconButton aria-label="productNav" sx={{ padding: 0 }} onClick={handleProductNavClick}>
                                        <Explore sx={{ width: "48px", height: "48px" }} />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        }
                        <Grid item xs>
                            <Grid container direction={isMobile ? "column" : "row"} spacing={1}>
                                <Grid item xs>
                                    <SelectCategory
                                        mfgCustomerID={quote.mfgCustomerID}
                                        mfgSiteID={quote.mfgSiteID}
                                        onChange={onCategoryChange}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <SelectPart
                                        mfgCustomerID={quote.mfgCustomerID}
                                        mfgSiteID={quote.mfgSiteID}
                                        categoryID={selectedCategory?.categoryID}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs="auto" display="flex" flexDirection="row" alignItems="center">
                            <Button variant="contained" color={ThemeColorEnum.Secondary} type="submit">{tm.Get("Add")}</Button>
                        </Grid>
                    </Grid>
                </form>
            </FormProvider>
        }

        {addNewLineItemDialog()}
    </>

}

export default AddLineItem;
