import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { RoleEnum } from 'helpers/enums';
import useTranslations from 'helpers/hooks/useTranslations';
import useUserInfo from 'helpers/context/User/useUserInfo';
import * as React from 'react';
import { QuoteDataTypeEnum } from './DashboardQuotes';
import useSelectionValuesActions from "helpers/context/SelectionValues/useSelectionValuesActions";
import useSelectionValuesData from "helpers/context/SelectionValues/useSelectionValuesData";


interface IProps {
}

const SelectQuoteType: React.FC<IProps> = (props: IProps) => {

  const tm = useTranslations();
  const user = useUserInfo();
  const selectionValuesActions = useSelectionValuesActions();
  const { dashboardQuoteType } = useSelectionValuesData();

  const handleChange = (e: SelectChangeEvent<QuoteDataTypeEnum>) => {
    const type = e.target.value as unknown as QuoteDataTypeEnum;
    selectionValuesActions.SetDashboardQuoteType(type);
  };

  return <>
    <Select
      value={dashboardQuoteType}
      onChange={handleChange}
    >

      {user.HasRole(RoleEnum.Dealer) && <MenuItem value={QuoteDataTypeEnum.CompanyQuotes}>{tm.Get("Company Quotes")}</MenuItem>}
      {user.HasRole(RoleEnum.Dealer) && <MenuItem value={QuoteDataTypeEnum.CustomerQuotes}>{tm.Get("Customer Quotes")}</MenuItem>}

    </Select>

  </>

};

export default SelectQuoteType;