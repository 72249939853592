import { useCallback } from "react";
import { Chip } from "@mui/material";

import useQuoteData from "components/Quotes/useQuoteData";
import useTranslations from "helpers/hooks/useTranslations";
import { ThemeColorEnum } from "helpers/enums";

function SubmittedChip() {

    const { quote } = useQuoteData();
    const tm = useTranslations();

    const getLabel = useCallback(() : any => {
        if (quote && quote.isContractorCreatedQuote && quote.isSubmitted)
        {
            return quote.contractorOrderNumber ? tm.Get("Submitted From Customer") : tm.Get("Submitted To Dealer");
        }
        return "";

    }, [quote, tm]);

    return <>
        {(quote && getLabel()) && 
            <Chip color={ThemeColorEnum.Primary} label={getLabel()} />
        }
    </>;

}

export default SubmittedChip;