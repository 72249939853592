import React, { useCallback } from 'react';
import { Autocomplete, TextField, CircularProgress } from "@mui/material";

import { useController, useFormContext } from 'react-hook-form';
import useTranslations from 'helpers/hooks/useTranslations';
import useParts from 'components/Quotes/QuoteEntry/AddLineItem/usePartList';

import { IPartListItem } from "helpers/interfaces";

interface IProps {
    mfgCustomerID: string,
    mfgSiteID: string,
    categoryID: number | undefined,
}

const SelectPart : React.FC<IProps> = ({ mfgCustomerID, mfgSiteID, categoryID }: IProps) => {

    const tm = useTranslations();
    const { control } = useFormContext();

    const { partList, isLoading } = useParts(mfgCustomerID, mfgSiteID, categoryID);

    const validatePart = (value: IPartListItem) : string | boolean => {
        // must have a partno selected (and not a {Placeholder} item)
        if (!value?.partKey?.masterPartNo)
        {
            return tm.Get("Please select a part");
        }

        return true;
    };

    const { field } = useController({
        name: "part", 
        control,
        rules: { 
                required: {value: true, message: tm.Get("Please select a part")} ,
                validate: validatePart,
               },
      });

    const partListItemEquals = (partListItem1: IPartListItem, partListItem2: IPartListItem) : boolean => {
        if (partListItem1 && partListItem2)
        {
            if (!partListItem1.partKey.masterPartNo && !partListItem2.partKey.masterPartNo) {
                // if both partno's are blank, match on description (this must be a {Placeholder})
                return partListItem1.description === partListItem2.description;
            }
            else {
                return partListItem1.partKey.masterPartNo === partListItem2.partKey.masterPartNo &&
                       partListItem1.partKey.partNoSuffix === partListItem2.partKey.partNoSuffix &&
                       partListItem1.partKey.shortcutName === partListItem2.partKey.shortcutName;
            }
        }
        return false;
    };

    const selectedPart = useCallback(() => {
        if (categoryID === undefined || isLoading)
            return null;
        const item = partList.find((p) => partListItemEquals(field.value, p))
        return item ?? null;
    }, [categoryID, isLoading, partList, field]);

    return (
        <Autocomplete 
            id="part"
            autoComplete
            autoSelect
            autoHighlight
            selectOnFocus
            handleHomeEndKeys
            options={isLoading ? [] : partList} 
            getOptionLabel={(option : IPartListItem) => option.description}
            renderInput={(params) => (
                <TextField 
                    variant="outlined" 
                    {...params} 
                    label={tm.Get("Part")}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                            {isLoading && <CircularProgress color="inherit" size={20} />}
                            {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
                )}
            onChange={(_, data) => {field.onChange(data)}}
            value={selectedPart()}
            ref={field.ref}
            disabled={categoryID === undefined}
        /> 
    );

}

export default SelectPart;