import { Accordion, AccordionDetails, Grid, Typography } from "@mui/material";
import AccordionSummarySlim from "components/Common/AccordionSummarySlim";
import CheckBoxForm from "components/Common/CheckBoxForm";
import useTranslations from "helpers/hooks/useTranslations";
import React from 'react';
import { useFieldArray, useFormContext } from "react-hook-form";

interface IProps {
    readOnly: boolean,
    disabled: boolean
}

const PermissionSelect: React.FC<IProps> = (props: IProps) => {

    const { control, watch } = useFormContext();
    const { fields } = useFieldArray({
        control: control,
        name: "permissions"
    });

    const tm = useTranslations();
    const [isExpanded, setIsEpanded] = React.useState<boolean>(true);

    return <>
        <Accordion expanded={isExpanded} onChange={() => setIsEpanded((prev) => !prev)} disableGutters >
            <AccordionSummarySlim>
                <Typography>
                    {tm.Get("Permissions")}
                </Typography>
            </AccordionSummarySlim>
            <AccordionDetails>
                <Grid container spacing={1} padding={0}>
                    {fields.map((field, index) => {
                        const label = watch(`permissions.${index}.description`);

                        return <Grid item xs={12} key={field.id}>
                            <CheckBoxForm
                                readOnly={props.readOnly}
                                fieldName={`permissions.${index}.enabled`}
                                label={label}
                                disabled={props.disabled && !props.readOnly}
                            />
                        </Grid>
                    })}
                </Grid>
            </AccordionDetails>
        </Accordion>
    </>;
}

export default PermissionSelect;