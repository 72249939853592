import React from "react";
import CachedIcon from '@mui/icons-material/Cached';
import { Grid, TextField, Stack, IconButton, InputAdornment } from "@mui/material";

import useIsMobile from "helpers/hooks/useIsMobile";
import useTranslations from "helpers/hooks/useTranslations";
import { ILineItemPriceBreakdownProps } from "components/LineItemPriceBreakdown/LineItemPriceBreakdown"
import LineItemPriceBreakdownAPI from "components/LineItemPriceBreakdown/LineItemPriceBreakdownAPI"
import Constants from "helpers/constants";
import ErrorAdornment from "components/Common/ErrorAdornment";
import useLocaleNumberFormatter from "helpers/hooks/useLocaleNumberFormatter";

const PBPricing: React.FC<ILineItemPriceBreakdownProps> = ({ editEnabled, viewCost, currentOverallSqFtPrice, currentOverallPrice,
    itemWeight, itemCost, itemSqFtPrice, itemPrice, overallWeight, overallCost, overallSqFtPrice, overallPrice,
    overallSqFtPriceErrorMessage, overallPriceErrorMessage, anyPriceOverridden, handleOverallSqFtPriceChange, handleOverallPriceChange,
    setOverallSqFtPriceErrorMessage, setOverallPriceErrorMessage, handleRepriceClick, setIsPosting, setData, cf }) => {

    const isMobile = useIsMobile();
    const tm = useTranslations();
    const lnf = useLocaleNumberFormatter({style: "decimal", useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2});

    const validateOverallSqFtPrice = React.useCallback((value: string) => {
        var numberPrice = cf.Parse(value);
        if (value === "") {
            return tm.Get("SqFt Price must be specified");
        } else if (isNaN(numberPrice)) {
            return tm.Get("SqFt Price must be a valid currency value");
        } else if (numberPrice < Constants.Min.Price || numberPrice > Constants.Max.Price) {
            return tm.GetWithParams("Price must be between {0} and {1}", Constants.Min.Price.toString(), Constants.Max.Price.toString());
        } else {
            return "";
        }
    }, [cf, tm]);

    const handleOverallSqFtPriceBlur = React.useCallback((e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (editEnabled && e.target.value !== currentOverallSqFtPrice) {
            var errorMessage = validateOverallSqFtPrice(e.target.value);
            setOverallSqFtPriceErrorMessage(errorMessage);
            if (errorMessage === "") {
                setIsPosting(true);
                LineItemPriceBreakdownAPI.ChangeOverallSqFtPrice(cf.Parse(e.target.value)).then((newBreakdown) => {
                    if (newBreakdown) {
                        setData(newBreakdown);
                    }
                    setIsPosting(false);
                });
            }
        }
    }, [cf, editEnabled, currentOverallSqFtPrice, validateOverallSqFtPrice, setOverallSqFtPriceErrorMessage, setIsPosting, setData]);

    const validateOverallPrice = React.useCallback((value: string) => {
        var numberPrice = cf.Parse(value);
        if (value === "") {
            return tm.Get("Price must be specified");
        } else if (isNaN(numberPrice)) {
            return tm.Get("Price must be a valid currency value");
        } else if (numberPrice < Constants.Min.Price || numberPrice > Constants.Max.Price) {
            return tm.GetWithParams("Price must be between {0} and {1}", Constants.Min.Price.toString(), Constants.Max.Price.toString());
        } else {
            return "";
        }
    }, [cf, tm]);

    const handleOverallPriceBlur = React.useCallback((e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (editEnabled && e.target.value !== currentOverallPrice) {
            var errorMessage = validateOverallPrice(e.target.value);
            setOverallPriceErrorMessage(errorMessage);
            if (errorMessage === "") {
                setIsPosting(true);
                LineItemPriceBreakdownAPI.ChangeOverallPrice(cf.Parse(e.target.value)).then((newBreakdown) => {
                    if (newBreakdown) {
                        setData(newBreakdown);
                    }
                    setIsPosting(false);
                });
            }
        }
    }, [cf, editEnabled, currentOverallPrice, validateOverallPrice, setOverallPriceErrorMessage, setIsPosting, setData]);

    const captureEnterKeypress = React.useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            e.target.blur();
        }
    }, []);

    const RepriceAdornment = () => {
        return <>
            <InputAdornment position="start">
                <IconButton onClick={handleRepriceClick}>
                    <CachedIcon color="primary" />
                </IconButton>
            </InputAdornment>
        </>
    }

    return <>
        <Grid container direction="column" rowSpacing={1.5} justifyItems="stretch">
            <Grid item>
                <Grid container alignItems="center" justifyContent="left" spacing={1}>
                    <Grid item xs={isMobile ? (viewCost ? 6 : 12) : (viewCost ? 3 : 4)}>
                        <TextField label={tm.Get("Item Weight")} value={itemWeight ? lnf.Format(parseFloat(itemWeight)) : ""} disabled
                            InputProps={{
                                inputProps: {
                                    style: {
                                        textAlign: 'right',
                                    }
                                }
                            }} fullWidth />
                    </Grid>
                    {viewCost && <Grid item xs={isMobile ? 6 : 3}>
                        <TextField label={tm.Get("Item Cost")} value={itemCost ? itemCost : "0.00"} disabled
                            inputProps={{ style: { textAlign: 'right' } }} fullWidth />
                    </Grid>}<Grid item xs={isMobile ? 6 : (viewCost ? 3 : 4)}>
                        <TextField label={tm.Get("Item Sq. Ft. Price")} value={itemSqFtPrice} disabled
                            inputProps={{ style: { textAlign: 'right' } }} fullWidth />
                    </Grid>
                    <Grid item xs={isMobile ? 6 : (viewCost ? 3 : 4)}>
                        <TextField label={tm.Get("Item Price")} value={itemPrice} disabled
                            inputProps={{ style: { textAlign: 'right' } }} fullWidth />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container alignItems="center" justifyContent="left" spacing={1}>
                    <Grid item xs={isMobile ? (viewCost ? 6 : 12) : (viewCost ? 3 : 4)}>
                        <TextField label={tm.Get("Overall Weight")} value={overallWeight ? lnf.Format(parseFloat(overallWeight)) : ""} fullWidth disabled
                            InputProps={{
                                inputProps: {
                                    style: {
                                        textAlign: 'right',
                                    }
                                }
                            }} />
                    </Grid>
                    {viewCost && <Grid item xs={isMobile ? 6 : 3} visibility={viewCost ? "visible" : "collapse"}>
                        <TextField label={tm.Get("Overall Cost")} value={overallCost ? overallCost : "0.00"} fullWidth disabled
                            inputProps={{ style: { textAlign: 'right' } }} />
                    </Grid>}
                    <Grid item xs={isMobile ? 6 : (viewCost ? 3 : 4)}>
                        <TextField label={tm.Get("Overall Sq. Ft. Price")} value={overallSqFtPrice}
                            onChange={handleOverallSqFtPriceChange}
                            onBlur={handleOverallSqFtPriceBlur} fullWidth
                            error={overallSqFtPriceErrorMessage !== ""}
                            disabled={!editEnabled}
                            InputProps={{
                                inputProps: {
                                    style: {
                                        textAlign: 'right',
                                        fontWeight: anyPriceOverridden ? "bold" : "normal"
                                    }
                                },
                                endAdornment: overallSqFtPriceErrorMessage !== "" ?
                                    <ErrorAdornment validationError={overallSqFtPriceErrorMessage} /> : null
                            }}
                            onKeyDown={e => { if( editEnabled) captureEnterKeypress(e) }}
                            onFocus={e => { if(editEnabled) e.target.select() }}
                        />
                    </Grid>
                    <Grid item xs={isMobile ? 6 : (viewCost ? 3 : 4)}>
                        <Stack direction="row" alignItems="center">
                            <TextField label={tm.Get("Overall Price")} value={overallPrice}
                                onChange={handleOverallPriceChange}
                                onBlur={handleOverallPriceBlur} fullWidth
                                error={overallPriceErrorMessage !== ""}
                                disabled={!editEnabled}
                                InputProps={{
                                    inputProps: {
                                        style: {
                                            textAlign: 'right',
                                            fontWeight: anyPriceOverridden ? "bold" : "normal"
                                        }
                                    },
                                    startAdornment: (editEnabled && anyPriceOverridden) ?
                                        <RepriceAdornment /> : null,
                                    endAdornment: overallPriceErrorMessage !== "" ?
                                        <ErrorAdornment validationError={overallPriceErrorMessage} /> : null
                                }}
                                onKeyDown={e => { if( editEnabled) captureEnterKeypress(e) }}
                                onFocus={e => { if( editEnabled ) e.target.select() }}
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </>
}

export default PBPricing