import React, { useMemo } from "react";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import { useController, useFormContext } from "react-hook-form";
import useTranslations from 'helpers/hooks/useTranslations';
import useTerms from 'helpers/context/SelectionValues/useTerms'

import { IQuotePermissions } from "helpers/interfaces";

interface ISelectTerms {
    parentCustomerID: string,
    permissions?: IQuotePermissions,
    [x: string] : any  // this should always be the last prop, to support any other props that will be passed along down the component chain
}

function SelectTerms ({ parentCustomerID, permissions, ...rest }: ISelectTerms)
{

    const tm = useTranslations();
    const { control } = useFormContext();

    const termsList = useTerms(parentCustomerID);

    const { field } = useController({
        name: "billing.terms", 
        control,
        rules: { required: false },
      });

    const values = useMemo(() => {
        return termsList?.map(({description}) => description) ?? [];
    }, [termsList]);

    return (
        <Autocomplete 
            id="terms"
            autoComplete
            autoSelect
            autoHighlight
            selectOnFocus
            handleHomeEndKeys
            options={values} 
            getOptionLabel={(option) => option}
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) => (
                <TextField 
                variant="outlined" 
                {...params} 
                label={tm.Get("Terms")} 
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                            <>
                            {(termsList === undefined) && <CircularProgress color="inherit" size={20} />}
                            {params.InputProps.endAdornment}
                            </>
                        ),
                    }}                    
                    />
                    )}
            onChange={(_, data) => {field.onChange(data)}}
            value={(field.value === "") ? null : field.value}
            ref={field.ref}
            disabled={!permissions?.terms ?? true}
            {...rest}
        /> 
    );

}

export default SelectTerms;