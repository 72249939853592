import { useState, useCallback } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'

import { IQuotePermissions } from "helpers/interfaces";
import OKCancelControl from "components/Common/OKCancelControl";
import SelectWebCustomer from "./SelectWebCustomer";
import useTranslations from "helpers/hooks/useTranslations";

interface IProps {
    isOpen: boolean,
    isContractorQuote: boolean,
    contractorCustomerID: string,
    defaultMfgCustomer?: { customerID: string, siteID: string },
    defaultWebCustomerID?: string,
    permissions?: IQuotePermissions,
    onClose: () => void,
    onWebCustomerChanged: (mfgCustomerID: string, mfgSiteID: string, webCustomerID: string, webCustomerGUID: string) => void,
}

function SelectWebCustomerDialog({ isOpen, isContractorQuote, contractorCustomerID, defaultMfgCustomer, defaultWebCustomerID, permissions, onClose, onWebCustomerChanged, }: IProps) {

    const tm = useTranslations();
    const [mfgCustomerID, setMfgCustomerID] = useState<string>("");
    const [mfgSiteID, setMfgSiteID] = useState<string>("");
    const [webCustomer, setWebCustomer] = useState<{ webCustomerID: string, webCustomerGUID: string } | undefined>(undefined);
    const [showSelectionRequired, setShowSelectionRequired] = useState<boolean>(false);

    const onUserChangedWebCustomer = useCallback((webCustomerID: string, webCustomerGUID: string) => {
        setWebCustomer({ webCustomerID, webCustomerGUID });
    }, []);

    const onOK = useCallback(() => {
        if (webCustomer && webCustomer.webCustomerID) {
            onWebCustomerChanged(mfgCustomerID, mfgSiteID, webCustomer.webCustomerID, webCustomer.webCustomerGUID)
            onClose();
        }
        else {
            setShowSelectionRequired(true);
        }

    }, [mfgCustomerID, mfgSiteID, webCustomer, onWebCustomerChanged, onClose, setShowSelectionRequired]);


    return <>
        <Dialog
            open={isOpen}
            onClose={onClose}
            fullWidth={true}
        >
            <DialogTitle>{tm.GetWithParams("Edit {0}", tm.Get("Customer"))}</DialogTitle>
            <DialogContent>
                <SelectWebCustomer
                    isContractorQuote={isContractorQuote} contractorCustomerID={contractorCustomerID}
                    setWebCustomer={onUserChangedWebCustomer}
                    setMfgCustomerID={setMfgCustomerID}
                    setMfgSiteID={setMfgSiteID}
                    defaultMfgCustomer={defaultMfgCustomer}
                    defaultWebCustomerID={defaultWebCustomerID}
                    permissions={permissions}
                    showSelectionRequired={showSelectionRequired}
                />
            </DialogContent>
            <DialogActions>
                <OKCancelControl okCommand={onOK} cancelCommand={onClose} />
            </DialogActions>
        </Dialog>
    </>;
}

export default SelectWebCustomerDialog;