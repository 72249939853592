import * as React from "react";
import { useTheme } from "@mui/material";

import { ISectionRegion } from "../interfaces";
import ClickableImage from "./ClickableImage";
import useImageLoader from "helpers/hooks/useImageLoader";
import LoadingOverlay from "./LoadingOverlay";
import ImageWithLoadingOverlay from "./ImageWithLoadingOverlay";

interface IProps {
    src: string;
    clickableRegions: ISectionRegion[] | null;
    clickRegionHandler?: (clickableReg: ISectionRegion) => void;
    selected: number[] | null;
    renderAsDiv: boolean;
    imageStyle?: React.CSSProperties;
}

const ClickableImageWithLoadingOverlay: React.FC<IProps> = (props: IProps) => {

    const [loading, loadedImageUrl] = useImageLoader(props.src);
    const theme = useTheme();

    if (props.clickableRegions) {
        return <LoadingOverlay loading={loading}>
            {loadedImageUrl ?
                <ClickableImage src={loadedImageUrl}
                    clickableRegions={props.clickableRegions}
                    clickRegionHandler={props.clickRegionHandler}
                    selected={props.selected}
                    theme={theme}
                    renderAsDiv={props.renderAsDiv}
                    imageStyle={props.imageStyle}
                />
                :
                <div style={props.imageStyle} />
            }

        </LoadingOverlay>;
    }
    else {
        return <ImageWithLoadingOverlay src={props.src} imageStyle={props.imageStyle} renderAsDiv={props.renderAsDiv} />
    }
}

export default ClickableImageWithLoadingOverlay;
