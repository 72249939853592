import { Paper, Box, Grid } from "@mui/material";

import useTranslations from "helpers/hooks/useTranslations";
import useApplicationInfo from "helpers/context/Application/useApplicationInfo";
import useCurrencyFormatter from "helpers/hooks/useCurrencyFormatter";
import LabeledText from "components/Common/LabeledText";
import { MeasurementSystemEnum } from "helpers/fv.format";
import { ILocaleNumberFormatter } from "helpers/hooks/useLocaleNumberFormatter";

interface IProps {
    shouldShowSqFt: boolean,
    totalQty: number,
    totalSqFt: number,
    totalSales: number,
    lnf: ILocaleNumberFormatter
}

const PurchaseHistoryFooter = ({shouldShowSqFt, totalQty, totalSqFt, totalSales, lnf}: IProps) => {

    const tm = useTranslations();
    const appInfo = useApplicationInfo();
    const cf = useCurrencyFormatter(appInfo.currencyCultures[0].cultureInfo);
    const isMetric = appInfo.engineeringUnits.systemUnitSet === MeasurementSystemEnum.Metric;

    return <>
        <Paper>
            <Box sx={{ padding: 1 }}>
                <Grid container>
                    <Grid item xs={shouldShowSqFt ? 4 : 6}>
                        <LabeledText label={tm.Get("Total Quantity")} text={totalQty} />
                    </Grid>
                    {shouldShowSqFt &&
                        <Grid item xs={4}>
                            <LabeledText label={isMetric ? tm.Get("Total SqM") : tm.Get("Total SqFt")} text={lnf.Format(totalSqFt)} />
                        </Grid>
                    }
                    <Grid item xs={shouldShowSqFt ? 4 : 6}>
                        <LabeledText label={tm.Get("Total Sales")} text={cf.Format(totalSales)} />
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    </>
}

export default PurchaseHistoryFooter;