import { Dialog, DialogTitle } from "@mui/material";
import { ILineItemInfo, IOpeningDesignerSectionSubLineItemInfo, IWebDesigner } from "components/OptionsWizard/interfaces";
import useMessageBox from "helpers/context/Page/useMessageBox";
import useMeasurementTypes from "helpers/context/SelectionValues/useMeaurementTypes";
import { useEffectOnLoad } from "helpers/hooks/useEffectOnLoad";
import useIsMobile from "helpers/hooks/useIsMobile";
import useTranslations from "helpers/hooks/useTranslations";
import { IPartCallSizePresets } from "helpers/interfaces";
import PartsAPI from "helpers/PartsAPI";
import React from 'react';
import ODSLIProperties, { IODSLIProperties } from "./ODSLIProperties";

interface IProps {
    itemInfo: ILineItemInfo;
    webDesigner: IWebDesigner | null;
    dialogVisible: boolean;
    onCancel: () => void;
    onSubmit: (originalState: IODSLIProperties, newState: IODSLIProperties) => Promise<string | null>;
}

const ODSLIPropertiesDialog: React.FC<IProps> = ({itemInfo, webDesigner, dialogVisible, onCancel, onSubmit}: IProps) => {


    const measurementTypes = useMeasurementTypes();
    const isMobile = useIsMobile();
    const tm = useTranslations();

    const messageBox = useMessageBox();

    const quoteMeasurementType = React.useCallback(() => {
        if (itemInfo && measurementTypes) {
            return measurementTypes.find((m) => m.setID === itemInfo.unitSet);
        }
    }, [itemInfo, measurementTypes]);

    const measurementType = quoteMeasurementType();

    const [partCallSizes, setPartCallSizes] = React.useState<IPartCallSizePresets | null>(null);

    const onSubmitInternal = React.useCallback(async (originalState: IODSLIProperties, newState: IODSLIProperties) => {

        const responseMessage = await onSubmit(originalState, newState);

        if (responseMessage) {
            messageBox.Show({ message: responseMessage });
        }

    }, [messageBox, onSubmit])


    useEffectOnLoad(() => {
        /*TODO: Cache this */
        const callSizesPromise = PartsAPI.GetPartCallSizes(itemInfo.partNo, itemInfo.partNoSuffix);
        callSizesPromise.then((callSizes) => {
          setPartCallSizes(callSizes);
        });

    })


    const selectedSLIProperties = React.useMemo(() => {
        const selectedSLI = webDesigner?.sectionList.find(sli => sli.sli === webDesigner?.currentSection) ?? null;
        return (selectedSLI?.designerProperties ?? null) as (IOpeningDesignerSectionSubLineItemInfo | null)
    }, [webDesigner?.currentSection, webDesigner?.sectionList]);

    if (measurementType && selectedSLIProperties) {

        return <>
            <Dialog
                open={dialogVisible}
                onClose={onCancel}
                fullScreen={isMobile}
            >
                <DialogTitle>{tm.Get("Section Sizing")}</DialogTitle>
                <ODSLIProperties
                    openingHeight={webDesigner?.sectionList[0]?.designerProperties?.height ?? 0}
                    openingWidth={webDesigner?.sectionList[0]?.designerProperties?.width ?? 0}
                    widthFixed={selectedSLIProperties.widthFixed}
                    heightFixed={selectedSLIProperties.heightFixed}
                    initialState={selectedSLIProperties}
                    callSizes={partCallSizes}
                    measurementType={measurementType}
                    onCancel={onCancel}
                    onSubmit={onSubmitInternal}
                />
            </Dialog>
        </>;

    }

    return null;
}

export default ODSLIPropertiesDialog;