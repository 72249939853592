import { useState, useEffect, useMemo, useCallback } from "react"

import { Container, Box } from "@mui/material"
import { useForm, FormProvider } from "react-hook-form";

import QuoteInfo from "components/Quotes/AddQuote/QuoteInfo"
import Sales from "components/Quotes/AddQuote/Sales"
import Billing from "components/Quotes/AddQuote/Billing"
import Shipping from "components/Quotes/AddQuote/Shipping"
import MfgShipTo from "components/Quotes/AddQuote/MfgShipTo"
import { BlankQuote } from "components/Quotes/AddQuote/BlankQuote";

import useTranslations from "helpers/hooks/useTranslations";
import useWindowTitle from "helpers/context/Title/useWindowTitle";
import useWait from "helpers/context/Page/useWait";
import useActionButtons from "helpers/context/Page/useActionButtons";
import useMfgSecondaryShipTos from "helpers/context/SelectionValues/useMfgSecondaryShipTos";
import useMessageBox from "helpers/context/Page/useMessageBox";
import useQuoteData from "components/Quotes/useQuoteData";
import useQuoteActions from "components/Quotes/useQuoteActions";
import { FindAllNestedProps } from "helpers/objects";

import { OrderStatusEnum, RoleEnum, ThemeColorEnum, UserPermissionTypes } from "helpers/enums";
import { IQuote } from "models/IQuote";
import { IActionButton } from "helpers/context/Page/PageContext";
import LoadingLinearProgress from "components/Common/LoadingLinearProgress";
import useEditQuoteData from "components/Quotes/EditQuote/useEditQuoteData";
import useEditQuoteActions from "components/Quotes/EditQuote/useEditQuoteActions";
import useLocaleNumberFormatter from "helpers/hooks/useLocaleNumberFormatter";
import useWebCustomer from "helpers/context/SelectionValues/useWebCustomer";
import SelectWebCustomerDialog from "../SelectFields/SelectWebCustomerDialog";
import { useEffectOnLoad } from "helpers/hooks/useEffectOnLoad";
import useNavigationBlocker from "helpers/context/Application/useNavigationBlocker";
import useNavigationBlockerActions from "helpers/context/Application/useNavigationBlockerActions";
import useUserInfo from "helpers/context/User/useUserInfo";
import { useNavigate, useSearchParams } from "react-router-dom";
import QuoteNavigation from "../QuoteNavigation";

function EditQuote() {

    const tm = useTranslations();
    const lnf = useLocaleNumberFormatter({ style: "decimal", useGrouping: true, minimumFractionDigits: 4, maximumFractionDigits: 4 });

    const wait = useWait();
    const formMethods = useForm({ mode: "onChange", defaultValues: BlankQuote });
    const { handleSubmit, reset, getValues } = formMethods;

    const actionButtons = useActionButtons();
    const messageBox = useMessageBox();
    const navBlockerActions = useNavigationBlockerActions();
    const navigate = useNavigate();
    const [ query ] = useSearchParams();

    const { quote, permissions } = useQuoteData();
    const section = query.get("section") ?? "info";
    const oKey = quote?.oKey;
    const quoteActions = useQuoteActions();
    const quoteEditActions = useEditQuoteActions();
    const webCustomer = useWebCustomer(quote?.customerID ?? "");
    const user = useUserInfo();

    const defaultExpanderState = {
        info: false,
        sales: false,
        billing: false,
        shipping: false,
        [section]: true,
    }

    //#region State variables

    const [isPosting, setIsPosting] = useState<boolean>(false);
    const [isDefaultsLoading, setIsDefaultsLoading] = useState<boolean>(false);
    const [webCustomerGUID, setWebCustomerGUID] = useState<string>("");
    const [newWebCustomerGUID, setNewWebCustomerGUID] = useState<string | undefined>(undefined);
    const [webCustomerID, setWebCustomerID] = useState<string>("");
    const [mfgCustomerID, setMfgCustomerID] = useState<string>("");
    const [mfgSiteID, setMfgSiteID] = useState<string>("");
    const [isEditCustomerOpen, setIsEditCustomerOpen] = useState<boolean>(false);

    const [quoteToSubmit, setQuoteToSubmit] = useState<IQuote | null>(null);

    const [expandedState, setExpandedState] = useState<typeof defaultExpanderState>(defaultExpanderState);

    const mfgShippingAddresses = useMfgSecondaryShipTos(mfgCustomerID, mfgSiteID);

    const isContractorQuote = useMemo(() => {
        return quote?.isContractorCreatedQuote && (quote?.contractorOrderNumber ?? "") === "";
    }, [quote]);

    const contractorCustomerID = useMemo(() => {
        return isContractorQuote ? (quote?.parentCustomerID ?? "") : "";
    }, [isContractorQuote, quote]);

    const mfgCustomerIDSiteID = useMemo(() => {
        if (isContractorQuote) {
            return quote ? { customerID: quote.mfgCustomerID, siteID: quote.mfgSiteID } : undefined
        }
        else {
            return quote ? { customerID: quote.parentCustomerID, siteID: quote.parentSiteID } : undefined
        }
    }, [isContractorQuote, quote]);

    const { quoteDefaults: newCustomerDefaults } = useEditQuoteData();

    const quoteDefaults = useMemo(() => {
        return newCustomerDefaults ?? quote;
    }, [newCustomerDefaults, quote]);

    //#endregion

    //#region functions

    useNavigationBlocker(formMethods.formState.isDirty);

    const title = useMemo(() => {
        if (quote) {
            return (`${tm.Get("Edit Quote")} - #${quote.orderNumber ?? ""}`);
        }
        return "";
    }, [quote, tm]);

    useWindowTitle(title);

    const onSubmit = (formData: IQuote, e: any) => {
        if (formData.pricing.orderDiscount !== null) {
            formData.pricing.orderDiscount = lnf.Parse(formData.pricing.orderDiscount.toString());
        }
        if (formData.pricing.orderMarkdown !== null) {
            formData.pricing.orderMarkdown = lnf.Parse(formData.pricing.orderMarkdown.toString());
        }
        setQuoteToSubmit(formData);
    };

    const onError = (errors: { [x: string]: any }, e: any) => {
        const messages = FindAllNestedProps(errors, "message");
        const formattedMessage = messages.join("\n");

        if (formattedMessage) {
            messageBox.Show({ message: formattedMessage, title: tm.Get("Please correct before saving.") });
        }
    }

    const handleChange = (section: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
        //Note: to allow more than one expansion at a time: setExpandedState({...expandedState, [section]: isExpanded});
        setExpandedState({ info: false, sales: false, billing: false, shipping: false, [section]: isExpanded });
    };

    const userChangedCustomer = useMemo(() => {
        if (quote && webCustomerID) {
            if (quote.customerID !== webCustomerID) {
                return (true);
            }
        }
        return false;
    }, [webCustomerID, quote]);

    //#endregion

    //#region Fetch data effects

    useEffect(() => {
        if (newWebCustomerGUID) {
            setIsDefaultsLoading(true);
            quoteEditActions.LoadQuoteDefaultsAsync(newWebCustomerGUID).then(() => {
                setIsDefaultsLoading(false);
            });
        }

    }, [newWebCustomerGUID, quoteEditActions]);

    //#endregion

    //#region Post data effects

    useEffect(() => {
        if (quoteToSubmit && oKey) {
            setIsPosting(true);

            quoteEditActions.UpdateQuoteHeaderAsync(oKey, quoteToSubmit)
                .then(async () => {
                    quoteActions.LoadQuoteAsync(oKey).then((result) => {
                        if (result) {
                            navigate(QuoteNavigation.QuoteEntryURL(oKey));
                        }
                    });
                })
                .finally(() => {
                    setQuoteToSubmit(null);
                    setIsPosting(false);
                });
        }
    }, [quoteToSubmit, quoteActions, quoteEditActions, oKey, navigate]);

    //#endregion

    //#region other effects

    useEffectOnLoad(() => {
        if (mfgCustomerIDSiteID) {
            setMfgSiteID(mfgCustomerIDSiteID.siteID);
            setMfgCustomerID(mfgCustomerIDSiteID.customerID);
        }
    });

    useEffect(() => {
        if (webCustomer) {
            setWebCustomerID(webCustomer.customerID)
            setWebCustomerGUID(webCustomer.customerGUID)
        }
    }, [webCustomer]);

    useEffect(() => {

        if (quoteDefaults && quote) {
            if (userChangedCustomer && quote.status !== OrderStatusEnum.Uploaded) {
                //reset the form with the new customer's default, but preserve some of the values
                const currentValues = getValues();
                reset({
                    ...quoteDefaults,
                    poNumber: currentValues.poNumber,
                    customerRef: currentValues.customerRef,
                    orderContact: currentValues.orderContact,
                    orderComment: currentValues.orderComment,
                    orderNotes: currentValues.orderNotes,
                    engineeringUnitSetID: (permissions?.measurementType ? quoteDefaults.engineeringUnitSetID : currentValues.engineeringUnitSetID),
                    shipping: {
                        ...quoteDefaults.shipping,
                        mfgShipToGUID: currentValues.shipping.mfgShipToGUID,
                        dropShip: currentValues.shipping.dropShip
                    },
                });
            }
            else {
                reset(quote);
            }
        }

    }, [quoteDefaults, quote, userChangedCustomer, permissions?.measurementType, getValues, reset]);

    useEffect(() => {
        wait.Show(isDefaultsLoading || isPosting);
    }, [isDefaultsLoading, isPosting, wait])

    useEffect(() => {
        const addButton: IActionButton = {
            text: tm.Get("Save"),
            color: ThemeColorEnum.Secondary,
            disabled: (webCustomerGUID && !isPosting) ? false : true,
            type: "submit",
            form: "quoteForm",
            onClick: (() => navBlockerActions.Unblock())
        };
        actionButtons.Set(0, addButton);

        const cancelButton: IActionButton = {
            text: tm.Get("Cancel"),
            color: ThemeColorEnum.Primary,
            onClick: () => {
                navigate(QuoteNavigation.QuoteEntryURL(quote?.oKey ?? 0));
                navBlockerActions.Unblock();
            }
        };
        actionButtons.Set(1, cancelButton);

    }, [actionButtons, webCustomerGUID, quoteActions, tm, isPosting, navBlockerActions, navigate, quote]);

    const onUserChangedWebCustomer = useCallback((mfgCustomerID: string, mfgSiteID: string, webCustomerID: string, webCustomerGUID: string) => {
        setMfgCustomerID(mfgCustomerID);
        setMfgSiteID(mfgSiteID);
        setWebCustomerID(webCustomerID);
        setWebCustomerGUID(webCustomerGUID);
        setNewWebCustomerGUID(webCustomerGUID);
    }, [setWebCustomerID, setWebCustomerGUID, setNewWebCustomerGUID]);

    const onEditWebCustomerClick = useCallback(() => {
        setIsEditCustomerOpen(true);
    }, []);

    const onEditCustomerClose = useCallback(() => {
        setIsEditCustomerOpen(false);
    }, []);

    const markdownVisible = useMemo(() => {
        if (user.HasRole(RoleEnum.Contractor) && user.parentCustomerID === quote?.customerID) {
            // Legacy contractor mode, I can't view my own discount
            return false;
        } else {
            return user.HasPermission(UserPermissionTypes.ViewPricing);
        }
    }, [user, quote?.customerID]);

    //#endregion

    if (!quote || !oKey || !permissions || isContractorQuote === undefined) {
        return <LoadingLinearProgress />;
    }

    return (
        <>
            <Container maxWidth="lg" sx={{ marginTop: 1 }}>
                <FormProvider {...formMethods}>
                    <form id="quoteForm" onSubmit={handleSubmit(onSubmit, onError)}>
                        <Box display="flex" flexDirection="column" gap={1} >
                            {(webCustomerGUID) &&
                                <>
                                    <Box display="flex" flexDirection="column" gap={1} >
                                        <QuoteInfo
                                            expanded={expandedState.info} onExpandedChange={handleChange("info")}
                                            webCustomerGUID={webCustomerGUID} webCustomerID={webCustomerID}
                                            oKey={oKey} isUploaded={quote?.status === OrderStatusEnum.Uploaded}
                                            permissions={permissions}
                                            originalClassificationID={quote?.classificationID ?? null}
                                            showWebCustomer={true} canEditWebCustomer={permissions?.webCustomer ?? false}
                                            onEditWebCustomerClick={onEditWebCustomerClick} />
                                        <Sales
                                            expanded={expandedState.sales} onExpandedChange={handleChange("sales")}
                                            parentCustomerID={quoteDefaults!.parentCustomerID}
                                            parentSiteID={quoteDefaults!.parentSiteID}
                                            pricingMethod={quoteDefaults!.pricing.pricingMethod}
                                            permissions={permissions}
                                            markdownVisible={markdownVisible} />
                                        <Billing
                                            expanded={expandedState.billing} onExpandedChange={handleChange("billing")}
                                            permissions={permissions} />
                                        <Shipping
                                            expanded={expandedState.shipping} onExpandedChange={handleChange("shipping")}
                                            parentCustomerID={quoteDefaults!.parentCustomerID}
                                            permissions={permissions} />
                                        {(mfgShippingAddresses && mfgShippingAddresses.length > 0) &&
                                            <MfgShipTo
                                                mfgCustomerID={mfgCustomerID} mfgSiteID={mfgSiteID}
                                                permissions={permissions} />
                                        }
                                    </Box>
                                </>
                            }
                        </Box>
                    </form>
                </FormProvider>
            </Container>
            <SelectWebCustomerDialog
                isOpen={isEditCustomerOpen}
                onClose={onEditCustomerClose}
                contractorCustomerID={contractorCustomerID}
                isContractorQuote={isContractorQuote}
                permissions={permissions}
                defaultMfgCustomer={{ customerID: mfgCustomerID, siteID: mfgSiteID }}
                defaultWebCustomerID={webCustomerID}
                onWebCustomerChanged={onUserChangedWebCustomer}

            />
        </>
    );
}

export default EditQuote;
