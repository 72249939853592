import AuthHandler from "helpers/context/Authentication/AuthHandler";
import React, { PropsWithChildren } from "react";


export interface IAuthenticationInfo {

    signedIn: boolean;
    timedOut: boolean;

};

const initialState: IAuthenticationInfo = {
    signedIn: false,
    timedOut: false,
};


export enum TimeoutActionEnum {
    SetSignin,
    TimedOut,
    Reset
}

export type TimeoutAction =
    { action: TimeoutActionEnum.SetSignin, value: boolean } |
    { action: TimeoutActionEnum.TimedOut } |
    { action: TimeoutActionEnum.Reset }


const AuthReducer: (state: IAuthenticationInfo, action: TimeoutAction) => IAuthenticationInfo = (state, action) => {

    switch (action.action) {

        case TimeoutActionEnum.SetSignin:
            return { ...state, signedIn: action.value };
        case TimeoutActionEnum.TimedOut:
            return { ...state, timedOut: true };
        case TimeoutActionEnum.Reset:
            return { signedIn: false, timedOut: false };
    }
}



export const AuthHandlerContext = React.createContext<AuthHandler | undefined>(undefined);
export const AuthenticationContext = React.createContext<IAuthenticationInfo>(initialState);

const AuthenticationContextProvider = (props: PropsWithChildren<any>) => {

    const [authInfo, authDispatch] = React.useReducer(AuthReducer, initialState)

    const authHandler = React.useMemo(() => {
        return new AuthHandler(authDispatch)
    }, [authDispatch]);



    return <AuthenticationContext.Provider value={authInfo}>
        <AuthHandlerContext.Provider value={authHandler}>
            {props.children}
        </AuthHandlerContext.Provider>
    </AuthenticationContext.Provider>

};


export default AuthenticationContextProvider;