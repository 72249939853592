import React, { useCallback } from 'react';
import { Autocomplete, TextField, CircularProgress } from "@mui/material";

import { useController, useFormContext } from 'react-hook-form';
import useTranslations from 'helpers/hooks/useTranslations';
import useCategories from 'components/Quotes/QuoteEntry/AddLineItem/useCategoryList';

import { ICategoryListItem } from "helpers/interfaces";

interface IProps {
    mfgCustomerID: string,
    mfgSiteID: string,
    onChange?: (category: ICategoryListItem | null) => void,
}

const SelectCategory : React.FC<IProps> = ({ mfgCustomerID, mfgSiteID, onChange }: IProps) => {

    const tm = useTranslations();
    const { control } = useFormContext();

    const { categoryList, isLoading } = useCategories(mfgCustomerID, mfgSiteID);

    const { field } = useController({
        name: "category", 
        control,
        rules: { required: {value: true, message: tm.Get("Please select a category")} },
      });

    const selectedCategory = useCallback(() => {
        return categoryList.find((c) => field.value && c.categoryID === field.value.categoryID) ?? null
    }, [categoryList, field]);

    return (
        <Autocomplete 
            id="category"
            autoComplete
            autoSelect
            autoHighlight
            selectOnFocus
            handleHomeEndKeys
            options={categoryList} 
            getOptionLabel={(option : ICategoryListItem) => option.description}
            renderInput={(params) => (
                <TextField 
                    variant="outlined" 
                    {...params} 
                    label={tm.Get("Category")}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                            {isLoading && <CircularProgress color="inherit" size={20} />}
                            {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
                )}
            onChange={(_, data) => {
                field.onChange(data)
                if (onChange) {
                    onChange(data);
                }
            }}
            value={selectedCategory()}
            ref={field.ref}
        /> 
    );

}

export default SelectCategory;