import React, { useCallback } from "react";
import { useNavigate } from "react-router";

import { IconButton, Tooltip } from "@mui/material";
import { GroupWork, FileCopy, Delete, History, Link } from "@mui/icons-material"

import useTranslations from "helpers/hooks/useTranslations";
import useWait from "helpers/context/Page/useWait";
import useUserInfo from "helpers/context/User/useUserInfo";
import useMessageBox from "helpers/context/Page/useMessageBox";

import PrintReport from "components/Quotes/QuoteEntry/Header/PrintReport";
import { IQuote, IQuotePermissions, ILockedObject } from "helpers/interfaces";
import { RoleEnum } from "helpers/enums";
import DownloadReport from "./DownloadReport";
import useQuoteActions from "components/Quotes/useQuoteActions";
import useApplicationInfo from "helpers/context/Application/useApplicationInfo";
import QuoteNavigation from "components/Quotes/QuoteNavigation";

interface IProps {
    quote?: IQuote,
    permissions?: IQuotePermissions,
    lock?: ILockedObject,
    navigateToDashboard: () => void,
    quoteHasRelatedLinks: boolean,
    setRelatedLinksDialogOpen: (value: boolean) => void
}

const QuoteHeaderActions: React.FC<IProps> = ({ quote, permissions, lock, navigateToDashboard, quoteHasRelatedLinks, setRelatedLinksDialogOpen }: IProps) => {

    const tm = useTranslations();
    const navigate = useNavigate();
    const wait = useWait();
    const user = useUserInfo();
    const quoteActions = useQuoteActions();
    const application = useApplicationInfo();
    const messageBox = useMessageBox();

    const handleCopyConfirmation = useCallback(async (dialogResult?: boolean) => {
        if (dialogResult === true && quote?.oKey !== undefined) {
            wait.Show(true);
            const newOKey = await quoteActions.CopyQuoteAsync(quote.oKey);
            if (newOKey !== undefined && newOKey > 0) {
                navigate(`/quotes/?oKey=${newOKey}`);
            }
        }
        wait.Show(false);
    }, [quote, quoteActions, navigate, wait]);

    const handleCopyClick = useCallback(async () => {
        if (quote?.oKey !== undefined) {
            messageBox.Show({ message: tm.Get("Are you sure you want to copy this quote?"), title: tm.Get("Quote Entry"), yesNoPrompt: true, callback: handleCopyConfirmation });
        }
    }, [quote, messageBox, handleCopyConfirmation, tm]);

    const handleGlobalOptionsClick = useCallback(() => {
        navigate(QuoteNavigation.GlobalOptionsURL(quote?.oKey ?? 0));
    }, [navigate, quote]);

    const handleTransLogClick = useCallback(() => {
        navigate(QuoteNavigation.TransLogURL(quote?.oKey ?? 0));
    }, [navigate, quote]);


    const handleDeleteConfirmation = useCallback(async (dialogResult?: boolean) => {
        if (dialogResult === true && quote?.oKey !== undefined) {
            wait.Show(true);
            const result = await quoteActions.DeleteQuoteAsync(quote.oKey);
            if (result) {
                navigateToDashboard();
            }
            wait.Show(false);
        }
    }, [quote, quoteActions, navigateToDashboard, wait]);

    const handleDeleteClick = useCallback(() => {
        if (quote?.oKey !== undefined) {
            messageBox.Show({ message: tm.Get("Are you sure you want to delete this quote?"), title: tm.Get("Quote Entry"), yesNoPrompt: true, callback: handleDeleteConfirmation });
        }
    }, [quote, messageBox, handleDeleteConfirmation, tm]);

    const userCanModifyQuote = React.useMemo(() => {
        if (permissions && lock) {
            return permissions.editQuote && !lock.isLockedByAnotherUser;
        }
        return false;
    }, [permissions, lock]);

    const userCanCopyQuote = React.useMemo(() => {
        if (permissions) {
            return permissions.copyQuote;
        }
        return false;
    }, [permissions]);

    const userCanViewTransLog = React.useMemo(() => {
        if (permissions && user.HasRole(RoleEnum.CSR) && application.parameters.performOrderLocking) {
            return true;
        }
        return false;
    }, [permissions, user, application]);

    const userCanDeleteQuote = React.useMemo(() => {
        if (permissions && lock) {
            return permissions.deleteQuote && !lock.isLockedByAnotherUser;
        }
        return false;
    }, [permissions, lock]);

    return <>
        {quoteHasRelatedLinks &&
            <Tooltip title={tm.Get("Links")}>
                <IconButton aria-label="related links" sx={{ padding: 0 }} onClick={() => setRelatedLinksDialogOpen(true)}>
                    <Link color="primary" />
                </IconButton>
            </Tooltip>
        }

        {quote && <>
            <PrintReport quote={quote} />
            <DownloadReport quote={quote} />
        </>}

        {userCanModifyQuote &&
            <Tooltip title={tm.Get("Global Option Changes")}>
                <IconButton aria-label="global options" sx={{ padding: 0 }} onClick={handleGlobalOptionsClick}>
                    <GroupWork color="primary" />
                </IconButton>
            </Tooltip>
        }
        {userCanCopyQuote &&
            <Tooltip title={tm.Get("Copy")}>
                <IconButton aria-label="copy" sx={{ padding: 0 }} onClick={handleCopyClick}>
                    <FileCopy color="primary" />
                </IconButton>
            </Tooltip>
        }
        {userCanViewTransLog &&
            <Tooltip title={tm.Get("Transaction Log")}>
                <IconButton aria-label="log" sx={{ padding: 0 }} onClick={handleTransLogClick}>
                    <History color="primary" />
                </IconButton>
            </Tooltip>
        }
        {userCanDeleteQuote &&
            <Tooltip title={tm.Get("Delete")}>
                <IconButton aria-label="delete" sx={{ padding: 0 }} onClick={handleDeleteClick}>
                    <Delete color="primary" />
                </IconButton>
            </Tooltip>
        }
    </>;
};

export default QuoteHeaderActions;
