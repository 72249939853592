import React from "react"
import { GridCallbackDetails, GridRowIdGetter, GridRowModel } from "@mui/x-data-grid-pro";
import DataGridColumnGenerator from "components/Common/DataGridColumnGenerator";
import useIsMobile from "helpers/hooks/useIsMobile";
import { useTheme } from "@mui/material";
import useTranslations from "helpers/hooks/useTranslations";
import { IDashboardQuote } from "models/IDashboardQuote";
import useDashboardCompanyQuoteData from "./useDashboardCompanyQuoteData";
import useCurrencyFormatter from "helpers/hooks/useCurrencyFormatter";
import useUserInfo from "helpers/context/User/useUserInfo";
import useRowsPerPage from "helpers/hooks/useRowsPerPage";
import CustomDataGridPro from "components/Common/CustomDataGridPro";

const CompanyQuotesGrid: React.FC = () => {

    const tm = useTranslations();
    const cf = useCurrencyFormatter();
    const theme = useTheme();
    const data: IDashboardQuote[]|undefined = useDashboardCompanyQuoteData();
    const isMobile = useIsMobile();
    const user = useUserInfo();
    const rowsPerPage = useRowsPerPage("DashboardCompanyQuote");

    const getRowId: GridRowIdGetter = (row: GridRowModel) => {
        return row.oKey;
    }

    const generator = new DataGridColumnGenerator(tm, data, theme, isMobile, cf);

    generator.TryAddDocumentNumberColumn("oKey", "orderNumber", "Quote", "/quotes", { hideable: false });
    generator.TryAddWebCustomerColumn("webCenterCustomerName", "customerInactive", "Customer", { hideInMobile: false });
    generator.TryAddDateColumn("orderDate", "Date", { hideInMobile: true });
    generator.TryAddTextColumn("poNumber", "PO Number", { hideInMobile: true });
    generator.TryAddTextColumn("customerRef", "Customer Ref", { hideInMobile: true });
    generator.TryAddTotalPriceColumn({});
    generator.TryAddDateColumn("expirationDate", "Expires", { hideInMobile: false });
    if (user.isMultiSite) {
        generator.TryAddTextColumn("mfgSiteName", "Mfg Customer", { hideInMobile: true });
    }

    const onPageSizeChange = (pageSize: number, details: GridCallbackDetails) => rowsPerPage.setPageSize(pageSize);

    return <CustomDataGridPro
        rows={data}
        columns={generator.GetColumns()}
        getRowId={getRowId}
        onPageSizeChange={onPageSizeChange}
        pageSize={rowsPerPage.pageSize}
        rowsPerPageOptions={rowsPerPage.pageSizeOptions}
    />

};

export default CompanyQuotesGrid;