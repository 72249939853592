import React, { useState, useCallback, useMemo } from "react"
import { Autocomplete, Typography, Dialog, DialogContent, DialogActions, TextField, Stack, RadioGroup, Radio } from "@mui/material"
import useTranslations from "helpers/hooks/useTranslations";
import DialogTitleWithClose from "components/Dialog/DialogTitleWithClose";
import { ISimpleListItem } from "helpers/interfaces";
import OKCancelControl from "components/Common/OKCancelControl";
import ErrorAdornment from "components/Common/ErrorAdornment";

interface IProps {
    tables: ISimpleListItem[];
    sourceTable: string;
    open: boolean;
    close: () => void;
    handleCopy: (name: string, targetTable: number) => void;
}

const CopyTable: React.FC<IProps> = ({tables, sourceTable, open, close, handleCopy}) => {
    const tm = useTranslations();
    const message = tm.Get("Copy pricing data to a new table or an existing table");

    const [copyToExistingTable, setCopyToExistingTable] = useState<boolean>(false);
    const [newTable, setNewTable] = useState<string>("");
    const [existingTable, setExistingTable] = useState<number>(tables[0].id.toString() === sourceTable ? tables[1]?.id : tables[0].id);
    const [errorText, setErrorText] = useState<string>("");

    const handleCopyTypeChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, value: string) => {
        if (value === "false") {
            setCopyToExistingTable(false);
        } else {
            setCopyToExistingTable(true);
            setErrorText("");
        }
    }, []);

    const handleNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setNewTable(e.target.value);
    }, []);

    const validate = useCallback((name: string) => {
        if (name.trim() === "") {
            return tm.Get("The table name cannot be an empty string.")
        }
        return "";
    }, [tm]);

    const handleNameBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
        setErrorText(validate(e.target.value));
    }, [validate]);

    const handleTableChange = useCallback((selectedTable: number) => {
        setExistingTable(selectedTable);
    }, []);
    
    const trySubmit = useCallback((name: string) => {
        let error = validate(name);
        if (error === "") {
            handleCopy(name, 0);
        } else {
            setErrorText(error);
        }
    }, [handleCopy, validate]);

    const table = useMemo(() => {
        return tables.find((t) => t.id === existingTable) ?? {id: 0, description: ""};
    }, [tables, existingTable]);

    return <>
        <Dialog fullWidth
            onClose={close}
            open={open}
        >
            <DialogTitleWithClose onClose={close}>
                {tm.GetWithParams("Copy {0}", tm.Get("Price Table"))}
            </DialogTitleWithClose>
            <DialogContent>
                <Stack direction="column" alignItems="flex-start" justifyItems="stretch" spacing={2}>
                    <Typography whiteSpace="pre-wrap">
                        {message}
                    </Typography>
                    <RadioGroup value={copyToExistingTable} sx={{width: "100%"}} onChange={handleCopyTypeChange}>
                        <Stack direction="column" alignItems="flex-start" spacing={2}>
                            <Stack direction="row" width={1}>
                                <Radio value={false} />
                                <TextField size="small" label={tm.Get("New Table")} value={newTable} onChange={handleNameChange} onBlur={handleNameBlur} fullWidth 
                                    disabled={copyToExistingTable}
                                    error={errorText !== ""}
                                    InputProps={{
                                        endAdornment: errorText !== "" ? 
                                            <ErrorAdornment validationError={errorText}/> : null
                                    }}
                                />
                            </Stack>
                            <Stack direction="row" width={1}>
                                <Radio value={true} disabled={tables.length <= 1} />
                                <Autocomplete
                                    id="select-target-table"
                                    autoComplete
                                    autoHighlight
                                    autoSelect
                                    selectOnFocus
                                    disableClearable
                                    handleHomeEndKeys
                                    options={tables.filter((t) => t.id.toString() !== sourceTable)}
                                    fullWidth
                                    size="small"
                                    disabled={!copyToExistingTable}
                                    getOptionLabel={(option : ISimpleListItem) => option.description}
                                    renderInput={(params) => <TextField
                                        label={tm.Get("Existing Table")}
                                        {...params}
                                    />}
                                    value={table}
                                    onChange={(_, data) => handleTableChange(data.id)}
                                />
                            </Stack>
                        </Stack>
                    </RadioGroup>
                </Stack>
            </DialogContent>
            <DialogActions>
                <OKCancelControl 
                    okCommand={() => {
                        if (copyToExistingTable) {
                            handleCopy("", existingTable);
                        } else {
                            trySubmit(newTable.trim());
                        }
                    }}
                    cancelCommand={close}
                />
            </DialogActions>
        </Dialog>
    </>
}

export default CopyTable;