import { Accordion, AccordionDetails, Grid, Typography } from "@mui/material";
import AccordionSummarySlim from "components/Common/AccordionSummarySlim";
import CheckBoxForm from "components/Common/CheckBoxForm";
import SelectForm from "components/Common/SelectForm";
import TextFieldForm from "components/Common/TextFieldForm";
import Constants from "helpers/constants";
import useMeasurementTypes from "helpers/context/SelectionValues/useMeaurementTypes";
import useTranslations from "helpers/hooks/useTranslations";
import React from 'react';
import { useFormContext } from "react-hook-form";
import CustomerStatusChips from "./CustomerStatusChips";

interface IProps {
    expanded: boolean,
    onExpandedChange: (event: React.SyntheticEvent, expanded: boolean) => void,
    readOnly: boolean,
    isContractor: boolean
}

const CustomerGeneral: React.FC<IProps> = (props: IProps) => {

    const tm = useTranslations();
    const measurementTypes = useMeasurementTypes();
    const { watch, formState: { errors } } = useFormContext();

    const isInactive = watch("customer.inactive");
    const isDefault = watch("customer.default");

    const showStatusChips = props.readOnly && (isInactive || isDefault);

    return <>
        <Accordion expanded={props.expanded} onChange={props.onExpandedChange} disableGutters >
            <AccordionSummarySlim>
                <Typography>
                    {tm.Get("General")}
                </Typography>
            </AccordionSummarySlim>
            <AccordionDetails>
                <Grid container spacing={2} padding={0}>
                    <Grid item xs={12} sm={props.readOnly ? 3 : 12}>
                        <TextFieldForm
                            fieldName="customer.name"
                            readOnly={props.readOnly}
                            requiredRule={{ value: true, message: tm.GetWithParams("{0} Required", tm.Get("Name")) }}
                            validateValue={{
                                isNotEmpty: (value: string) => new RegExp("^(?!\\s*$).+").test(value) || tm.GetWithParams("{0} cannot be blank.", tm.Get("Name"))
                            }}
                            error={errors.customer?.name?.message ? true : false}
                            helperText={errors.customer?.name?.message}
                            variant="standard"
                            id="customer.name"
                            fullWidth
                            inputProps={{ maxLength: Constants.MaxLength.CustomerName }}
                            label={tm.Get("Name")}
                            placeholder={tm.Get("Name")} />
                    </Grid>
                    {showStatusChips &&
                        <Grid item xs>
                            <CustomerStatusChips isInactive={isInactive} isDefault={isDefault} />
                        </Grid>
                    }
                    {!props.readOnly &&
                        <Grid item xs={12} md={6}>
                            <CheckBoxForm
                                fieldName="customer.inactive"
                                label={tm.Get("Inactive")}
                                id="customer.inactive"
                                readOnly={props.readOnly}
                            />
                        </Grid>
                    }
                    {!props.readOnly &&
                        <Grid item xs={12} md={6}>
                            <CheckBoxForm
                                fieldName="customer.default"
                                label={tm.Get("Default")}
                                id="customer.default"
                                readOnly={props.readOnly}
                            />
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <TextFieldForm
                            fieldName="customer.notes"
                            readOnly={props.readOnly}
                            variant="outlined"
                            id="customer.notes"
                            fullWidth
                            inputProps={{ maxLength: Constants.MaxLength.CustomerNotes }}
                            label={tm.Get("Notes")}
                            placeholder={tm.Get("Notes")}
                            multiline={true}
                            rows={2} />
                    </Grid>
                    <Grid item xs={12}>
                        <SelectForm
                            fieldName="customer.engineeringUnitSetID"
                            dataSource={measurementTypes}
                            keyField="setID"
                            valueField="setID"
                            displayField="description"
                            translateDisplayField={true}
                            label={tm.Get("Measurement Type")}
                            readOnly={props.readOnly}
                        />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    </>;
}

export default CustomerGeneral;