import React from "react";
import { GridActionsCellItem, GridColumnHeaderParams, GridEnrichedColDef, GridRowParams } from "@mui/x-data-grid-pro";
import { Delete, Edit } from "@mui/icons-material";
import { ITranslationManager } from "helpers/hooks/useTranslations";
import AlertDialog from "components/Common/AlertDialog";
import { Tooltip } from "@mui/material";

interface IEditCommandProps {
    params: GridRowParams;
    editActionHandler: (data: any) => void;
}

interface IDeleteCommandProps {
    params: GridRowParams;
    deleteActionHandler: (data: any) => void;
}

const editDeleteCommandColumn = (editHandler: (data: any) => void, deleteHandler: (data: any) => void, tm: ITranslationManager, displayMember: string, subHeaderText: string) => {

    const minWidth = 92;

    const EditCommand: React.FC<IEditCommandProps> = ({ params, editActionHandler }: IEditCommandProps) => {
        const item: string = params.row[displayMember] as string;

        const handlerShowDialog = React.useCallback(() => {
            editActionHandler(item);
        }, [editActionHandler, item]);

        return <>
            <Tooltip title={tm.Get(`Edit ${subHeaderText} Record`)}>
                <GridActionsCellItem
                    icon={<Edit />}
                    color="primary"
                    label={tm.GetWithParams("Edit {0}", tm.Get(subHeaderText))}
                    onClick={handlerShowDialog}
                />
            </Tooltip>
        </>
    };

    const DeleteCommand: React.FC<IDeleteCommandProps> = ({ params, deleteActionHandler }: IDeleteCommandProps) => {

        const [open, setOpen] = React.useState<boolean>(false);

        const item: string = params.row[displayMember] as string;

        const handlerShowDialog = React.useCallback(() => {
            setOpen(true);
        }, [setOpen]);

        const handleDialogClose = React.useCallback((result: boolean) => {
            if (result) {
                deleteActionHandler(item);
            }
            setOpen(false);
        }, [deleteActionHandler, item])

        return <>
            <Tooltip title={tm.Get(`Delete ${subHeaderText} Record`)}>
                <GridActionsCellItem
                    icon={<Delete />}
                    color="primary"
                    label={tm.GetWithParams("Delete {0}", tm.Get(subHeaderText))}
                    onClick={handlerShowDialog}
                />
            </Tooltip>
            <AlertDialog open={open}
                handleClose={handleDialogClose}
                headerText={tm.Get("Delete")}
                message={tm.GetWithParams("Are you sure you want to delete {0}?", item)}
            />
        </>
    }

    const col = {
        field: "Commands",
        type: "actions",
        renderHeader: (params: GridColumnHeaderParams) => {
            return <div style={{ opacity: 0 }}>
                Commands
            </div>
        },
        minWidth: minWidth,
        filterable: false,
        sortable: false,
        getActions: (params: GridRowParams) => {

            const actions: JSX.Element[] = [];

            actions.push(<EditCommand params={params} editActionHandler={editHandler} />);
            actions.push(<DeleteCommand params={params} deleteActionHandler={deleteHandler} />);

            return actions;
        },
    } as GridEnrichedColDef;

    return col;
}

export default editDeleteCommandColumn;