import * as React from "react";

import { ICode, IQuestion } from "../interfaces";
import { OptionSequenceTypeEnum } from "../enum";
import useTranslations, { ITranslationManager } from "helpers/hooks/useTranslations";
import { styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import OptionGroupBox from "../OptionsWizard/OptionGroupBox";

interface IOptionsProps {
    options: Array<ICode | IQuestion>;
    tm: ITranslationManager;
}


const OptionSummary: React.FC<IOptionsProps> = (props: IOptionsProps) => {

    const tm = useTranslations();

    const [selections, setSelections] = React.useState<ICode[]>(() => EvaluateCurrentlySelected(props.options));

    const StyledRow = styled(TableRow)(() => ({
        '&:last-child td': { border: 0 }
    }));    

    React.useEffect(() => {
        setSelections(EvaluateCurrentlySelected(props.options));
    }, [props.options])

    function EvaluateCurrentlySelected(options: Array<ICode | IQuestion>): Array<ICode> {

        let i: number = 0;
        let selectedCodes: Array<ICode> = new Array<ICode>();

        for (i = 0; i < options.length; i++) {
            if (options[i].sequenceType === OptionSequenceTypeEnum.Question) {
                GetSelectedOptionsForQuestion(selectedCodes, (options[i] as IQuestion));
            } else {
                GetSelectedOptionsForCode(selectedCodes, (options[i] as ICode));
            }
        }

        return selectedCodes;

    }

    function GetSelectedOptionsForQuestion(selectedCodes: Array<ICode>, q: IQuestion) {
        for (let c of q.codes) {
            GetSelectedOptionsForCode(selectedCodes, c);
        }

        if (q.nestedQuestions) {
            for (let nq of q.nestedQuestions) {
                GetSelectedOptionsForQuestion(selectedCodes, nq);
            }
        }

    }

    function GetSelectedOptionsForCode(selectedCodes: Array<ICode>, c: ICode) {
        if (c.selected && c.visible) {
            selectedCodes.push(c);
        }

        if (c.nestedQuestions) {
            for (let nq of c.nestedQuestions) {
                GetSelectedOptionsForQuestion(selectedCodes, nq);
            }
        }

    }


    return <OptionGroupBox id="OPTION_SUMMARY" label="">

        <TableContainer>
            <Table>
                <TableHead >
                    <StyledRow>
                        <TableCell component="th">{tm.Get("Question")}</TableCell>
                        <TableCell component="th">{tm.Get("Option")}</TableCell>
                        <TableCell component="th">{tm.Get("Description")}</TableCell>
                        <TableCell component="th">{tm.Get("Input")}</TableCell>
                    </StyledRow>
                </TableHead>
                <TableBody>
                    {selections.map((c: ICode) => <StyledRow key={c.question + c.code + c.value}>

                        <TableCell>{c.question}</TableCell>
                        <TableCell>{c.code}</TableCell>
                        <TableCell>{c.description}</TableCell>
                        <TableCell>{c.value}</TableCell>

                    </StyledRow>)}

                </TableBody>

            </Table>
        </TableContainer>

    </OptionGroupBox>;

}

export default OptionSummary;
