import { Dialog, DialogContent, Stack } from "@mui/material";
import DialogTitleWithClose from "components/Dialog/DialogTitleWithClose";
import useTranslations from "helpers/hooks/useTranslations";
import React from 'react';
import ProductionStatusLink from "./ProductionStatusLink";
import RelatedQuoteLink from "./RelatedQuoteLink";

interface IProps {
    hasProductionStatus: boolean,
    hasRelatedQuote: boolean,
    isOpen: boolean,
    onClose: () => void
}

const RelatedLinksDialog: React.FC<IProps> = ({ isOpen, onClose, hasProductionStatus, hasRelatedQuote }: IProps) => {

    const tm = useTranslations();

    return <Dialog open={isOpen} onClose={onClose} fullWidth>
        <DialogTitleWithClose onClose={onClose}>{tm.Get("Links")}</DialogTitleWithClose>
        <DialogContent>
            <Stack direction="column" spacing={1} alignItems="flex-start">
                {hasProductionStatus &&
                    <ProductionStatusLink />
                }
                {hasRelatedQuote &&
                    <RelatedQuoteLink />
                }
            </Stack>
        </DialogContent>
    </Dialog>
}

export default RelatedLinksDialog;