import { Dialog, DialogActions, DialogContent, Grid, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import OKCancelControl from "components/Common/OKCancelControl";
import Translation from "components/Common/Translation";
import OptionsAPI, { ISelectItem, ISplitParts } from "components/OptionsWizard/OptionsAPI";
import useIsMobile from "helpers/hooks/useIsMobile";
import useTranslations from "helpers/hooks/useTranslations";
import React, { ChangeEvent } from 'react';

interface IProps {
    dialogVisible: boolean;
    onCancel: () => void;
    onSubmit: (qty: number, part: string, sideBySide: boolean) => void;
    sideBySide: boolean;
}

const SplitSectionsDialog: React.FC<IProps> = ({ dialogVisible, onCancel, onSubmit, sideBySide }: IProps) => {

    const isMobile = useIsMobile();
    const tm = useTranslations();

    const [splitParts, setSplitParts] = React.useState<ISplitParts | null>(null);

    React.useEffect(() => {
        if (dialogVisible) {
            OptionsAPI.GetSplitParts(sideBySide).then(s => {
                setSplitParts(s);
            });
        }
    }, [sideBySide, dialogVisible]);

    if (splitParts && dialogVisible) {

        return <Dialog
            open={dialogVisible}
            onClose={onCancel}
            fullScreen={isMobile}
            title={tm.Get("Split Section")}
        >
            <SplitSections
                sideBySide={sideBySide}
                partList={splitParts.parts}
                defaultPart={splitParts.default}
                onCancel={onCancel}
                onSubmit={onSubmit}
            />
        </Dialog>;
    } else return null;
}

interface ISSProps {
    sideBySide: boolean;
    partList: ISelectItem[];
    defaultPart: string;
    onCancel: () => void;
    onSubmit: (qty: number, part: string, sideBySide: boolean) => void;
}


const SplitSections: React.FC<ISSProps> = ({ sideBySide, partList, defaultPart, onCancel, onSubmit }: ISSProps) => {

    const isMobile = useIsMobile();
    const [sectionCount, setSectionCount] = React.useState<number>(2);
    const [selectedPartKey, setSelectedPartKey] = React.useState<string>(defaultPart);

    React.useEffect(() => {
        setSelectedPartKey(defaultPart);
        setSectionCount(2);
    }, [defaultPart, sideBySide])

    const handleSectionCountChange = React.useCallback((e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let newSectionCount = parseInt(e.target.value);
        if (isNaN(newSectionCount)) {
            setSectionCount(0);
        } else {
            setSectionCount(newSectionCount);
        }
    }, [])

    const handlePartKeyChange = React.useCallback((e: SelectChangeEvent<any>) => {
        setSelectedPartKey(e.target.value);
    }, []);

    const HandleSubmitClick = React.useCallback(() => {
        onSubmit(sectionCount, selectedPartKey, sideBySide);
    }, [onSubmit, sectionCount, selectedPartKey, sideBySide]);

    return <>
        <DialogContent>

            <Grid container direction="column" xs spacing={1} minWidth={isMobile ? undefined : 500}   >

                <Grid item xs>
                    <Translation>
                        {sideBySide ? "Side By Side Split" : "Stack Split"}
                    </Translation>
                </Grid>

                <Grid item xs container direction={"row"} alignItems={"center"} columnSpacing={1}>

                    <Grid item xs={3}>
                        <span><Translation>Evenly Into:</Translation></span>
                    </Grid>

                    <Grid item xs>
                        <TextField
                            fullWidth
                            value={sectionCount}
                            onChange={handleSectionCountChange}
                            InputProps={{
                                // startAdornment: 
                                endAdornment: <Typography><Translation>Sections</Translation></Typography>,
                                inputProps: { inputMode: "numeric", pattern: "[0-9]*" }
                            }}
                        />
                    </Grid>

                </Grid>

                <Grid item xs container direction={"row"} alignItems={"center"} columnSpacing={1}>

                    <Grid item xs={3}>
                        <Typography><Translation>Split Part:</Translation></Typography>
                    </Grid>

                    <Grid item xs>

                        <Select
                            fullWidth
                            value={selectedPartKey}
                            onChange={handlePartKeyChange}
                        >
                            {partList?.map(pl =>
                                <MenuItem key={pl.value} value={pl.value} >{pl.text}</MenuItem>
                            )}
                        </Select>
                    </Grid>
                </Grid>
            </Grid>
        </DialogContent>

        <DialogActions>
            <OKCancelControl okCommand={HandleSubmitClick} cancelCommand={onCancel} />
        </DialogActions>
    </>
};

export default SplitSectionsDialog;