import { Alert, AlertColor, Container, Snackbar, Stack, TextField } from "@mui/material";
import MaintenanceFilter from "components/Common/MaintenanceFilter";
import QuotesAPI from "components/Quotes/QuotesAPI";
import UserAPI from "components/User/UserAPI";
import { IActionButton } from "helpers/context/Page/PageContext";
import useActionButtons from "helpers/context/Page/useActionButtons";
import useWait from "helpers/context/Page/useWait";
import useWindowTitle from "helpers/context/Title/useWindowTitle";
import useUserInfo from "helpers/context/User/useUserInfo";
import { MaintenanceFilterEnum, RoleEnum, ThemeColorEnum } from "helpers/enums";
import { useEffectOnLoad } from "helpers/hooks/useEffectOnLoad";
import useIsMobile from "helpers/hooks/useIsMobile";
import useTranslations from "helpers/hooks/useTranslations";
import { IUserSummary, IWebCustomer } from "helpers/interfaces";
import { searchObjectStringProperties } from "helpers/objects";
import React from 'react';
import { useNavigate } from "react-router-dom";
import CustomerSelect from "./CustomerSelect";
import UserTable from "./UserTable";
import useUserSetupActions from "./useUserSetupActions";
import useUserSetupData from "./useUserSetupData";
import useApplicationInfo from "helpers/context/Application/useApplicationInfo";

const Users: React.FC = () => {

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isPosting, setIsPosting] = React.useState<boolean>(false);
    const [alert, setAlert] = React.useState<[text: string, alertType: AlertColor] | null>(null);

    const [customers, setCustomers] = React.useState<IWebCustomer[] | null>(null);
    const [selectedCustomer, setSelectedCustomer] = React.useState<IWebCustomer | null>(null);

    const [users, setUsers] = React.useState<IUserSummary[] | null>(null);
    const [userFilter, setUserFilter] = React.useState<MaintenanceFilterEnum>(MaintenanceFilterEnum.All);
    const [searchText, setSearchText] = React.useState<string | null>(null);

    const actionButtons = useActionButtons();
    const appInfo = useApplicationInfo();
    const tm = useTranslations();
    const wait = useWait();
    const user = useUserInfo();
    const userData = useUserSetupData();
    const userActions = useUserSetupActions();
    const isMobile = useIsMobile();
    const navigate = useNavigate();
    useWindowTitle(tm.Get("Users"));

    const companyCustomer: IWebCustomer = {
        customerGUID: "-1",
        customerID: "-1",
        siteID: "-1",
        name: tm.Get("{Company}"),
        isDefault: false,
        isInactive: false
    }

    const filteredUsers = React.useMemo(() => {
        if (!users) return null;

        let filtered = users;
        if (userFilter !== MaintenanceFilterEnum.All) {
            filtered = filtered.filter(user => userFilter === MaintenanceFilterEnum.Active ? !user.displayInactive : user.displayInactive)
        }
        if (searchText && searchText !== "") {
            filtered = filtered.filter(user => searchObjectStringProperties(searchText, user, ["site"]))
        }

        return filtered;

    }, [userFilter, users, searchText])

    React.useEffect(() => {
        actionButtons.Clear();
        const addCustomerButton: IActionButton = {
            text: tm.GetWithParams("Add {0}", tm.Get("User")),
            color: ThemeColorEnum.Secondary,
            disabled: (isLoading || isPosting),
            onClick: (() => {
                if (selectedCustomer) {
                    const url = selectedCustomer.customerGUID === "-1" ? "add" : `add/${encodeURIComponent(selectedCustomer.customerGUID)}`;
                    navigate(url);
                }
            })
        }
        actionButtons.Set(0, addCustomerButton);
    }, [actionButtons, isLoading, isPosting, navigate, selectedCustomer, tm])

    React.useEffect(() => {
        wait.Show(isLoading || isPosting);
    }, [isLoading, isPosting, wait])

    useEffectOnLoad(() => {
        if (user.HasRole(RoleEnum.Contractor) || !appInfo.extendedContractorModeUnlocked) {
            setSelectedCustomer(companyCustomer);
        } else {
            setIsLoading(true);
            //Pass empty string for SiteID, this will grab from all sites if the user is multisite
            QuotesAPI.GetWebCustomerList(user.mfgCustomerID, "", false)
                .then(result => {
                    setCustomers(result.Result);
                    setSelectedCustomer(companyCustomer);
                })
                .finally(() => {
                    userActions.SetIsDealerCreatingAdminContractor(false);
                    setIsLoading(false);
                })
        }
    });

    const getUsers = React.useCallback(async (selectedCustomer) => {
        setIsLoading(true);
        try {
            let result = (user.HasRole(RoleEnum.Contractor) || !userData.isDealerCreatingAdminContractor) ?
                await UserAPI.GetUsers(user.employeeID) :
                await UserAPI.GetContractorUsers(selectedCustomer.customerID, selectedCustomer.siteID, user.parentCustomerID);
            setUsers(result);
        } finally {
            setIsLoading(false);
        }
    }, [user, userData.isDealerCreatingAdminContractor]);

    React.useEffect(() => {
        if (selectedCustomer) {
            getUsers(selectedCustomer);
        }
    }, [getUsers, selectedCustomer]);

    const handleEditUser = (employeeID: string) => {
        const url = `edit/${encodeURIComponent(employeeID)}`;
        navigate(url);
    }

    const handleDeleteUser = async (employeeID: string) => {
        setIsPosting(true);
        try {
            let result = await UserAPI.DeleteUser(employeeID);
            if (result.message) {
                const translated = tm.Get(result);
                setAlert([translated, "error"]);
            } else {
                if (selectedCustomer) {
                    await getUsers(selectedCustomer);
                    setAlert([tm.Get("Deleted"), "success"]);
                }
            }
        } finally {
            setIsPosting(false);
        }
    }

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway')
            return;
        setAlert(null);
    }

    return <>
        <Snackbar open={alert !== null} autoHideDuration={5000} onClose={handleClose} >
            <Alert onClose={handleClose} severity={alert ? alert[1] : "info"} variant='filled' sx={{ width: '100%', fontWeight: 'bold' }}>{alert ? alert[0] : ""}</Alert>
        </Snackbar>

        <Container sx={{ marginY: user.HasRole(RoleEnum.Contractor) ? 1 : 2 }} maxWidth={isMobile ? "md" : "xl"}>
            <Stack gap={1} spacing={1}>
                {!user.HasRole(RoleEnum.Contractor) && appInfo.extendedContractorModeUnlocked &&
                    <CustomerSelect
                        customers={customers}
                        setSelectedCustomer={setSelectedCustomer}
                    />
                }
                <Stack direction={isMobile ? "column" : "row"} spacing={isMobile ? 1 : 3}>
                    <MaintenanceFilter
                        currentFilter={userFilter}
                        setFilter={setUserFilter}
                    />
                    <TextField
                        value={searchText ?? ""}
                        onChange={(e) => setSearchText(e.target.value)}
                        placeholder={tm.Get("Search")}
                        label={tm.Get("Search")}
                    />
                </Stack>
                <UserTable
                    users={filteredUsers ?? users}
                    showUserSite={!user.HasRole(RoleEnum.Contractor) && !userData.isDealerCreatingAdminContractor}
                    isDealerCreatingAdminContractor={userData.isDealerCreatingAdminContractor}
                    editUser={handleEditUser}
                    deleteUser={handleDeleteUser}
                />
            </Stack>
        </Container>
    </>;
}

export default Users;