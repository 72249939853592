
import React, { useMemo, useRef } from 'react';

interface IImageLoaderState {
    loading: boolean;
    imageUrl: string | undefined;
    loadedImageUrl: string | undefined;
    delayTimeout: NodeJS.Timeout | null;
    loadComplete: boolean;
}

export type LoadingAction =
    { type: "BEGIN", imageUrl: string, timerFunction: () => void } |
    { type: "TIMERTICK" } |
    { type: "LOADCOMPLETE" } |
    { type: "UNMOUNT" }

const initialState: IImageLoaderState = {
    loading: false,
    imageUrl: undefined,
    loadedImageUrl: undefined,
    delayTimeout: null,
    loadComplete: false,
}

/*
 * This hook allows a component to download an image in the background before updating the visible image.
 * A spinner is displayed over the top of the previously visible image while loading the new one. There is a 1/4 second delay before showing
 * the spinner to prevent a flicker when an image is being loaded from the browser cache.
 */

const ImageLoaderReducer: (existingState: IImageLoaderState, action: LoadingAction) => IImageLoaderState = (existingState, action) => {


    switch (action.type) {
        case "BEGIN":
            const timer = setTimeout(() => {
                action.timerFunction();
            }, 250);

            return { ...existingState, imageUrl: action.imageUrl, loading: true, delayTimeout: timer, loadComplete: false };

        case "TIMERTICK":

            if (!existingState.loadComplete) {
                return { ...existingState, loading: true };
            } else {
                return existingState;
            }


        case "LOADCOMPLETE":

            if (existingState.delayTimeout) {
                clearTimeout(existingState.delayTimeout);
            }

            return {
                ...existingState,
                loadedImageUrl: existingState.imageUrl,
                loading: false,
                delayTimeout: null,
                loadComplete: true,
            }

        case "UNMOUNT":
            //Clear timer on unmount
            if (existingState.delayTimeout) {
                clearTimeout(existingState.delayTimeout);
                return { ...existingState, delayTimeout: null }

            } else {
                return { ...existingState }
            }

    }


};

function useImageLoader(src: string | undefined): [loading: boolean, loadedImageUrl: string | undefined] {


    const ref = useRef<HTMLImageElement | null>(new Image());

    const [state, stateDispatcher] = React.useReducer(ImageLoaderReducer, initialState);

    const image = useMemo(() => {
        return ref.current;
    }, [ref]);

    React.useEffect(() => {
        if (image) {
            image.onload = () => {
                stateDispatcher({ type: "LOADCOMPLETE" });
            };
        }

        return () => {
            // cleanup event handlers
            if (image) {
                image.onload = null;
            }
        }
    }, [image]);


    React.useEffect(() => {

        if (src && image) {
            const srcChanged = (image.src !== src);
            image.src = "";
            if (srcChanged) {
                image.src = src;

                //Delay the visibility of the <Waiting> by 1/4 second.  this prevents a flicker if loading from the cache
                stateDispatcher({
                    type: "BEGIN", imageUrl: src, timerFunction: () => {
                        stateDispatcher({ type: "TIMERTICK" })
                    }
                });
            }
        }

        return () => {
            //Clear timer on unmount
            stateDispatcher({ type: "UNMOUNT" })
        }

    }, [image, src, stateDispatcher]);

    return [state.loading, state.loadedImageUrl];

};

export default useImageLoader;


