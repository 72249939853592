import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Paper, Typography, Stack, Grid, IconButton } from "@mui/material";
import { SpeakerNotes, Person, Edit, ModeStandby, Event } from "@mui/icons-material";

import LabeledText from "components/Common/LabeledText";
import DuplicatePOCheck from "components/Quotes/DuplicatePOCheck/DuplicatePOCheck";

import useTranslations from "helpers/hooks/useTranslations";
import useQuoteData from "components/Quotes/useQuoteData";
import useWebCustomer from "helpers/context/SelectionValues/useWebCustomer";
import useApplicationInfo from "helpers/context/Application/useApplicationInfo";

import Format from "helpers/fv.format";
import QuoteNavigation from "components/Quotes/QuoteNavigation";

function QuoteInfoCard() {
    const tm = useTranslations();
    const { quote, permissions, lock } = useQuoteData();
    const webCustomer = useWebCustomer(quote?.customerID ?? "");
    const application = useApplicationInfo();
    const navigate = useNavigate();

    const canEditInfo = useMemo(() => {
        if (permissions && lock) {
            return permissions.editQuote && !lock.isLockedByAnotherUser;
        }
        return false;
    }, [permissions, lock]);

    const deliveryDateLabel = useMemo(() => {
        let label: string = "Est. Delivery Date";
        if (application && application.parameters.customDeliveryDateLabel) {
            label = application.parameters.customDeliveryDateLabel;
        }
        return tm.Get(label);
    }, [application, tm]);

    const showDeliveryDateLabel = useMemo(() => {
        return quote && quote.hasOwnProperty("requestedDeliveryDate");
    }, [quote]);

    const requestedDeliveryLabel = useMemo(() => {
        let label: string = "Requested Date";
        if (application && application.parameters.customRequestedDeliveryDateLabel) {
            label = application.parameters.customRequestedDeliveryDateLabel;
        }
        return tm.Get(label);
    }, [application, tm]);

    return <>
        <Paper sx={{ display: "flex", width: "100%" }}>
            <Stack display="flex" width="100%" m={1}>
                <Grid container direction="row">
                    <Grid item xs display="flex" alignItems="center">
                        <Typography variant="h6">
                            {tm.Get("Quote")}
                        </Typography>
                    </Grid>
                    <Grid item display="flex" justifyContent="right">
                        {canEditInfo &&
                            <IconButton onClick={() => navigate(QuoteNavigation.QuoteEditURL(quote?.oKey ?? 0, "info"))}>
                                <Edit />
                            </IconButton>
                        }
                    </Grid>
                </Grid>

                <Grid container mt={1} direction="row">
                    <Grid item mr={1} display="flex" alignItems="center">
                        <Person color="primary" />
                    </Grid>
                    <Grid item xs display="flex" alignItems="center">
                        <LabeledText label={tm.Get("Contact")} text={quote?.orderContact} />
                    </Grid>
                </Grid>

                <Grid container mt={1} direction="row">
                    <Grid item mr={1} display="flex" alignItems="center">
                        <SpeakerNotes color="primary" />
                    </Grid>
                    <Grid item xs display="flex" alignItems="center">
                        <LabeledText label={tm.Get("Comment")} text={quote?.orderComment} />
                    </Grid>
                </Grid>


                <Grid container mt={1} direction="row">
                    <Grid item mr={1} display="flex" alignItems="center">
                        <Person sx={{ opacity: 0 }} />
                    </Grid>
                    <Grid item xs display="flex" alignItems="center">
                        <LabeledText label={tm.Get("PO Number")} text={quote?.poNumber} />
                    </Grid>
                    <Grid item mr={1} display="flex" alignItems="center">
                        <DuplicatePOCheck oKey={quote?.oKey} customerGUID={webCustomer?.customerGUID} poNumberToCheck={quote?.poNumber} />
                    </Grid>
                </Grid>

                <Grid container mt={1} direction="row">
                    <Grid item mr={1} display="flex" alignItems="center">
                        <Person sx={{ opacity: 0 }} />
                    </Grid>
                    <Grid item xs display="flex" alignItems="center">
                        <LabeledText label={tm.Get("Customer Ref")} text={quote?.customerRef} />
                    </Grid>
                </Grid>

                <Grid container mt={1} direction="row">
                    <Grid item mr={1}>
                        <ModeStandby color="primary" />
                    </Grid>
                    <Grid item xs display="flex">
                        <LabeledText
                            label={deliveryDateLabel}
                            text={Format.FormatDate(quote?.estimatedDeliveryDate)}
                        />
                    </Grid>
                </Grid>

                {showDeliveryDateLabel && requestedDeliveryLabel &&
                    <Grid container mt={1} direction="row">
                        <Grid item mr={1} display="flex">
                            <Event color="primary" />
                        </Grid>
                        <Grid item xs display="flex">
                            <LabeledText
                                label={requestedDeliveryLabel}
                                text={Format.FormatDate(quote?.requestedDeliveryDate)}
                            />
                        </Grid>
                    </Grid>
                }

            </Stack>
        </Paper>

    </>

}


export default QuoteInfoCard;