import { Chip, Stack } from "@mui/material";
import useTranslations from "helpers/hooks/useTranslations";
import React from 'react';

interface IProps {
    isDefault: boolean,
    isInactive: boolean
}

const CustomerStatusChips: React.FC<IProps> = (props: IProps) => {

    const tm = useTranslations();

    return <Stack direction="row" spacing={1}>
        {props.isDefault && <Chip label={tm.Get("Default Customer")} color="info" />}
        {props.isInactive && <Chip label={tm.Get("Inactive")} color="error" />}
    </Stack>;
}

export default CustomerStatusChips;