import { Paper, Typography, Stack, Grid, Link } from "@mui/material";
import { Room, Phone } from "@mui/icons-material";

import LabeledText from "components/Common/LabeledText";

import useTranslations from "helpers/hooks/useTranslations";
import useOrderData from "components/Orders/useOrderData";

import Format from "helpers/fv.format";

function GeneralCard() {
    const tm = useTranslations();
    const { order } = useOrderData();

    return <>
        <Paper sx={{display: "flex", width: "100%"}}>
            <Stack display="flex" width="100%" m={1}>
                <Grid container direction="row">
                    <Grid item xs display="flex" alignItems="center">
                        <Typography variant="h6">
                            {tm.Get("General")}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container mt={1}>
                    <Grid item mr={1}>
                        <Room color="primary"/>
                    </Grid>
                    <Grid item xs>
                        <Typography>
                            {order?.general?.name ?? ""}
                            <br />
                            {order?.general?.address?.address1 ?? ""}
                            <br />
                            {order?.general?.address?.address2 ?? ""}
                            <br />
                            {Format.FormatCityStateZip(order?.general?.address?.city ?? "", order?.general?.address?.state ?? "", order?.general?.address?.zipCode ?? "")}
                            <br />
                            {order?.general?.address?.country ?? ""}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container mt={1} direction="row">
                    <Grid item mr={1} display="flex" alignItems="center">
                        <Phone color="primary"/>
                    </Grid>
                    <Grid item xs display="flex" alignItems="center">
                        <LabeledText label={tm.Get("Phone")} text={
                            <Link href={`tel:${order?.general?.phone ?? ""}`} underline="always" tabIndex={(order?.general?.phone ?? "") === "" ? -1 : undefined}>
                                {order?.general?.phone ?? ""}
                            </Link>
                        } />
                    </Grid>
                </Grid>

                {order?.general?.fax &&
                    <Grid container mt={1} direction="row">
                        <Grid item mr={1} display="flex" alignItems="center">
                            <Phone opacity={0} />
                        </Grid>
                        <Grid item xs display="flex" alignItems="center">
                            <LabeledText label={tm.Get("Fax")} text={order.general.fax} />
                        </Grid>
                    </Grid>
                }

            </Stack>
        </Paper>

    </>

}


export default GeneralCard;