import React, { useMemo } from 'react';
import { Dialog, DialogContent, Stack, Typography, useTheme } from "@mui/material";

import useTranslations from "helpers/hooks/useTranslations";
import useIsMobile from "helpers/hooks/useIsMobile";

import { IProdStatusUnitHistory } from "helpers/interfaces";
import useOrderData from "../useOrderData";
import CustomDataGridPro from "components/Common/CustomDataGridPro";
import { GridCallbackDetails, GridRowIdGetter, GridRowModel } from "@mui/x-data-grid-pro";
import DataGridColumnGenerator from "components/Common/DataGridColumnGenerator";
import Format from "helpers/fv.format";
import useFormatHelper from "helpers/hooks/useFormatHelper";
import { DateTime } from "luxon";
import useRowsPerPage from "helpers/hooks/useRowsPerPage";

interface IProps {
    dialogVisible: boolean;
    onClose: () => void;
}

interface IProdStatusUnitHistoryRow extends IProdStatusUnitHistory {
    statusDescription: string,
    formattedPart: string,
}

const ProdStatusUnitHistory: React.FC<IProps> = ({ dialogVisible, onClose }: IProps) => {

    const tm = useTranslations();
    const theme = useTheme();
    const isMobile = useIsMobile();
    const formatMethods = useFormatHelper();
    const rowsPerPage = useRowsPerPage("ProdStatusUnitHistory");
    const { order, prodStatusUnitHistory } = useOrderData();

    const unitHistoryRows = useMemo(() => {
        const details: IProdStatusUnitHistoryRow[] = [];

        if (order && prodStatusUnitHistory?.unitHistory) {
            prodStatusUnitHistory.unitHistory.forEach((uh) => {
                const props: IProdStatusUnitHistoryRow = {
                    ...uh,
                    statusDescription: formatMethods.FormatStatusCode(uh.code),
                    formattedPart: Format.FormatPartDescription(uh.partNo, uh.partNoSuffix, uh.description),
                };
                details.push(props);
            });

            return details;

        } else {
            return null;
        }

    }, [prodStatusUnitHistory?.unitHistory, order, formatMethods]);

    const generator = useMemo(() => {
        const columns = new DataGridColumnGenerator(tm, unitHistoryRows, theme, isMobile);
        columns.TryAddDateTimeColumn("dateTime", "Date/Time", DateTime.DATETIME_SHORT_WITH_SECONDS, { flex: 2 });
        columns.TryAddTextColumn("stationID", "Station", { flex: 1 });
        columns.TryAddTextColumn("statusDescription", "Code", { flex: 1 });
        columns.TryAddTextColumn("formattedPart", "Part", { flex: 4, hideInMobile: true });
        return columns;
    }, [tm, unitHistoryRows, theme, isMobile]);

    const getRowId: GridRowIdGetter = (row: GridRowModel) => {
        const history = row as IProdStatusUnitHistory;
        return `${history.stationID}-${history.dateTime}-${history.code}`;
    };
    
    const onPageSizeChange = (pageSize: number, details: GridCallbackDetails) => rowsPerPage.setPageSize(pageSize);

    return <>
        <Dialog
            open={dialogVisible}
            onClose={onClose}
            fullWidth={true}
            maxWidth={"lg"}
        >
            <DialogContent sx={{ padding: 1 }}>
                <Stack direction="column" gap={1}>
                    <Typography variant="h5">
                        {tm.Get("Unit History")}
                    </Typography>
                    <Typography>
                        {tm.Get("Schedule:")} {prodStatusUnitHistory?.schedule.schedID} - {prodStatusUnitHistory?.schedule.description} {tm.Get("Unit:")} {prodStatusUnitHistory?.unit.unitID}
                    </Typography>
                    {prodStatusUnitHistory &&
                        <CustomDataGridPro
                            getRowId={getRowId}
                            columns={generator.GetColumns()}
                            rows={unitHistoryRows}
                            onPageSizeChange={onPageSizeChange}
                            pageSize={rowsPerPage.pageSize}
                            rowsPerPageOptions={rowsPerPage.pageSizeOptions}
                        />
                    }
                </Stack>
            </DialogContent>
        </Dialog>
    </>;

}

export default ProdStatusUnitHistory;