import { useMemo } from "react"
import { Box, Typography, Accordion, AccordionDetails } from "@mui/material"

import SelectAddress from "components/Quotes/SelectFields/SelectAddress"
import AccordionSummarySlim from "components/Common/AccordionSummarySlim"

import useTranslations from "helpers/hooks/useTranslations";
import { IQuotePermissions } from "helpers/interfaces";

interface IProps {
    expanded : boolean,
    permissions? : IQuotePermissions,
    onExpandedChange : (event: React.SyntheticEvent, expanded: boolean) => void,      
}

function Billing({ expanded, permissions, onExpandedChange } : IProps) {

    const tm = useTranslations();

    const isAddressReadOnly = useMemo(() => {

        if ((permissions?.shippingAddress ?? false) === false)
        {
            return true;
        }

        return false;
    }, [permissions]);

    return <>

        <Accordion expanded={expanded} onChange={onExpandedChange} disableGutters sx={{ width: 1 }} >
            <AccordionSummarySlim>
                <Typography color="secondary.contrastText">
                    {tm.Get("Billing Address")}
                </Typography>
            </AccordionSummarySlim>
            <AccordionDetails sx={{padding: 0}}>
                <Box display="flex" flexDirection="column" p={1} gap={1} > 
                    <SelectAddress 
                        addressPrefix="billing"
                        readOnly={isAddressReadOnly}
                          />
                </Box>
            </AccordionDetails>
        </Accordion>

    </>
}

export default Billing;