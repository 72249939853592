import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import OKCancelControl from "components/Common/OKCancelControl";
import MaintenanceAPI from "components/Maintenance/MaintenanceAPI";
import useApplicationInfo from "helpers/context/Application/useApplicationInfo";
import useTranslations from "helpers/hooks/useTranslations";
import { IMaintenanceEditQuote, IMaintenanceEditQuoteSubmit } from "helpers/interfaces";
import { DateTime } from "luxon";
import CustomLuxonAdapter from "helpers/classes/CustomLuxonAdapter";

import React from 'react';
import useWait from "helpers/context/Page/useWait";
import { useEffectOnLoad } from "helpers/hooks/useEffectOnLoad";

interface IProps {
    okey: number;
    open: boolean;
    showRequestDate: boolean;
    close: (newState: IMaintenanceEditQuoteSubmit | null) => void;
}

const ParentPONumberFromQuoteAndSelection = (selection: string, quote: IMaintenanceEditQuote | null) => {

    switch (selection) {
        case "Order":
            return quote?.quoteNumber ?? "";

        case "PO":
            return quote?.poNumber ?? "";

        case "custom":
            return quote?.parentPONumber ?? "";
    }

    throw Error("Invalid PO Selection");

}

const ParentCustomerRefFromQuoteAndSelection = (selection: string, quote: IMaintenanceEditQuote | null) => {

    switch (selection) {
        case "Customer":
            return quote?.customerName ?? "";

        case "Order-Customer":
            return quote?.quoteNumber + '-' + quote?.customerName;

        case "Customer Ref":
            return quote?.customerRef ?? "";

        case "custom":
            return quote?.parentCustomerRef ?? "";
    }

    throw Error("Invalid Customer Ref Selection");

}

const MaintenanceQuoteEdit: React.FC<IProps> = ({ open, close, okey, showRequestDate }: IProps) => {

    const tm = useTranslations();
    const appInfo = useApplicationInfo();
    const wait = useWait();

    let requestDateColumnHeader: string = "Request Date";
    if (appInfo && appInfo.parameters.customRequestedDeliveryDateLabel) {
        requestDateColumnHeader = appInfo.parameters.customRequestedDeliveryDateLabel;
    }

    const [customerRef, setCustomerRef] = React.useState<string>();

    const [requestDateRange, setRequestDateRange] = React.useState<[minDate: DateTime, maxDate: DateTime]>([DateTime.now(), DateTime.now()]);
    const [requestDate, setRequestDate] = React.useState<DateTime | null>(null);

    const [poNumber, setPONumber] = React.useState<string>("");

    const [selectionPONumber, setSelectionPONumber] = React.useState<string>("custom");
    const [selectionCustomerRef, setSelectionCustomerRef] = React.useState<string>("custom");

    const [quote, setQuote] = React.useState<IMaintenanceEditQuote | null>(null);
    const [message, setMessage] = React.useState<string | null>(null);

    useEffectOnLoad(() => {
        wait.Show(true);

        MaintenanceAPI.GetMaintenanceEditQuote(okey).then((q) => {
            setQuote(q);

            //Figure out default PONumber selection
            if (q.parentPONumber) {
                if (q.parentPONumber === q.quoteNumber) {
                    setSelectionPONumber("Order");
                } else if (q.parentPONumber === q.poNumber) {
                    setSelectionPONumber("PO");
                } else {
                    setSelectionPONumber("custom");
                }
                setPONumber(q.parentPONumber);
            } else {
                if (q.parentPONumberSetting) {
                    setSelectionPONumber(q.parentPONumberSetting);
                    setPONumber(ParentPONumberFromQuoteAndSelection(q.parentPONumberSetting, q));
                } else {
                    setSelectionPONumber("custom");
                    setPONumber("");
                }
            }

            if (q.parentCustomerRef) {
                if (q.parentCustomerRef === q.customerName) {
                    setSelectionCustomerRef("Customer");
                } else if (q.parentCustomerRef === (q.quoteNumber + '-' + q.customerName)) {
                    setSelectionCustomerRef("Order-Customer");
                } else if (q.parentCustomerRef === q.customerRef) {
                    setSelectionCustomerRef("Customer Ref");
                } else {
                    setSelectionCustomerRef("custom");
                }
                setCustomerRef(q.parentCustomerRef);

            } else {
                if (q.parentCustomerRefSetting) {
                    setSelectionCustomerRef(q.parentCustomerRefSetting);
                    setCustomerRef(ParentCustomerRefFromQuoteAndSelection(q.parentCustomerRefSetting, q));
                } else {
                    setSelectionCustomerRef("custom");
                    setCustomerRef("");
                }

            }

            if (q.requestDate)
                setRequestDate(DateTime.fromISO(q.requestDate));
            else
                setRequestDate(null);

            let startDate = DateTime.fromISO(q.reqDate);

            var dtNull = DateTime.fromISO("1900-01-01T00:00:00");
            if (startDate.startOf("day").toMillis() === dtNull.startOf("day").toMillis()) {
                startDate = DateTime.now();
            }

            const endDate = DateTime.now().plus({ days: appInfo.parameters.requestDateMaxLookAheadDays });

            setRequestDateRange([startDate, endDate]);

        }).finally(() => wait.Show(false));

    });


    const handleClose = React.useCallback((result: boolean | null) => {

        let newState: IMaintenanceEditQuoteSubmit | null = null;

        if (result) {
            wait.Show(true);
            newState = {
                okey: okey,
                poNumber: poNumber,
                customerRef: customerRef ?? "",
                requestDate: requestDate ? requestDate.toJSDate() : null,
            };

            console.log(newState);

            MaintenanceAPI.PutMaintenanteQuoteEditAsync(newState).then((message) => {
                if (message) {
                    //validation message.
                    setMessage(message);
                } else {
                    //success
                    close(newState);
                }

            }).finally(() => wait.Show(false));

        } else {
            close(newState);
        }

    }, [close, poNumber, customerRef, requestDate, okey, wait]);



    const handleSelectionChangePO = React.useCallback((event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        setSelectionPONumber(value);
        setPONumber(ParentPONumberFromQuoteAndSelection(value, quote));
    }, [setSelectionPONumber, setPONumber, quote])

    const handleSelectionChangeRef = React.useCallback((event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        setSelectionCustomerRef(value);
        setCustomerRef(ParentCustomerRefFromQuoteAndSelection(value, quote));
    }, [setSelectionCustomerRef, quote, setCustomerRef])

    const handleCustomerRefTextChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setCustomerRef(e.target.value);
        setSelectionCustomerRef("custom");
    }, [setCustomerRef, setSelectionCustomerRef]);

    const handlePONumberTextChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPONumber(e.target.value);
        setSelectionPONumber("custom");
    }, [setPONumber]);

    return <>
        <LocalizationProvider dateAdapter={CustomLuxonAdapter} locale={navigator.language}>
            <Dialog
                open={open}
                onClose={() => handleClose(null)}
            >
                <DialogTitle>{tm.Get("Edit Quote")}  - {quote?.quoteNumber}</DialogTitle>
                <DialogContent>
                    <Stack spacing={3}>
                        <FormControl component="fieldset" >
                            <FormLabel>{tm.Get("PO Number")}</FormLabel>

                            <RadioGroup value={selectionPONumber} name="po-sources" onChange={handleSelectionChangePO} >
                                <FormControlLabel value="Order" control={<Radio />} label={tm.Get("Order")} />
                                <FormControlLabel value="PO" control={<Radio />} label={tm.Get("PO")} />
                                <FormControlLabel value="custom" control={<Radio />} label={tm.Get("Custom")} />
                            </RadioGroup>

                            <Box display="flex" minHeight={40} flexDirection="column" alignItems="center" justifyContent="center"  >
                                {selectionPONumber === "custom" && <>
                                    <TextField autoFocus inputProps={{ maxLength: 20 }} size="small" value={poNumber} onChange={handlePONumberTextChange} />
                                </>}

                                {selectionPONumber !== "custom" && <>
                                    <Typography>{poNumber}</Typography>
                                </>}
                            </Box>
                        </FormControl>
                        <FormControl component="fieldset">
                            <FormLabel>{tm.Get("Customer Ref")}</FormLabel>

                            <RadioGroup value={selectionCustomerRef} name="ref-sources" onChange={handleSelectionChangeRef}>
                                <FormControlLabel value="Customer" control={<Radio />} label={tm.Get("Customer")} />
                                <FormControlLabel value="Order-Customer" control={<Radio />} label={tm.Get("Order-Customer")} />
                                <FormControlLabel value="Customer Ref" control={<Radio />} label={tm.Get("Customer Ref")} />
                                <FormControlLabel key="custom" value="custom" control={<Radio />} label={tm.Get("Custom")} />
                            </RadioGroup>

                            <Box display="flex" minHeight={40} flexDirection="column" alignItems="center" justifyContent="center"  >
                                {selectionCustomerRef === "custom" && <>
                                    <TextField autoFocus inputProps={{ maxLength: 50 }} size="small" value={customerRef} onChange={handleCustomerRefTextChange} />
                                </>}

                                {selectionCustomerRef !== "custom" && <>
                                    <Typography>{customerRef}</Typography>
                                </>}
                            </Box>
                        </FormControl>
                        {showRequestDate && <>
                            <FormControl component="fieldset">
                                <FormLabel>{requestDateColumnHeader}</FormLabel>

                                {/* The typings on this control appear to be wrong.  value/mindate/maxdate are typed as Date, but the underlying
                                        code requires to have a complex date handler (Luxon/Moment/etc) DateTime object with additional functions.  That is why the double casting
                                        is being done below  */}
                                <DatePicker autoFocus
                                    value={requestDate as unknown as Date | null}
                                    onChange={(newValue: Date | null) => setRequestDate(newValue as unknown as DateTime)}
                                    renderInput={(params: any) => <TextField {...params} />}
                                    minDate={requestDateRange[0] as unknown as Date}
                                    maxDate={requestDateRange[1] as unknown as Date}
                                />

                                {message && <Typography color="error" maxWidth={200} >{message}</Typography>}

                            </FormControl>
                        </>}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <OKCancelControl okCommand={() => handleClose(true)} cancelCommand={() => handleClose(false)} />
                </DialogActions>
            </Dialog>
        </LocalizationProvider>
    </>;

}

export default MaintenanceQuoteEdit;