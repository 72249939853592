import React from 'react';
import { IPreference } from "../../helpers/interfaces";
import PreferenceInfo from "./PreferenceInfo";
import EditPreference from "./EditPreference";
import useTranslations from "helpers/hooks/useTranslations";
import BoxWithHeader from "components/Common/BoxWithHeader";
import { Grid } from "@mui/material";

interface IProps {
    groupName: string,
    key: number,
    isEditMode: boolean,
    preferences: IPreference[]
}

const PreferenceGrouping: React.FC<IProps> = (props: IProps) => {

    const tm = useTranslations();

    return <BoxWithHeader caption={tm.Get(props.groupName)} sx={{ mt: 2 }}>
        <Grid container mt={1} spacing={1}>
            {props.preferences.map(p =>
                <Grid item xs={12} sm={6} key={p.key}>
                    {props.isEditMode ? <EditPreference preference={p} /> : <PreferenceInfo key={p.key} preference={p} />}
                </Grid>
            )}
        </Grid>
    </BoxWithHeader>;
}

export default PreferenceGrouping;