import React from "react";
import { Grid, TextField, MenuItem } from "@mui/material";

import useIsMobile from "helpers/hooks/useIsMobile";
import useTranslations from "helpers/hooks/useTranslations";
import { ILineItemPriceBreakdownProps } from "components/LineItemPriceBreakdown/LineItemPriceBreakdown"
import LineItemPriceBreakdownAPI from "components/LineItemPriceBreakdown/LineItemPriceBreakdownAPI"
import Constants from "helpers/constants";
import ErrorAdornment from "components/Common/ErrorAdornment";

const PBHeader: React.FC<ILineItemPriceBreakdownProps> = ({editEnabled, subLineQuantitiesCanChange, currentQty, currentPartPrice, 
    qty, item, orderedQty, partPrice, size, lineItems, qtyErrorMessage, partPriceErrorMessage, qtyOverride, partPriceOverride, 
    handleQtyChange, handlePartPriceChange, handleItemChange, setQtyErrorMessage, setPartPriceErrorMessage, setIsPosting, setData, cf}) => {

    const isMobile = useIsMobile();
    const tm = useTranslations();

    const validateQty = React.useCallback((value: string) => {
        var numberQty = Number(value);
        if (value === "") {
            return tm.Get("Quantity must be specified");
        } else if (!Number.isInteger(numberQty)) {
            return tm.Get("Quantity must be a valid integer value");
        } else if (numberQty < Constants.Min.Quantity || numberQty > Constants.Max.Quantity) {
            return tm.GetWithParams("Quantity must be between {0} and {1}", Constants.Min.Quantity.toString(), Constants.Max.Quantity.toString());
        } else if (numberQty % orderedQty !== 0) {
            return tm.GetWithParams("Quantity must be a multiple of {0}", orderedQty.toString());
        } else {
            return "";
        }
    }, [tm, orderedQty]);

    const handleQtyBlur = React.useCallback((e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (editEnabled && item !== 0 && subLineQuantitiesCanChange && e.target.value !== currentQty) {
            var errorMessage = validateQty(e.target.value);
            setQtyErrorMessage(errorMessage);
            if (errorMessage === "") {
                setIsPosting(true);
                LineItemPriceBreakdownAPI.ChangeQuantity(item, Number(e.target.value)).then((newBreakdown) => {
                    if (newBreakdown) {
                        setData(newBreakdown);
                    }
                    setIsPosting(false);
                });
            }
        }
    }, [editEnabled, currentQty, item, subLineQuantitiesCanChange, validateQty, setQtyErrorMessage, setIsPosting, setData]);

    const validatePartPrice = React.useCallback((value: string) => {
        var numberPrice = cf.Parse(value);
        if (value === "") {
            return tm.Get("Price must be specified");
        } else if (isNaN(numberPrice)) {
            return tm.Get("Price must be a valid currency value");
        } else if (numberPrice < Constants.Min.Price || numberPrice > Constants.Max.Price) {
            return tm.GetWithParams("Price must be between {0} and {1}", Constants.Min.Price.toString(), Constants.Max.Price.toString());
        } else {
            return "";
        }
    }, [tm, cf]);

    const handlePartPriceBlur = React.useCallback((e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (editEnabled && e.target.value !== currentPartPrice) {
            var errorMessage = validatePartPrice(e.target.value);
            setPartPriceErrorMessage(errorMessage);
            if (errorMessage === "") {
                setIsPosting(true);
                LineItemPriceBreakdownAPI.ChangePartPrice(item, cf.Parse(e.target.value)).then((newBreakdown) => {
                    if (newBreakdown) {
                        setData(newBreakdown);
                    }
                    setIsPosting(false);
                });
            }
        }
    }, [cf, editEnabled, item, currentPartPrice, validatePartPrice, setPartPriceErrorMessage, setIsPosting, setData]);
    
    const captureEnterKeypress = React.useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            e.target.blur();
        }
    }, []);

    const quantityEnabled = React.useMemo(() => {
        return editEnabled && item !== 0 && subLineQuantitiesCanChange;
    }, [editEnabled, item, subLineQuantitiesCanChange]);

    return <>
        <Grid container direction="column" rowSpacing={1} justifyItems="stretch">
            <Grid item>
                <Grid container alignItems="center" justifyContent="left" spacing={1}>
                    <Grid item xs={ isMobile ? 12 : 4 }>
                        <TextField label={tm.Get("Quantity")} value={qty} onChange={handleQtyChange} onBlur={handleQtyBlur} fullWidth
                            error={qtyErrorMessage !== ""} disabled={!quantityEnabled}
                            InputProps={{
                                inputProps: {
                                    style: {
                                        fontWeight: qtyOverride ? "bold" : "normal"
                                    }
                                }, 
                                endAdornment: qtyErrorMessage !== "" ? 
                                    <ErrorAdornment validationError={qtyErrorMessage}/> : null
                            }}
                            onKeyDown={e => { if( editEnabled) captureEnterKeypress(e) }}
                            onFocus={e => { if( quantityEnabled ) e.target.select() }}
                        />
                    </Grid>
                    <Grid item xs={ isMobile ? 12 : 4 }>
                        <TextField label={tm.Get("Item")} value={item} fullWidth onChange={handleItemChange} select>
                            {lineItems?.map((li) => (
                                <MenuItem value={li.subLineItem} key={li.subLineItem}>{li.displayText}</MenuItem>
                                )
                            )}
                        </TextField>
                    </Grid>
                    <Grid item xs={ isMobile ? 12 : 4 }>
                        <TextField label={tm.Get("Size")} value={size} fullWidth disabled/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container alignItems="center" justifyContent="right" spacing={1}>
                    <Grid item xs={ isMobile ? 12 : 4 }>
                        <TextField label={tm.Get("Part Price")} value={partPrice} onChange={handlePartPriceChange} onBlur={handlePartPriceBlur} fullWidth
                            error={partPriceErrorMessage !== ""} disabled={!editEnabled}
                            InputProps={{ 
                                inputProps: {
                                    style: { 
                                        textAlign: 'right',
                                        fontWeight: partPriceOverride ? "bold" : "normal",
                                    }, 
                                }, 
                                endAdornment: partPriceErrorMessage !== "" ? 
                                    <ErrorAdornment validationError={partPriceErrorMessage}/> : null
                            }}
                            onKeyDown={e => { if( editEnabled) captureEnterKeypress(e) }}
                            onFocus={e => { if( editEnabled ) e.target.select() }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </>
}

export default PBHeader