import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import useTranslations from "helpers/hooks/useTranslations";
import React from 'react';
import PricingFieldForm from "./PricingFieldForm";
import { useFieldArray, useFormContext } from "react-hook-form";
import { PricingMethodEnum } from "helpers/enums";
import { IDiscountTableGrouping } from "helpers/interfaces";
import useDiscountTables from "helpers/context/SelectionValues/useDiscountTables";
import constants from "helpers/constants";
import useLocaleNumberFormatter from "helpers/hooks/useLocaleNumberFormatter";
import AutocompleteForm from "components/Common/AutocompleteForm";

interface IProps {
    pricingMethod: PricingMethodEnum,
    isPricingMethodPercentBased: boolean,
    readOnly: boolean,
    parentCustomerID: string
}

const CustomerPricingCategories: React.FC<IProps> = ({pricingMethod, isPricingMethodPercentBased, readOnly, parentCustomerID}: IProps) => {

    const tm = useTranslations();
    const lnf = useLocaleNumberFormatter({ style: "decimal", useGrouping: true, minimumFractionDigits: 4, maximumFractionDigits: 4 });
    const discountTables: IDiscountTableGrouping[] | undefined = useDiscountTables(parentCustomerID);
    const { control, watch, getValues, setValue, getFieldState } = useFormContext();
    const { fields } = useFieldArray({
        control: control,
        name: "pricing"
    })

    const getDefaultPricingValue = React.useMemo(() => {
        switch (pricingMethod) {
            case PricingMethodEnum.Discount:
                return isPricingMethodPercentBased ? constants.Default.DiscountPercent : constants.Default.DiscountMultiplier;
            case PricingMethodEnum.Margin:
                return constants.Default.MarginPercent;
            default:
                return isPricingMethodPercentBased ? constants.Default.MarkupPercent : constants.Default.MarkupMultiplier;
        }
    }, [pricingMethod, isPricingMethodPercentBased]);

    //If the pricing method changes after the first render, reset the fields to defaults
    React.useEffect(() => {
        const pricingMethodState = getFieldState("customer.pricingMethod");
        if (!pricingMethodState.isDirty) return;
        const prevValues = getValues("pricing");
        fields.forEach((field, index) => {
            setValue(`pricing.${index}`, { ...prevValues[index], discountPercent: getDefaultPricingValue, discountTableID: -1 })
        });
    }, [fields, pricingMethod, getDefaultPricingValue, getFieldState, getValues, setValue]);

    const getSecondColumnTitle = React.useCallback(() => {
        switch (pricingMethod) {
            case PricingMethodEnum.Discount:
                return isPricingMethodPercentBased ? tm.Get("Discount %") : tm.Get("Discount Mult.");
            case PricingMethodEnum.Margin:
                return tm.Get("Gross Profit Margin");
            case PricingMethodEnum.Markup:
                return isPricingMethodPercentBased ? tm.Get("Markup %") : tm.Get("Markup Mult.");
            default: return "";
        }
    }, [pricingMethod, isPricingMethodPercentBased, tm]);

    const getValidDiscountTables = React.useCallback((categoryID: number) => {
        return discountTables?.find(dtg => dtg.tableType === pricingMethod)?.discountTables.filter(dt => dt.categoryID === categoryID) ?? [];
    }, [pricingMethod, discountTables]);

    if (fields.length === 0) return <></>;
    return <>
        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ width: "33%" }}><Typography>{tm.Get("Category")}</Typography></TableCell>
                        <TableCell sx={{ width: "33%" }}><Typography>{getSecondColumnTitle()}</Typography></TableCell>
                        <TableCell sx={{ width: "33%" }}><Typography>{tm.Get("Table")}</Typography></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {fields.map((field, index) => {
                        const category = watch(`pricing.${index}.category`);
                        const categoryID = watch(`pricing.${index}.id`);
                        return <TableRow key={field.id}>
                            <TableCell>{category}</TableCell>
                            <TableCell>
                                <PricingFieldForm
                                    pricingMethod={pricingMethod}
                                    isPricingMethodPercentBased={isPricingMethodPercentBased}
                                    fieldName={`pricing.${index}.discountPercent`}
                                    readOnly={readOnly}
                                    lnf={lnf}
                                    fullWidth
                                />
                            </TableCell>
                            <TableCell>
                                <AutocompleteForm
                                    dataSource={getValidDiscountTables(categoryID)}
                                    fieldName={`pricing.${index}.discountTableID`}
                                    valueField="discountTableID"
                                    displayField="description"
                                    translateDisplayField={false}
                                    readOnly={readOnly}
                                    label=""
                                    defaultValue={-1}
                                    size="small"
                                />
                            </TableCell>
                        </TableRow>
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    </>;
}

export default CustomerPricingCategories;