import * as React from "react";
import { ICode, ISelectItem } from "../interfaces";
import { ChangeEvent } from "react";

import WizardHelper, { HeaderValuePair } from "../WizardHelper";
import NonTiledSplitOptions from "./NonTiledSplitOptions";
import useTranslations from "helpers/hooks/useTranslations";
import { Flag } from "@mui/icons-material";
import CachedIcon from '@mui/icons-material/Cached';
import { Grid, Autocomplete, Box, Button, IconButton, Stack, TextField } from "@mui/material";
import APIHelper from "helpers/APIHelper";
import useWizardState, { useWizardStateActions } from "../WebDesigner/useWizardState";
import { WizardViewEnum } from "../WizardContext";
import ShapeAPI from "../ShapeAPI";
import useWizardInteractions from "../useWizardInteractions";

interface IOptionValueProps {
    code: ICode;
    withinTiled: boolean;
    locked: boolean;
    readOnlyMode: boolean;
}

const NonTiledOptionValue: React.FC<IOptionValueProps> = (props: IOptionValueProps) => {

    const [inputText, setInputText] = React.useState<string>(props.code.value);

    const tm = useTranslations();
    const wizardActions = useWizardStateActions();
    const wizardInteractions = useWizardInteractions();
    const wizardState = useWizardState();
    
    let c: ICode = props.code;

    const valueSplits = React.useMemo(() => {
        let initialHeaderValuePairs: Array<HeaderValuePair> | null = WizardHelper.ValueSplit(props.code.value, WizardHelper.getOptionValueDelimiter());
        if (initialHeaderValuePairs != null) {
            //There is split option value syntax, need to construct children
            return initialHeaderValuePairs;
        }
        else {
            return new Array<HeaderValuePair>();
        }
    }, [props.code.value])

    const hasValueSplits = React.useMemo(() => {
        return valueSplits.length !== 0;
    }, [valueSplits])


    const commitInputValueTextChange = (e: HTMLInputElement | HTMLTextAreaElement) => {
        //We are handling the onBlur event, so we must check to see if the user
        //actually made a change that we must commit.
        if (props.code.value !== e.value && !props.readOnlyMode) {
            let optionValue: string = e.value;

            let lstOfSplits: HeaderValuePair[] | null = WizardHelper.ValueSplit(optionValue, WizardHelper.getOptionValueDelimiter());

            if (lstOfSplits != null) {

                let inputTextString: string = WizardHelper.ValueCombine(lstOfSplits);
                optionValue = inputTextString;
            }

            wizardInteractions.SelectCodeValueAsync(props.code.sequence, props.code.code, optionValue);
        }
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            commitInputValueTextChange((e.target as HTMLInputElement));
        }
    }

    const handleByValueSelectionChange = (event: React.SyntheticEvent, value: ISelectItem) => {
        if (!props.readOnlyMode) {

            let optionValue: string = value ? value.value : "";
            wizardInteractions.SetFocusedCode(null);
            wizardInteractions.SelectCodeValueAsync(props.code.sequence, props.code.code, optionValue);
        }
    }

    const handleValueTextChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let input: HTMLInputElement | HTMLTextAreaElement = event.target;

        if (input.type === "text") {
            commitInputValueTextChange(input);
        }
        wizardInteractions.SetFocusedCode(null);
    }

    const handleValueRefresh = (event: React.MouseEvent<any>) => {
        //We must prevent the default behavior of the image button, because it will
        // try to submit the form.
        event.preventDefault();
        wizardInteractions.SetFocusedCode(null);
        wizardInteractions.RunExpressionsAsync(props.code.sequence, props.code.code);
    }

    const handleConfiguratorClick = (e: React.MouseEvent<any>) => {
        switch (c.config.description) {
            case "Shape Library":
                wizardActions?.SwitchToView(WizardViewEnum.ShapeLibrary);
                break;
            case "Shape Editor":
                ShapeAPI.InitEditor(props.code.question, props.code.code).then(() => {
                    wizardActions?.SwitchToView(WizardViewEnum.ShapeEditor);
                })
                break;
            default:
                console.log("TODO: Handle " + c.config.description + " configurator click");
        }
    }

    const [selectItems, selectedItem] = React.useMemo<[ISelectItem[], ISelectItem | undefined]>(() => {

        let items: ISelectItem[] = [];

        if (c.value === "") {
            items.push({
                description: tm.Get("{Required}"),
                value: "",
                imageExists: false
            })
        }

        if (c.selectItems && c.selectItems.length > 0)
            items = items.concat(c.selectItems);

        const selected = items.find(si => si.value === c.value);

        return [items, selected];

    }, [c.selectItems, c.value, tm]);




    if (!c.selected || !(c.userInput || c.config)) {
        return null;
    }

    let usesConfigurator: boolean = c.config != null && c.config.page != null && c.config.page.length > 0;

    //OptionViewModel.vb
    let actionRequired: boolean = c.selected && (c.userInput || (usesConfigurator && c.config && c.config.optionValueRequired)) && inputText.length === 0;

    const showRecalcButton: boolean = c.userInput && !usesConfigurator && !hasValueSplits && !props.locked;


    //the core vm is checking InputType = ValueSelect or ImageSelect
    let hasSelections: boolean = c.selectItems && c.selectItems.length > 0;


    const renderValueSelect = () => {

        return <Autocomplete
            key={c.question + "_" + c.code}
            size="small"
            disableClearable={true}
            disablePortal
            disabled={props.locked || props.readOnlyMode}
            handleHomeEndKeys
            options={selectItems}
            getOptionLabel={(si: ISelectItem) => si.description}
            renderOption={(props, si: ISelectItem) => {
                let imageSource: string = APIHelper.GetBaseURL() + `api/images/OptionImage?optionCode=${c?.code ?? ""}&thumbnail=true&selectedItemValue=${si.value}`;

                return <Box component="li"
                    {...props}
                >
                    {si.imageExists &&
                        <img loading="lazy"
                            width="82"
                            src={imageSource} />}
                    {<Box ml={1}>{si.description}</Box>}
                </Box>;
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={c.description}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (actionRequired && <Flag sx={{ width: "16px", height: "16px" }} color="warning" />),
                        endAdornment: (showRecalcButton && <RecalcButton readOnlyMode={props.readOnlyMode} handleValueRefresh={handleValueRefresh} />),
                    }}
                    inputProps={{
                        ...params.inputProps,
                        inputMode: wizardState.userPreferences.enableMobileKeyboard ? "text" : "none"
                    }}
                />
            )}
            onChange={handleByValueSelectionChange}
            value={selectedItem} />;
    };

    const renderTextInput = () => {

        return <TextField
            label={c.description}
            fullWidth
            size="small"
            value={inputText}
            disabled={usesConfigurator || props.locked || props.readOnlyMode}
            onBlur={handleValueTextChange}
            onKeyDown={handleKeyDown}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setInputText((e.target as HTMLInputElement).value);
            }}

            InputProps={{
                startAdornment: (actionRequired && <Flag sx={{ width: "16px", height: "16px" }} color="warning" />),
                endAdornment: (showRecalcButton && <RecalcButton readOnlyMode={props.readOnlyMode} handleValueRefresh={handleValueRefresh} />),
            }} />;
    };

    const renderValueSplits = () => {
        return <NonTiledSplitOptions
            headerValuePairs={valueSplits}
            sequence={c.sequence}
            code={props.code}
            codeText={c.code}
            optionValue={c.value}
            handleValueRefresh={handleValueRefresh}
            usesConfigurator={usesConfigurator}
            locked={props.locked}
            readOnlyMode={props.readOnlyMode} />;
    };

    const renderConfigurator = () => {
        return <Button
            variant="contained"
            color="primary"
            value="..."
            title={c.config ? tm.Get(c.config.description) : ""}
            onClick={handleConfiguratorClick}
            disabled={props.locked || props.readOnlyMode}
        >
            {"..."}
        </Button>;
    };

    if (!hasSelections && !hasValueSplits && !usesConfigurator) {
        return renderTextInput();
    }
    else {

        return <Stack direction="row" spacing={1} alignItems="top">
            <Grid container direction="row" alignItems="center" spacing={1} >
                {hasSelections &&
                    <Grid item xs >
                        {renderValueSelect()}
                    </Grid>
                }

                {
                    !hasSelections && !hasValueSplits && (!usesConfigurator || c.value.length > 0) &&
                    <Grid item xs >
                        {renderTextInput()}
                    </Grid>
                }

                {
                    hasValueSplits &&
                    <Grid item xs>
                        {renderValueSplits()}
                    </Grid>
                }
            </Grid >
            {
                usesConfigurator && renderConfigurator()
            }
        </Stack>;
    }

}


interface IProps {
    readOnlyMode: boolean;
    handleValueRefresh: (event: React.MouseEvent<any>) => void;
}

export const RecalcButton: React.FC<IProps> = (props: IProps) => {

    const tm = useTranslations();

    return <>
        <IconButton
            size="small"
            disabled={props.readOnlyMode}
            tabIndex={-1}
            title={tm.Get("Reset to Default")}
            onClick={props.handleValueRefresh}
            color="primary"
        >
            <CachedIcon fontSize="inherit" />
        </IconButton>
    </>;
}


export default NonTiledOptionValue;
