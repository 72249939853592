import React, { useState, useMemo, useCallback } from "react";
import { TableContainer, Table, TableHead, TableBody, TableRow, Box } from "@mui/material";
import { StyledHeaderCell } from "./RowStyles";

import useTranslations from "helpers/hooks/useTranslations";
import useIsMobile from "helpers/hooks/useIsMobile";
import useApplicationInfo from "helpers/context/Application/useApplicationInfo";
import { PricingMethodEnum } from "helpers/enums";
import { ISimpleListItem, IPriceTablePartGridRow } from "helpers/interfaces";
import PartsGridRow from "./PartsGridRow";
import useCurrencyFormatter from "helpers/hooks/useCurrencyFormatter";
import useLocaleNumberFormatter from "helpers/hooks/useLocaleNumberFormatter";

interface IProps {
    tableID : string,
    schedules: ISimpleListItem[],
    partsGrid: IPriceTablePartGridRow[],
    tableType: PricingMethodEnum,
    fillDownHandler: (index: number, scheduleID: number, discount: string, fixedPrice: boolean, checked: boolean) => void,
}

const PartsGrid = ({tableID, schedules, partsGrid, tableType, fillDownHandler}: IProps) => {
    const tm = useTranslations();
    const cf = useCurrencyFormatter("");
    const lnf = useLocaleNumberFormatter({style: "decimal", useGrouping: true, minimumFractionDigits: 4, maximumFractionDigits: 4});
    const isMobile = useIsMobile();
    const appInfo = useApplicationInfo();

    const [usesMultiplier, setUsesMultiplier] = useState<boolean>(false);

    const priceColumnText = useMemo(() => {
        switch (tableType) {
            case PricingMethodEnum.Discount:
                if (appInfo.parameters.pricingShowDiscountMultiplierInsteadOfPercent) {
                    setUsesMultiplier(true);
                    return tm.Get("Discount Mult.");
                } else {
                    setUsesMultiplier(false);
                    return tm.Get("Discount %");
                }
            case PricingMethodEnum.Markup:
                if (appInfo.parameters.pricingShowMarkupMultiplierInsteadOfPercent) {
                    setUsesMultiplier(true);
                    return tm.Get("Markup Mult.");
                } else {
                    setUsesMultiplier(false);
                    return tm.Get("Markup %");
                }
            case PricingMethodEnum.Margin:
                setUsesMultiplier(false);
                return tm.Get("Gross Profit Margin");
        }
    }, [appInfo, tableType, tm]);

    const getKey = useCallback((part: IPriceTablePartGridRow) => {
        return part.partNo + part.partNoSuffix + part.timestamp;
    }, []);

    return <>
        <Box sx={{ display: "grid", width: "100%" }}>
            <TableContainer sx={{ width: "100%" }}>
                <Table sx={{ minWidth: "100%", width: "max-content" }} size="small" padding="none">
                    <TableHead>
                        <TableRow>
                            <StyledHeaderCell style={{ width: "15%" }}>{tm.Get("Part")}</StyledHeaderCell>
                            {!isMobile && 
                                <StyledHeaderCell style={{ width: "30%" }}>{tm.Get("Description")}</StyledHeaderCell>
                            }
                            <StyledHeaderCell style={{ width: "30%" }}>{tm.Get("Schedule")}</StyledHeaderCell>
                            <StyledHeaderCell style={{ width: "15%", textAlign: "right" }}>{priceColumnText}</StyledHeaderCell>
                            <StyledHeaderCell style={{ width: "5%", textAlign: "center" }}>{tm.Get("Option")}</StyledHeaderCell>
                            <StyledHeaderCell style={{ width: "5%" }}>&nbsp;</StyledHeaderCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {partsGrid.map((part, i) => (
                            <PartsGridRow key={getKey(part)}
                                tableID={tableID}
                                schedules={schedules}
                                part={part}
                                index={i}
                                usesMultiplier={usesMultiplier}
                                tableType={tableType}
                                fillDownHandler={fillDownHandler}
                                cf={cf}
                                lnf={lnf}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    </>
}

export default PartsGrid;