import React, { useMemo } from "react";

import OrdersAPI from "components/Orders/OrdersAPI";
import { IOrder, IOrderLineItem, IOrderPermissions, IOrderTotals, IOrderPolicy, IBaseContent, IApplicationInfo, IContainerDetail, IContainer, IProdStatusSummary, IProdStatusUnitHistory, IProdStatusUnitRejectHistory, ISchedule } from "helpers/interfaces";
import { DispatchActionsBase } from "components/Common/DispatchActionsBase";
import useApplicationInfo from "helpers/context/Application/useApplicationInfo";

export interface IOrderData {
    order?: IOrder,
    permissions?: IOrderPermissions,
    lineItems: IOrderLineItem[],
    isLoading: boolean,
    totals?: IOrderTotals,
    policies?: IOrderPolicy[],
    isOnContainer?: boolean,
    hasBeenReleased?: boolean,
    containers?: IContainer[],
    containerDetail?: { container: IContainer, details: IContainerDetail[] },
    prodStatus?: IProdStatusSummary[],
    prodStatusUnitHistory?: { unit: IProdStatusSummary, schedule: ISchedule, unitHistory: IProdStatusUnitHistory[] },
    prodStatusUnitRejectHistory?: { unit: IProdStatusSummary, schedule: ISchedule, unitRejectHistory: IProdStatusUnitRejectHistory[] },
}

export enum OrderActionTypeEnum {
    LoadOrder,
    LoadLineItems,
    LoadOrderTotals,
    LoadPolicies,
    Clear,
    SetIsLoading,
    LoadIsOnContainer,
    LoadHasBeenReleased,
    LoadContainerStatus,
    LoadContainers,
    LoadContainerDetail,
    LoadProdStatus,
    LoadProdStatusUnitHistory,
    LoadProdStatusUnitRejectHistory
}

export type OrderActionType =
    { type: OrderActionTypeEnum.LoadOrder, value: { order: IOrder, permissions: IOrderPermissions } }
    | { type: OrderActionTypeEnum.LoadLineItems, value: IOrderLineItem[] }
    | { type: OrderActionTypeEnum.LoadOrderTotals, value: IOrderTotals }
    | { type: OrderActionTypeEnum.LoadPolicies, value: IOrderPolicy[] }
    | { type: OrderActionTypeEnum.Clear }
    | { type: OrderActionTypeEnum.SetIsLoading, value: boolean }
    | { type: OrderActionTypeEnum.LoadIsOnContainer, value: boolean }
    | { type: OrderActionTypeEnum.LoadHasBeenReleased, value: boolean }
    | { type: OrderActionTypeEnum.LoadContainers, value: IContainer[] }
    | { type: OrderActionTypeEnum.LoadContainerDetail, value: { container: IContainer, details: IContainerDetail[] } }
    | { type: OrderActionTypeEnum.LoadProdStatus, value: IProdStatusSummary[] }
    | { type: OrderActionTypeEnum.LoadProdStatusUnitHistory, value: { unit: IProdStatusSummary, schedule: ISchedule, unitHistory: IProdStatusUnitHistory[] } }
    | { type: OrderActionTypeEnum.LoadProdStatusUnitRejectHistory, value: { unit: IProdStatusSummary, schedule: ISchedule, unitRejectHistory: IProdStatusUnitRejectHistory[] } }
    ;

const orderReducer: React.Reducer<IOrderData, OrderActionType> = (data: IOrderData, action) => {

    switch (action.type) {
        case OrderActionTypeEnum.LoadOrder:
            return { ...data, ...action.value };
        case OrderActionTypeEnum.LoadLineItems:
            return { ...data, lineItems: action.value };
        case OrderActionTypeEnum.LoadOrderTotals:
            return { ...data, totals: action.value };
        case OrderActionTypeEnum.LoadPolicies:
            return { ...data, policies: action.value };
        case OrderActionTypeEnum.Clear:
            return { ...initialState };
        case OrderActionTypeEnum.SetIsLoading:
            return { ...data, isLoading: action.value };
        case OrderActionTypeEnum.LoadIsOnContainer:
            return { ...data, isOnContainer: action.value };
        case OrderActionTypeEnum.LoadHasBeenReleased:
            return { ...data, hasBeenReleased: action.value };
        case OrderActionTypeEnum.LoadContainers:
            return { ...data, containers: action.value };
        case OrderActionTypeEnum.LoadContainerDetail:
            return { ...data, containerDetail: action.value };
        case OrderActionTypeEnum.LoadProdStatus:
            return { ...data, prodStatus: action.value };
        case OrderActionTypeEnum.LoadProdStatusUnitHistory:
            return { ...data, prodStatusUnitHistory: action.value };
        case OrderActionTypeEnum.LoadProdStatusUnitRejectHistory:
            return { ...data, prodStatusUnitRejectHistory: action.value };
    }
};

const initialState: IOrderData = {
    lineItems: [],
    isLoading: false,
};

export class OrderActions extends DispatchActionsBase<OrderActionType> {

    #applicationInfo: IApplicationInfo;

    constructor(dispatcher: React.Dispatch<OrderActionType>, applicationInfo: IApplicationInfo) {
        super(dispatcher);
        this.#applicationInfo = applicationInfo;
    }

    public async LoadOrderAsync(oKey: number) {
        if (oKey) {
            const headerPromise = OrdersAPI.GetOrderAndPermissions(oKey);
            const itemsPromise = OrdersAPI.GetLineItems(oKey);
            const totalsPromise = OrdersAPI.GetOrderTotals(oKey);
            const policiesPromise = OrdersAPI.GetOrderPolicies(oKey);
            this.dispatch({ type: OrderActionTypeEnum.SetIsLoading, value: true });
            const [header, items, totals, policies, isOnContainer, hasBeenReleased] = await Promise.all([headerPromise, itemsPromise, totalsPromise, policiesPromise, ...this.GetProductionStatusPromises(oKey)]);
            this.dispatch({ type: OrderActionTypeEnum.LoadOrder, value: { order: header.order, permissions: header.permissions } });
            this.dispatch({ type: OrderActionTypeEnum.LoadLineItems, value: items });
            this.dispatch({ type: OrderActionTypeEnum.LoadOrderTotals, value: totals });
            this.dispatch({ type: OrderActionTypeEnum.LoadPolicies, value: policies });
            this.dispatch({ type: OrderActionTypeEnum.LoadIsOnContainer, value: isOnContainer });
            this.dispatch({ type: OrderActionTypeEnum.LoadHasBeenReleased, value: hasBeenReleased });
            this.dispatch({ type: OrderActionTypeEnum.SetIsLoading, value: false });

        }
    }

    public async LoadOrderPoliciesAsync(oKey: number) {
        if (oKey) {
            const policies = await OrdersAPI.GetOrderPolicies(oKey);
            this.dispatch({ type: OrderActionTypeEnum.LoadPolicies, value: policies });
        }
    }

    public async LoadContainersAsync(oKey: number) {
        if (oKey) {
            const containers = await OrdersAPI.GetContainers(oKey);
            this.dispatch({ type: OrderActionTypeEnum.LoadContainers, value: containers });
        }
    }

    public async LoadContainerDetailAsync(oKey: number, container: IContainer) {
        if (oKey && container && container.containerKey) {
            const containerDetail = await OrdersAPI.GetContainerDetail(oKey, container.containerKey);
            this.dispatch({ type: OrderActionTypeEnum.LoadContainerDetail, value: { container: container, details: containerDetail } });
        }
    }

    public async LoadProdStatusAsync(oKey: number) {
        if (oKey) {
            const prodStatus = await OrdersAPI.GetProdStatusSummary(oKey);
            this.dispatch({ type: OrderActionTypeEnum.LoadProdStatus, value: prodStatus });
        }
    }

    public async LoadProdStatusUnitHistoryAsync(oKey: number, prodStatusSummary: IProdStatusSummary) {
        if (oKey && prodStatusSummary && prodStatusSummary.schedID && prodStatusSummary.unitID) {
            const schedule = await OrdersAPI.GetSchedule(oKey, prodStatusSummary.schedID);
            const prodStatusUnitHistory = await OrdersAPI.GetProdStatusUnitHistory(oKey, prodStatusSummary.schedID, prodStatusSummary.unitID);
            this.dispatch({ type: OrderActionTypeEnum.LoadProdStatusUnitHistory, value: { unit: prodStatusSummary, schedule: schedule, unitHistory: prodStatusUnitHistory } });
        }
    }

    public async LoadProdStatusUnitRejectHistoryAsync(oKey: number, prodStatusSummary: IProdStatusSummary) {
        if (oKey && prodStatusSummary && prodStatusSummary.schedID && prodStatusSummary.unitID) {
            const schedule = await OrdersAPI.GetSchedule(oKey, prodStatusSummary.schedID);
            const prodStatusUnitRejectHistory = await OrdersAPI.GetProdStatusUnitRejectHistory(oKey, prodStatusSummary.schedID, prodStatusSummary.unitID);
            this.dispatch({ type: OrderActionTypeEnum.LoadProdStatusUnitRejectHistory, value: { unit: prodStatusSummary, schedule: schedule, unitRejectHistory: prodStatusUnitRejectHistory } });
        }
    }

    public async ApproveOrderPoliciesAsync(oKey: number): Promise<IBaseContent> {
        var result: IBaseContent = { message: "" };
        if (oKey) {
            result = await OrdersAPI.ApproveOrderPolicies(oKey);
            if (result.message === "") {
                const policies = await OrdersAPI.GetOrderPolicies(oKey);
                this.dispatch({ type: OrderActionTypeEnum.LoadPolicies, value: policies });
            }
        }
        return result;
    }

    private GetProductionStatusPromises(oKey: number) {

        if (this.#applicationInfo.parameters.displayProdStatus) {
            var containerPromise = OrdersAPI.GetOrderIsOnContainer(oKey);
            var releasePromise = OrdersAPI.GetOrderHasBeenReleased(oKey);
            return [containerPromise, releasePromise];
        }
        else {
            return [Promise.resolve(false), Promise.resolve(false)];
        }
    }
}

export const OrderContextDispatch = React.createContext<OrderActions | null>(null);
export const OrderContext = React.createContext<IOrderData>(initialState);

export const OrderContextProvider: React.FC<React.PropsWithChildren<any>> = (props: React.PropsWithChildren<any>) => {

    const [state, dispatch] = React.useReducer(orderReducer, initialState);
    const appInfo = useApplicationInfo();

    const actions = useMemo(() => {
        return new OrderActions(dispatch, appInfo);
    }, [appInfo, dispatch]);

    return <OrderContext.Provider value={state}>
        <OrderContextDispatch.Provider value={actions}>
            {props.children}
        </OrderContextDispatch.Provider>
    </OrderContext.Provider>
}

export default OrderContext;


