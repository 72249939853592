import React, { useEffect, useState, useCallback } from "react";
import { TableContainer, Table, TableHead, TableBody, TableRow, Tooltip, IconButton, TextField } from "@mui/material";
import { StyledHeaderCell, StyledRow, StyledCell } from "components/Setup/PriceTables/RowStyles";
import AddIcon from '@mui/icons-material/Add';

import SetupAPI from "../SetupAPI";
import useTranslations from "helpers/hooks/useTranslations";
import { IPriceScheduleItem } from "helpers/interfaces";
import { ICurrencyFormatter } from "helpers/hooks/useCurrencyFormatter";
import PriceScheduleItemsGridRow from "./PriceScheduleItemsGridRow";
import ErrorAdornment from "components/Common/ErrorAdornment";
import useMessageBox from "helpers/context/Page/useMessageBox";
import { ILocaleNumberFormatter } from "helpers/hooks/useLocaleNumberFormatter";
import Constants from "helpers/constants";

interface IProps {
    priceScheduleID: number,
    items : IPriceScheduleItem[],
    setItems: React.Dispatch<IPriceScheduleItem[]>,
    cf: ICurrencyFormatter,
    lnf: ILocaleNumberFormatter
}

const PriceScheduleItemsGrid = ({priceScheduleID, items, setItems, cf, lnf}: IProps) => {
    const tm = useTranslations();
    const messageBox = useMessageBox();

    const [newRowIncrement, setNewRowIncrement] = useState<string>("");
    const [newRowPrice, setNewRowPrice] = useState<string>("");

    const [incrementErrorText, setIncrementErrorText] = useState<string>("");
    const [priceErrorText, setPriceErrorText] = useState<string>("");

    useEffect(() => {
        setNewRowIncrement("");
        setNewRowPrice("");
        setIncrementErrorText("");
        setPriceErrorText("");
    }, [priceScheduleID]);

    const getKey = useCallback((item: IPriceScheduleItem) => {
        return item.price.toString() + item.increment.toString();
    }, []);

    const editUIPriceHandler = useCallback((ui: number, price: number) => {
        SetupAPI.PriceScheduleEditUI(priceScheduleID, price, ui).then(() => {
            let itemsCopy = items.slice();
            let itemCopy = itemsCopy.find((i) => i.increment === ui)!;
            itemCopy.price = price;
            setItems(itemsCopy);
        });
    }, [priceScheduleID, items, setItems]);

    const deleteUIHandler = useCallback((ui: number) => {
        SetupAPI.PriceScheduleDeleteUI(priceScheduleID, ui).then(() => {
            let thisIndex = items.findIndex((i) => i.increment === ui);
            let itemsCopy = items.slice();
            itemsCopy.splice(thisIndex, 1);
            setItems(itemsCopy);
        });
    }, [priceScheduleID, items, setItems]);

    const addUIHandler = useCallback(() => {
        if (incrementErrorText !== "") {
            messageBox.Show({
                message: incrementErrorText,
                title: tm.Get("Price Schedules")
            });
        } else if (priceErrorText !== "") {
            messageBox.Show({
                message: priceErrorText,
                title: tm.Get("Price Schedules")
            });
        } else if (newRowIncrement === "") {
            messageBox.Show({
                message: tm.Get("UI must be a positive number."),
                title: tm.Get("Price Schedules")
            });
        } else if (newRowPrice === "") {
            messageBox.Show({
                message: tm.Get("Price must be a valid currency value."),
                title: tm.Get("Price Schedules")
            });
        } else {
            // API call
            let increment = lnf.Parse((newRowIncrement));
            let price = cf.Parse(newRowPrice);
            SetupAPI.PriceScheduleAddUI(priceScheduleID, price, increment).then(() => {
                let itemsCopy = items.slice();
                itemsCopy.push({increment, price});
                itemsCopy.sort((a, b) => a.increment - b.increment);
                setItems(itemsCopy);
                setNewRowIncrement("");
                setNewRowPrice("");
            });
        }
    }, [tm, cf, lnf, messageBox, incrementErrorText, priceErrorText, newRowIncrement, newRowPrice, priceScheduleID, items, setItems]);

    const handleUIChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setNewRowIncrement(e.target.value);
    }, []);

    const handleUIBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
        if (e.target.value === "") {
            setIncrementErrorText("");
        } else {
            let numericValue = lnf.Parse(e.target.value);
            if (isNaN(numericValue) || numericValue < Constants.Min.UnitInches || numericValue > Constants.Max.UnitInches) {
                setIncrementErrorText(tm.GetWithParams("{0} must be between {1} and {2}.", tm.Get("UI"), Constants.Min.UnitInches.toString(), Constants.Max.UnitInches.toString()));
            } else {
                if (items.find((i) => i.increment === numericValue)) {
                    setIncrementErrorText(tm.Get("UI already exists."));
                } else {
                    setIncrementErrorText("");
                }
            }
        }
    }, [tm, items, lnf]);

    const handlePriceChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setNewRowPrice(e.target.value);
    }, []);

    const handlePriceBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
        let numericValue = cf.Parse(e.target.value);
        if (e.target.value === "") {
            setPriceErrorText("");
        } else if (isNaN(numericValue)) {
            setPriceErrorText(tm.Get("Price must be a valid currency value."));
        } else if (numericValue < Constants.Min.Price || numericValue > Constants.Max.Price) {
            setPriceErrorText(tm.GetWithParams("Price must be between {0} and {1}.", Constants.Min.Price.toString(), Constants.Max.Price.toString()));
        } else {
            setPriceErrorText("");
        }
    }, [tm, cf]);

    return <>
        <TableContainer sx={{ width: "100%" }}>
            <Table size="small" padding="none">
                <TableHead>
                    <TableRow>
                        <StyledHeaderCell style={{ width: "50%", textAlign: "center" }}>{tm.Get("UI")}</StyledHeaderCell>
                        <StyledHeaderCell style={{ width: "50%", textAlign: "center" }}>{tm.Get("Price")}</StyledHeaderCell>
                        <StyledHeaderCell style={{ minWidth: "80px" }}>&nbsp;</StyledHeaderCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item) => (
                        <PriceScheduleItemsGridRow key={getKey(item)} item={item} 
                            editHandler={(price: number) => editUIPriceHandler(item.increment, price)}
                            deleteHandler={() => deleteUIHandler(item.increment)}
                            cf={cf}
                            lnf={lnf}
                        />
                    ))}
                    <StyledRow>
                        <StyledCell style={{ textAlign: "center" }}>
                            <TextField size="small" value={newRowIncrement}
                                variant="standard"
                                InputProps={{
                                    endAdornment: incrementErrorText !== "" ? 
                                        <ErrorAdornment validationError={incrementErrorText}/> : null,
                                    inputProps: {style: { textAlign: "center", maxWidth: "200px" }}
                                }}
                                onChange={handleUIChange}
                                onBlur={handleUIBlur}
                            />
                        </StyledCell>
                        <StyledCell style={{ textAlign: "center" }}>
                            <TextField size="small" value={newRowPrice}
                                variant="standard"
                                InputProps={{
                                    endAdornment: priceErrorText !== "" ? 
                                        <ErrorAdornment validationError={priceErrorText}/> : null,
                                    inputProps: {style: { textAlign: "center", maxWidth: "200px" }}
                                }}
                                onChange={handlePriceChange}
                                onBlur={handlePriceBlur}
                            />
                        </StyledCell>
                        <StyledCell align="center">
                            <Tooltip title={tm.Get("Add")}>
                                <IconButton onClick={addUIHandler}>
                                    <AddIcon fontSize="small" color="primary"/>
                                </IconButton>
                            </Tooltip>
                        </StyledCell>
                    </StyledRow>
                </TableBody>
            </Table>
        </TableContainer>
    </>
}

export default PriceScheduleItemsGrid;