import { useState, useEffect, useMemo, useCallback } from 'react';

import QuotesAPI from 'components/Quotes/QuotesAPI';
import useTranslations from "helpers/hooks/useTranslations";
import useApplicationInfo from "helpers/context/Application/useApplicationInfo";


function useDuplicatePOCheck(oKey?: number, customerGUID?: string, poNumber?: string): [string | undefined, string] {

    const [quoteWithDuplicatePONumber, setQuoteWithDuplicatePONumber] = useState<string | undefined>(undefined);
    const [message, setMessage] = useState<string>("");

    const tm = useTranslations();
    const appInfo = useApplicationInfo();

    const shouldCheckDuplicatePONumber = useMemo(() => {
        return appInfo.parameters.orderEntryPOCheckAlpha || appInfo.parameters.orderEntryPOCheckNumber;
    }, [appInfo]);


    useEffect(() => {
        if (!shouldCheckDuplicatePONumber) {
            setQuoteWithDuplicatePONumber("");
        }
        else {
            setQuoteWithDuplicatePONumber(undefined);
        }
    }, [shouldCheckDuplicatePONumber, poNumber]);

    const getDuplicatePONumberAsync = useCallback(async (oKey: number, customerGUID: string, poNumber: string) => {
        const response = await QuotesAPI.GetQuoteNumberWithDuplicatePONumber(oKey, customerGUID, poNumber);
        const quoteNumber = response.Result;

        if (response.IsOk()) {
            setQuoteWithDuplicatePONumber(quoteNumber);
            setMessage(quoteNumber ? tm.GetWithParams("This PO Number was already used on quote {0}.", quoteNumber) : "");
        }
    }, [tm]);

    useEffect(() => {

        if (oKey !== undefined && customerGUID && poNumber) {
            getDuplicatePONumberAsync(oKey, customerGUID, poNumber);
        }

    }, [oKey, customerGUID, poNumber, getDuplicatePONumberAsync]);

    return [quoteWithDuplicatePONumber, message];
}

export default useDuplicatePOCheck;