import { Alert, Button, Grid, ListItem, Stack, Tooltip } from "@mui/material";
import BoxWithHeader from "components/Common/BoxWithHeader";
import useOrderData from "components/Orders/useOrderData";
import { ThemeColorEnum } from "helpers/enums";
import useTranslations from "helpers/hooks/useTranslations";
import { useCallback, useMemo, useState } from "react";

interface IProps {
    onApprove: () => Promise<string>,
}

const Policies: React.FC<IProps> = ({ onApprove }: IProps) => {

    const { policies } = useOrderData();
    const tm = useTranslations();
    const [approveMessage, setApproveMessage] = useState<string>("");

    const policiesRequiringApproval = useMemo(() => {
        return policies?.filter((p, i) => p.webApproval === true) ?? [];
    }, [policies]);

    const manufacturerPolicies = useMemo(() => {
        return policies?.filter((p, i) => p.webApproval === false) ?? [];
    }, [policies]);

    const onApproveClick = useCallback(async () => {
        const message = await onApprove();
        setApproveMessage(message);
    }, [onApprove]);

    return <>
        <Stack direction="column" gap={1}>
            {policiesRequiringApproval.length > 0 &&
                <BoxWithHeader
                    caption={tm.Get("Order Holds Requiring Approval")}>

                    <Stack direction="column" gap={1}>
                        {policiesRequiringApproval?.map((p, i) => (
                            <ListItem key={`{A_POLICY_IDX${i}`} disablePadding sx={{ display: 'list-item' }}>
                                {p.description}
                            </ListItem>
                        ))}
                        <Grid container justifyContent={"center"}>
                            <Tooltip title={tm.Get("Approves all order holds requiring approval.")}>
                                <Button
                                    onClick={onApproveClick}
                                    variant="contained"
                                    color={ThemeColorEnum.Secondary}>
                                    {tm.Get("Approve")}
                                </Button>
                            </Tooltip>
                        </Grid>
                        {approveMessage && approveMessage !== "" &&
                            <Alert
                                severity="error"
                                onClose={() => setApproveMessage("")}
                            >
                                {approveMessage}
                            </Alert>
                        }
                    </Stack>
                </BoxWithHeader>
            }
            {manufacturerPolicies.length > 0 &&
                <BoxWithHeader
                    caption={tm.Get("Manufacturer Holds")}>

                    {manufacturerPolicies?.map((p, i) => (
                        <ListItem key={`{M_POLICY_IDX${i}`} disablePadding sx={{ display: 'list-item' }}>
                            {p.description}
                        </ListItem>
                    ))}
                </BoxWithHeader >
            }
        </Stack >

    </>;

}

export default Policies;