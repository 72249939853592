import APIHelper from "helpers/APIHelper";
import { ExportTypeEnum } from "helpers/enums";
import {
    IWebCustomer, IMfgCustomer, IQuote, ITerms, IShipVia, IQuotePermissions, IEmployee, IMfgSecondaryShipTo, ILockedObject, IApplicationInfo, ICategoryListItem,
    IPartListItem, IPartKey, ILineItem, IQuoteTotals, ILineItemQuantityChange, ISurchargeOverrideInfo, ITaxDetail, ITaxOverrideInfo, ITransLogHeader, ITransLogDiff
} from "helpers/interfaces";
import WebResponse, { ResponseTypeEnum } from "helpers/WebResponse";

export default class QuotesAPI {

    public static async GetWebCustomerList(mfgCustomerID: string, mfgSiteID: string, createQuickCustomer: boolean): Promise<WebResponse<IWebCustomer[]>> {
        const url: string = `api/WebCustomers/List`;
        const params = {
            mfgCustomerID: mfgCustomerID,
            mfgSiteID: mfgSiteID,
            createQuickCustomer: createQuickCustomer
        }
        return await APIHelper.GetAsync<IWebCustomer[]>(url, params);
    }

    public static async GetWebContractorCustomerList(parentCustomerID: string, createQuickCustomer: boolean): Promise<WebResponse<IWebCustomer[]>> {
        const url: string = `api/WebCustomers/Contractors/List`;
        const params = {
            parentCustomerID: parentCustomerID,
            createQuickCustomer: createQuickCustomer
        }
        return await APIHelper.GetAsync<IWebCustomer[]>(url, params);
    }

    public static async GetWebCustomerNotes(webCustomerID: string): Promise<WebResponse<string>> {
        const query: string = `api/WebCustomers/${encodeURIComponent(webCustomerID)}/Notes`;
        return await APIHelper.GetAsync<string>(query);
    }

    public static async GetWebCustomer(webCustomerID: string): Promise<WebResponse<IWebCustomer>> {
        const query: string = `api/WebCustomers/${encodeURIComponent(webCustomerID)}`;
        return await APIHelper.GetAsync<IWebCustomer>(query);
    }

    public static async GetMfgCustomerList(): Promise<WebResponse<IMfgCustomer[]>> {
        const query: string = `api/MfgCustomers/List`;
        return await APIHelper.GetAsync<IMfgCustomer[]>(query);
    }

    public static async GetMfgCustomer(mfgCustomerID: string, mfgSiteID: string): Promise<WebResponse<IMfgCustomer>> {
        const url: string = `api/MfgCustomers/${encodeURIComponent(mfgCustomerID)}/${encodeURIComponent(mfgSiteID)}`;
        return await APIHelper.GetAsync<IMfgCustomer>(url);
    }

    public static async GetQuoteDefaults(customerGUID: string): Promise<WebResponse<IQuote>> {
        const url: string = `api/Quotes/Defaults`;
        const params = {
            customerGUID: customerGUID,
        };
        return await APIHelper.GetAsync<IQuote>(url, params);
    }

    public static async GetTerms(parentCustomerID: string): Promise<WebResponse<ITerms[]>> {
        const query: string = `api/Terms/${encodeURIComponent(parentCustomerID)}`;
        return await APIHelper.GetAsync<ITerms[]>(query);
    }

    public static async GetShipVia(parentCustomerID: string): Promise<WebResponse<IShipVia[]>> {
        const query: string = `api/ShipVia/${encodeURIComponent(parentCustomerID)}`;
        return await APIHelper.GetAsync<IShipVia[]>(query);
    }

    public static async GetEmployee(employeeID: number): Promise<WebResponse<IEmployee>> {
        const query: string = `api/Employees/${encodeURIComponent(employeeID.toString())}`;
        return await APIHelper.GetAsync<IEmployee>(query, undefined, undefined, false);
    }

    public static async GetSalespersons(parentCustomerID: string, parentSiteID: string): Promise<WebResponse<IEmployee[]>> {
        const url: string = `api/Employees/Salespersons/List`;
        const params = {
            parentCustomerID: parentCustomerID,
            parentSiteID: parentSiteID,
        }
        return await APIHelper.GetAsync<IEmployee[]>(url, params);
    }

    public static async GetMfgSecondaryShipTos(mfgCustomerID: string, mfgSiteID: string): Promise<WebResponse<IMfgSecondaryShipTo[]>> {
        const url: string = `api/SecondaryShipTos`;
        const params = {
            mfgCustomerID: mfgCustomerID,
            mfgSiteID: mfgSiteID,
        }
        return await APIHelper.GetAsync<IMfgSecondaryShipTo[]>(url, params);
    }

    public static async PostQuoteHeader(quoteHeader: IQuote): Promise<WebResponse<number>> {
        const query: string = `api/Quotes`;
        return await APIHelper.PostAsync<number>(query, {}, quoteHeader);
    }

    public static async PutQuoteHeader(oKey: number, quoteHeader: IQuote): Promise<WebResponse<void>> {
        const query: string = `api/Quotes`;
        const params = {
            oKey: oKey,
        };
        return await APIHelper.PutAsync<void>(query, params, quoteHeader);
    }

    public static async GetQuoteTotals(oKey: number): Promise<WebResponse<IQuoteTotals>> {
        const url: string = `api/Quotes/${oKey}/Totals`;
        return await APIHelper.GetAsync<IQuoteTotals>(url);
    }

    public static async GetTaxDetails(oKey: number): Promise<WebResponse<ITaxDetail[]>> {
        const url: string = `api/Quotes/${oKey}/TaxDetails`;
        return await APIHelper.GetAsync<ITaxDetail[]>(url);
    }

    public static async GetPermissions(oKey: number): Promise<WebResponse<IQuotePermissions>> {
        const url: string = `api/Quotes/${oKey}/Permissions`;
        return await APIHelper.GetAsync<IQuotePermissions>(url);
    }

    public static async GetQuoteAndPermissions(oKey: number): Promise<WebResponse<{ quote: IQuote, permissions: IQuotePermissions }>> {
        const url: string = `api/Quotes/${oKey}/QuoteAndPermissions`;
        return await APIHelper.GetAsync<{ quote: IQuote, permissions: IQuotePermissions }>(url);
    }

    public static async LogRecentQuote(oKey: number): Promise<WebResponse<void>> {
        const url: string = "api/Quotes/RecentQuote";
        const params = {
            oKey: oKey
        }
        return await APIHelper.PutAsync<void>(url, params);
    }

    public static async GetQuoteNumberWithDuplicatePONumber(oKey: number, customerGUID: string, poNumber: string): Promise<WebResponse<string>> {
        const url: string = `api/Quotes/${oKey}/QuoteNumberWithDuplicatePONumber`;
        const params = {
            customerGUID: customerGUID,
            poNumber: poNumber
        };
        return await APIHelper.GetAsync<string>(url, params);
    }

    public static async GetQuoteLock(oKey: number, appInfo: IApplicationInfo, acquireLock: boolean = false): Promise<WebResponse<ILockedObject>> {

        if (!appInfo.parameters.performOrderLocking) {
            const wr = new WebResponse<ILockedObject>(ResponseTypeEnum.Ok)
            wr.Result = {
                isLocked: false,
                lockID: "",
                lockedBy: "",
                isLockedByAnotherUser: false,
                message: "",
            };
            return wr;
        }

        const url: string = `api/Quotes/${oKey}/Lock`;
        const params = {
            acquireLock: acquireLock,
        };
        return await APIHelper.GetAsync<ILockedObject>(url, params);
    }

    public static async DeleteQuoteLock(oKey: number, appInfo: IApplicationInfo): Promise<WebResponse<void>> {
        if (!appInfo.parameters.performOrderLocking) {
            return new WebResponse(ResponseTypeEnum.Ok);
        }

        const url: string = `api/Quotes/${oKey}/Lock`;
        return await APIHelper.DeleteAsync(url);
    }

    public static async CopyQuote(okey: number): Promise<WebResponse<number>> {
        return await APIHelper.CopyAsync<number>(`api/quotes/${okey}`);
    }

    public static async DeleteQuote(okey: number): Promise<WebResponse<void>> {
        return await APIHelper.DeleteAsync(`api/quotes/${okey}`, {});
    }

    public static async GetLastCategoryIDOnQuote(oKey: number): Promise<WebResponse<number | null>> {
        const url: string = `api/Quotes/${oKey}/LastCategoryID`;
        return await APIHelper.GetAsync<number | null>(url);
    }

    public static async GetLastPartKeyOnQuote(oKey: number): Promise<WebResponse<IPartKey | null>> {
        const url: string = `api/Quotes/${oKey}/LastPartKey`;
        return await APIHelper.GetAsync<IPartKey | null>(url);
    }

    public static async GetCategoryList(mfgCustomerID: string, mfgSiteID: string): Promise<WebResponse<ICategoryListItem[]>> {
        const url: string = `api/MfgCustomers/${mfgCustomerID}/${mfgSiteID}/Categories/List`;
        return await APIHelper.GetAsync<ICategoryListItem[]>(url);
    }

    public static async GetPartList(mfgCustomerID: string, mfgSiteID: string, categoryID: number): Promise<WebResponse<IPartListItem[]>> {
        const url: string = `api/MfgCustomers/${mfgCustomerID}/${mfgSiteID}/Parts/List`;
        const params = {
            productCategoryID: categoryID,
        };
        return await APIHelper.GetAsync<IPartListItem[]>(url, params);
    }

    public static async GetLineItems(oKey: number): Promise<WebResponse<ILineItem[]>> {
        const url: string = `api/Quotes/${oKey}/LineItems`;
        return await APIHelper.GetAsync<ILineItem[]>(url);
    }

    public static async GetLineItemOptionsString(oKey: number, odKey: number, toolTip: boolean): Promise<WebResponse<string>> {
        const url: string = `api/Quotes/${oKey}/LineItems/${odKey}/OptionsString`;
        const params = {
            toolTip: toolTip,
        };
        return await APIHelper.GetAsync<string>(url, params, undefined, false);
    }

    public static async GetLineItemExceptions(oKey: number, odKey: number): Promise<WebResponse<string[]>> {
        const url: string = `api/Quotes/${oKey}/LineItems/${odKey}/Exceptions`;
        return await APIHelper.GetAsync<string[]>(url);
    }

    public static async UpdateLineItemQuantities(oKey: number, quantities: ILineItemQuantityChange[]): Promise<WebResponse<void>> {
        const url: string = `api/Quotes/LineItemQuantities`;
        const params = {
            oKey: oKey,
        };
        return await APIHelper.PutAsync(url, params, quantities);
    }

    public static async CopyLineItem(oKey: number, odKey: number): Promise<WebResponse<number>> {
        const url: string = `api/Quotes/${oKey}/LineItems/${odKey}/Copy`;
        return await APIHelper.GetAsync<number>(url);
    }

    public static async MoveLineItem(oKey: number, odKey: number, increment: boolean): Promise<WebResponse<void>> {
        const url: string = `api/Quotes/${oKey}/LineItems/${odKey}/Move`;
        const params = {
            increment: increment,
        };
        return await APIHelper.PutAsync(url, params);
    }

    public static async DeleteLineItem(oKey: number, odKey: number): Promise<WebResponse<void>> {
        const url: string = `api/Quotes/${oKey}/LineItems/${odKey}`;
        return await APIHelper.DeleteAsync(url);
    }

    public static async OverrideSurcharges(oKey: number, surchargeOverrideInfos: ISurchargeOverrideInfo[]): Promise<WebResponse<void>> {
        const url: string = `api/Quotes/${oKey}/SurchargeOverrides`;
        const body = surchargeOverrideInfos;
        return await APIHelper.PutAsync(url, undefined, body);
    }

    public static async OverrideTaxes(oKey: number, taxOverrideInfos: ITaxOverrideInfo[]): Promise<WebResponse<void>> {
        const url: string = `api/Quotes/${oKey}/TaxOverrides`;
        const body = taxOverrideInfos;
        return await APIHelper.PutAsync(url, undefined, body);
    }

    public static async RenewQuote(oKey: number, preservePrice: boolean): Promise<WebResponse<void>> {
        const url: string = `api/Quotes/${oKey}/Renew`;
        const params = {
            preservePrice: preservePrice,
        };
        return await APIHelper.GetAsync(url, params);
    }

    public static async IsRenewQuoteAllowed(oKey: number): Promise<WebResponse<boolean>> {
        const url: string = `api/Quotes/${oKey}/Renew/Allowed`;
        return await APIHelper.GetAsync<boolean>(url);
    }

    //#region Reports

    private static GetContentType(exportType: ExportTypeEnum): string {
        switch (exportType) {
            case ExportTypeEnum.PDF:
                return "application/pdf";
            case ExportTypeEnum.Excel:
                return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            case ExportTypeEnum.Word:
                return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
            case ExportTypeEnum.Image:
                return "image/png";
        }
    }

    public static async GetOrderAcknowledgement(oKey: number, exportType: ExportTypeEnum): Promise<WebResponse<Blob>> {
        const url: string = `api/Reports/Quotes/OrderAcknowledgement`;
        const params = {
            oKey: oKey,
            exportType: exportType,
        }
        return await APIHelper.GetAsync<Blob>(url, params, undefined, undefined, QuotesAPI.GetContentType(exportType));
    }

    public static async GetOrderAcknowledgementWOPricing(oKey: number, exportType: ExportTypeEnum): Promise<WebResponse<Blob>> {
        const url: string = `api/Reports/Quotes/OrderAcknowledgementWOPricing`;
        const params = {
            oKey: oKey,
            exportType: exportType,
        }
        return await APIHelper.GetAsync<Blob>(url, params, undefined, undefined, QuotesAPI.GetContentType(exportType));
    }

    public static async GetOrderAcknowledgementWOLineItemPricing(oKey: number, exportType: ExportTypeEnum): Promise<WebResponse<Blob>> {
        const url: string = `api/Reports/Quotes/OrderAcknowledgementWOLineItemPricing`;
        const params = {
            oKey: oKey,
            exportType: exportType,
        }
        return await APIHelper.GetAsync<Blob>(url, params, undefined, undefined, QuotesAPI.GetContentType(exportType));
    }

    public static async GetOrderList(oKey: number, exportType: ExportTypeEnum): Promise<WebResponse<Blob>> {
        const url: string = `api/Reports/Quotes/OrderList`;
        const params = {
            oKey: oKey,
            exportType: exportType,
        }
        return await APIHelper.GetAsync<Blob>(url, params, undefined, undefined, QuotesAPI.GetContentType(exportType));
    }

    public static async GetOrderSelling(oKey: number, exportType: ExportTypeEnum): Promise<WebResponse<Blob>> {
        const url: string = `api/Reports/Quotes/OrderSelling`;
        const params = {
            oKey: oKey,
            exportType: exportType,
        }
        return await APIHelper.GetAsync<Blob>(url, params, undefined, undefined, QuotesAPI.GetContentType(exportType));
    }

    public static async GetOrderAccounting(oKey: number, exportType: ExportTypeEnum): Promise<WebResponse<Blob>> {
        const url: string = `api/Reports/Quotes/OrderAccounting`;
        const params = {
            oKey: oKey,
            exportType: exportType,
        }
        return await APIHelper.GetAsync<Blob>(url, params, undefined, undefined, QuotesAPI.GetContentType(exportType));
    }

    public static async GetCustomReport(oKey: number, reportID: number, exportType: ExportTypeEnum): Promise<WebResponse<Blob>> {
        const url: string = `api/Reports/Quotes/Custom`;
        const params = {
            oKey: oKey,
            reportID: reportID,
            exportType: exportType,
        }
        return await APIHelper.GetAsync<Blob>(url, params, undefined, undefined, QuotesAPI.GetContentType(exportType));
    }

    //#endregion

    //#region Exports

    public static async GetQuoteCSVExport(oKey: number): Promise<WebResponse<Blob>> {
        const url: string = `api/Exports/Quotes/CSV`;
        const params = {
            oKey: oKey,
        }
        return await APIHelper.GetAsync<Blob>(url, params, undefined, undefined, "text/csv");
    }

    public static async GetQuoteXMLExport(oKey: number): Promise<WebResponse<Blob>> {
        const url: string = `api/Exports/Quotes/XML`;
        const params = {
            oKey: oKey,
        }
        return await APIHelper.GetAsync<Blob>(url, params, undefined, undefined, "text/xml");
    }


    //#endregion

    //#region TransLog

    public static async GetQuoteHistory(oKey: number): Promise<WebResponse<ITransLogHeader[]>> {
        const url: string = `api/Quotes/${oKey}/History`;
        return await APIHelper.GetAsync<ITransLogHeader[]>(url);
    }

    public static async GetQuoteHistoryDetail(oKey: number, odKey: number, question: string, code: string, tableName: string, rowNumber: number): Promise<WebResponse<ITransLogDiff[]>> {
        const url: string = `api/Quotes/${oKey}/History/Detail`;
        const params = {
            odKey: odKey,
            question: question ?? "",
            code: code ?? "",
            tableName: tableName ?? "",
            rowNumber: rowNumber,
        };
        return await APIHelper.GetAsync<ITransLogDiff[]>(url, params);
    }

    //#endregion

}