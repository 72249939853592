
import { Container, useTheme } from "@mui/material";
import { GridCallbackDetails, GridRowIdGetter, GridRowModel } from "@mui/x-data-grid-pro";
import CustomDataGridPro from "components/Common/CustomDataGridPro";
import DataGridColumnGenerator from "components/Common/DataGridColumnGenerator";
import useMfgCustomersAndSites from "helpers/context/SelectionValues/useMfgCustomersAndSites";
import useWindowTitle from "helpers/context/Title/useWindowTitle";
import useUserInfo from "helpers/context/User/useUserInfo";
import { RoleEnum } from "helpers/enums";
import useIsMobile from "helpers/hooks/useIsMobile";
import useRowsPerPage from "helpers/hooks/useRowsPerPage";
import useTranslations from "helpers/hooks/useTranslations";
import React from 'react';
import { useNavigate } from "react-router-dom";
import { CompanyCommandsColumn } from "./CompanyCommandsColumn";

const Company: React.FC = () => {

    const theme = useTheme();
    const tm = useTranslations();
    const isMobile = useIsMobile();
    const mfgCustomerSites = useMfgCustomersAndSites();
    const user = useUserInfo();
    const navigate = useNavigate();
    useWindowTitle(tm.Get("Company"));
    const rowsPerPage = useRowsPerPage("Company");

    const mfgSites = React.useMemo(() => {
        if (mfgCustomerSites && !user.HasRole(RoleEnum.Contractor)) {
            return mfgCustomerSites.GetMfgSitesFromMfgCustomerID(user.mfgCustomerID);
        }
        return null;
    }, [mfgCustomerSites, user]);

    const handleEditCompany = (siteID: string) => {
        navigate(`edit/${siteID}`);
    }
    
    const getURI = (row: GridRowModel) => `view/${row["siteID"]}`;

    const generator = new DataGridColumnGenerator(tm, mfgSites, theme, isMobile);
    if ((mfgSites?.length ?? 0) > 0) {
        generator.TryAddHyperlinkColumn("name", "Name", getURI, null, { hideInMobile: false });
        generator.TryAddTextColumn("siteName", "Site Name", { hideInMobile: false });
        generator.AddColumn(CompanyCommandsColumn(handleEditCompany));
    }
    const onPageSizeChange = (pageSize: number, details: GridCallbackDetails) => rowsPerPage.setPageSize(pageSize);
    const getRowId: GridRowIdGetter = (row: GridRowModel) => row["customerGUID"];

    return <>
        <Container sx={{marginTop: 2}} maxWidth="lg">
            <CustomDataGridPro
                onPageSizeChange={onPageSizeChange}
                pageSize={rowsPerPage.pageSize}
                rowsPerPageOptions={rowsPerPage.pageSizeOptions}
                getRowId={getRowId}
                columns={generator.GetColumns()}
                rows={mfgSites}
            />
        </Container>
    </>;
}

export default Company;