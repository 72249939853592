import React from 'react';
import { Autocomplete, Checkbox, Grid, TextField } from "@mui/material"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { IMfgSite } from "helpers/interfaces";
import useTranslations from "helpers/hooks/useTranslations";
import { useController, useFormContext } from "react-hook-form";
import CheckBoxForm from "components/Common/CheckBoxForm";
import LabeledText from "components/Common/LabeledText";

interface IProps {
    mfgSites: IMfgSite[] | null,
    isAllSites: boolean,
    allSitesVisible: boolean,
    isVisible: boolean,
    readOnly: boolean
}

const MfgSiteSelect: React.FC<IProps> = (props: IProps) => {

    const tm = useTranslations();
    const { control, watch, formState: { errors } } = useFormContext();

    const allSitesChecked = watch("isAllSites");

    const validateValue = (assignedSiteIDs: string[]): string | boolean => {
        if (props.isVisible && !allSitesChecked && assignedSiteIDs.length === 0) {
            return tm.GetWithParams("{0} cannot be blank.", tm.Get("Mfg Site"))
        }
        return true;
    }

    const { field } = useController({
        name: "siteIDs",
        control,
        rules: { validate: validateValue },
    });

    const onChange = (_, data: IMfgSite[]) => {
        const siteIDArray: string[] = data.map((site => site.siteID));
        field.onChange(siteIDArray)
    }

    const selectedValue = React.useCallback(() => {
        return props.mfgSites?.filter((site) => field.value && field.value.includes(site.siteID)) ?? []
    }, [props.mfgSites, field]);

    return <>
        <Grid item sx={{ display: props.allSitesVisible ? "flex" : "none" }} xs={12}>
            <CheckBoxForm
                fieldName="isAllSites"
                label={tm.Get("All Sites")}
                readOnly={props.readOnly}
            />
        </Grid>
        <Grid item sx={{ display: (allSitesChecked || !props.isVisible) ? "none" : "flex" }} xs={12}>
            {props.readOnly ?
                <LabeledText
                    label={tm.Get("Mfg Site")}
                    text={props.mfgSites?.find((site) => field.value && field.value.includes(site.siteID))?.siteName ?? ""}
                    isLoading={!props.mfgSites}
                /> :
                <Autocomplete
                    multiple
                    id="select-mfg-site"
                    autoComplete
                    autoHighlight
                    autoSelect
                    selectOnFocus
                    handleHomeEndKeys
                    options={props.mfgSites ?? []}
                    readOnly={props.readOnly}
                    getOptionLabel={(option) => option.siteName}
                    fullWidth
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.siteName}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={tm.Get("Mfg Site")}
                            placeholder={tm.Get("Assigned")}
                            error={errors.siteIDs?.message ? true : false}
                            helperText={errors.siteIDs?.message}
                            disabled={props.readOnly} />
                    )}
                    onChange={onChange}
                    value={selectedValue()}
                    ref={field.ref}
                />
            }
        </Grid>
    </>
}

export default MfgSiteSelect;