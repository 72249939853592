import React, { useCallback, useMemo, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { TableContainer, Table, TableBody } from "@mui/material"

import LineItemHeader from "components/Quotes/QuoteEntry/LineItems/Rows/LineItemHeader";
import LineItem from "components/Quotes/QuoteEntry/LineItems/Rows/LineItem";
import LineItemFooter from "components/Quotes/QuoteEntry/LineItems/Rows/LineItemFooter";
import LineItemColumn from "components/Quotes/QuoteEntry/LineItems/Columns/LineItemColumn";
import LineItemExceptionIconColumn from "components/Quotes/QuoteEntry/LineItems/Columns/LineItemExceptionIconColumn";
import LineItemQtyColumn from "components/Quotes/QuoteEntry/LineItems/Columns/LineItemQtyColumn";
import LineItemSizeColumn from "components/Quotes/QuoteEntry/LineItems/Columns/LineItemSizeColumn";
import LineItemTextColumn from "components/Quotes/QuoteEntry/LineItems/Columns/LineItemTextColumn";
import LineItemPartColumn from "components/Quotes/QuoteEntry/LineItems/Columns/LineItemPartColumn";
import LineItemUnitPriceColumn, { ILineItemUnitPriceEvents } from "components/Quotes/QuoteEntry/LineItems/Columns/LineItemUnitPriceColumn";
import LineItemTotalsColumn from "components/Quotes/QuoteEntry/LineItems/Columns/LineItemTotalsColumn";
import LineItemImageColumn from "components/Quotes/QuoteEntry/LineItems/Columns/LineItemImageColumn";
import LineItemCommandsColumn from "components/Quotes/QuoteEntry/LineItems/Columns/LineItemCommandsColumn";
import LineItemCard from "components/Quotes/QuoteEntry/LineItems/Cards/LineItemCard";
import LineItemFooterCard from "components/Quotes/QuoteEntry/LineItems/Cards/LineItemFooterCard";

import useTranslations, { ITranslationManager } from "helpers/hooks/useTranslations";
import useMessageBox from "helpers/context/Page/useMessageBox";
import useWait from "helpers/context/Page/useWait";
import useQuoteData from "components/Quotes/useQuoteData";
import useIsMobile from "helpers/hooks/useIsMobile";

import { FindAllNestedProps } from "helpers/objects";
import { ILineItem, ILineItemQuantityChange } from "helpers/interfaces";
import { ILineItemCommandEvents } from "components/Quotes/QuoteEntry/LineItems/Fields/LineItemCommands";
import useQuoteActions from "components/Quotes/useQuoteActions";
import useNavigationBlocker from "helpers/context/Application/useNavigationBlocker";

export interface ILineItemGridProps {
    tm: ITranslationManager;
    priceVisible: boolean;
    sqFtPriceVisible: boolean;
    onSurchargeClick: () => void;
    onTaxClick: () => void;
}

const LineItemGrid: React.FC<ILineItemGridProps> = (gridProps: ILineItemGridProps) => {

    const { priceVisible, sqFtPriceVisible, onSurchargeClick, onTaxClick } = gridProps;
    const { quote, lineItems, permissions, lock } = useQuoteData();
    const quoteActions = useQuoteActions();

    const tm = useTranslations();
    const messageBox = useMessageBox();
    const wait = useWait();
    const isMobile = useIsMobile();

    const userCanModifyQuote = React.useMemo(() => {
        if (permissions && lock) {
            return permissions.editQuote && !lock.isLockedByAnotherUser;
        }
        return false;
    }, [permissions, lock]);

    const onLineItemExceptionClick = useCallback(async (lineItem: ILineItem) => {

        if (quote?.oKey && lineItem.odKey) {
            const exceptions = await quoteActions.GetLineItemExceptionsAsync(quote.oKey, lineItem.odKey);
            if (exceptions !== undefined) {
                messageBox.Show({ message: exceptions.join("\n"), title: tm.Get("Exceptions") });
            }
        }

    }, [messageBox, tm, quote, quoteActions]);

    const onLineItemImageClick = useCallback((imageSource: string) => {

        messageBox.Show({ imageSource: imageSource });

    }, [messageBox]);

    const onLineItemAction = useCallback(async (lineItem: ILineItem, action: (oKey: number, odKey: number) => Promise<void | any>) => {
        if (quote?.oKey) {
            wait.Show(true);
            await action(quote.oKey, lineItem.odKey);
            await quoteActions.LoadQuoteAsync(quote.oKey);
            wait.Show(false);
        }
    }, [quote, wait, quoteActions]);

    const onLineItemCopyClick = useCallback((lineItem: ILineItem) => {
        onLineItemAction(lineItem, (oKey: number, odKey: number) => quoteActions.CopyLineItemAsync(oKey, odKey));
    }, [onLineItemAction, quoteActions]);

    const onLineItemDeleteConfirm = useCallback((lineItem: ILineItem, result?: boolean) => {
        if (result) {
            onLineItemAction(lineItem, (oKey: number, odKey: number) => quoteActions.DeleteLineItemAsync(oKey, odKey));
        }
    }, [onLineItemAction, quoteActions]);

    const onLineItemDeleteClick = useCallback((lineItem: ILineItem) => {
        messageBox.Show({ message: tm.Get("Are you sure you want to delete this line item?"), yesNoPrompt: true, callback: (result) => onLineItemDeleteConfirm(lineItem, result) });
    }, [messageBox, tm, onLineItemDeleteConfirm]);

    const onLineItemMoveDownClick = useCallback((lineItem: ILineItem) => {
        onLineItemAction(lineItem, (oKey: number, odKey: number) => quoteActions.MoveLineItemAsync(oKey, odKey, true));
    }, [onLineItemAction, quoteActions]);

    const onLineItemMoveUpClick = useCallback((lineItem: ILineItem) => {
        onLineItemAction(lineItem, (oKey: number, odKey: number) => quoteActions.MoveLineItemAsync(oKey, odKey, false));
    }, [onLineItemAction, quoteActions]);

    const commandEvents: ILineItemCommandEvents = useMemo(() => {
        return {
            onCopyClick: onLineItemCopyClick,
            onDeleteClick: onLineItemDeleteClick,
            onMoveDownClick: onLineItemMoveDownClick,
            onMoveUpClick: onLineItemMoveUpClick,
        }
    }, [onLineItemCopyClick, onLineItemDeleteClick, onLineItemMoveDownClick, onLineItemMoveUpClick]);

    const unitPriceEvents: ILineItemUnitPriceEvents = useMemo(() => {
        return {
            onSurchargeClick: onSurchargeClick,
            onTaxClick: onTaxClick,
        };
    }, [onSurchargeClick, onTaxClick]);

    const formMethods = useForm<any, any>({ mode: "onChange" });
    const { handleSubmit, register, reset } = formMethods;

    useNavigationBlocker(formMethods.formState.isDirty);

    useEffect(() => {
        const qtyDefaults: { Quantity: string[] } = { Quantity: [] };
        
        lineItems.filter((li) => li.isSurchargePart === false).forEach((li) => {
            qtyDefaults.Quantity[li.lineItemNumber] = li.quantity.toString();
        });

        reset(qtyDefaults);
    }, [lineItems, reset]);

    const onLineItemQtysSubmit = useCallback(async (formData: { Quantity: string[] }, e: any) => {

        if (quote?.oKey && formData.Quantity.length > 0) {
            const lineItemQuantityChanges: ILineItemQuantityChange[] = [];
            let errorExists = false;

            formData.Quantity.forEach((value, index) => {
                const odKey = lineItems.find((li) => li.lineItemNumber === index)?.odKey;
                if (odKey) {
                    const intValue = parseInt(value);
                    if (intValue > 0 && intValue <= 32767) {
                        lineItemQuantityChanges.push({ odKey: odKey, newQuantity: intValue })
                    }
                    else {
                        errorExists = true;
                    }
                }
            });

            if (errorExists) {
                messageBox.Show({ message: tm.Get("Quantity must be between 1 and 32767"), title: tm.Get("Please correct before saving.") })
            }
            else {
                wait.Show(true);
                await quoteActions.UpdateLineItemQuantitiesAsync(quote.oKey, lineItemQuantityChanges);
                await quoteActions.LoadQuoteAsync(quote.oKey);
                wait.Show(false);
            }
        }

    }, [quote, messageBox, wait, quoteActions, lineItems, tm]);

    const onLineItemQtysError = useCallback((errors: { [x: string]: any }, e: any) => {
        const messages = FindAllNestedProps(errors, "message");
        const formattedMessage = messages.join("\n");

        if (formattedMessage) {
            messageBox.Show({ message: formattedMessage, title: tm.Get("Please correct before saving.") });
        }
    }, [messageBox, tm]);

    const sqFtPriceText = useMemo(() => {
        return quote?.engineeringUnitSetID === 2 ? tm.Get("SqM Price") : tm.Get("SqFt Price");
    }, [quote, tm]);

    const columns = useMemo(() => {
        let cols: LineItemColumn[] = [];

        cols.push(new LineItemExceptionIconColumn("colException", "", "8px", (li) => onLineItemExceptionClick(li)));
        cols.push(new LineItemTextColumn("colItem", tm.Get("Item"), "50px", "center", (li) => li.lineItemNumber.toString()));
        cols.push(new LineItemQtyColumn("colQty", tm.Get("Qty"), "", !userCanModifyQuote, register));
        cols.push(new LineItemPartColumn("colPart", tm.Get("Part"), "60%", !userCanModifyQuote));
        cols.push(new LineItemSizeColumn("colSize", tm.Get("Size"), "20%"));
        if (priceVisible) {
            cols.push(new LineItemUnitPriceColumn("colPrice", tm.Get("Price"), "10%", false, !sqFtPriceVisible, unitPriceEvents));
            if (sqFtPriceVisible) {
                cols.push(new LineItemUnitPriceColumn("colSqFtPrice", sqFtPriceText, "10%", true, true, unitPriceEvents));
            }
            cols.push(new LineItemTotalsColumn("colTotal", tm.Get("Total"), "10%"));
        }
        if (userCanModifyQuote) {
            cols.push(new LineItemCommandsColumn("colCommands", "", "16px", commandEvents));
        }
        cols.push(new LineItemImageColumn("colImage", "", "16px", onLineItemImageClick));
        //FUTURE: Options button?   Maybe desktop only?
        return cols;
    }, [tm, onLineItemExceptionClick, register, userCanModifyQuote, unitPriceEvents, commandEvents, 
        onLineItemImageClick, priceVisible, sqFtPriceText, sqFtPriceVisible]);

    return <>
        {isMobile ?
            <>
                {quote && permissions &&
                    lineItems.filter(li => li.isSurchargePart === false).map((li) => (
                        <LineItemCard
                            key={li.lineItemNumber}
                            quote={quote}
                            permissions={permissions}
                            lineItem={li}
                            lineItemGridProps={gridProps}
                            isReadOnly={!userCanModifyQuote}
                            onImageClick={onLineItemImageClick}
                            onExceptionClick={onLineItemExceptionClick}
                            commandEvents={commandEvents}
                        />
                    ))}
                {priceVisible &&
                    <LineItemFooterCard
                        onSurchargeClick={onSurchargeClick}
                        onTaxClick={onTaxClick}
                    />
                }
            </>
            :
            <FormProvider {...formMethods}>
                <form id="lineItemQtys" onSubmit={handleSubmit(onLineItemQtysSubmit, onLineItemQtysError)}>
                    <TableContainer>
                        <Table sx={{ width: "100%" }} size="small" padding="none">
                            <LineItemHeader lineItemGridProps={gridProps} columns={columns} />
                            <TableBody>
                                {lineItems.filter(li => li.isSurchargePart === false).map((li) => (
                                    <LineItem key={li.lineItemNumber} lineItemGridProps={gridProps} columns={columns} lineItem={li} />
                                )
                                )}
                                <LineItemFooter lineItemGridProps={gridProps} columns={columns} />
                            </TableBody>
                        </Table>
                    </TableContainer>
                </form>
            </FormProvider>
        }
    </>;
}

export default LineItemGrid;
