import * as React from "react";

import { ISubLineItemInfo } from "../interfaces";
import useTranslations, { ITranslationManager } from "helpers/hooks/useTranslations";
import Format from "helpers/fv.format";
import { Flag } from "@mui/icons-material";
import { TabContext, TabPanel } from "@mui/lab";
import { Container, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Tab } from "@mui/material";
import useIsMobile from "helpers/hooks/useIsMobile";
import CustomTabList from "components/Common/CustomTabList";
import WizardHelper from "../WizardHelper";

interface IProps {
    sliList: Array<ISubLineItemInfo>;
    currentSLI: number;
    handleSectionChange: (sli: number, tm: ITranslationManager) => void;
    requiredOptionSubLineItems: Array<number>;
    requiredPartSubLineItems: Array<number>;
}

//When placing a continuous frame unit inside another opening, the sub line items are encoded using negative values.
// that is why we use absolute value to sort. (otherwise the main line item won't be first)
const sliSortMethod = (a: ISubLineItemInfo, b: ISubLineItemInfo) => {
    let absA: number = Math.abs(a.sli);
    let absB: number = Math.abs(b.sli);
    if (absA < absB)
        return -1;
    if (absA > absB)
        return 1;
    return 0;
}


const SubLineItemTabs: React.FC<React.PropsWithChildren<IProps>> = ({sliList, currentSLI, handleSectionChange, requiredOptionSubLineItems, requiredPartSubLineItems, children}: React.PropsWithChildren<IProps>) => {

    const tm = useTranslations();
    const isMobile = useIsMobile();

    const selectedSLI: ISubLineItemInfo | undefined = sliList.find((sli: ISubLineItemInfo) => sli.sli === currentSLI);
    let tabsEnabled: boolean = WizardHelper.AreSubLineItemTabsEnabled(requiredOptionSubLineItems, currentSLI);

    const orderedSLIList = React.useMemo<number[]>(() => {
        return sliList.sort(sliSortMethod).map((slii: ISubLineItemInfo) => slii.sli);
    }, [sliList]);

    const handleTabSelection = React.useCallback((event: React.SyntheticEvent, value: number) => {
        if (orderedSLIList) {
            handleSectionChange(value, tm);
        }
    }, [orderedSLIList, tm, handleSectionChange]);

    const handleSelectChange = React.useCallback((event: SelectChangeEvent<number>) => {
        if (orderedSLIList) {
            handleSectionChange(event.target.value as number, tm);
        }
    }, [orderedSLIList, tm, handleSectionChange]);

    const subLineItemHasFlag = React.useCallback((sli: ISubLineItemInfo) => {
        return (requiredOptionSubLineItems.indexOf(sli.sli) > -1) || (requiredPartSubLineItems.indexOf(sli.sli) > -1);
    }, [requiredOptionSubLineItems, requiredPartSubLineItems]);

    const anySubLineItemHasFlag = React.useMemo(() => {
        const slisWithFlag = sliList.filter((sli) => subLineItemHasFlag(sli));
        return slisWithFlag.length > 0;
    }, [sliList, subLineItemHasFlag]);

    if (!selectedSLI)
        return <></>;

    if (isMobile) {
        return <Grid container direction="column" spacing={1} my={1} >
            <Grid item>
                <FormControl fullWidth>
                    <InputLabel>
                        {
                            tm.Get("Section")
                        }
                    </InputLabel>

                    <Select
                        label={tm.Get("Section")}
                        value={selectedSLI.sli}
                        onChange={handleSelectChange}
                        variant="outlined"
                        fullWidth
                        error={anySubLineItemHasFlag}
                    >

                        {sliList.map((sli: ISubLineItemInfo) =>
                            <MenuItem key={sli.sli} value={sli.sli}>
                                {subLineItemHasFlag(sli) && <span><Flag sx={{ width: "16px", height: "16px" }} color="warning" /></span>}
                                {sli.sli === 0 && <span className="sli-tab-txt">{tm.Get("Overall")}</span>}
                                {sli.sli !== 0 && <span className="sli-tab-txt">{Format.FormatSubLineItem(sli.sli)}</span>}
                            </MenuItem>
                        )}

                    </Select>

                </FormControl>

            </Grid>

            <Grid item>
                {children}
            </Grid>

        </Grid>


    } else {

        return <TabContext value={selectedSLI.sli.toString()} >
            <CustomTabList onChange={handleTabSelection} >
                {sliList.map((sli: ISubLineItemInfo) =>
                    <Tab disabled={!tabsEnabled}
                        key={"SLITL_" + sli.sli}
                        value={sli.sli.toString()}
                        label={
                            <Container>
                                {((requiredOptionSubLineItems.indexOf(sli.sli) > -1) || (requiredPartSubLineItems.indexOf(sli.sli) > -1)) && <span><Flag sx={{ width: "16px", height: "16px" }} color="warning" /></span>}
                                {sli.sli === 0 && <span className="sli-tab-txt">{tm.Get("Overall")}</span>}
                                {sli.sli !== 0 && <span className="sli-tab-txt">{Format.FormatSubLineItem(sli.sli)}</span>}
                            </Container>
                        }
                    />
                )}
            </CustomTabList>

            {sliList.map((sli: ISubLineItemInfo) =>
                <TabPanel key={"SLITP_" + sli.sli} value={sli.sli.toString()} sx={{ padding: 0 }} >
                    {children}
                </TabPanel>
            )}

        </TabContext>;
    }
}

export default SubLineItemTabs;
