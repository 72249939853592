import React, { useCallback } from 'react';
import { IActionButton } from "helpers/context/Page/PageContext";
import useActionButtons from "helpers/context/Page/useActionButtons";
import { IWebCustomerPricingInfo } from "models/IWebCustomer";
import { PricingMethodEnum, RoleEnum, ThemeColorEnum } from "helpers/enums";
import useTranslations from "helpers/hooks/useTranslations";
import { useNavigate, useParams } from "react-router-dom";
import CustomerAPI from "./CustomerAPI";
import { Alert, AlertColor, Container, Snackbar, Stack } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { BlankCustomer } from "./FormComponents/BlankCustomer";
import { FindAllNestedProps } from "helpers/objects";
import useMessageBox from "helpers/context/Page/useMessageBox";
import CustomerGeneral from "./FormComponents/CustomerGeneral";
import CustomerFinancial from "./FormComponents/CustomerFinancial";
import CustomerBilling from "./FormComponents/CustomerBilling";
import CustomerShipping from "./FormComponents/CustomerShipping";
import CustomerUserDefined from "./FormComponents/CustomerUserDefined";
import useWindowTitle from "helpers/context/Title/useWindowTitle";
import useUserInfo from "helpers/context/User/useUserInfo";
import useWait from "helpers/context/Page/useWait";
import useApplicationInfo from "helpers/context/Application/useApplicationInfo";
import useLocaleNumberFormatter from "helpers/hooks/useLocaleNumberFormatter";
import useSelectionValuesActions from "helpers/context/SelectionValues/useSelectionValuesActions";
import useNavigationBlocker from "helpers/context/Application/useNavigationBlocker";
import useNavigationBlockerActions from "helpers/context/Application/useNavigationBlockerActions";
import { useEffectOnLoad } from "helpers/hooks/useEffectOnLoad";

interface IProps {
    isEditMode: boolean
}

const defaultExpanderState = {
    general: false,
    financial: false,
    billing: false,
    shipping: false,
    userDef: false
}

const CustomerInfo: React.FC<IProps> = (props: IProps) => {

    let actionButtons = useActionButtons();
    const tm = useTranslations();
    const user = useUserInfo();
    const wait = useWait();
    const navigate = useNavigate();
    const messageBox = useMessageBox();
    const formMethods = useForm({ mode: "onChange", defaultValues: BlankCustomer });
    const { handleSubmit, reset, watch } = formMethods;
    const applicationInfo = useApplicationInfo();
    const pricingMethod = watch("customer.pricingMethod");
    const lnf = useLocaleNumberFormatter({ style: "decimal", useGrouping: true, minimumFractionDigits: 4, maximumFractionDigits: 4 });

    const { customerID } = useParams();
    if (!customerID) navigate(-1);

    const [customerPricingInfo, setCustomerPricingInfo] = React.useState<IWebCustomerPricingInfo | null>(null);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isPosting, setIsPosting] = React.useState<boolean>(false);
    const [isEditMode, setIsEditMode] = React.useState<boolean>(props.isEditMode);
    const [expandedState, setExpandedState] = React.useState<typeof defaultExpanderState>(defaultExpanderState);
    const [alert, setAlert] = React.useState<[text: string, alertType: AlertColor] | null>(null);
    const selectionValuesActions = useSelectionValuesActions();
    const navBlockerActions = useNavigationBlockerActions();

    useWindowTitle(customerPricingInfo?.customer.name ?? "");

    useNavigationBlocker(isEditMode && formMethods.formState.isDirty);

    const isPricingMethodPercentBased = React.useMemo(() => {
        switch (pricingMethod) {
            case PricingMethodEnum.Discount:
                return !applicationInfo.parameters.pricingShowDiscountMultiplierInsteadOfPercent;
            case PricingMethodEnum.Markup:
                return !applicationInfo.parameters.pricingShowMarkupMultiplierInsteadOfPercent;
            case PricingMethodEnum.Markdown:
                return true;
            case PricingMethodEnum.Margin:
                return true;
            default:
                return true;
        }
    }, [pricingMethod, applicationInfo]);

    React.useEffect(() => {
        wait.Show(isLoading || isPosting);
    }, [isLoading, isPosting, wait])

    React.useEffect(() => {
        actionButtons.SetBackButton(0, "/setup/customers");
        if (!isEditMode) {
            const editButton: IActionButton = {
                text: tm.Get("Edit"),
                color: ThemeColorEnum.Secondary,
                disabled: (customerPricingInfo === null || isLoading || isPosting),
                onClick: (() => setIsEditMode(true))
            };
            actionButtons.Set(1, editButton);
        } else {
            const saveButton: IActionButton = {
                text: tm.Get("Save"),
                color: ThemeColorEnum.Secondary,
                disabled: (isLoading || isPosting) ? true : false,
                type: "submit",
                form: "edit-customer",
                onClick: (() => navBlockerActions.Unblock())

            };
            const cancelButton: IActionButton = {
                text: tm.Get("Cancel"),
                color: ThemeColorEnum.Primary,
                disabled: (isLoading || isPosting) ? true : false,
                onClick: () => {
                    setIsEditMode(false);
                    actionButtons.Remove(2);
                    reset({ ...customerPricingInfo });
                    navBlockerActions.Unblock();
                }
            };
            actionButtons.Set(1, saveButton);
            actionButtons.Set(2, cancelButton);
        }
    }, [customerPricingInfo, isEditMode, isLoading, isPosting, navBlockerActions, actionButtons, reset, tm]);

    const getCustomerPricingInfo = useCallback(async (customerID) => {
        setIsLoading(true);
        try {
            let result = await CustomerAPI.GetWebCustomerPricingInfo(customerID);
            setCustomerPricingInfo(result);
            reset({ ...result });
            setExpandedState({ general: true, financial: false, billing: false, shipping: false, userDef: false });
        } finally {
            setIsLoading(false);
        }
    }, [reset]);

    const clearCache = useCallback(() => {
        selectionValuesActions.ClearCustomersCache();
    }, [selectionValuesActions]);

    useEffectOnLoad(() => {
        if (!customerPricingInfo) {
            getCustomerPricingInfo(customerID);
        }
    });

    const updateCustomer = useCallback(async (customerToSubmit: IWebCustomerPricingInfo, customerID: string) => {
        setIsPosting(true);
        try {
            let result = user.HasRole(RoleEnum.Contractor) ?
                await CustomerAPI.UpdateWebContractorCustomer(customerID, customerToSubmit) :
                await CustomerAPI.UpdateWebCustomer(customerID, customerToSubmit);
            if (result.message) {
                const translated = tm.Get(result);
                setAlert([translated, "error"]);
            } else {
                clearCache();
                await getCustomerPricingInfo(customerID);
                setAlert([tm.Get("Changes have been saved."), "success"]);
                setIsEditMode(false);
                actionButtons.Remove(1);
            }
        } finally {
            setIsPosting(false);
        }
    }, [actionButtons, clearCache, getCustomerPricingInfo, tm, user]);

    const handleChange = (section: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
        //Note: to allow more than one expansion at a time: setExpandedState({...expandedState, [section]: isExpanded});
        setExpandedState({ general: false, financial: false, billing: false, shipping: false, userDef: false, [section]: isExpanded });
    };

    const onSubmit = useCallback((formData: IWebCustomerPricingInfo) => {
        //Backend accepts percentage pricing, need to convert mult
        if (!isPricingMethodPercentBased) {
            for (const pricing of formData.pricing) {
                if (pricing.discountPercent.toString() === "") {
                    pricing.discountPercent = 0;
                } else {
                    pricing.discountPercent = convertMultiplierToPercent(pricingMethod, lnf.Parse(pricing.discountPercent.toString()));
                }
            }
        } else {
            for (const pricing of formData.pricing) {
                if (pricing.discountPercent.toString() === "") {
                    pricing.discountPercent = 0;
                } else {
                    pricing.discountPercent = lnf.Parse(pricing.discountPercent.toString());
                }
            }
        }
        if (formData.customer.taxPercent.toString() === "") formData.customer.taxPercent = 0;
        if (formData.customer.taxPercent1.toString() === "") formData.customer.taxPercent1 = 0;
        if (formData.customer.taxPercent2.toString() === "") formData.customer.taxPercent2 = 0;
        if (formData.customer.taxPercent3.toString() === "") formData.customer.taxPercent3 = 0;
        if (formData.customer.taxPercent4.toString() === "") formData.customer.taxPercent4 = 0;
        if (customerID) {
            updateCustomer(formData, customerID);
        }
    }, [customerID, isPricingMethodPercentBased, lnf, pricingMethod, updateCustomer]);

    const convertMultiplierToPercent = (pricingMethod: PricingMethodEnum, mult: number) => {
        if (pricingMethod === PricingMethodEnum.Markup) {
            return (mult - 1) * 100;
        } else if (pricingMethod === PricingMethodEnum.Discount) {
            return (1 - mult) * 100;
        } else if (pricingMethod === PricingMethodEnum.Margin) {
            throw new Error("Cannot convert Gross Margin to a percent!");
        } else {
            throw new Error("Invalid pricing method");
        }
    }

    const onError = (errors: { [x: string]: any }) => {
        const messages = FindAllNestedProps(errors, "message");
        const formattedMessage = messages.join("\n");
        console.log(formattedMessage);

        if (formattedMessage) {
            messageBox.Show({ message: formattedMessage, title: tm.Get("Please correct before saving.") });
        }
    }

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway')
            return;
        setAlert(null);
    }

    return customerPricingInfo ? <>
        <Snackbar open={alert !== null} autoHideDuration={5000} onClose={handleClose} >
            <Alert onClose={handleClose} severity={alert ? alert[1] : "info"} variant='filled' sx={{ width: '100%', fontWeight: 'bold' }}>{alert ? alert[0] : ""}</Alert>
        </Snackbar>

        <Container maxWidth="lg">
            <FormProvider {...formMethods}>
                <form id="edit-customer" onSubmit={handleSubmit(onSubmit, onError)}>
                    <Stack direction="column" gap={1} sx={{ marginTop: 1 }}>
                        <CustomerGeneral
                            expanded={expandedState.general}
                            onExpandedChange={handleChange("general")}
                            readOnly={!isEditMode}
                            isContractor={user.HasRole(RoleEnum.Contractor)}
                        />
                        <CustomerFinancial
                            expanded={expandedState.financial}
                            onExpandedChange={handleChange("financial")}
                            readOnly={!isEditMode}
                            parentCustomerID={user.parentCustomerID}
                            parentSiteID={customerPricingInfo.customer.parentSiteID}
                            pricingMethod={pricingMethod}
                            isPricingMethodPercentBased={isPricingMethodPercentBased}
                        />
                        <CustomerBilling
                            expanded={expandedState.billing}
                            onExpandedChange={handleChange("billing")}
                            readOnly={!isEditMode}
                        />
                        <CustomerShipping
                            expanded={expandedState.shipping}
                            onExpandedChange={handleChange("shipping")}
                            readOnly={!isEditMode}
                            parentCustomerID={user.HasRole(RoleEnum.Contractor) ? user.parentCustomerID : user.mfgCustomerID}
                        />
                        <CustomerUserDefined
                            expanded={expandedState.userDef}
                            onExpandedChange={handleChange("userDef")}
                            readOnly={!isEditMode}
                        />
                    </Stack>
                </form>
            </FormProvider>
        </Container>
    </> : <></>;
}

export default CustomerInfo;