import { useFormContext } from 'react-hook-form';
import useTranslations from 'helpers/hooks/useTranslations';
import TextFieldForm from "components/Common/TextFieldForm";
import { ResolvePath } from "helpers/objects";
import { ILocaleNumberFormatter } from "helpers/hooks/useLocaleNumberFormatter";
import Constants from "helpers/constants";
import { useEffectOnLoad } from "helpers/hooks/useEffectOnLoad";
import { useEffect } from "react";


interface IProps {
    fieldName: string,
    readOnly: boolean,
    lnf: ILocaleNumberFormatter,
    [x: string]: any    // this should always be the last prop, to support any other props that will be passed along down the component chain
}

function TaxFieldForm({ fieldName, readOnly, lnf, label, ...rest }: IProps) {
    const tm = useTranslations();
    const { setValue, watch, formState: { errors } } = useFormContext();

    useEffectOnLoad(() => {
        const initialValue = watch(fieldName);
        setValue(fieldName, lnf.Format(initialValue));
    });

    const formValue = watch(fieldName);
    useEffect(() => {
        // If the form value is set (or reset) to an unformatted value, format it now
        const floatValue = lnf.Parse(formValue.toString());
        if (!isNaN(floatValue)) {
            const formattedValue = lnf.Format(floatValue);
            // only set the form value if its different, otherwise this will get stuck in an endless loop
            if (formattedValue !== formValue.toString()) {
                setValue(fieldName, formattedValue);
            }
        }
    }, [fieldName, lnf, setValue, formValue]);

    const validateValue = (value: string): string | boolean => {
        if (value) {
            const floatValue = lnf.Parse(value.toString());

            if (isNaN(floatValue)) {
                const message = tm.Get("Must be a number.");
                return message;
            } else {
                let minValue: number = Constants.Min.TaxRate;
                let maxValue: number = Constants.Max.TaxRate;
                if (floatValue > maxValue || floatValue < minValue) {
                    const message = tm.GetWithParams("{0} must be between {1} and {2}.", label ?? "value", minValue.toString(), maxValue.toString());
                    return message;
                }
            }
        }
        return true;
    }

    function handleOnBlur(e: any): void {
        if (e.target.value !== "") {
            let numericValue = lnf.Parse(e.target.value);
            setValue(fieldName, lnf.Format(numericValue));
        }
    }

    const getValidationError = (): any => ResolvePath(fieldName, errors);

    return <>
        <TextFieldForm
            variant="standard"
            readOnly={readOnly}
            id={fieldName}
            fieldName={fieldName}
            validateValue={validateValue}
            label={label}
            placeholder={label}
            onBlur={handleOnBlur}
            error={getValidationError() ? true : false}
            helperText={getValidationError()?.message}
            showPercentAdornment={true}
            {...rest}
        />
    </>
}

export default TaxFieldForm;