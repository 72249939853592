import { GridColumns, GridRenderCellParams, GridEnrichedColDef } from "@mui/x-data-grid-pro";
import { ITranslationManager } from "helpers/hooks/useTranslations";
import { Button, Theme, Typography } from "@mui/material";
import DataGridColumnGenerator from "components/Common/DataGridColumnGenerator";
import { PurchaseHistoryGridMode } from "./PurchaseHistory";
import { ICurrencyFormatter } from "helpers/hooks/useCurrencyFormatter";
import { ILocaleNumberFormatter } from "helpers/hooks/useLocaleNumberFormatter";

const customerNameColumn = (isMobile: boolean, tm: ITranslationManager, onClick: (customerID: string, siteID: string, customerName: string, type: string) => void) => {
    
    const col = {
        field: "customerName",
        minWidth: isMobile ? 90 : 130,
        headerName: "Customer",
        flex: 1,
        hideable: false,
        headerAlign: 'left',
        align: 'left',
        filterable: false,
        renderCell: (params: GridRenderCellParams) => {
            return (params.value !== undefined && <>
                <Button onClick={() => onClick(params.row["customerID"], params.row["siteID"], params.row["customerName"], "part")} style={{maxWidth: "10px", minWidth: "10px", padding: "0px"}}>P</Button>
                <Button onClick={() => onClick(params.row["customerID"], params.row["siteID"], params.row["customerName"], "option")} style={{maxWidth: "10px", minWidth: "10px", padding: "0px", marginLeft: "5px"}}>O</Button>
                <Button onClick={() => onClick(params.row["customerID"], params.row["siteID"], params.row["customerName"], "order")} style={{maxWidth: "20px", minWidth: "20px", padding: "0px", marginLeft: "5px"}}>OR</Button>
                <Typography variant="body2" style={{marginLeft: "5px"}}>{params.value ?? ("{" + tm.Get("Orders Entered by Manufacturer") + "}")}</Typography>
            </>)
        },
    } as GridEnrichedColDef

    return col
}

const partNameColumn = (isMobile: boolean, tm: ITranslationManager, showLink: boolean, onClick: (partNo: string, partNoSuffix: string) => void) => {
    
    const col = {
        field: "description",
        minWidth: isMobile ? 90 : 130,
        headerName: tm.Get("Part"),
        flex: 1,
        headerAlign: 'left',
        align: 'left',
        filterable: false,
        renderCell: (params: GridRenderCellParams) => {
            return (params.value !== undefined && 
                (showLink ? 
                    <Button onClick={() => onClick(params.row["partNo"], params.row["partNoSuffix"])} style={{padding: "0px", justifyContent: "flex-start"}}>
                        {params.row.partNo + " - " + params.value}
                    </Button>
                :
                    <Typography variant="body2">{params.row.partNo + " - " + params.value}</Typography>
                )
            )
        },
    } as GridEnrichedColDef

    return col
}

const optionNameColumn = (isMobile: boolean, tm: ITranslationManager, onClick: (optionCode: string) => void) => {
    
    const col = {
        field: "description",
        minWidth: isMobile ? 90 : 130,
        headerName: tm.Get("Option"),
        flex: 1,
        headerAlign: 'left',
        align: 'left',
        filterable: false,
        renderCell: (params: GridRenderCellParams) => {
            return (params.value !== undefined && <>
                <Button onClick={() => onClick(params.row["optionCode"])} style={{maxWidth: "10px", minWidth: "10px", padding: "0px"}}>P</Button>
                <Typography variant="body2" style={{marginLeft: "5px"}}>{params.row.optionCode + " - " + params.value}</Typography>
            </>)
        },
    } as GridEnrichedColDef

    return col
}

export const generatePurchaseHistoryColumns = (tm: ITranslationManager, data: any[] | null, theme: Theme, isMobile: boolean, showSquareFeet: boolean, mode: PurchaseHistoryGridMode, showPartLink: boolean,
    totalSales: number, cf: ICurrencyFormatter, lnf: ILocaleNumberFormatter, isMetric: boolean,
    customerLinkClick: (customerID: string, siteID: string, customerName: string, type: string) => void,
    partLinkClick: (partNo: string, partNoSuffix: string) => void,
    optionLinkClick: (optionCode: string) => void) => {

    if (!data || data.length === 0) return [] as GridColumns;

    data.forEach(d => {
        d["percentage"] = totalSales === 0 ? 0 : (d.sales / totalSales);
    });

    const generator = new DataGridColumnGenerator(tm, data, theme, isMobile);

    switch (mode) {
        case PurchaseHistoryGridMode.Customers:
            generator.AddColumn(customerNameColumn(isMobile, tm, customerLinkClick));
            generator.TryAddNumericColumn("qty", "Quantity", (num) => num.toString(), { hideInMobile: false });
            if (showSquareFeet) {
                generator.TryAddNumericColumn("sqFeet", isMetric ? "SqM" : "SqFt", lnf.Format, { hideInMobile: false });
            }
            generator.TryAddNumericColumn("sales", "Total Sales", cf.Format, { hideInMobile: false });
            generator.TryAddNumericColumn("percentage", "Percentage", (num) => {return lnf.Format(num * 100) + "%"}, { hideInMobile: false });
            break;

        case PurchaseHistoryGridMode.Orders:
            generator.TryAddDocumentNumberColumn("oKey", "orderNumber", "Order", "/orders", {});
            generator.TryAddTextColumn("poNumber", "PO Number", { hideInMobile: false });
            generator.TryAddNumericColumn("qty", "Quantity", (num) => num.toString(), { hideInMobile: false });
            if (showSquareFeet) {
                generator.TryAddNumericColumn("sqFeet", isMetric ? "SqM" : "SqFt", lnf.Format, { hideInMobile: false });
            }
            generator.TryAddNumericColumn("sales", "Total Sales", cf.Format, { hideInMobile: false });
            generator.TryAddNumericColumn("percentage", "Percentage", (num) => {return lnf.Format(num * 100) + "%"}, { hideInMobile: false });
            break;

        case PurchaseHistoryGridMode.Parts:
            generator.AddColumn(partNameColumn(isMobile, tm, showPartLink, partLinkClick));
            generator.TryAddNumericColumn("qty", "Quantity", (num) => num.toString(), { hideInMobile: false });
            if (showSquareFeet && showPartLink) {
                generator.TryAddNumericColumn("sqFeet", isMetric ? "SqM" : "SqFt", lnf.Format, { hideInMobile: false });
            }
            generator.TryAddNumericColumn("sales", "Total Sales", cf.Format, { hideInMobile: false });
            generator.TryAddNumericColumn("percentage", "Percentage", (num) => {return lnf.Format(num * 100) + "%"}, { hideInMobile: false });
            break;

        case PurchaseHistoryGridMode.Options:
            generator.AddColumn(optionNameColumn(isMobile, tm, optionLinkClick));
            generator.TryAddNumericColumn("qty", "Quantity", (num) => num.toString(), { hideInMobile: false });
            generator.TryAddNumericColumn("sales", "Total Sales", cf.Format, { hideInMobile: false });
            generator.TryAddNumericColumn("percentage", "Percentage", (num) => {return lnf.Format(num * 100) + "%"}, { hideInMobile: false });
            break;
    }

    return generator.GetColumns();
}
