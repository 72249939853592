import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Grid, TextField, MenuItem, Container, Box, Stack } from "@mui/material";

import useIsMobile from "helpers/hooks/useIsMobile";
import useTranslations from "helpers/hooks/useTranslations";
import useWait from "helpers/context/Page/useWait";
import useWindowTitle from "helpers/context/Title/useWindowTitle";
import SetupAPI from "components/Setup/SetupAPI"
import PricingTablesControls from "./PricingTablesControls";
import PartsGrid from "./PartsGrid";
import OptionsGrid from "./OptionsGrid";
import { PricingMethodEnum } from "helpers/enums"
import { ICategoryListItem, ISimpleListItem, ISimpleListItemStringKey, IPriceTablePartGridRow, IPriceTableOptionGridRow, IPartListItem, IPartKey } from "helpers/interfaces";
import useMessageBox from "helpers/context/Page/useMessageBox";

const PriceTables = () => {
    const isMobile = useIsMobile();
    const tm = useTranslations();
    const wait = useWait();
    const messageBox = useMessageBox();

    const [mainScreenIsLoading, setMainScreenIsLoading] = useState<boolean>(true);
    const [partGridIsLoading, setPartGridIsLoading] = useState<boolean>(true);
    const [optionGridIsLoading, setOptionGridIsLoading] = useState<boolean>(true);

    const [tableType, setTableType] = useState<PricingMethodEnum>(PricingMethodEnum.Discount);
    const [categories, setCategories] = useState<ICategoryListItem[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<number>(-1);
    const [discountTables, setDiscountTables] = useState<ISimpleListItem[]>([]);
    const [selectedDiscountTable, setSelectedDiscountTable] = useState<string>("");
    const [markupTables, setMarkupTables] = useState<ISimpleListItem[]>([]);
    const [selectedMarkupTable, setSelectedMarkupTable] = useState<string>("");
    const [marginTables, setMarginTables] = useState<ISimpleListItem[]>([]);
    const [selectedMarginTable, setSelectedMarginTable] = useState<string>("");

    const [gridView, setGridView] = useState<"p" | "o">("p");
    const [schedules, setSchedules] = useState<ISimpleListItem[]>([]);
    const [partsGrid, setPartsGrid] = useState<IPriceTablePartGridRow[]>([]);

    const [parts, setParts] = useState<IPartListItem[]>([]);
    const [optionGroups, setOptionGroups] = useState<ISimpleListItemStringKey[]>([]);
    const [selectedPart, setSelectedPart] = useState<string>("~");
    const [selectedOptionGroup, setSelectedOptionGroup] = useState<string>("~");
    const [optionsGrid, setOptionsGrid] = useState<IPriceTableOptionGridRow[]>([]);

    useWindowTitle(tm.Get("Price Tables"));

    useEffect(() => {
        setMainScreenIsLoading(true);
        SetupAPI.QueryPricingTablesInit().then(ptm => {
            setSelectedCategory(-1);
            setCategories(ptm.categories);
            setDiscountTables(ptm.discountTables);
            setSelectedDiscountTable(ptm.discountTables.length > 0 ? ptm.discountTables[0].id.toString() : "");
            setMarkupTables(ptm.markupTables);
            setSelectedMarkupTable(ptm.markupTables.length > 0 ? ptm.markupTables[0].id.toString() : "");
            setMarginTables(ptm.marginTables);
            setSelectedMarginTable(ptm.marginTables.length > 0 ? ptm.marginTables[0].id.toString() : "");
            setSchedules(ptm.schedules);
            setOptionGroups(ptm.optionGroups);
            setMainScreenIsLoading(false);
        });
    }, []);

    useEffect(() => {
        wait.Show(mainScreenIsLoading || partGridIsLoading || optionGridIsLoading);
    }, [wait, mainScreenIsLoading, partGridIsLoading, optionGridIsLoading]);

    const selectedTable = useMemo(() => {
        var tableId = "";
        switch (tableType) {
            case PricingMethodEnum.Discount:
                tableId = selectedDiscountTable;
                break;
            case PricingMethodEnum.Markup:
                tableId = selectedMarkupTable;
                break;
            case PricingMethodEnum.Margin:
                tableId = selectedMarginTable;
                break;
        }
        return tableId
    }, [selectedDiscountTable, selectedMarkupTable, selectedMarginTable, tableType]);

    const tables = useMemo(() => {
        var tableList: ISimpleListItem[] = [];
        switch (tableType) {
            case PricingMethodEnum.Discount:
                tableList = discountTables;
                break;
            case PricingMethodEnum.Markup:
                tableList = markupTables;
                break;
            case PricingMethodEnum.Margin:
                tableList = marginTables;
                break;
        }
        return tableList
    }, [discountTables, markupTables, marginTables, tableType]);

    const setSelectedTable = useCallback((tableID: string) => {
        switch (tableType) {
            case PricingMethodEnum.Discount:
                setSelectedDiscountTable(tableID);
                break;
            case PricingMethodEnum.Markup:
                setSelectedMarkupTable(tableID);
                break;
            case PricingMethodEnum.Margin:
                setSelectedMarginTable(tableID);
                break;
        }
    }, [tableType]);

    const setTables = useCallback((tableList: ISimpleListItem[]) => {
        switch (tableType) {
            case PricingMethodEnum.Discount:
                setDiscountTables(tableList);
                break;
            case PricingMethodEnum.Markup:
                setMarkupTables(tableList);
                break;
            case PricingMethodEnum.Margin:
                setMarginTables(tableList);
                break;
        }
    }, [tableType]);

    const queryPartsGrid = useCallback(async (table: string) => {
        if (table === "") {
            setPartsGrid([]);
        } else {
            await SetupAPI.QueryPartsGrid(parseInt(table)).then((grid) => {
                let timestamp = new Date().getTime();
                grid.forEach((row) => row.timestamp = timestamp);
                setPartsGrid(grid);
            });
        }
    }, []);

    const queryOptionsGrid = useCallback(async (part: string, table: string, optionGroup: string) => {
        if (table === "") {
            setOptionsGrid([]);
        } else {
            let partNo: string = "";
            let partNoSuffix: string = "";
            if (part === "~") {
                await SetupAPI.QueryOptionsGrid(parseInt(table), optionGroup).then((grid) => {
                    let timestamp = new Date().getTime();
                    grid.forEach((row) => row.timestamp = timestamp);
                    setOptionsGrid(grid);
                });
            } else {
                var split = part.split("~");
                partNo = split[0];
                partNoSuffix = split[1];
                await SetupAPI.QueryOptionsGridForPart(parseInt(table), partNo, partNoSuffix, optionGroup).then((grid) => {
                    let timestamp = new Date().getTime();
                    grid.forEach((row) => row.timestamp = timestamp);
                    setOptionsGrid(grid);
                });
            }
        }
    }, []);

    useEffect(() => {
        setPartGridIsLoading(true);
        if (selectedTable === "") {
            setPartsGrid([]);
            setParts([]);
            setPartGridIsLoading(false);
        } else {
            queryPartsGrid(selectedTable).then(() => setPartGridIsLoading(false));
        }
    }, [selectedTable, queryPartsGrid]);

    useEffect(() => {
        setOptionGridIsLoading(true);
        if (selectedTable === "") {
            setOptionsGrid([]);
            setOptionGridIsLoading(false);
        } else {
            queryOptionsGrid(selectedPart, selectedTable, selectedOptionGroup).then(() => {
                setOptionGridIsLoading(false);
            });
            SetupAPI.QueryParts(parseInt(selectedTable)).then((parts) => {
                setParts(parts);
            });
            let partNo: string = "";
            let partNoSuffix: string = "";
            if (selectedPart !== "~") {
                let split = selectedPart.split("~");
                partNo = split[0];
                partNoSuffix = split[1];
            }
            SetupAPI.QueryOptionGroupsForPart(partNo, partNoSuffix).then((ogs) => setOptionGroups(ogs));
        }
    }, [selectedTable, selectedPart, selectedOptionGroup, queryOptionsGrid]);

    const handleTableChange = useCallback((newTable: string) => {
        setSelectedTable(newTable);
        // ~ means All Parts or All Option Groups. It can't be blank, otherwise React won't show text for that selection
        setSelectedPart("~");
        setSelectedOptionGroup("~");
    }, [setSelectedTable]);

    const requery = useCallback((categoryID : number, forceSelectTable: string = "") => {
        setMainScreenIsLoading(true);
        SetupAPI.QueryPricingTables(categoryID).then(ptm => {
            var discountTableToSelect = ptm.discountTables.length > 0 ? ptm.discountTables[0].id.toString() : ""
            var markupTableToSelect = ptm.markupTables.length > 0 ? ptm.markupTables[0].id.toString() : ""
            var marginTableToSelect = ptm.marginTables.length > 0 ? ptm.marginTables[0].id.toString() : ""
            setDiscountTables(ptm.discountTables);
            setSelectedDiscountTable(discountTableToSelect);
            setMarkupTables(ptm.markupTables);
            setSelectedMarkupTable(markupTableToSelect);
            setMarginTables(ptm.marginTables);
            setSelectedMarginTable(marginTableToSelect);
            setSelectedPart("~");
            setSelectedOptionGroup("~");
            setOptionGroups(ptm.optionGroups);
            setMainScreenIsLoading(false);
            if (forceSelectTable !== "") {
                setSelectedTable(forceSelectTable);
            }
        });
    }, [setSelectedTable]);

    const handleGridViewChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === "p") {
            setPartGridIsLoading(true);
            queryPartsGrid(selectedTable).then(() => {
                setGridView("p");
                setPartGridIsLoading(false);
            });
        }
        if (e.target.value === "o") {
            setOptionGridIsLoading(true);
            queryOptionsGrid(selectedPart, selectedTable, selectedOptionGroup).then(() => {
                setGridView("o");
                setOptionGridIsLoading(false);
            });
        }
    }, [selectedTable, selectedPart, selectedOptionGroup, queryPartsGrid, queryOptionsGrid]);

    const handlePartFillDown = useCallback((index: number, scheduleID: number, discount: string, fixedPrice: boolean, checked: boolean) => {
        let newGrid = partsGrid.slice();
        let parts: IPartKey[] = [];
        let timestamp = new Date().getTime();
        let numericDiscount = (discount === "" ? null : parseFloat(discount));
        for (let i = index + 1; i < partsGrid.length; i++) {
            parts.push({
                masterPartNo: partsGrid[i].partNo,
                partNoSuffix: partsGrid[i].partNoSuffix,
                shortcutName: ""
            });

            if (!newGrid[i].isSurchargePart) {
                newGrid[i].wcPriceScheduleID = scheduleID;
            }
            newGrid[i].optionDiscount = checked;
            if (fixedPrice) {
                newGrid[i].fixedPrice = numericDiscount;
                newGrid[i].discount = null;
            } else {
                newGrid[i].fixedPrice = null;
                newGrid[i].discount = numericDiscount;
            }
            newGrid[i].timestamp = timestamp;
        }

        setPartGridIsLoading(true);
        if (scheduleID === 0) {
            SetupAPI.FillDownPricingItemPartWithValue(parseInt(selectedTable), parts, numericDiscount, fixedPrice, checked).then(() => {
                setPartGridIsLoading(false);
            });
        } else {
            SetupAPI.FillDownPricingItemPartWithSchedule(parseInt(selectedTable), parts, scheduleID).then(() => {
                setPartGridIsLoading(false);
            });
        }
        setPartsGrid(newGrid);
    }, [partsGrid, selectedTable]);

    const handleOptionFillDown = useCallback((index: number, scheduleID: number, discount: string, fixedPrice: boolean) => {
        let newGrid = optionsGrid.slice();
        let optionCodes: string[] = [];
        let partNo = "";
        let partNoSuffix = "";
        let timestamp = new Date().getTime();
        if (selectedPart !== "~") {
            let temp = selectedPart.split("~");
            partNo = temp[0];
            partNoSuffix = temp[1];
        }
        let numericDiscount = (discount === "" ? null : parseFloat(discount));

        for (let i = index + 1; i < optionsGrid.length; i++) {
            optionCodes.push(optionsGrid[i].code);

            newGrid[i].wcPriceScheduleID = scheduleID;
            if (fixedPrice) {
                newGrid[i].fixedPrice = numericDiscount;
                newGrid[i].discount = null;
            } else {
                newGrid[i].fixedPrice = null;
                newGrid[i].discount = numericDiscount;
            }
            if (partNo !== "") {
                newGrid[i].override = true;
            }
            newGrid[i].timestamp = timestamp;
        }

        setOptionGridIsLoading(true)
        if (scheduleID === 0) {
            SetupAPI.FillDownPricingItemOptionWithValue(parseInt(selectedTable), partNo, partNoSuffix, optionCodes, selectedOptionGroup, numericDiscount, fixedPrice).then(() => {
                setOptionGridIsLoading(false);
            });
        } else {
            SetupAPI.FillDownPricingItemOptionWithSchedule(parseInt(selectedTable), partNo, partNoSuffix, optionCodes, selectedOptionGroup, scheduleID).then(() => {
                setOptionGridIsLoading(false);
            });
        }

        setOptionsGrid(newGrid);
    }, [optionsGrid, selectedOptionGroup, selectedPart, selectedTable]);

    const addHandler = useCallback((name: string, category: number) => {
        setMainScreenIsLoading(true);
        SetupAPI.AddPriceTable(name, category, tableType).then((results) => {
            if (results.errorMessage === "") {
                // Success
                setSelectedCategory(category);
                requery(category, results.newTableID.toString());
            } else {
                // Fail
                messageBox.Show({
                    title: tm.Get("An error has occurred"),
                    message: tm.Get(results.errorMessage)
                });
                setMainScreenIsLoading(false);
            }
        });
    }, [tableType, messageBox, tm, requery]);
    
    const editHandler = useCallback((name: string) => {
        setMainScreenIsLoading(true);
        SetupAPI.EditPriceTable(parseInt(selectedTable), name).then((errorMessage) => {
            if (errorMessage === "") {
                // Success
                let tableCopy: ISimpleListItem[] = tables.slice();
                let thisTable = tableCopy.find((t) => t.id === parseInt(selectedTable));
                if (thisTable) {
                    thisTable.description = name;
                    setTables(tableCopy);
                }
            } else {
                // Fail
                messageBox.Show({
                    title: tm.Get("An error has occurred"),
                    message: tm.Get(errorMessage)
                });
            }
            setMainScreenIsLoading(false);
        });
    }, [selectedTable, messageBox, tm, tables, setTables]);

    const deleteHandler = useCallback(() => {
        setMainScreenIsLoading(true);
        SetupAPI.DeletePriceTable(parseInt(selectedTable)).then((errorMessage) => {
            if (errorMessage === "") {
                // Success
                let tableCopy: ISimpleListItem[] = [];
                let thisIndex = tables.findIndex((t) => t.id === parseInt(selectedTable));
                if (thisIndex !== -1) {
                    tableCopy = tables.slice();
                    tableCopy.splice(thisIndex, 1);
                    if (tableCopy.length === 0) {
                        setSelectedTable("");
                    } else {
                        setSelectedTable(tableCopy[0].id.toString());
                    }
                    setTables(tableCopy);
                }
            } else {
                // Fail
                messageBox.Show({
                    title: tm.Get("An error has occurred"),
                    message: tm.Get(errorMessage)
                });
            }
            setMainScreenIsLoading(false);
        });
    }, [selectedTable, messageBox, tm, tables, setSelectedTable, setTables]);
    
    const copyHandler = useCallback((name: string, targetTable: number) => {
        setMainScreenIsLoading(true);
        if (name === "") {
            // Copy to existing table
            SetupAPI.CopyPriceTableExisting(parseInt(selectedTable), targetTable).then(() => {
                setSelectedTable(targetTable.toString());
                setMainScreenIsLoading(false);
            });
        } else {
            // Copy to new table
            SetupAPI.CopyPriceTableNew(parseInt(selectedTable), name).then((results) => {
                if (results.errorMessage === "") {
                    // Success
                    let tableCopy = tables.slice();
                    tableCopy.push({id: results.newTableID, description: name});
                    setTables(tableCopy);
                    setSelectedTable(results.newTableID.toString());
                } else {
                    // Fail
                    messageBox.Show({
                        title: tm.Get("An error has occurred"),
                        message: tm.Get(results.errorMessage)
                    });
                }
                setMainScreenIsLoading(false);
            });
        }
    }, [selectedTable, tables, messageBox, tm, setSelectedTable, setTables]);

    return <>
        <Container maxWidth="xl">
            <Box p={1} gap={1} mt={1}>
                <Grid container direction="column" rowSpacing={3} justifyItems="stretch">
                    <Grid item>
                        <PricingTablesControls 
                            requery={requery}
                            categories={categories}
                            tables={tables}
                            tableType={tableType}
                            selectedCategory={selectedCategory}
                            selectedTable={selectedTable}
                            setTableType={setTableType}
                            handleTableChange={handleTableChange}
                            setSelectedCategory={setSelectedCategory}
                            addHandler={addHandler}
                            editHandler={editHandler}
                            deleteHandler={deleteHandler}
                            copyHandler={copyHandler}
                        />
                    </Grid>

                    <Grid item>
                        <Stack direction="column" alignItems="flex-start" justifyItems="stretch" spacing={1}>
                            <TextField size="small" value={gridView} onChange={handleGridViewChange} select sx={{width: isMobile ? 1 : (1 / 3)}}>
                                <MenuItem value="p">{tm.Get("Parts")}</MenuItem>
                                <MenuItem value="o">{tm.Get("Options")}</MenuItem>
                            </TextField>
                            {gridView === 'p' && partsGrid.length > 0 &&
                                <PartsGrid 
                                    tableID={selectedTable}
                                    schedules={schedules}
                                    partsGrid={partsGrid}
                                    tableType={tableType}
                                    fillDownHandler={handlePartFillDown}
                                />
                            }
                            {gridView === 'o' &&
                                <OptionsGrid
                                    tableID={selectedTable}
                                    parts={parts}
                                    optionGroups={optionGroups}
                                    schedules={schedules}
                                    optionsGrid={optionsGrid}
                                    selectedPart={selectedPart}
                                    selectedOptionGroup={selectedOptionGroup}
                                    tableType={tableType}
                                    setSelectedPart={setSelectedPart}
                                    setSelectedOptionGroup={setSelectedOptionGroup}
                                    fillDownHandler={handleOptionFillDown}
                                />
                            }
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    </>
}

export default PriceTables;