import { Delete, Edit, FileCopy, Person, Print } from "@mui/icons-material";
import { Stack, Tooltip } from "@mui/material";
import { GridActionsCellItem, GridColumnHeaderParams, GridEnrichedColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid-pro";
import DataGridColumnGenerator from "components/Common/DataGridColumnGenerator";
import AlertDialog from "components/Common/AlertDialog";
import OrderBlockReasonAction from "components/Maintenance/OrderBlockReasons";
import { ClassificationTypeEnum, ThemeColorEnum } from "helpers/enums";
import useTranslations from "helpers/hooks/useTranslations";
import ApplicationInfo from "models/ApplicationInfo";
import { IMaintenanceQuote } from "models/IMaintenanceQuote";
import React from "react";
import { IUploadedOrder } from "models/IUploadedOrder";
import { ISubmittedQuote } from "models/ISubmittedQuote";
import Translation from "components/Common/Translation";
import ApiUser from "models/ApiUser";

type QuoteActionHandler = (okey: number) => void;

interface ICommandProps {
    params: GridRowParams<IMaintenanceQuote | IUploadedOrder>;
    actionHandler: QuoteActionHandler
}

const EditCommand: React.FC<ICommandProps> = ({ params, actionHandler }: ICommandProps) => {
    const tm = useTranslations();


    const okey: number = params.row.oKey;

    const handlerShowDialog = React.useCallback(() => {
        actionHandler(okey);
    }, [actionHandler, okey]);


    return <>
        <Tooltip title={tm.Get("Edit Quote")}>
            <GridActionsCellItem
                icon={<Edit />}
                color="primary"
                label={tm.Get("Edit Quote")}
                onClick={handlerShowDialog}
            />
        </Tooltip>
    </>
};

const PrintCommand: React.FC<ICommandProps> = ({ params, actionHandler }: ICommandProps) => {

    const tm = useTranslations();

    const okey: number = React.useMemo(() => {
        if ((params.row as ISubmittedQuote).parentOKey) {
            return (params.row as ISubmittedQuote).parentOKey;
        }
        return params.row.oKey;
    }, [params]);

    const handlePrint = React.useCallback(() => {
        actionHandler(okey);
    }, [actionHandler, okey]);

    return <>
        <Tooltip title={(params.row as IUploadedOrder).oKeyQuote ? tm.Get("Print Order Acknowledgement") : tm.Get("Print Quote")}>
            <GridActionsCellItem
                icon={<Print />}
                color="primary"
                label={tm.Get("Print Quote")}
                onClick={handlePrint}
            />
        </Tooltip>
    </>
}

const CopyCommand: React.FC<ICommandProps> = ({ params, actionHandler }: ICommandProps) => {

    const tm = useTranslations();

    const okey: number = React.useMemo(() => {
        if ((params.row as ISubmittedQuote).parentOKey) {
            return (params.row as ISubmittedQuote).parentOKey;
        }
        if ((params.row as IUploadedOrder).oKeyQuote) {
            return (params.row as IUploadedOrder).oKeyQuote;
        }
        return params.row.oKey;
    }, [params]);

    const [open, setOpen] = React.useState<boolean>(false);

    const handlerShowDialog = React.useCallback(() => {
        setOpen(true);
    }, [setOpen]);

    const handleDialogClose = React.useCallback((result: boolean) => {
        if (result) {
            actionHandler(okey);
        }
        setOpen(false);
    }, [actionHandler, okey]);

    return <>
        <Tooltip title={tm.Get("Copy Quote")}>
            <GridActionsCellItem
                icon={<FileCopy />}
                color="primary"
                label={tm.Get("Copy Quote")}
                onClick={handlerShowDialog}
            />
        </Tooltip>
        <AlertDialog open={open}
            handleClose={handleDialogClose}
            headerText={tm.Get("Copy")}
            message={tm.Get("Are you sure you want to copy this quote?")}
        />
    </>
}

const DeleteCommand: React.FC<ICommandProps> = ({ params, actionHandler }: ICommandProps) => {

    const tm = useTranslations();
    const [open, setOpen] = React.useState<boolean>(false);

    const okey: number = params.row.oKey;

    const handlerShowDialog = React.useCallback(() => {
        setOpen(true);
    }, [setOpen]);

    const handleDialogClose = React.useCallback((result: boolean) => {
        if (result) {
            actionHandler(okey);
        }
        setOpen(false);
    }, [actionHandler, okey]);

    return <>
        <Tooltip title={tm.Get("Delete Quote")}>
            <GridActionsCellItem
                icon={<Delete />}
                color="primary"
                label={tm.Get("Delete Quote")}
                onClick={handlerShowDialog}
            />
        </Tooltip>
        <AlertDialog open={open}
            handleClose={handleDialogClose}
            headerText={tm.Get("Delete")}
            message={tm.Get("Are you sure you want to delete this quote?")}

        />
    </>
}

const CustomerQuoteIcon: React.FC<any> = () => {
    const tm = useTranslations();

    return <div style={{display: "flex", justifyContent: "center", alignItems: "center", padding: "5px"}}>
        <Tooltip title={tm.Get("Customer Quote")}>
            <Person color={ThemeColorEnum.Primary} fontSize="small"/>
        </Tooltip>
    </div>
}

const StatusColumn = () => {

    const Col: GridEnrichedColDef = {

        field: "Status",
        filterable: false,
        sortable: false,
        type: "actions",
        width: 70,
        renderCell: (params: GridRenderCellParams<any, IMaintenanceQuote, any>) => {
            const customerQuote = params.row.customerQuote;

            const orderContact = params.row.orderContact;
            const hasExceptions = params.row.hasExceptions;
            const customerInactive = params.row.customerInactive;
            const parentCustomerInactive = params.row.parentCustomerInactive;

            return <>
                <Stack direction="row" alignItems="center">
                    <OrderBlockReasonAction
                        hasExceptions={hasExceptions}
                        quoteContact={orderContact}
                        customerInactive={customerInactive ?? true}
                        parentCustomerInactive={parentCustomerInactive}
                    />
                    {customerQuote && 
                        <CustomerQuoteIcon />
                    }
                </Stack>
            </>
        },
        renderHeader: (params: GridColumnHeaderParams) => {
            return <div style={{ opacity: 0 }}>
                <Translation>Status</Translation>
            </div >
        },
    }
    return Col;
}

const commandsColumn = (printHandler: QuoteActionHandler | null, copyHandler: QuoteActionHandler | null, deleteHandler: QuoteActionHandler | null, editHandler: QuoteActionHandler | null) => {

    const actionArray = [printHandler, copyHandler, deleteHandler, editHandler]
    const numberOfActions = actionArray.reduce((p, c) => p + (c == null ? 0 : 1), 0); //get count of non-null columns
    const minWidth = numberOfActions * 46;

    const col = {
        field: "Commands",
        type: "actions",
        renderHeader: (params: GridColumnHeaderParams) => {
            return <div style={{ opacity: 0 }}>
                Commands
            </div>
        },
        minWidth: minWidth,
        filterable: false,
        sortable: false,
        getActions: (params: GridRowParams) => {

            const actions: JSX.Element[] = [];

            if (editHandler) actions.push(<EditCommand params={params} actionHandler={editHandler} />);
            if (printHandler) actions.push(<PrintCommand params={params} actionHandler={printHandler} />);
            if (copyHandler) actions.push(<CopyCommand params={params} actionHandler={copyHandler} />);
            if (deleteHandler) actions.push(<DeleteCommand params={params} actionHandler={deleteHandler} />);

            return actions;
        },
    } as GridEnrichedColDef;

    return col;
}



const commandsColumnSubmitted = (printHandler: QuoteActionHandler | null, copyHandler: QuoteActionHandler | null) => {
    const col = {
        field: "Commands",
        type: "actions",
        renderHeader: (params: GridColumnHeaderParams) => {
            return <div style={{ opacity: 0 }}>
                Commands
            </div>
        },
        filterable: false,
        sortable: false,
        getActions: (params: GridRowParams<ISubmittedQuote>) => {

            const parentQuoteNumber = params.row.parentQuoteNumber;

            if (parentQuoteNumber) {
                const actions: JSX.Element[] = [];

                if (printHandler) actions.push(<PrintCommand params={params} actionHandler={printHandler} />);
                if (copyHandler) actions.push(<CopyCommand params={params} actionHandler={copyHandler} />);

                return actions;
            } else {
                return [];
            }
        },
    } as GridEnrichedColDef;

    return col;
}

export function AddColumnsForType(type: ClassificationTypeEnum | undefined, generator: DataGridColumnGenerator, application: ApplicationInfo, user: ApiUser
    , quotes: any[] | null
    , printHandler: QuoteActionHandler | null
    , copyHandler: QuoteActionHandler | null
    , deleteHandler: QuoteActionHandler | null
    , editHandler: QuoteActionHandler | null
): void {

    if (!type || !quotes || quotes.length === 0)
        return;

    let reqDateColumnHeader: string = "Est. Delivery Date";
    if (application && application.parameters.customDeliveryDateLabel) {
        reqDateColumnHeader = application.parameters.customDeliveryDateLabel;
    }

    let requestDateColumnHeader: string = "Request Date";
    if (application && application.parameters.customRequestedDeliveryDateLabel) {
        requestDateColumnHeader = application.parameters.customRequestedDeliveryDateLabel;
    }

    switch (type) {

        case ClassificationTypeEnum.Uploaded:
        case ClassificationTypeEnum.AfterUpload:
            generator.TryAddDocumentNumberColumn("oKey", "orderNumber", "Order", "/orders/", { hideable: false });
            generator.TryAddDocumentNumberColumn("oKeyQuote", "quoteNumber", "Quote", "/quotes/", {});
            generator.TryAddDateColumn("orderDate", "Date", {});
            generator.TryAddTextColumn("uploadedBy", "Uploaded By", {});
            generator.TryAddTotalPriceColumn({});
            generator.TryAddTextColumn("poNumber", "Order PO Number", {});
            generator.TryAddTextColumn("customerRef", "Order Customer Ref", {});
            if (user.isMultiSite) {
                generator.TryAddTextColumn("mfgSiteName", "Mfg Customer", {});
            }
            generator.AddColumn(commandsColumn(printHandler, copyHandler, null, null));
            break;

        case ClassificationTypeEnum.OnHold:
        case ClassificationTypeEnum.OnHoldBeforeUpload:
            generator.AddColumn(StatusColumn());
            generator.TryAddDocumentNumberColumn("oKey", "orderNumber", "Quote", "/quotes/", { hideable: false });
            generator.TryAddDateColumn("orderDate", "Date", {});
            generator.TryAddWebCustomerColumn("webCenterCustomerName", "customerInactive", "Customer", {});
            generator.TryAddTotalPriceColumn({});
            generator.TryAddTextColumn("poNumber", "My PO Number", {});
            generator.TryAddTextColumn("customerRef", "My Customer Ref", {});
            generator.TryAddDateColumn("expirationDate", "Expires", {});
            if (user.isMultiSite) {
                generator.TryAddTextColumn("mfgSiteName", "Mfg Customer", {});
            }
            generator.AddColumn(commandsColumn(printHandler, copyHandler, deleteHandler, null));
            break;

        case ClassificationTypeEnum.Expired:
            generator.AddColumn(StatusColumn());
            generator.TryAddDocumentNumberColumn("oKey", "orderNumber", "Quote", "/quotes/", { hideable: false });
            generator.TryAddDateColumn("orderDate", "Date", {});
            generator.TryAddWebCustomerColumn("webCenterCustomerName", "customerInactive", "Customer", {});
            generator.TryAddTotalPriceColumn({});
            generator.TryAddTextColumn("poNumber", "My PO Number", {});
            generator.TryAddTextColumn("customerRef", "My Customer Ref", {});
            generator.TryAddDateColumn("expirationDate", "Expired", {});
            if (user.isMultiSite) {
                generator.TryAddTextColumn("mfgSiteName", "Mfg Customer", {});
            }
            generator.AddColumn(commandsColumn(printHandler, copyHandler, deleteHandler, null));
            break;

        case ClassificationTypeEnum.Available:
        case ClassificationTypeEnum.AvailableBeforeUpload:
            generator.AddColumn(StatusColumn());
            generator.TryAddDocumentNumberColumn("oKey", "orderNumber", "Quote", "/quotes/", { hideable: false });
            generator.TryAddDateColumn("orderDate", "Date", {});
            generator.TryAddWebCustomerColumn("webCenterCustomerName", "customerInactive", "Customer", {});
            generator.TryAddTotalPriceColumn({});
            generator.TryAddTextColumn("poNumber", "My PO Number", {});
            generator.TryAddTextColumn("customerRef", "My Customer Ref", {});
            generator.TryAddDateColumn("reqDate", reqDateColumnHeader, {});
            generator.TryAddDateColumn("requestDate", requestDateColumnHeader, {});
            generator.TryAddDateColumn("expirationDate", "Expires", {});
            if (user.isMultiSite) {
                generator.TryAddTextColumn("mfgSiteName", "Mfg Customer", {});
            }
            generator.AddColumn(commandsColumn(printHandler, copyHandler, deleteHandler, editHandler));
            break;

        case ClassificationTypeEnum.Submitted:
            generator.TryAddDocumentNumberColumn("oKey", "orderNumber", "Quote", "/quotes/", { hideable: false });
            generator.TryAddDocumentNumberColumn("parentOKey", "parentQuoteNumber", "Customer Quote", "/quotes/", {});
            generator.TryAddDateColumn("orderDate", "Date", {});
            generator.TryAddTotalPriceColumn({});
            generator.TryAddTextColumn("poNumber", "My PO Number", {});
            generator.TryAddTextColumn("customerRef", "My Customer Ref", {});
            generator.AddColumn(commandsColumnSubmitted(printHandler, copyHandler));
            break;

        case ClassificationTypeEnum.Recent:
            generator.TryAddDocumentNumberColumn("oKey", "orderNumber", "Quote", "/quotes/", { hideable: false });
            generator.TryAddDateColumn("orderDate", "Date", {});
            generator.TryAddWebCustomerColumn("webCenterCustomerName", "customerInactive", "Customer", {});
            generator.TryAddTotalPriceColumn({});
            generator.TryAddTextColumn("poNumber", "My PO Number", {});
            generator.TryAddTextColumn("customerRef", "My Customer Ref", {});
            generator.TryAddDateColumn("expirationDate", "Expires", {});
            generator.TryAddDateColumn("lastAccessDate", "Last Viewed", {});
            if (user.isMultiSite) {
                generator.TryAddTextColumn("mfgSiteName", "Mfg Customer", {});
            }
            generator.AddColumn(commandsColumn(printHandler, copyHandler, null, null));

            break;

    }


}
