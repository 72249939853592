import { nullish } from "components/OptionsWizard/WizardContext";
import APIHelper from "helpers/APIHelper";
import { CaseInsensitiveMap } from "helpers/classes/CaseInsensitiveMap";
import { ConfiguredLanguages, IConfiguredLanguage, ILanguageAPIInfo } from "models/Translations/IConfiguredLanguage";
import { ILocaleTranslations, ILocaleTranslationsAPI, ITranslatedContent } from "models/Translations/ILocaleTranslations";
import { ITMConfig } from "models/Translations/ITMConfig";

interface ILanguageInfo {
    defaultCultureInfo: string,
    languageMap: Map<string, IConfiguredLanguage>,
}


interface ILocaleTranslationsStore extends ILocaleTranslationsAPI {
    lastFetchDate: string;
}

const CACHE_TIMEOUT: number = 432000000; //5 days in ms

export default class TranslationsAPI {



    public static QueryLocaleTranslations(languageCulture: string): Promise<ILocaleTranslations> {


        return new Promise(async (resolve) => {

            const serializedDataString = localStorage.getItem("LANGUAGE_TRANSLATIONS_" + languageCulture);

            let translationArray: ITranslatedContent[] | null = null;
            let timestamp: number = 0;
            let lastFetchDate: number = 0;

            if (serializedDataString) {
                const api: ILocaleTranslationsStore = JSON.parse(serializedDataString);
                translationArray = api.content;
                timestamp = Date.parse(api.timestamp);
                lastFetchDate = Date.parse(api.lastFetchDate);
            }

            if (!lastFetchDate || (lastFetchDate + CACHE_TIMEOUT) < Date.now()) {
                const wr = await APIHelper.GetAsync<ILocaleTranslationsAPI>('api/translations/languagecontent', { languageCulture });

                if (wr.Result) {
                    translationArray = wr.Result.content;
                    timestamp = Date.parse(wr.Result.timestamp);
                } else {
                    translationArray = [];
                    timestamp = Date.now();
                }

                localStorage.setItem("LANGUAGE_TRANSLATIONS_" + languageCulture, JSON.stringify({
                    content: translationArray,
                    timestamp: new Date(timestamp).toISOString(),
                    lastFetchDate: new Date(Date.now()).toISOString(),
                } as ILocaleTranslationsStore));

            }



            let translationMap: CaseInsensitiveMap<string, string>;

            if (translationArray !== null) {
                const kvp = translationArray.map(t => { return [t.base, t.translation] as readonly [string, string]; });
                translationMap = new CaseInsensitiveMap<string, string>(kvp);
            } else {
                translationMap = new CaseInsensitiveMap<string, string>();
            }


            let translations = {
                locale: languageCulture,
                timestamp: timestamp,
                content: translationMap,
            }

            resolve(translations);

        });

    }


    public static QueryConfiguredLanguages(): Promise<ILanguageInfo> {

        return new Promise<ILanguageInfo>(async (resolve) => {

            /*Try pulling from local storage first*/
            const configuredLanuagesString = localStorage.getItem("LANGUAGES_CONFIGURED");
            let defaultLanguage = localStorage.getItem("LANGUAGE_DEFAULT");

            let configuredLanguages: ConfiguredLanguages;

            if (defaultLanguage && configuredLanuagesString) {
                //Parse local storage string into object
                configuredLanguages = JSON.parse(configuredLanuagesString) as ConfiguredLanguages;

            } else {
                //Doesn't exist in local storage, grab from the API
                const wr = await APIHelper.GetAsync<ILanguageAPIInfo>('api/translations/languages');

                defaultLanguage = wr.Result.defaultCultureInfo;
                configuredLanguages = wr.Result.configuredCultures;

                //Save results back to local storage
                localStorage.setItem("LANGUAGES_CONFIGURED", JSON.stringify(configuredLanguages));
                localStorage.setItem("LANGUAGE_DEFAULT", defaultLanguage);

            }

            /*The API returns the configured language wrapped as an array, the map below returns the first element of the array */
            let languageMap = new Map<string, IConfiguredLanguage>(Object.entries(configuredLanguages));

            resolve({
                defaultCultureInfo: defaultLanguage,
                languageMap,
            });

        });

    }



    public static QueryTMConfig(): Promise<ITMConfig | null> {

        return APIHelper.GetAsync<ITMConfig | nullish>('api/translations/config', undefined, undefined, false).then(wr => {
            return wr.Result ?? null; //Ensure we are dealing with null not undefined
        });

    }

    public static PostMissingContent(content: string): Promise<any> {

        return APIHelper.PostAsync('api/translations/missingcontent', undefined, content);

    }


}
