import * as React from "react";
import { useState, useCallback, useMemo, useLayoutEffect, createRef } from 'react';

import { ILineItemPriceInfo, IOptionPriceInfo } from "../interfaces";
import { ITranslationManager } from "helpers/hooks/useTranslations";
import LoadingOverlayContainer from "components/Common/LoadingOverlayContainer";
import { Table, TableRow, TableCell, TableBody, TableHead, TableContainer, styled, useTheme } from "@mui/material";
import OptionGroupBox from "../OptionsWizard/OptionGroupBox";
import useQuoteData from "components/Quotes/useQuoteData";
import useCurrencyFormatter from "helpers/hooks/useCurrencyFormatter";

interface IPricingProps {
    prices: ILineItemPriceInfo;
    subLineItem: number | null;
    tm: ITranslationManager;
}


const PricingSummary: React.FC<IPricingProps> = (props: IPricingProps) => {

    const { quote } = useQuoteData();
    const cf = useCurrencyFormatter(quote?.currencyCulture);
    const [priceAlign, setPriceAlign] = useState<number>(0);
    const theme = useTheme();
    const outerPriceColumnTargetWidth = 116;
    const innerPriceColumnTargetWidth = outerPriceColumnTargetWidth - parseFloat(theme.spacing(3.25));

    const formatPrice = useCallback((price: string) => {
        let parsedPrice = Number(price);
        return isNaN(parsedPrice) ? price : cf.Format(parsedPrice);
    }, [cf]);

    const StyledRow = styled(TableRow)(() => ({
        '&:last-child td': { border: 0 }
    }));

    const optionPriceHeaderRef = createRef<HTMLTableCellElement>();
    const itemPriceHeaderRef = createRef<HTMLTableCellElement>();

    const handleResize = useCallback(() => {
        if (optionPriceHeaderRef.current && itemPriceHeaderRef.current) {
            setPriceAlign(innerPriceColumnTargetWidth - optionPriceHeaderRef.current.offsetWidth)
        } else {
            setPriceAlign(0)
        }
    }, [optionPriceHeaderRef, itemPriceHeaderRef, innerPriceColumnTargetWidth]);

    const resizeObserver = useMemo(() => {
        return new ResizeObserver(() => {
            handleResize();
        });
    }, [handleResize]);

    useLayoutEffect(() => {
        const element = optionPriceHeaderRef.current;
        if (element) {
            resizeObserver.observe(element);
        }

        return () => {
            if (element) {
                resizeObserver.unobserve(element);
            }
        }
    }, [resizeObserver, optionPriceHeaderRef]);

    const outerPriceColumnWidth = useMemo(() => {
        return outerPriceColumnTargetWidth - priceAlign + "px";
    }, [outerPriceColumnTargetWidth, priceAlign]);

    const innerPriceColumnWidth = useMemo(() => {
        return innerPriceColumnTargetWidth + "px";
    }, [innerPriceColumnTargetWidth]);

    return <OptionGroupBox id="PRICING_SUMMARY" label="">
        <LoadingOverlayContainer waiting={props.prices && props.prices.waiting} disableGutters >
            <TableContainer>
                {props.prices &&
                    <Table>
                        <TableHead>
                            <StyledRow>
                                <TableCell component="th"></TableCell>
                                <TableCell component="th"></TableCell>
                                <TableCell component="th"></TableCell>
                                <TableCell ref={itemPriceHeaderRef} sx={{width: outerPriceColumnWidth}} component="th">{props.tm.Get("Price")}</TableCell>
                            </StyledRow>
                        </TableHead>

                        <TableBody>

                            {props.prices && props.prices.partPrice !== "" &&
                                <StyledRow>
                                    <TableCell>{props.tm.Get("Part Price") + ":"}</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>{formatPrice(props.prices.partPrice)}</TableCell>
                                </StyledRow>}


                            {props.prices && props.prices.optionsPrice !== "" &&
                                <>
                                    {props.prices.options.length > 0 &&
                                        <>
                                            <StyledRow>
                                                <TableCell sx={{borderBottom: 0}}>{props.tm.Get("Options") + ":"}</TableCell>
                                                <TableCell sx={{borderBottom: 0}}></TableCell>
                                                <TableCell sx={{borderBottom: 0}}></TableCell>
                                                <TableCell sx={{borderBottom: 0}}></TableCell>
                                            </StyledRow>

                                            <StyledRow>
                                                <TableCell colSpan={4} sx={{paddingTop: 0, borderBottom: 0, paddingBottom: 0}}>
                                                    <OptionGroupBox id="OPTION_PRICING_SUMARRY" label="">
                                                        <TableContainer>
                                                            <Table>
                                                                <TableHead>
                                                                    <StyledRow>
                                                                        <TableCell component="th">{props.tm.Get("Question")}</TableCell>
                                                                        <TableCell component="th">{props.tm.Get("Option")}</TableCell>
                                                                        <TableCell component="th">{props.tm.Get("Description")}</TableCell>
                                                                        <TableCell ref={optionPriceHeaderRef} sx={{width: innerPriceColumnWidth}} component="th">{props.tm.Get("Price")}</TableCell>
                                                                    </StyledRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {props.prices.options.map((c: IOptionPriceInfo) =>
                                                                        <StyledRow key={c.question + "_" + c.code + "_p"}>
                                                                            <TableCell>{c.question}</TableCell>
                                                                            <TableCell>{c.code}</TableCell>
                                                                            <TableCell>{c.description}</TableCell>
                                                                            <TableCell>{formatPrice(c.price)}</TableCell>
                                                                        </StyledRow>
                                                                    )}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </OptionGroupBox>
                                                </TableCell>
                                            </StyledRow>
                                        </>
                                    }
                                    <StyledRow>
                                        <TableCell>{props.tm.Get("Options Price") + ":"}</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>{formatPrice(props.prices.optionsPrice)}</TableCell>
                                    </StyledRow>
                                </>
                            }


                            {props.prices && props.prices.itemPrice !== "" &&
                                <StyledRow>
                                    <TableCell>{props.tm.Get("Item Price") + ":"}</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>{formatPrice(props.prices.itemPrice)}</TableCell>
                                </StyledRow>}

                            {props.prices && props.prices.overallPrice !== ""
                                && props.prices.overallPrice !== props.prices.itemPrice
                                && props.subLineItem !== 0 &&

                                <StyledRow>
                                    <TableCell>{props.tm.Get("Overall Price") + ":"}</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>{formatPrice(props.prices.overallPrice)}</TableCell>
                                </StyledRow>
                            }


                        </TableBody>

                    </Table>
                }



            </TableContainer>
        </LoadingOverlayContainer>
    </OptionGroupBox>;
}

export default PricingSummary;
