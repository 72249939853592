import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { ITranslationManager } from "helpers/hooks/useTranslations";
import { IApplicationInfo, IDashboardOrder } from "helpers/interfaces";
import { Theme, Typography } from "@mui/material";
import { DateTime } from "luxon";
import DataGridColumnGenerator from "components/Common/DataGridColumnGenerator";
import { IRecordWithReqDate } from "models/IDashboardOrder";
import { ICurrencyFormatter } from "helpers/hooks/useCurrencyFormatter";
import ApiUser from "models/ApiUser";

export const generateOrderColumns = (tm: ITranslationManager, data: IDashboardOrder[] | null, theme: Theme, application: IApplicationInfo, isMobile: boolean, cf: ICurrencyFormatter, user: ApiUser) => {

  if (!data || data.length === 0) return [] as GridColumns;

  let reqDateColumnHeader: string = "Est Delivery Date";
  if (application && application.parameters.customDeliveryDateLabel) {
    reqDateColumnHeader = application.parameters.customDeliveryDateLabel;
  }


  const generator = new DataGridColumnGenerator(tm, data, theme, isMobile, cf);

  generator.TryAddDocumentNumberColumn("oKey", "orderNumber", "Order", "/orders", { hideable: false });
  generator.TryAddDateColumn("orderDate", "Date", { hideInMobile: true });

  generator.TryAddTextColumn("poNumber", "PO Number", { hideInMobile: false });
  generator.TryAddTextColumn("customerRef", "Customer Ref", { hideInMobile: false });
  generator.TryAddTotalPriceColumn({ hideInMobile: true });

  generator.AddColumn(
    {
      field: "requiredDate",
      headerName: tm.Get(reqDateColumnHeader),
      minWidth: generator.GetMinWidth(),
      flex: 1,
      hide: isMobile,
      renderCell: (params: GridRenderCellParams<any, IRecordWithReqDate>) => {
        const reqDate = params.row.reqDate;
        const reqDateString = params.row.reqDateString;

        if (reqDate) {

          var dt = DateTime.fromISO(reqDate as string);
          var dtNull = DateTime.fromISO("1900-01-01T00:00:00");
          if (dt.startOf("day").toMillis() === dtNull.startOf("day").toMillis()) {
            return <></>;
          }
          return <>
            <Typography variant="body2">
              {dt.setLocale(navigator.language).toLocaleString(DateTime.DATE_SHORT)}
            </Typography>
          </>;

        } else {
          return <>
            <Typography variant="body2">
              {reqDateString}
            </Typography>
          </>;
        }
      }
    });

  generator.TryAddDateColumn("targetShipDate", "Target Ship Date", { hideInMobile: true });

  generator.TryAddStatusColumn();
  if (user.isMultiSite) {
    generator.TryAddTextColumn("mfgSiteName", "Mfg Customer", { hideInMobile: true });
  }

  return generator.GetColumns();


}
