import * as React from "react";
import ObjectViewer from "../ObjectViewer/ObjectViewer";
import { IPartPreferences, ILineItemInfo, IWebDesigner, ICode, IAltWizInfo, ISectionRegion } from "../interfaces";
import WizardHelper from "../WizardHelper";
import { ITranslationManager } from "helpers/hooks/useTranslations";
import Format from "helpers/fv.format";
import APIHelper from "helpers/APIHelper";
import { Box } from "@mui/material";
import { WizardModeEnum } from "components/OptionsWizard/WizardContext";
import { useMemo } from "react";

interface IOVProps {
    optionHash: string;
    partPreferences: IPartPreferences | null;
    itemInfo: ILineItemInfo | null;
    webDesigner: IWebDesigner | null;
    focusedCode: ICode | null;
    altWizInfo: IAltWizInfo | null;
    clickRegionHandler?: (clickableReg: ISectionRegion, tm: ITranslationManager) => void;
    mode: WizardModeEnum;
    tm: ITranslationManager;
    format: Format;
    renderAsDiv: boolean;
}

const OptionPreview: React.FC<IOVProps> = (props: IOVProps) => {

    const showMain = useMemo(() => {
        return (props.webDesigner || props.altWizInfo || !props.partPreferences) ? true : props.partPreferences.showMain;
    }, [props]);

    const showCross = useMemo(() => {
        return (props.webDesigner || props.altWizInfo || !props.partPreferences) ? false : props.partPreferences.showCross;
    }, [props]);

    const showTop = useMemo(() => {
        return (props.webDesigner || props.altWizInfo || !props.partPreferences) ? false : props.partPreferences.showTop;
    }, [props]);

    const showOptionImage = useMemo(() => {
        return props.focusedCode !== null && props.focusedCode.imageExists && WizardHelper.getMaximizeOptionImage();
    }, [props]);

    return <>

        {showOptionImage && props.focusedCode && !props.altWizInfo && props.mode === WizardModeEnum.Standard &&
            <Box display="flex" width="100%" height="100%" alignItems={"center"} justifyContent={"center"} >
                <img src={APIHelper.GetBaseURL() + `api/images/OptionImage?optionCode=${props.focusedCode.code} &thumbnail=false`} alt="Option Code" />
            </Box>
        }

        {!showOptionImage &&
            <ObjectViewer
                optionHash={props.optionHash}
                showMain={showMain}
                showCross={showCross}
                showTop={showTop}
                webDesigner={props.webDesigner}
                altWizInfo={props.altWizInfo}
                clickRegionHandler={props.clickRegionHandler}
                mode={props.mode}
                tm={props.tm}
                renderAsDiv={props.renderAsDiv}
            />
        }

    </>;
}

export default OptionPreview;

