import * as React from "react";

import { ISubLineItemInfo, IWDCRepsonse } from "../interfaces";
import { DesignerToolsEnum } from "../enum";
import useTranslations from "helpers/hooks/useTranslations";
import { Box, IconButton } from "@mui/material";
import { Delete, PushPin, RestartAlt, SelectAll, TableRows, ViewColumn } from "@mui/icons-material";
import { useWizardStateActions } from "components/OptionsWizard/WebDesigner/useWizardState";
import useMessageBox from "helpers/context/Page/useMessageBox";
import OptionsAPI from "components/OptionsWizard/OptionsAPI";
import useWait from "helpers/context/Page/useWait";
import SplitSectionsDialog from "components/OptionsWizard/ItemProperties/SplitSectionDialog";
import useWizardInteractions from "../useWizardInteractions";


interface IProps {
    itemInfo: ISubLineItemInfo;
}


const DesignerTools: React.FC<IProps> = (props: IProps) => {

    const wizardActions = useWizardStateActions();
    const wizardInteractions = useWizardInteractions();

    const messageBox = useMessageBox();
    const tm = useTranslations();
    const wait = useWait();

    const [splitDialogType, setSplitDialogType] = React.useState<"SIDE" | "STACK" | null>(null);


    const HandleSideSplit = (e: React.MouseEvent<any>) => {
        setSplitDialogType("SIDE");
    }

    const HandleStackSplit = (e: React.MouseEvent<any>) => {
        setSplitDialogType("STACK");
    }

    const HandleDeleteSection = (e: React.MouseEvent<any>) => {
        const dialogTitle = tm.Get("Delete Section");
        const callback = (dialogResult: boolean | undefined) => {
            if (dialogResult) {
                HandleToolDialogCallback(OptionsAPI.DeleteSection(), dialogTitle);
            }
        }
        messageBox.Show({ yesNoPrompt: true, message: dialogTitle, callback })
    }

    const RequeryState = React.useCallback(async () => {
        await wizardInteractions.RequeryFullStateAsync();
    }, [wizardInteractions]);

    const HandleToolDialogCallback = React.useCallback((toolAction: Promise<IWDCRepsonse>, dialogTitle: string) => {
        wait.Show(true);
        toolAction.then(async (r) => {
            if (r.redirect) {
                await RequeryState();
            } else {
                messageBox.Show({ title: dialogTitle, message: r.message });
            }
        }).finally(() => wait.Show(false));
    }, [wait, messageBox, RequeryState]);

    const HandleResetDesigner = (e: React.MouseEvent<any>) => {
        const dialogTitle = tm.Get("Reset Designer");
        const callback = (dialogResult: boolean | undefined) => {
            if (dialogResult) {
                HandleToolDialogCallback(OptionsAPI.ResetDesigner(), dialogTitle);
            }
        }
        messageBox.Show({ yesNoPrompt: true, message: dialogTitle, callback })
    }

    const HandlePinSection = (e: React.MouseEvent<any>) => {
        const dialogTitle = tm.Get("Pin Section");
        const callback = (dialogResult: boolean | undefined) => {
            if (dialogResult) {
                HandleToolDialogCallback(OptionsAPI.PinSection(true), dialogTitle);
            }
        }
        messageBox.Show({ yesNoPrompt: true, message: dialogTitle, callback })
    }

    const HandleUnpinSection = (e: React.MouseEvent<any>) => {
        const dialogTitle = tm.Get("Unpin Section");
        const callback = (dialogResult: boolean | undefined) => {
            if (dialogResult) {
                HandleToolDialogCallback(OptionsAPI.PinSection(false), dialogTitle);
            }
        }
        messageBox.Show({ yesNoPrompt: true, message: dialogTitle, callback })
    }

    const HandleMultiSelect = (e: React.MouseEvent<any>) => {
        wizardActions?.SwitchToMultiSelect();
        wizardActions?.SetCameFromMultiSelect(true);
    }

    const HandleSectionSplit = React.useCallback((qty: number, part: string, sideBySide: boolean) => {

        HandleToolDialogCallback(OptionsAPI.SplitCurrentSection(qty, part, sideBySide).then((r) => {
            setSplitDialogType(null);
            return r;
        }), tm.Get("Split Section"));


    }, [HandleToolDialogCallback, tm]);


    let supportedTools: DesignerToolsEnum | null = null;

    if (props.itemInfo)
        supportedTools = props.itemInfo.tools;


    if (supportedTools) {

        let sideSplit: boolean = (supportedTools & DesignerToolsEnum.SideSplit) > 0;
        let stackSplit: boolean = (supportedTools & DesignerToolsEnum.StackSplit) > 0;
        let deleteSection: boolean = (supportedTools & DesignerToolsEnum.DeleteSection) > 0;
        let resetDesigner: boolean = (supportedTools & DesignerToolsEnum.ResetDesigner) > 0;
        let pinSection: boolean = (supportedTools & DesignerToolsEnum.PinSection) > 0;
        let unPinSection: boolean = (supportedTools & DesignerToolsEnum.UnPinSection) > 0;
        let multiSelect: boolean = (supportedTools & DesignerToolsEnum.MultiSelect) > 0;

        return <Box color="inherit" gap={1} >

            {sideSplit &&
                <IconButton sx={{ color: "inherit" }} title={tm.Get("Side By Side Split")} onClick={HandleSideSplit} >
                    <ViewColumn />
                </IconButton>
            }

            {stackSplit &&
                <IconButton sx={{ color: "inherit" }} title={tm.Get("Stack Split")} onClick={HandleStackSplit} >
                    <TableRows />
                </IconButton>
            }

            {deleteSection &&
                <IconButton sx={{ color: "inherit" }} title={tm.Get("Delete Section")} onClick={HandleDeleteSection} >
                    <Delete />
                </IconButton>
            }

            {resetDesigner &&
                <IconButton sx={{ color: "inherit" }} title={tm.Get("Reset Designer")} onClick={HandleResetDesigner} >
                    <RestartAlt />
                </IconButton>
            }

            {pinSection &&
                <IconButton sx={{ color: "inherit", transform: "rotate(-90deg)" }} title={tm.Get("Pin Section")} onClick={HandlePinSection}   >
                    <PushPin />
                </IconButton>
            }

            {unPinSection &&
                <IconButton color="primary" title={tm.Get("Unpin Section")} onClick={HandleUnpinSection}   >
                    <PushPin />
                </IconButton>
            }

            {multiSelect &&
                <IconButton sx={{ color: "inherit" }} title={tm.Get("Multi Select")} onClick={HandleMultiSelect} >
                    <SelectAll />
                </IconButton>
            }

            <SplitSectionsDialog
                dialogVisible={!!splitDialogType}
                onCancel={() => setSplitDialogType(null)}
                onSubmit={HandleSectionSplit}
                sideBySide={splitDialogType === "SIDE"}
            />

        </Box>;

    } else
        return null;

}

export default DesignerTools;