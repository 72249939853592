import * as React from "react";
import useImageLoader from "helpers/hooks/useImageLoader";
import LoadingOverlay from "./LoadingOverlay";
import { CSSProperties } from "react";
import ScalableImage from "./ScalableImage";

interface IProps {
    src: string;
    renderAsDiv: boolean;
    imageStyle?: CSSProperties;
}

const ImageWithLoadingOverlay: React.FC<IProps> = (props: IProps) => {

    const [loading, loadedImageUrl] = useImageLoader(props.src);

    return <LoadingOverlay loading={loading}>
        {loadedImageUrl ?
            <ScalableImage
                renderAsDiv={props.renderAsDiv}
                src={loadedImageUrl}
                imageStyle={props.imageStyle}
            />
            :
            <div style={props.imageStyle} />
        }
    </LoadingOverlay>;

}

export default ImageWithLoadingOverlay;
