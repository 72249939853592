import React from "react"
import { Paper, Box, Grid } from "@mui/material";

import LabeledText from "components/Common/LabeledText";
import useTranslations from "helpers/hooks/useTranslations";
import useOrderData from "components/Orders/useOrderData";

import Format from "helpers/fv.format";

interface IProps {

}

const FooterCard: React.FC<IProps> = (props: IProps) => {

    const tm = useTranslations();
    const { order } = useOrderData();

    return <>
        <Paper>
            <Box sx={{ margin: 1 }}>
                <Grid container>
                    <Grid item xs={6}>
                        <LabeledText label={tm.Get("Order Date")} text={Format.FormatDate(order?.date)} />
                    </Grid>
                </Grid>
            </Box>
        </Paper>

    </>

};


export default FooterCard;