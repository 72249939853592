import React from "react"
import { UNSAFE_NavigationContext, Navigator, useNavigate } from "react-router-dom";
import type { Blocker } from "history";
import useTranslations from "./useTranslations";


/**
 * Prevents both the react-router-dom and the browser from naviagting away from a page that contains unsaved work.
 * 
 * UNSAFE_NavigationContext is used as a solution to prevent react-router-dom navigation because it exposes the block option:
 * https://stackoverflow.com/questions/70573526/prevent-navigation-with-react-router-v6
 * 
 * #START This was written using a combination of the following sources:
 * 
 * Mostly follows the code provided by @lacazeto with a couple of modifications and fixes.
 * https://github.com/remix-run/react-router/issues/8139
 * 
 * An earlier solution, that the newer solution could be based off of.
 * https://gist.github.com/rmorse/426ffcc579922a82749934826fa9f743
 * 
 * Mozilla documentation on how to setup and use the beforeunload event which is used to prevent the browser from navigating away.
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
 * 
 * #END
 * 
 * #START Other useful information:
 * 
 * Make sure that when you are determining of a page is dirty, that the page is editible.
 * 
 * To check if a specific field state is dirty you can use getFieldState to get a get the field and use isDirty to see if it is dirty.
 * 
 * The form must have a fieldName, that fieldName is what is passed into the getFieldState method.
 * 
 * getFieldState documentation:
 * https://react-hook-form.com/api/useform/getfieldstate
 * 
 * If the component is using useForm you can use the method formState which is one of the form methods returned by useForm to get the state of the whole form and then use isDirty to determine if the whole form is dirty
 * 
 * useForm (which contains isDirty) documentation:
 * https://react-hook-form.com/api/useform/formstate
 * 
 * #END
 * 
 * @param isDirty If an editible item is changed then it is Dirty
 * @return {React.MutableRefObject<() => void>} Which can be used to unblock the page if the editible item is saved
 */

export interface IPageLoadBlocker {
    unblock: () => void,
}

function usePageLoadBlocker(unblockRef: React.MutableRefObject<() => void>, isDirty: boolean = true) : IPageLoadBlocker {

    type BlockNavigator = Navigator & {
        location: Location;
        block: (blocker: Blocker) => () => void;
    }
    
    const tm = useTranslations();

    const { navigator } = React.useContext(UNSAFE_NavigationContext);

    const navigate = useNavigate();
    
    const beforeUnloadListener = React.useCallback((event: BeforeUnloadEvent) => {
        event.preventDefault();
        return event.returnValue = tm.Get("Are you sure you want to exit? Changes you made may not be saved.");
    }, [tm]);

    const basePath = React.useMemo(() => {
        return document.getElementsByTagName("base")[0].href.replace(window.location.protocol + '//' + window.location.host +  "/", "");
    }, []);

    React.useEffect (() => {
        if (isDirty) {
            //Prevents page closing/reloading
            window.addEventListener("beforeunload", beforeUnloadListener, { capture: true })

            //Prevents react-router-dom from navigating away from the page
            const { block } = navigator as BlockNavigator
            unblockRef.current = block((transition) => {
                const {
                    location: { pathname: targetPathname, search },
                    action,
                } = transition;

                window.removeEventListener("beforeunload", beforeUnloadListener, { capture: true })

                if (window.confirm(tm.Get("Are you sure you want to exit? Changes you made may not be saved."))) {
                    unblockRef.current();

                    if (action.toLowerCase() === "pop") {
                        navigate(`${targetPathname.replace(basePath, "")}${search}`);
                    }
                    else {
                        transition.retry();
                    }
                }
            });

            return () => window.removeEventListener("beforeunload", beforeUnloadListener, { capture: true })
        }
        else {
            unblockRef.current();
            return;
        }
    }, [navigator, navigate, isDirty, basePath, tm, beforeUnloadListener])

    return { unblock: () => { unblockRef.current() } };

}

export default usePageLoadBlocker