import React, { useState, useCallback, useEffect } from "react";
import { TextField, Container, Box, Stack, Typography } from "@mui/material";

import useIsMobile from "helpers/hooks/useIsMobile";
import useTranslations from "helpers/hooks/useTranslations";
import useWait from "helpers/context/Page/useWait";
import useWindowTitle from "helpers/context/Title/useWindowTitle";
import SetupAPI from "components/Setup/SetupAPI";
import { IPriceSchedule, IPriceScheduleItem } from "helpers/interfaces";
import PricingSchedulesControls from "./PriceSchedulesControls";
import PriceScheduleItemsGrid from "./PriceScheduleItemsGrid";
import useCurrencyFormatter from "helpers/hooks/useCurrencyFormatter";
import ErrorAdornment from "components/Common/ErrorAdornment";
import useMessageBox from "helpers/context/Page/useMessageBox";
import useLocaleNumberFormatter from "helpers/hooks/useLocaleNumberFormatter";
import Constants from "helpers/constants";

const PriceSchedules = () => {
    const isMobile = useIsMobile();
    const tm = useTranslations();
    const wait = useWait();
    const cf = useCurrencyFormatter("");
    const lnf = useLocaleNumberFormatter({style: "decimal", useGrouping: false, maximumFractionDigits: 4});
    const messageBox = useMessageBox();

    const [mainScreenIsLoading, setMainScreenIsLoading] = useState<boolean>(true);
    const [itemsGridIsLoading, setItemsGridIsLoading] = useState<boolean>(true);

    const [schedules, setSchedules] = useState<IPriceSchedule[]>([]);
    const [selectedSchedule, setSelectedSchedule] = useState<IPriceSchedule | null>(null);
    const [scheduleItems, setScheduleItems] = useState<IPriceScheduleItem[]>([]);

    const [displayAdderPrice, setDisplayAdderPrice] = useState<string>("");
    const [displayAdderIncrement, setDisplayAdderIncrement] = useState<string>("");

    const [adderPriceErrorText, setAdderPriceErrorText] = useState<string>("");
    const [adderIncrementErrorText, setAdderIncrementErrorText] = useState<string>("");

    useWindowTitle(tm.Get("Price Schedules"));

    useEffect(() => {
        wait.Show(mainScreenIsLoading || itemsGridIsLoading);
    }, [wait, mainScreenIsLoading, itemsGridIsLoading]);

    useEffect(() => {
        setMainScreenIsLoading(true);
        SetupAPI.GetPriceSchedules().then(schedules => {
            setSchedules(schedules);
            if (schedules.length > 0) {
                setSelectedSchedule(schedules[0]);
            } else {
                setSelectedSchedule(null);
            }
            setMainScreenIsLoading(false);
        });
    }, []);

    useEffect(() => {
        setItemsGridIsLoading(true);
        if (selectedSchedule) {
            setDisplayAdderPrice(cf.Format(selectedSchedule.gridAdderPrice));
            setDisplayAdderIncrement(lnf.Format(selectedSchedule.gridAdderIncrement));
            SetupAPI.GetPriceScheduleItems(selectedSchedule.priceScheduleID).then(items => {
                setScheduleItems(items);
                setItemsGridIsLoading(false);
            });
        } else {
            setDisplayAdderPrice("");
            setDisplayAdderIncrement("");
            setItemsGridIsLoading(false);
        }
        setAdderPriceErrorText("");
        setAdderIncrementErrorText("");
    }, [selectedSchedule, cf, lnf]);

    const scheduleAddHandler = useCallback((name: string) => {
        SetupAPI.PriceScheduleAdd(name).then((results) => {
            if (results.errorMessage === "") {
                // Success
                let scheduleListCopy = schedules.slice();
                let newSchedule: IPriceSchedule = {
                    priceScheduleID: results.newTableID,
                    description: name,
                    gridAdderPrice: 0,
                    gridAdderIncrement: 0
                }
                scheduleListCopy.push(newSchedule);
                setSchedules(scheduleListCopy);
                setSelectedSchedule(newSchedule);
            } else {
                // Failure
                messageBox.Show({
                    title: tm.Get("An error has occurred"),
                    message: tm.Get(results.errorMessage)
                });
            }
        });
    }, [tm, messageBox, schedules]);

    const scheduleEditHandler = useCallback((name: string) => {
        SetupAPI.PriceScheduleEditName(selectedSchedule!.priceScheduleID, name).then((errorMessage) => {
            if (errorMessage === "") {
                // Success
                let scheduleListCopy = schedules.slice();
                let scheduleCopy = scheduleListCopy.find((s) => s.priceScheduleID === selectedSchedule!.priceScheduleID)!;
                scheduleCopy.description = name;
                setSchedules(scheduleListCopy);
                setSelectedSchedule(scheduleCopy);
            } else {
                // Failure
                messageBox.Show({
                    title: tm.Get("An error has occurred"),
                    message: tm.Get(errorMessage)
                });
            }
        });
    }, [tm, messageBox, schedules, selectedSchedule]);

    const scheduleDeleteHandler = useCallback(() => {
        SetupAPI.PriceScheduleDelete(selectedSchedule!.priceScheduleID).then((errorMessage) => {
            if (errorMessage === "") {
                // Success
                let thisIndex = schedules.findIndex((s) => s.priceScheduleID === selectedSchedule!.priceScheduleID)!;
                let scheduleListCopy = schedules.slice();
                scheduleListCopy.splice(thisIndex, 1);
                setSchedules(scheduleListCopy);
                if (scheduleListCopy.length > 0) {
                    setSelectedSchedule(scheduleListCopy[0]);
                } else {
                    setSelectedSchedule(null);
                }
            } else {
                // Failure
                messageBox.Show({
                    title: tm.Get("An error has occurred"),
                    message: tm.Get(errorMessage)
                });
            }
        });
    }, [tm, messageBox, schedules, selectedSchedule]);

    const handleAdderPriceChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setDisplayAdderPrice(e.target.value);
    }, []);

    const handleAdderPriceBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
        let price: number;
        if (e.target.value.trim() === "") {
            price = 0;
        } else {
            price = cf.Parse(e.target.value);
        }
        if (isNaN(price)){
            setAdderPriceErrorText(tm.Get("Price must be a valid currency value."));
        } else if (price < Constants.Min.Price || price > Constants.Max.Price) {
            setAdderPriceErrorText(tm.GetWithParams("Price must be between {0} and {1}.", Constants.Min.Price.toString(), Constants.Max.Price.toString()));
        } else {
            if (adderIncrementErrorText === "") {
                let increment = lnf.Parse(displayAdderIncrement);
                SetupAPI.SetPriceScheduleAdders(selectedSchedule!.priceScheduleID, price, increment).then(() => {
                    let scheduleListCopy = schedules.slice();
                    let scheduleCopy = schedules.find((s) => s.priceScheduleID === selectedSchedule!.priceScheduleID)!;
                    scheduleCopy.gridAdderIncrement = increment;
                    scheduleCopy.gridAdderPrice = price;
                    setSchedules(scheduleListCopy);
                    setSelectedSchedule(scheduleCopy);
                });
            }
            setDisplayAdderPrice(cf.Format(price));
            setAdderPriceErrorText("");
        }
    }, [tm, cf, lnf, adderIncrementErrorText, displayAdderIncrement, selectedSchedule, schedules]);

    const handleAdderIncrementChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setDisplayAdderIncrement(e.target.value);
    }, []);

    const handleAdderIncrementBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
        let increment: number;
        if (e.target.value.trim() === "") {
            increment = 0;
        } else {
            increment = lnf.Parse(e.target.value.trim());
        }
        if (isNaN(increment) || increment < Constants.Min.UnitInches || increment > Constants.Max.UnitInches) {
            setAdderIncrementErrorText(tm.GetWithParams("{0} must be between {1} and {2}.", tm.Get("UI"), Constants.Min.UnitInches.toString(), Constants.Max.UnitInches.toString()));
        } else {
            if (adderPriceErrorText === "") {
                let price = cf.Parse(displayAdderPrice);
                SetupAPI.SetPriceScheduleAdders(selectedSchedule!.priceScheduleID, price, increment).then(() => {
                    let scheduleListCopy = schedules.slice();
                    let scheduleCopy = schedules.find((s) => s.priceScheduleID === selectedSchedule!.priceScheduleID)!;
                    scheduleCopy.gridAdderIncrement = increment;
                    scheduleCopy.gridAdderPrice = price;
                    setSchedules(scheduleListCopy);
                    setSelectedSchedule(scheduleCopy);
                });
            }
            setDisplayAdderIncrement(lnf.Format(increment));
            setAdderIncrementErrorText("");
        }
    }, [tm, cf, lnf, adderPriceErrorText, displayAdderPrice, selectedSchedule, schedules]);

    return <>
        <Container maxWidth="xl">
            <Box p={1} gap={1} mt={1}>
                <Stack direction="column" spacing={3} justifyItems="stretch">
                    <PricingSchedulesControls
                        schedules={schedules}
                        selectedSchedule={selectedSchedule}
                        handleScheduleChange={setSelectedSchedule}
                        addHandler={scheduleAddHandler}
                        editHandler={scheduleEditHandler}
                        deleteHandler={scheduleDeleteHandler}
                    />

                    {selectedSchedule && <>
                        <PriceScheduleItemsGrid priceScheduleID={selectedSchedule.priceScheduleID} items={scheduleItems} setItems={setScheduleItems} cf={cf} lnf={lnf} />

                        <Stack direction={isMobile ? "column" : "row"} spacing={1} width={1} justifyContent="center" alignItems="center">
                            <Typography>{tm.Get("Add an additional")}</Typography>
                            <TextField 
                                value={displayAdderPrice} 
                                size="small"
                                variant={isMobile ? "outlined" : "standard"}
                                onChange={handleAdderPriceChange}
                                onBlur={handleAdderPriceBlur}
                                InputProps={{
                                    endAdornment: adderPriceErrorText !== "" ? 
                                        <ErrorAdornment validationError={adderPriceErrorText}/> : null,
                                    inputProps: {style: { textAlign: "center" }}
                                }}
                            />
                            <Typography>{tm.Get("for every")}</Typography>
                            <TextField 
                                value={displayAdderIncrement} 
                                size="small"
                                variant={isMobile ? "outlined" : "standard"}
                                onChange={handleAdderIncrementChange}
                                onBlur={handleAdderIncrementBlur}
                                InputProps={{
                                    endAdornment: adderIncrementErrorText !== "" ? 
                                        <ErrorAdornment validationError={adderIncrementErrorText}/> : null,
                                    inputProps: {style: { textAlign: "center" }}
                                }}
                            />
                            <Typography>{tm.Get("UI beyond the maximum.")}</Typography>
                        </Stack>
                    </>}
                </Stack>
            </Box>
        </Container>
    </>
}

export default PriceSchedules;