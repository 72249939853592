import { Theme } from "@mui/material";
import { GridColumns } from "@mui/x-data-grid-pro";
import DataGridColumnGenerator from "components/Common/DataGridColumnGenerator";
import { ITranslationManager } from "helpers/hooks/useTranslations";
import { IDashboardRMA } from "helpers/interfaces";
import ApiUser from "models/ApiUser";

export const generateRMAColumns = (tm: ITranslationManager, data: IDashboardRMA[] | undefined, theme: Theme, isMobile: boolean, user: ApiUser) => {

  if (!data || data.length === 0) return [] as GridColumns;

  const generator = new DataGridColumnGenerator(tm, data, theme, isMobile);

  generator.TryAddTextColumn("rmaNumber", "RMA", { hideable: false });
  generator.TryAddDateColumn("date", "Date", { hideInMobile: true });
  generator.TryAddTextColumn("customerRef", "Customer Ref", { hideInMobile: true });
  generator.TryAddDateColumn("expirationDate", "Expires", { hideInMobile: false });
  generator.TryAddTextColumnWithTooltip("statusBasic", "Status", { tooltip: "{0}", tooltipParams: ["statusAdvanced"] }, { hideInMobile: false });
  if (user.isMultiSite) {
    generator.TryAddTextColumn("mfgSiteName", "Mfg Customer", { hideInMobile: true });
  }

  return generator.GetColumns();
}
