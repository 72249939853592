import React, { useState, useMemo } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Box } from "@mui/material";

import ListOptionValueInput from "components/GlobalOptions/Results/EditOptionValueDialog/ListOptionValueInput";
import TextOptionValueInput from "components/GlobalOptions/Results/EditOptionValueDialog/TextOptionValueInput";

import { IGlobalItem } from "helpers/interfaces";
import useTranslations from "helpers/hooks/useTranslations";
import OKCancelControl from "components/Common/OKCancelControl";

interface IProps {
    isOpen: boolean,
    onClose: () => void,
    globalItem: IGlobalItem,
    onCommit: (newValue: string) => void,
}

const EditOptionValueDialog: React.FC<any> = ({ isOpen, onClose, globalItem, onCommit }: IProps) => {

    const tm = useTranslations();

    const defaultValue = useMemo(() => {
        if (globalItem.changeInfo.newValueDescription) {
            return globalItem.changeInfo.newValueDescription;
        }
        return globalItem.changeInfo.newValue;
    }, [globalItem]);

    const [newValue, setNewValue] = useState<string>(defaultValue);

    const isTextInput = useMemo(() => {
        if (globalItem.selectItems?.length > 1) {
            return false;
        }
        return true;
    }, [globalItem]);

    return <>
        <Dialog
            open={isOpen}
            onClose={onClose}
        >
            <DialogTitle>{tm.Get("Edit")}</DialogTitle>
            <DialogContent>
                <Box mt={1}>
                    {isTextInput ?
                        <TextOptionValueInput newValue={newValue} setNewValue={setNewValue} />
                        :
                        <ListOptionValueInput globalItem={globalItem} newValue={newValue} setNewValue={setNewValue} />
                    }
                </Box>
            </DialogContent>
            <DialogActions>
                <OKCancelControl okCommand={() => onCommit(newValue)} cancelCommand={onClose} />
            </DialogActions>
        </Dialog>
    </>;
};

export default EditOptionValueDialog;
