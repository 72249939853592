import * as React from "react";

import ItemInfoContainer from "../MainWizard/ItemInfoContainer";

import { IOpeningDesignerOpeningSubLineItemInfo, IOpeningDesignerSectionSubLineItemInfo, ISectionRegion, ISubLineItemInfo } from "../interfaces";
import { SubLineItemTypeEnum } from "../enum";

import MultiSelectCheckboxes from "./MultiSelectCheckboxes";
import useTranslations from "helpers/hooks/useTranslations";
import { WizardModeEnum } from "components/OptionsWizard/WizardContext";
import useWizardState from "components/OptionsWizard/WebDesigner/useWizardState";
import useFormatHelper from "helpers/hooks/useFormatHelper";
import { Box, Button, Grid, Stack } from "@mui/material";
import useIsMobile from "helpers/hooks/useIsMobile";
import useMessageBox from "helpers/context/Page/useMessageBox";
import useWait from "helpers/context/Page/useWait";
import useWizardInteractions from "../useWizardInteractions";
import { ThemeColorEnum } from "helpers/enums";



const MultiSelectMain: React.FC<any> = () => {

    const wait = useWait();
    const tm = useTranslations();
    const format = useFormatHelper();
    const wizardState = useWizardState();
    const wizardInteractions = useWizardInteractions();
    const isMobile = useIsMobile();
    const messageBox = useMessageBox();

    const [selectedSLIs, setSelectedSLIs] = React.useState<ISubLineItemInfo[]>([]);

    React.useEffect(() => {
        wait.Show(wizardState.loading);
    }, [wait, wizardState.loading]);


    const clickRegionHandler = (e: ISectionRegion) => {
        if (wizardState.webDesigner) {
            let index: number = wizardState.webDesigner.sectionList && wizardState.webDesigner.sectionList.findIndex((slinfo: ISubLineItemInfo) => {
                return slinfo.sli === e.id;
            });
            handleSelectionOfSubLineItem(wizardState.webDesigner.sectionList[index]);
        }
    }


    const handleSelectionOfSubLineItem = (itemClicked: ISubLineItemInfo) => {

        itemClicked.isSelected = !itemClicked.isSelected;

        if (itemClicked.isSelected) {
            clearAllSelectionsExceptType(itemClicked.subLineItemType);
        }

        setSelectedSLIs(wizardState.webDesigner?.sectionList.filter(f => f.isSelected) ?? []);

    }

    const clearAllSelectionsExceptType = (typeToKeep: SubLineItemTypeEnum) => {
        if (wizardState.webDesigner) {
            for (var i = 0; i < wizardState.webDesigner.sectionList.length; i++) {
                if (wizardState.webDesigner.sectionList[i].subLineItemType !== typeToKeep) {
                    wizardState.webDesigner.sectionList[i].isSelected = false;
                }
            }
        }
    }

    const getSimpleNumericArrayOfSelections = () => {
        let simpleArray: Array<number> = new Array<number>();
        if (wizardState.webDesigner) {

            for (var i = 0; i < wizardState.webDesigner.sectionList.length; i++) {
                if (wizardState.webDesigner.sectionList[i].isSelected) {
                    simpleArray.push(wizardState.webDesigner.sectionList[i].sli);
                }
            }

        }
        return simpleArray;

    }

    const partButtonClickHandler = (e: React.MouseEvent<any>) => {
        navigateAway(true);
    }

    const optionsButtonClickHandler = (e: React.MouseEvent<any>) => {
        navigateAway(false);
    }

    const navigateAway = async (doParts: boolean) => {
        if (wizardState.itemInfo) {

            try {
                wait.Show(true);

                const results = await wizardInteractions.SelectPartOrOptionsInMultiSelect(doParts, getSimpleNumericArrayOfSelections());

                if (!results) {
                    messageBox.Show({ message: tm.Get("Could not perform action for current selections.") });
                }

            } finally {
                wait.Show(false);
            }

        }
    }


    const partButtonEnabled = React.useMemo<boolean>(() => {
        if (selectedSLIs.length === 0)
            return false;

        if (wizardState.webDesigner) {
            if ((wizardState.webDesigner.sectionList[0].designerProperties as IOpeningDesignerOpeningSubLineItemInfo).templateID !== -1) {
                let sectionCategoryID = (selectedSLIs[0].designerProperties as IOpeningDesignerSectionSubLineItemInfo).categoryID;
                if (sectionCategoryID === 0) {
                    return false;
                }

                return selectedSLIs.every(f => {
                    let designerProperties = f.designerProperties as IOpeningDesignerSectionSubLineItemInfo;
                    return designerProperties.categoryID === sectionCategoryID && designerProperties.uniqueTemplateID <= 0;
                });
            } else {
                return selectedSLIs.every(f => {
                    let designerProperties = f.designerProperties as IOpeningDesignerSectionSubLineItemInfo;
                    return designerProperties.uniqueTemplateID <= 0;
                });
            }

        } else {
            // Should never reach this
            return false;
        }
    }, [selectedSLIs, wizardState.webDesigner]);

    const optionsButtonEnabled = React.useMemo<boolean>(() => {

        if (selectedSLIs.length === 0 || (selectedSLIs[0].partNo ?? "") === "") {
            return false;
        } else {
            return selectedSLIs.every(f => f.partNo === selectedSLIs[0].partNo && f.partNoSuffix === selectedSLIs[0].partNoSuffix);
        }

    }, [selectedSLIs]);


    return <Grid container direction={isMobile ? "column" : "row"} width="100%">

        {/*Left Pane*/}
        {wizardState.webDesigner && wizardState.webDesigner.sectionList &&
            <Grid item xs={isMobile ? 1 : 8}>
                <Stack direction="column" spacing={1} mt={1} mx={1}>
                    <MultiSelectCheckboxes sectionList={wizardState.webDesigner.sectionList} itemClickedHandler={handleSelectionOfSubLineItem} />

                    <Stack direction="row" justifyContent={isMobile ? "center" : undefined} m={1} spacing={1} >
                        <Button
                            variant="contained"
                            color={ThemeColorEnum.Secondary}
                            disabled={!partButtonEnabled}
                            onClick={partButtonClickHandler}
                            >
                            {tm.Get("Part")}
                        </Button>

                        <Button
                            variant="contained"
                            color={ThemeColorEnum.Secondary}
                            disabled={!optionsButtonEnabled}
                            onClick={optionsButtonClickHandler}
                            >
                            {tm.Get("Options")}
                        </Button>

                    </Stack>
                </Stack>
            </Grid>
        }

        {/*Right Pane*/}
        <Grid item xs>
            <Box width={isMobile ? 1 / 2 : 1} m={"0 auto"}>
                <ItemInfoContainer
                    options={null}
                    partPreferences={wizardState.partPreferences}
                    validations={null}
                    itemInfo={wizardState.itemInfo}
                    webDesigner={wizardState.webDesigner}
                    displayViewSettings={wizardState.displayViewSettings}
                    focusedCode={null}
                    prices={null}
                    altWizInfo={null}
                    clickRegionHandler={clickRegionHandler}
                    mode={WizardModeEnum.MultiSelect}
                    format={(format)}
                />
            </Box>
        </Grid>

    </Grid>;

}

export default MultiSelectMain;
