import React, { useCallback, useEffect, useState } from "react";
import { TransitionGroup } from 'react-transition-group';
import { Container, Grid, Stack, TextField, MenuItem, Box } from "@mui/material";
import Collapse from '@mui/material/Collapse';

import { IProductNavigatorNode } from "helpers/interfaces";
import useTranslations from "helpers/hooks/useTranslations";
import useIsMobile from "helpers/hooks/useIsMobile";
import useWait from "helpers/context/Page/useWait";
import { IPartKey } from "helpers/interfaces";
import Constants from "helpers/constants";
import ProductNavigatorNodeDisplay from "./ProductNavigatorNodeDisplay";
import ProductNavigatorAPI from "components/ProductNavigator/ProductNavigatorAPI";
import ProductNavigatorBreadCrumbs from "components/ProductNavigator/ProductNavigatorBreadCrumbs"

interface IProps {
    oKey: number;
    initialFinalNodeID: string;
    initialCallSize: string;
    partList: IPartKey[] | null;
    onCancel: () => void;
    onPartSelected: (partKey: IPartKey) => void;
    onFinalNodeChange?: (newNodeID: string | undefined) => void;
    onCallSizeChange?: (newCallSize: string) => void;
}

const ProductNavigator = ({oKey, initialFinalNodeID, initialCallSize, partList, onCancel, onPartSelected, onFinalNodeChange, onCallSizeChange} : IProps) => {
    const tm = useTranslations();
    const wait = useWait();
    const isMobile = useIsMobile();

    const [nodeHierarchy, setNodeHierarchy] = useState<IProductNavigatorNode[] | null>(null);
    const [displayHierarchy, setDisplayHierarchy] = useState<IProductNavigatorNode[] | null>(null);
    const [callSizeList, setCallSizeList] = useState<string[]>([]);
    const [callSize, setCallSize] = useState<string>("");
    const [nodeMap, setNodeMap] = useState<Map<string, IProductNavigatorNode>>(new Map());
    const [finalNode, setFinalNode] = useState<IProductNavigatorNode | null>(null);
    const [displayNodes, setDisplayNodes] = useState<IProductNavigatorNode[]>([]);

    const updateDisplayWithCallSize = useCallback((cs: string) => {
        wait.Show(true);
        ProductNavigatorAPI.ProductNavigator(Number(oKey), false, cs, partList).then((pn) => {
            setDisplayHierarchy(pn.nodeHierarchy);
            wait.Show(false);
        })
    }, [oKey, partList, wait])

    const changeCallSize = useCallback((callSize: string) => {
        setCallSize(callSize);
        updateDisplayWithCallSize(callSize);
        if (onCallSizeChange) {
            onCallSizeChange(callSize);
        }
    }, [updateDisplayWithCallSize, onCallSizeChange]);

    const changeFinalNode = useCallback((node: IProductNavigatorNode | null) => {
        setFinalNode(node);
        if (onFinalNodeChange) {
            onFinalNodeChange(node?.nodeID);
        }
    }, [onFinalNodeChange]);

    const buildNodeMap = useCallback((nh: IProductNavigatorNode[]) => {
        var map = new Map<string, IProductNavigatorNode>();
        nh.forEach((n) => {
            map.set(n.nodeID, n);
        })
        setNodeMap(map);
        var finalNodeToStart = map.get(initialFinalNodeID)
        if (finalNodeToStart) {
            changeFinalNode(finalNodeToStart);
        }
    }, [initialFinalNodeID, changeFinalNode]);

    const handleCallSizeChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        changeCallSize(e.target.value);
    }, [changeCallSize]);

    useEffect(() => {
        if (oKey !== null && nodeHierarchy === null) {
            wait.Show(true);
            ProductNavigatorAPI.ProductNavigator(Number(oKey), true, "", partList).then((pn) => {
                setNodeHierarchy(pn.nodeHierarchy);
                changeCallSize(initialCallSize);
                setCallSizeList(pn.callSizes);
            })
        }
    }, [oKey, initialCallSize, partList, nodeHierarchy, wait, changeCallSize]);

    useEffect(() => {
        if (nodeHierarchy) {
            buildNodeMap(nodeHierarchy);
        }
    }, [nodeHierarchy, buildNodeMap])

    useEffect(() => {
        let d = Array<IProductNavigatorNode>();
        if (displayHierarchy) {
            displayHierarchy.forEach((n) => {
                if (n.parentNodeID === (finalNode?.nodeID ?? "")) {
                    d.push(n);
                }
            })
        }
        var same = d.length === displayNodes.length;
        if (same) {
            d.forEach((d, i) => {same = (same && d.nodeID === displayNodes[i].nodeID)})
        }
        if (!same) {
            setDisplayNodes([]);
            const timer = setTimeout(() => {
                setDisplayNodes(d);
            }, Constants.TransitionDelays.Collapse);
            return (() => clearTimeout(timer));
        }

    }, [finalNode, displayHierarchy]);

    return <> 
        <Container maxWidth="xl">
            <Box p={1} gap={1}>
                <Grid container direction="column" justifyItems="stretch">
                    <Grid item>
                        <Stack direction="row" justifyContent="space-between">
                            <ProductNavigatorBreadCrumbs partList={partList} nodeMap={nodeMap} finalNode={finalNode} onCancel={onCancel} setFinalNode={changeFinalNode}/>
                            <TextField  size="small" style={{minWidth: 125, maxWidth: 125, marginLeft: 3}} label={tm.Get("Call Size")} value={callSize} onChange={handleCallSizeChange} select>
                                {callSizeList.map((cs) => {
                                    return <MenuItem key={cs} value={cs}>{cs === "" ? "{" + tm.Get("All") + "}" : cs}</MenuItem>
                                })}
                            </TextField>
                        </Stack>
                    </Grid>
                </Grid>
                <TransitionGroup style={isMobile ? {display: "flex", flexWrap: "wrap", justifyContent:"center"} : {}}>
                    {displayNodes.map((n) => {
                        return <Collapse key={n.nodeID} style={{display: "inline-block"}}>
                            <ProductNavigatorNodeDisplay node={n} setFinalNode={changeFinalNode} onPartSelected={onPartSelected} />
                        </Collapse>
                    })}
                </TransitionGroup>
            </Box>
        </Container>
    </>
}

export default ProductNavigator;