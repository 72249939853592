import React from "react";
import { TranslationContext } from "helpers/context/Translations/TranslationContext";
import { IBaseContent } from "models/IBaseContent";

export interface ITranslationManager {
    Get: (content: string | IBaseContent) => string;
    GetWithParams: (content: string, ...params: string[]) => string;
}


export default function useTranslations(): ITranslationManager {

    const tmContext = React.useContext(TranslationContext);

    return tmContext.TM;

};



