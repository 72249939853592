import { Container } from '@mui/material';
import useWindowTitle from "helpers/context/Title/useWindowTitle";
import useTranslations from 'helpers/hooks/useTranslations';
import useDefaultActionButtons from 'helpers/context/Page/useDefaultActionButtons';
import DashboardOrders from './Orders/DashboardOrders';
import DashboardQuotes from './Quotes/DashboardQuotes';
import useApplicationInfo from 'helpers/context/Application/useApplicationInfo';
import SubmittedQuotesGrid from './Quotes/SubmittedQuotesGrid';
import useUserInfo from 'helpers/context/User/useUserInfo';
import { RoleEnum } from 'helpers/enums';
import AdRotator from './Advertisements/AdRotator';
import { useNavigate } from 'react-router-dom';
import { useEffectOnLoad } from "helpers/hooks/useEffectOnLoad";


function Dashboard(props: any) {

    const tm = useTranslations();
    const appInfo = useApplicationInfo();
    const user = useUserInfo();
    const navigate = useNavigate();

    const contractor = user.HasRole(RoleEnum.Contractor);
    const dealer = (user.HasRole(RoleEnum.Dealer));
    const csr = (user.HasRole(RoleEnum.CSR));

    useWindowTitle(tm.Get("Dashboard"));
    useDefaultActionButtons();

    useEffectOnLoad(() => {
        if (csr) {
            navigate("/maintenance", { replace: true });
        }
    })

    if (csr) {
        return <></>;
    }

    return <>
        <Container maxWidth="xl" >

            {appInfo.parameters.dashboardQuotesFirst && <>
                <DashboardQuotes />
                {contractor && <SubmittedQuotesGrid />}
                {dealer && <DashboardOrders />}
            </>
            }

            {!appInfo.parameters.dashboardQuotesFirst && <>
                {contractor && <SubmittedQuotesGrid />}
                {dealer && <DashboardOrders />}
                <DashboardQuotes />
            </>
            }

            {dealer && <AdRotator />}

        </Container>
    </>;

};

export default Dashboard;
