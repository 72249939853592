import React from "react";
import useTranslations, { ITranslationManager } from "../../helpers/hooks/useTranslations";
import { GridActionsCellItem, GridColumnHeaderParams, GridEnrichedColDef, GridRowParams, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { Delete, Edit } from "@mui/icons-material";
import AlertDialog from "../../components/Common/AlertDialog";
import { Link, Theme, Tooltip } from "@mui/material";
import WarningIcon from '@mui/icons-material/Warning';
import { Link as RouterLink } from "react-router-dom";
import { IShowMessageBox } from "helpers/context/Page/useMessageBox";

type ActionHandler = (employeeID: string) => void;

interface IEditCommandProps {
    params: GridRowParams;
    editActionHandler: ActionHandler;
}

interface IDeleteCommandProps {
    params: GridRowParams;
    deleteActionHandler: ActionHandler;
}

export const userNameColumn = (field: string, title: string, tm: ITranslationManager, isMobile: boolean, theme: Theme, messageBox: IShowMessageBox) => {

    const col = {
        field: field,
        headerName: tm.Get(title),
        mindWidth: isMobile ? 90 : 130,
        hideable: false,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
            if (!params || !params.value) return <></>;
            const url = `view/${encodeURIComponent(params.row.employeeID)}`;

            if (params.row.displayInactive) {
                return <>
                    <Link variant="body2" underline="hover" component={RouterLink} to={url} sx={{ color: "error.main", marginRight: 1 }}>
                        {params.row.userName}
                    </Link>
                    <Tooltip title={tm.Get("Inactive")}>
                        <WarningIcon style={{ color: theme.palette.error.main }} fontSize="small" onClick={() => {
                            if (isMobile)
                                messageBox.Show({
                                    title: params.row.userName,
                                    message: tm.Get("User Inactive")
                                });
                        }} />
                    </Tooltip>
                </>
            }

            return <>
                <Link variant="body2" underline="hover" component={RouterLink} to={url}>
                    {params.row.userName}
                </Link>
            </>
        }
    }
    return col;
}

export const userCommandsColumn = (editHandler: ActionHandler | null, deleteHandler: ActionHandler | null, isDealerCreatingAdminContractor: boolean, isContractor: boolean) => {

    const minWidth = 92;

    const EditCommand: React.FC<IEditCommandProps> = ({ params, editActionHandler }: IEditCommandProps) => {
        const tm = useTranslations();
        const employeeID: string = params.row.employeeID as string;

        const handlerShowDialog = React.useCallback(() => {
            editActionHandler(employeeID);
        }, [editActionHandler, employeeID]);

        return <>
            <GridActionsCellItem
                icon={<Edit />}
                color="primary"
                label={tm.GetWithParams("Edit {0}", tm.Get("User"))}
                onClick={handlerShowDialog}
            />
        </>
    };

    const DeleteCommand: React.FC<IDeleteCommandProps> = ({ params, deleteActionHandler }: IDeleteCommandProps) => {

        const tm = useTranslations();
        const [open, setOpen] = React.useState<boolean>(false);

        const employeeID: string = params.row.employeeID as string;

        const handlerShowDialog = React.useCallback(() => {
            setOpen(true);
        }, [setOpen]);

        const handleDialogClose = React.useCallback((result: boolean) => {
            if (result) {
                deleteActionHandler(employeeID);
            }
            setOpen(false);
        }, [deleteActionHandler, employeeID])

        return <>
            <GridActionsCellItem
                icon={<Delete />}
                color="primary"
                label={tm.Get("Delete User")}
                onClick={handlerShowDialog}
            />
            <AlertDialog open={open}
                handleClose={handleDialogClose}
                headerText={tm.Get("Delete")}
                message={tm.Get("Are you sure you want to delete this user?")}
            />
        </>
    }

    const col = {
        field: "Commands",
        type: "actions",
        renderHeader: (params: GridColumnHeaderParams) => {
            return <div style={{ opacity: 0 }}>
                Commands
            </div>
        },
        minWidth: minWidth,
        filterable: false,
        sortable: false,
        getActions: (params: GridRowParams) => {

            const actions: JSX.Element[] = [];
            const deleteDisabled: boolean = isContractor ? params.row.mfgCreatedEmployee : (params.row.mfgCreatedEmployee && !isDealerCreatingAdminContractor)

            if (editHandler) actions.push(<EditCommand params={params} editActionHandler={editHandler} />);
            if (deleteHandler && !deleteDisabled) actions.push(<DeleteCommand params={params} deleteActionHandler={deleteHandler} />);

            return actions;
        },
    } as GridEnrichedColDef;

    return col;
}
