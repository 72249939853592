import { Button } from "@mui/material";

import { ChangeButtonStateEnum } from "models/GlobalOptions/IGlobalOptionsSelections";
import useTranslations from "helpers/hooks/useTranslations";
import useGlobalOptions from "components/GlobalOptions/useGlobalOptions";
import { useMemo } from "react";
import { ThemeColorEnum } from "helpers/enums";

const CommitSelections: React.FC<any> = () => {

    const tm = useTranslations();
    const { selections, onCommitSelections } = useGlobalOptions();

    const buttonText = useMemo(() => {
        switch (selections.changeButtonState) {
            case ChangeButtonStateEnum.AddOption:
                if (selections.selectedQuestion) {
                    return tm.Get("Add/Change")
                }
                else {
                    return tm.Get("Add");
                }
            case ChangeButtonStateEnum.ChangeOption:
                return tm.Get("Change");
            case ChangeButtonStateEnum.RemoveOption:
                return tm.Get("Remove");
        }

    }, [tm, selections])

    const isDisabled = useMemo(() => {
        return !selections.isValid;
    }, [selections]);

    return <>
        <Button
            fullWidth
            color={ThemeColorEnum.Secondary}
            variant="contained"
            disabled={isDisabled}
            onClick={onCommitSelections}
        >
            {buttonText}
        </Button>
    </>;
};


export default CommitSelections;
