import { useMemo, useState } from "react"
import { Tooltip, Chip } from "@mui/material";
import { Lock, LockOpen, MoreHoriz } from "@mui/icons-material";

import useUserInfo from "helpers/context/User/useUserInfo";
import useTranslations from "helpers/hooks/useTranslations";
import { RoleEnum } from "helpers/enums";
import useMessageBox from "helpers/context/Page/useMessageBox";

import { ILockedObject } from "helpers/interfaces";

interface IProps {
    lock?: ILockedObject, //todo: remove
    onAcquireLock?: () => void,
}

function QuoteLockChip({ lock, onAcquireLock }: IProps) {

    const user = useUserInfo();
    const tm = useTranslations();
    const messageBox = useMessageBox();

    const [lockHovering, setLockHovering] = useState<boolean>(false);

    const lockVisible = useMemo(() => {
        return lock?.isLockedByAnotherUser ?? false;
    }, [lock]);

    const acquireLockVisible = useMemo(() => {
        return lock?.isLockedByAnotherUser && user.HasRole(RoleEnum.CSR);
    }, [lock, user]);

    const lockedByText = useMemo(() => {
        if (lock) {
            return tm.GetWithParams("The {0} is in use by {1}.", tm.Get("quote"), lock.lockedBy);
        }
        return "";
    }, [lock, tm])

    const handleAcquireLockClick = () => {
        messageBox.Show({ message: tm.Get("Are you sure?"), title: tm.Get("Acquire Quote Lock"), yesNoPrompt: true, callback: handleAcquireLockCallback });
    }

    const handleAcquireLockCallback = (dialogResult?: boolean) => {
        if (onAcquireLock && dialogResult === true) {
            onAcquireLock();
        }
    }

    return <>
        {lockVisible &&
            <>
                {acquireLockVisible ?
                    <Tooltip title={tm.Get("Acquire Quote Lock")}>
                        <Chip
                            onMouseEnter={() => setLockHovering(true)}
                            onMouseLeave={() => setLockHovering(false)}
                            color="warning"
                            icon={lockHovering ? <LockOpen /> : <Lock />}
                            label={lockedByText}
                            onClick={handleAcquireLockClick}
                            onDelete={handleAcquireLockClick}
                            deleteIcon={ <MoreHoriz /> }
                        />
                    </Tooltip>
                    :
                    <Chip
                        color="warning"
                        icon={<Lock />}
                        label={lockedByText}
                    />
                }
            </>
        }
    </>

}


export default QuoteLockChip;