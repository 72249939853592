import { MenuItem, Select } from "@mui/material";
import { MaintenanceFilterEnum } from "helpers/enums";
import useTranslations from "helpers/hooks/useTranslations";
import { GetEnumAsListOfKeyValuePairs } from "helpers/objects";
import React from 'react';

interface IProps {
    currentFilter: MaintenanceFilterEnum,
    setFilter: (update: MaintenanceFilterEnum) => void
}

const MaintenanceFilter: React.FC<IProps> = (props: IProps) => {

    const tm = useTranslations();

    return <>
        <Select
            value={props.currentFilter}
            onChange={(e) => props.setFilter(e.target.value as MaintenanceFilterEnum)}
            sx={{ minWidth: "102px" }}
        >
            {GetEnumAsListOfKeyValuePairs(Object.values(MaintenanceFilterEnum)).map((maintenanceFilter) => {
                return <MenuItem key={maintenanceFilter.key} value={maintenanceFilter.value}>{tm.Get(maintenanceFilter.key)}</MenuItem>
            })}
        </Select>

    </>;
}

export default MaintenanceFilter;