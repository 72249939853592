import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Typography, Container, Box, Grid, useTheme } from "@mui/material";
import { GridCallbackDetails, GridRowIdGetter, GridRowModel } from "@mui/x-data-grid-pro";
import { DateTime } from "luxon";

import useIsMobile from "helpers/hooks/useIsMobile";
import useTranslations from "helpers/hooks/useTranslations";
import { generatePurchaseHistoryColumns } from "./PurchaseHistoryColumns";
import HistoryAPI from "./HistoryAPI";
import useWindowTitle from "helpers/context/Title/useWindowTitle";
import useApplicationInfo from "helpers/context/Application/useApplicationInfo";
import { IPurcharseHistoryCustomer, IPurcharseHistoryOption, IPurcharseHistoryOrder, IPurcharseHistoryPart } from "models/IPurchaseHistory";
import useWait from "helpers/context/Page/useWait";
import useCurrencyFormatter from "helpers/hooks/useCurrencyFormatter";
import { MeasurementSystemEnum } from "helpers/fv.format";
import PurchaseHistoryFooter from "./PurchaseHistoryFooter";
import PurchaseHistoryControls from "./PurchaseHistoryControls";
import useUserInfo from "helpers/context/User/useUserInfo";
import useLocaleNumberFormatter from "helpers/hooks/useLocaleNumberFormatter";
import useRowsPerPage from "helpers/hooks/useRowsPerPage";
import CustomDataGridPro from "components/Common/CustomDataGridPro";

export enum PurchaseHistoryGridMode {
    Customers = 0,
    Orders,
    Parts,
    Options
}

const PurchaseHistory = () => {

    const isMobile = useIsMobile();
    const theme = useTheme();
    const tm = useTranslations();
    const wait = useWait();
    const appInfo = useApplicationInfo();
    const userInfo = useUserInfo();
    const rowsPerPage = useRowsPerPage("PurchaseHistory");
    const cf = useCurrencyFormatter(userInfo.locationCurrencyCulture);
    const lnf = useLocaleNumberFormatter({style: "decimal", useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2})
    const isMetric = appInfo.engineeringUnits.systemUnitSet === MeasurementSystemEnum.Metric;
    const showSquareFeet = appInfo.parameters.showSquareFeet;

    const today = DateTime.now();
    const lastMonth = today.minus({ months: 1 });

    const [startDate, setStartDate] = useState<DateTime>(lastMonth);
    const [endDate, setEndDate] = useState<DateTime>(today);
    const [submittedStartDate, setSubmittedStartDate] = useState<DateTime>(lastMonth);
    const [submittedEndDate, setSubmittedEndDate] = useState<DateTime>(today);
    const [gridMode, setGridMode] = useState<PurchaseHistoryGridMode>(0);
    const [data, setData] = useState<IPurcharseHistoryCustomer[] | IPurcharseHistoryOrder[] | IPurcharseHistoryPart[] | IPurcharseHistoryOption[]>([]);

    const [startDateError, setStartDateError] = useState<boolean>(false);
    const [endDateError, setEndDateError] = useState<boolean>(false);
    const [submittedOnce, setSubmittedOnce] = useState<boolean>(false);

    const [currentCustomerID, setCurrentCustomerID] = useState<string>("");
    const [currentSiteID, setCurrentSiteID] = useState<string>("");
    const [currentCustomerName, setCurrentCustomerName] = useState<string>("");
    const [redirectedFromCustomers, setRedirectedFromCustomers] = useState<boolean>(false);

    const [currentPartNo, setCurrentPartNo] = useState<string>("");
    const [currentPartNoSuffix, setCurrentPartNoSuffix] = useState<string>("");

    const [currentOptionCode, setCurrentOptionCode] = useState<string>("");
    const [redirectedFromOptions, setRedirectedFromOptions] = useState<boolean>(false);

    useWindowTitle(tm.Get("Purchase History"));

    useEffect(() => {
        if (!startDate || !endDate || !startDate.isValid || startDate.year < 1753 || startDate.year > 9999 || startDate.startOf("day") > endDate.startOf("day")) {
            setStartDateError(true);
        } else {
            setStartDateError(false);
        }
        if (!startDate || !endDate || !endDate.isValid || endDate.year < 1753 || endDate.year > 9999 || startDate.startOf("day") > endDate.startOf("day")) {
            setEndDateError(true);
        } else {
            setEndDateError(false);
        }
    }, [startDate, endDate]);

    const resultsString = useMemo(() => {
        let subject: string;
        switch (gridMode) {
            case PurchaseHistoryGridMode.Customers:
                subject = "customer";
                break;
            case PurchaseHistoryGridMode.Orders:
                subject = "order";
                break;
            case PurchaseHistoryGridMode.Parts:
                subject = "part";
                break;
            case PurchaseHistoryGridMode.Options:
                subject = "option";
                break;
        }
        if (data.length !== 1) {
            subject += "s";
        }
        if (redirectedFromOptions) {
            return tm.GetWithParams(`{0} ${subject} found between {1} and {2} with option code {3}.`, data.length.toString(), submittedStartDate.toJSDate().toLocaleDateString(navigator.language), submittedEndDate.toJSDate().toLocaleDateString(navigator.language), currentOptionCode);
        } else if (redirectedFromCustomers) {
            return tm.GetWithParams(`{0} ${subject} found between {1} and {2} for customer {3}.`, data.length.toString(), submittedStartDate.toJSDate().toLocaleDateString(navigator.language), submittedEndDate.toJSDate().toLocaleDateString(navigator.language), currentCustomerName);
        } else {
            return tm.GetWithParams(`{0} ${subject} found between {1} and {2}.`, data.length.toString(), submittedStartDate.toJSDate().toLocaleDateString(navigator.language), submittedEndDate.toJSDate().toLocaleDateString(navigator.language));
        }
    }, [redirectedFromCustomers, redirectedFromOptions, currentCustomerName, currentOptionCode, submittedStartDate, submittedEndDate, gridMode, data, tm]);

    const shouldShowSqFt = useMemo(() => {
        return gridMode !== PurchaseHistoryGridMode.Options && !redirectedFromOptions && showSquareFeet;
    }, [gridMode, redirectedFromOptions, showSquareFeet]);
    
    const totalQty = useMemo(() => {
        let total: number = 0;
        data.forEach(d => total += d.qty);
        return total;
    }, [data]);
    
    const totalSqFt = useMemo(() => {
        if (gridMode === PurchaseHistoryGridMode.Options || redirectedFromOptions || !showSquareFeet) {
            return 0;
        } else {
            let total: number = 0;
            data.forEach(d => total += d.sqFeet);
            return total;
        }
    }, [gridMode, data, redirectedFromOptions, showSquareFeet]);
    
    const totalSales = useMemo(() => {
        let total: number = 0;
        data.forEach(d => total += d.sales);
        return total;
    }, [data]);

    const resetDrillDown = useCallback(() => {
        setCurrentCustomerID("");
        setCurrentSiteID("");
        setCurrentCustomerName("");
        setRedirectedFromCustomers(false);
        setCurrentPartNo("");
        setCurrentPartNoSuffix("");
        setCurrentOptionCode("");
        setRedirectedFromOptions(false);
    }, []);

    const handleCustomersClick = useCallback(() => {
        if (!startDateError && !endDateError) {
            wait.Show(true);
            HistoryAPI.QueryPurchaseHistoryCustomers(startDate, endDate).then(customers => {
                setSubmittedOnce(true);
                setSubmittedStartDate(startDate);
                setSubmittedEndDate(endDate);
                setGridMode(PurchaseHistoryGridMode.Customers);
                setData(customers);
                resetDrillDown();
                wait.Show(false);
            });
        }
    }, [startDate, endDate, startDateError, endDateError, wait, resetDrillDown]);

    const handleOrdersClick = useCallback(() => {
        if (!startDateError && !endDateError) {
            wait.Show(true);
            HistoryAPI.QueryPurchaseHistoryOrders(startDate, endDate).then(orders => {
                setSubmittedOnce(true);
                setSubmittedStartDate(startDate);
                setSubmittedEndDate(endDate);
                setGridMode(PurchaseHistoryGridMode.Orders);
                setData(orders);
                resetDrillDown();
                wait.Show(false);
            });
        }
    }, [startDate, endDate, startDateError, endDateError, wait, resetDrillDown]);

    const handlePartsClick = useCallback(() => {
        if (!startDateError && !endDateError) {
            wait.Show(true);
            HistoryAPI.QueryPurchaseHistoryParts(startDate, endDate).then(parts => {
                setSubmittedOnce(true);
                setSubmittedStartDate(startDate);
                setSubmittedEndDate(endDate);
                setGridMode(PurchaseHistoryGridMode.Parts);
                setData(parts);
                resetDrillDown();
                wait.Show(false);
            });
        }
    }, [startDate, endDate, startDateError, endDateError, wait, resetDrillDown]);

    const handleOptionsClick = useCallback(() => {
        if (!startDateError && !endDateError) {
            wait.Show(true);
            HistoryAPI.QueryPurchaseHistoryOptions(startDate, endDate).then(options => {
                setSubmittedOnce(true);
                setSubmittedStartDate(startDate);
                setSubmittedEndDate(endDate);
                setGridMode(PurchaseHistoryGridMode.Options);
                setData(options);
                resetDrillDown();
                wait.Show(false);
            });
        }
    }, [startDate, endDate, startDateError, endDateError, wait, resetDrillDown]);

    const handleCustomerLinkClick = useCallback((customerID: string, siteID: string, customerName: string, type: string) => {
        wait.Show(true);
        if (type === "part") {
            HistoryAPI.QueryPurchaseHistoryCustomerParts(submittedStartDate, submittedEndDate, customerID, siteID).then(parts => {
                setGridMode(PurchaseHistoryGridMode.Parts);
                setData(parts);
                setStartDate(submittedStartDate);
                setEndDate(submittedEndDate);
                setCurrentCustomerID(customerID);
                setCurrentSiteID(siteID);
                setCurrentCustomerName(customerName ?? ("{" + tm.Get("Orders Entered by Manufacturer") + "}"));
                setRedirectedFromCustomers(true);
                wait.Show(false);
            });
        } else if (type === "option") {
            HistoryAPI.QueryPurchaseHistoryCustomerOptions(submittedStartDate, submittedEndDate, customerID, siteID).then(options => {
                setGridMode(PurchaseHistoryGridMode.Options);
                setData(options);
                setStartDate(submittedStartDate);
                setEndDate(submittedEndDate);
                setCurrentCustomerID(customerID);
                setCurrentSiteID(siteID);
                setCurrentCustomerName(customerName ?? ("{" + tm.Get("Orders Entered by Manufacturer") + "}"));
                setRedirectedFromCustomers(true);
                wait.Show(false);
            });

        } else if (type === "order") {
            HistoryAPI.QueryPurchaseHistoryCustomerOrders(submittedStartDate, submittedEndDate, customerID, siteID).then(orders => {
                setGridMode(PurchaseHistoryGridMode.Orders);
                setData(orders);
                setStartDate(submittedStartDate);
                setEndDate(submittedEndDate);
                setCurrentCustomerID(customerID);
                setCurrentSiteID(siteID);
                setCurrentCustomerName(customerName ?? ("{" + tm.Get("Orders Entered by Manufacturer") + "}"));
                setRedirectedFromCustomers(true);
                wait.Show(false);
            });
        }
    }, [submittedStartDate, submittedEndDate, wait, tm]);

    const handlePartLinkClick = useCallback((partNo: string, partNoSuffix: string) => {
        wait.Show(true);
        if (redirectedFromCustomers) {
            HistoryAPI.QueryPurchaseHistoryCustomerOptions(submittedStartDate, submittedEndDate, currentCustomerID, currentSiteID, partNo, partNoSuffix).then(options => {
                setGridMode(PurchaseHistoryGridMode.Options);
                setData(options);
                setStartDate(submittedStartDate);
                setEndDate(submittedEndDate);
                setCurrentPartNo(partNo);
                setCurrentPartNoSuffix(partNoSuffix);
                wait.Show(false);
            });
        } else {
            HistoryAPI.QueryPurchaseHistoryOptions(submittedStartDate, submittedEndDate, partNo, partNoSuffix).then(options => {
                setGridMode(PurchaseHistoryGridMode.Options);
                setData(options);
                setStartDate(submittedStartDate);
                setEndDate(submittedEndDate);
                setCurrentPartNo(partNo);
                setCurrentPartNoSuffix(partNoSuffix);
                wait.Show(false);
            });
        }
    }, [submittedStartDate, submittedEndDate, redirectedFromCustomers, currentCustomerID, currentSiteID, wait]);

    const handleOptionLinkClick = useCallback((optionCode: string) => {
        wait.Show(true);
        HistoryAPI.QueryPurchaseHistoryOptionParts(submittedStartDate, submittedEndDate, currentPartNo, currentPartNoSuffix, optionCode).then(parts => {
            setGridMode(PurchaseHistoryGridMode.Parts);
            setData(parts);
            setStartDate(submittedStartDate);
            setEndDate(submittedEndDate);
            setCurrentOptionCode(optionCode);
            setRedirectedFromOptions(true);
            wait.Show(false);
        });
    }, [submittedStartDate, submittedEndDate, currentPartNo, currentPartNoSuffix, wait]);

    const getRowId: GridRowIdGetter = useCallback((row: GridRowModel) => {
        switch (gridMode) {
            case PurchaseHistoryGridMode.Customers:
                return row["customerID"] + row["siteID"];
            case PurchaseHistoryGridMode.Orders:
                return row["oKey"];
            case PurchaseHistoryGridMode.Parts:
                return row["partNo"] + row["partNoSuffix"];
            case PurchaseHistoryGridMode.Options:
                return row["optionCode"];
        }
    }, [gridMode]);
    const onPageSizeChange = (pageSize: number, details: GridCallbackDetails) => rowsPerPage.setPageSize(pageSize);

    return <>
        <Container maxWidth="xl">
            <Box p={1} gap={1} mt={1}>
                <Grid container direction="column" rowSpacing={1} justifyItems="stretch">
                    <Grid item>
                        <PurchaseHistoryControls
                            startDate={startDate}
                            endDate={endDate}
                            startDateError={startDateError}
                            endDateError={endDateError}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                            handleCustomersClick={handleCustomersClick}
                            handleOrdersClick={handleOrdersClick}
                            handlePartsClick={handlePartsClick}
                            handleOptionsClick={handleOptionsClick}
                        />
                    </Grid>

                    {submittedOnce && <>
                        <Grid item>
                            <Typography>{resultsString}</Typography>
                        </Grid>

                        <Grid item>
                            <CustomDataGridPro
                                onPageSizeChange={onPageSizeChange}
                                pageSize={rowsPerPage.pageSize}
                                rowsPerPageOptions={rowsPerPage.pageSizeOptions}
                                getRowId={getRowId}
                                columns={generatePurchaseHistoryColumns(tm, data, theme, isMobile, shouldShowSqFt, gridMode, !redirectedFromOptions, totalSales, cf, lnf, isMetric,
                                    handleCustomerLinkClick, handlePartLinkClick, handleOptionLinkClick)}
                                rows={data}
                            />
                        </Grid>

                        <Grid item>
                            <PurchaseHistoryFooter
                                shouldShowSqFt={shouldShowSqFt}
                                totalQty={totalQty}
                                totalSqFt={totalSqFt}
                                totalSales={totalSales}
                                lnf={lnf}
                            />
                        </Grid>
                    </>}
                            
                </Grid>
            </Box>
        </Container>
    </>
}

export default PurchaseHistory;