import { Typography, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import useQuoteData from "components/Quotes/useQuoteData";
import { useCallback } from "react";
import useTranslations from "helpers/hooks/useTranslations";
import useUserInfo from "helpers/context/User/useUserInfo";
import { RoleEnum } from "helpers/enums";

function RelatedQuoteLink() {

    const { quote } = useQuoteData();
    const tm = useTranslations();
    const user = useUserInfo();

    const getLabel = useCallback((): { label: string, url: string } => {

        let label = "";
        let url = "";
        if (quote && quote.dealerOrderNumber && quote.dealerOKey) {
            label = user.HasRole(RoleEnum.Contractor) ? tm.Get("My Quote") : tm.Get("Quote");
            label += ": " + quote.dealerOrderNumber;
            url = `/quotes?oKey=${quote.dealerOKey}`;
        }
        else if (quote && quote.contractorOrderNumber && quote.contractorOKey) {
            label = user.HasRole(RoleEnum.Contractor) ? tm.Get("Quote") : tm.Get("Customer Quote");
            label += ": " + quote.contractorOrderNumber;

            if (user.HasRole(RoleEnum.CSR)) {
                //CSR's do not have access to end customer quotes created by contractors
                url = "";
            }
            else {
                url = `/quotes?oKey=${quote.contractorOKey}`;
            }
        }

        return { label, url };

    }, [quote, tm, user]);

    return <>
        {(getLabel().label && getLabel().url) ?
            <Link component={RouterLink} to={getLabel().url} >
                {getLabel().label}
            </Link>
            :
            <>
                <Typography>
                    {getLabel().label}
                </Typography>
            </>
        }
    </>;

}

export default RelatedQuoteLink;