import { Paper, Typography, Stack, Grid } from "@mui/material";
import { Person } from "@mui/icons-material";

import LabeledText from "components/Common/LabeledText";

import useTranslations from "helpers/hooks/useTranslations";
import useOrderData from "components/Orders/useOrderData";

function SalesCard() {
    const tm = useTranslations();
    const { order } = useOrderData();

    return <>
        <Paper sx={{display: "flex", width: "100%"}}>
            <Stack display="flex" width="100%" m={1}>
                <Grid container direction="row">
                    <Grid item xs display="flex" alignItems="center">
                        <Typography variant="h6">
                            {tm.Get("Sales")}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container mt={1} direction="row">
                    <Grid item mr={1} display="flex" alignItems="center">
                        <Person color="primary" />
                    </Grid>
                    <Grid item xs display="flex" alignItems="center">
                        <LabeledText label={tm.Get("Salesperson")} text={order?.salesPersonName} />
                    </Grid>
                </Grid>

            </Stack>
        </Paper>

    </>

}


export default SalesCard;