import './App.scss';

import { useNavigate } from 'react-router-dom';
import React, { Suspense } from 'react';
import { CssBaseline } from '@mui/material';
import '@fontsource/roboto';

import LoginMain from "./components/Login/LoginMain";
import ApplicationProviders from "helpers/context/Application/ApplicationProviders";
import NonAuthenticatingProviders from "helpers/context/NonAuthenticatingProviders";
import OfflineMessage from "components/Offline/OfflineMessage";

import APIHelper from 'helpers/APIHelper';
import WebResponse, { ResponseTypeEnum } from 'helpers/WebResponse';
import API from "helpers/API";
import useAuthHandler from "helpers/context/Authentication/useAuthHandler";
import useAuthInfo from "helpers/context/Authentication/useAuthInfo";
import LoadingLinearProgress from "components/Common/LoadingLinearProgress";
import AppAuthorized from "AppAuthorized";
import useErrorActions from "components/Errors/useErrorActions";
import useNavigationBlockerActions from "helpers/context/Application/useNavigationBlockerActions";

//FUTURE: Further investigate lazy loading of components to create smaller js bundle on initial load
// const AppAuthorized = React.lazy(() => import("./AppAuthorized"));



function App() {

  const navigate = useNavigate();

  const authHandler = useAuthHandler();
  const authInfo = useAuthInfo();

  const [isOffline, setIsOffline] = React.useState<boolean | null>(null);
  const errorActions = useErrorActions();
  const navBlockerActions = useNavigationBlockerActions();

  const checkOffline = React.useCallback(() => {
    API.PingOnline().then((r) => {
      setIsOffline(!r.online);

      if (r.authenticated) {
        //If we are loading the page from scratch, but the server pings back that we actually are authenticated, then we set our internal state to be logged in so we don't 
        // force the user back to the login page
        authHandler.SetUserAsAuthenticated();
      }


    })
  }, [authHandler]);

  React.useEffect(() => {
    // Handle knowing if the site is offline on pages that do not make API calls, like login screen
    checkOffline();
  }, [checkOffline]);

  React.useEffect(() => {

    const preRequestCallback = () => {
      if (authInfo.signedIn && !authInfo.timedOut)
        authHandler.ResetTimer();
    };

    const postRequestCallback = (wr: WebResponse<any>) => {

      if (authInfo.signedIn) {

        if (wr.GetResponseType() === ResponseTypeEnum.ServiceUnavailable) {
          // Catches if the site goes offline while user is signed in
          navBlockerActions.Unblock();
          setIsOffline(true);
          authHandler.SignOutAsync();
        } else if (wr.AuthRequired) {
          // if we received an unauthorized, then our credentials must be stale
          navBlockerActions.Unblock();
          authHandler.SignOutAsync().then(() => {
            navigate("/login");
          });
        }
      }
    };

    const handleErrorCallback = (errorMessage: string, redirectToErrorPage: boolean) => {
      if (redirectToErrorPage) {
        navBlockerActions.Unblock();
        errorActions.NavigateToErrorPage(errorMessage)
      }
      else {
        console.log(errorMessage);
      }
    };

    APIHelper.registerLayoutCallback(preRequestCallback, postRequestCallback, handleErrorCallback);

  }, [navigate, authHandler, authInfo, errorActions, navBlockerActions]);

  return <NonAuthenticatingProviders>

    <CssBaseline />

    {isOffline && <>
      <OfflineMessage signedIn={authInfo.signedIn} checkOffline={checkOffline} />
    </>}

    {!authInfo.signedIn && isOffline === false && <>
      <LoginMain />
    </>}

    {authInfo.signedIn && isOffline === false && <>
      <ApplicationProviders>
        <Suspense fallback={<LoadingLinearProgress />} >
          <AppAuthorized />
        </Suspense>
      </ApplicationProviders>
    </>}
  </NonAuthenticatingProviders>;

}
export default App;


