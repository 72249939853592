import React from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, List, ListItem, ListItemText, MenuItem, Theme } from "@mui/material";
import useUserInfo from "helpers/context/User/useUserInfo";
import ApiUser from "models/ApiUser";
import useApplicationInfo from "helpers/context/Application/useApplicationInfo";
import ApplicationInfo from "models/ApplicationInfo";
import useTranslations from "helpers/hooks/useTranslations";
import { SecurityLevelEnum } from "helpers/enums";
import { IMenuItemRecord } from "helpers/hooks/useMenuItems";

interface IItemProps {
    component: "menu" | "list";
    record: IMenuItemRecord;
    theme: Theme;
    handleNavClick: (uri: string) => void;
}

export function UserCanAccessMenuItem(record: IMenuItemRecord, user: ApiUser, appInfo: ApplicationInfo): boolean {

    if (record.requiredGSPs) {
        for (const gspName of record.requiredGSPs) {
            const gspValue = (appInfo.parameters as any)[gspName];
            if (!gspValue) {
                //Required gsp not found
                return false;
            }
        }
    }

    if (record.requiredPermission) {
        if (!user.HasPermission(record.requiredPermission)) {
            return false;
        }
    }

    if (record.requiredRoles) {
        const userHasAtLeastOneRequiredRole = record.requiredRoles.map(r => user.HasRole(r)).reduce((p, c) => p || c);
        if (!userHasAtLeastOneRequiredRole) {
            return false;
        }
    }

    if (record.requiresAdmin) {
        if (user.securityLevel === SecurityLevelEnum.Standard) {
            return false;
        }
    }

    return true;
}


const listItemComponent = (props: React.PropsWithChildren<any>) => {
    return <ListItem button {...props} >
        {props.children}
    </ListItem>
}

const menuItemComponent = (props: React.PropsWithChildren<any>) => {
    return <MenuItem {...props}>
        {props.children}
    </MenuItem>
}

const NavMenuItem: React.FC<IItemProps> = (props: IItemProps) => {

    const Component = props.component === "menu" ? menuItemComponent : listItemComponent;

    const [itemsExpanded, setItemsExpanded] = React.useState<boolean>(false);

    const handleExpandClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
        e.preventDefault();
        setItemsExpanded(!itemsExpanded);
    };

    const appInfo = useApplicationInfo();
    const user = useUserInfo();
    const canAccess = UserCanAccessMenuItem(props.record, user, appInfo);
    const tm =  useTranslations();

    if (!canAccess) return null;

    if (props.record.items && props.record.items.length) {
        return <>
            <Component onClick={handleExpandClick} >
                <ListItemText primary={tm.Get(props.record.label)} />
                {itemsExpanded ? <ExpandLess /> : <ExpandMore />}
            </Component>
            <Collapse in={itemsExpanded} timeout="auto" unmountOnExit >
                <List disablePadding sx={{ pl: "12px" }}>
                    {props.record.items.map((i) =>
                        <NavMenuItem component={props.component} key={i.label} record={i} handleNavClick={props.handleNavClick} theme={props.theme} />
                    )}
                </List>
            </Collapse>
        </>;

    } else {
        return <>
            <Component onClick={() => props.handleNavClick(props.record.uri)} >
                <ListItemText primary={tm.Get(props.record.label)} />
            </Component>
        </>;
    }
}

export default NavMenuItem;