import React from "react";
import { Stack, TextField, Button } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DateTime } from "luxon";

import useIsMobile from "helpers/hooks/useIsMobile";
import useTranslations from "helpers/hooks/useTranslations";
import useWindowTitle from "helpers/context/Title/useWindowTitle";
import CustomLuxonAdapter from "helpers/classes/CustomLuxonAdapter";

interface IProps {
    startDate: DateTime,
    endDate: DateTime,
    startDateError: boolean,
    endDateError: boolean,
    setStartDate: React.Dispatch<DateTime>,
    setEndDate: React.Dispatch<DateTime>,
    handleCustomersClick: () => void,
    handleOrdersClick: () => void,
    handlePartsClick: () => void,
    handleOptionsClick: () => void,
}

const PurchaseHistoryControls = ({startDate, endDate, startDateError, endDateError, setStartDate, setEndDate, handleCustomersClick, handleOrdersClick, handlePartsClick, handleOptionsClick}: IProps) => {

    const isMobile = useIsMobile();
    const tm = useTranslations();

    useWindowTitle(tm.Get("Purchase History"));

    return <>
        <LocalizationProvider dateAdapter={CustomLuxonAdapter} locale={navigator.language}>
            <Stack direction="column" alignItems="flex-start" justifyItems="stretch" spacing={1} width={isMobile ? 1 : (1 / 3)}>
                <DatePicker
                    value={startDate as unknown as Date | null}
                    label={tm.Get("Starting Date")}
                    onChange={(newValue: Date | null) => setStartDate(newValue as unknown as DateTime)}
                    renderInput={(params: any) => <TextField {...params} fullWidth error={startDateError} />}
                />
                <DatePicker
                    value={endDate as unknown as Date | null}
                    label={tm.Get("Ending Date")}
                    onChange={(newValue: Date | null) => setEndDate(newValue as unknown as DateTime)}
                    renderInput={(params: any) => <TextField {...params} fullWidth error={endDateError} />}
                />
                <Stack direction={isMobile ? "column" : "row"} spacing={1}>
                    <Button variant="contained" color="secondary" onClick={handleCustomersClick}>{tm.Get("Customers")}</Button>
                    <Button variant="contained" color="secondary" onClick={handleOrdersClick}>{tm.Get("Orders")}</Button>
                    <Button variant="contained" color="secondary" onClick={handlePartsClick}>{tm.Get("Parts")}</Button>
                    <Button variant="contained" color="secondary" onClick={handleOptionsClick}>{tm.Get("Options")}</Button>
                </Stack>
            </Stack>
        </LocalizationProvider>       
    </>
}

export default PurchaseHistoryControls;