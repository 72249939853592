import { FormControl, FormControlLabel, Radio, TextField } from "@mui/material";
import React from "react";
import { FieldTypeEnum } from "./Search";
import useTranslations from "helpers/hooks/useTranslations";
import useFormInput from "helpers/hooks/useFormInput";

interface IProps {
    fieldType: FieldTypeEnum;
    setFieldType: (fieldTypeValue: string) => void;
}

const SearchField: React.FC<IProps> = (props: IProps) => {

    const tm = useTranslations();

    const [text, textHandler] = useFormInput<string>("");

    const enumValue: string = (FieldTypeEnum as any)[props.fieldType];

    return <FormControl >
        <FormControlLabel
            sx={{ ml: 0 }}
            key={enumValue}
            value={enumValue}
            control={<TextField sx={{ flex: 1, mr: 2 }}

                name={props.fieldType}
                variant="standard"
                label={tm.Get(enumValue)}
                value={text}
                onChange={textHandler}
                onFocus={() => props.setFieldType(enumValue)}
            />}
            label={<Radio value={enumValue} />

            }
            labelPlacement="start"
        />

    </FormControl>

};

export default SearchField;