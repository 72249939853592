import React from "react";
import { Stack, TextField, Button } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DateTime } from "luxon";

import useIsMobile from "helpers/hooks/useIsMobile";
import useTranslations from "helpers/hooks/useTranslations";
import CustomLuxonAdapter from "helpers/classes/CustomLuxonAdapter";

interface IProps {
    startDate: DateTime,
    endDate: DateTime,
    startDateError: boolean,
    endDateError: boolean,
    setStartDate: React.Dispatch<DateTime>,
    setEndDate: React.Dispatch<DateTime>,
    handleSubmit: () => void
}

const OrderHistoryControls = ({startDate, endDate, startDateError, endDateError, setStartDate, setEndDate, handleSubmit}: IProps) => {

    const isMobile = useIsMobile();
    const tm = useTranslations();

    return <>
        <LocalizationProvider dateAdapter={CustomLuxonAdapter} locale={navigator.language}>
            <Stack direction="column" alignItems="flex-start" justifyItems="stretch" spacing={1} width={isMobile ? 1 : (1 / 3)}>
                <DatePicker
                    value={startDate as unknown as Date | null}
                    label={tm.Get("Starting Date")}
                    onChange={(newValue: Date | null) => setStartDate(newValue as unknown as DateTime)}
                    renderInput={(params: any) => <TextField {...params} fullWidth error={startDateError} />}
                />
                <DatePicker
                    value={endDate as unknown as Date | null}
                    label={tm.Get("Ending Date")}
                    onChange={(newValue: Date | null) => setEndDate(newValue as unknown as DateTime)}
                    renderInput={(params: any) => <TextField {...params} fullWidth error={endDateError} />}
                />
                <Button variant="contained" color="secondary" onClick={handleSubmit}>{tm.Get("Submit")}</Button>
            </Stack>
        </LocalizationProvider>
    </>
}

export default OrderHistoryControls;