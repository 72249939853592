import { useMemo } from "react";
import { Typography, Link, Stack } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import useOrderData from "components/Orders/useOrderData";
import useTranslations from "helpers/hooks/useTranslations";

function ProductionStatusLink() {

    const { order, isOnContainer, hasBeenReleased } = useOrderData();
    const tm = useTranslations();

    const prodStatusUrl = useMemo((): string => {

        let url = "";
        if (order && order.oKey) {
            url = `/orders/prodStatus?oKey=${order.oKey}`;
        }

        return url;

    }, [order]);

    const containerStatusUrl = useMemo((): string => {

        let url = "";
        if (order && order.oKey) {
            url = `/orders/containerStatus?oKey=${order.oKey}`;
        }

        return url;

    }, [order]);    

    return <>
        <Stack direction="row" gap={1}>
            {hasBeenReleased && 
                <Link component={RouterLink} to={prodStatusUrl} >
                    {tm.Get("Production Status")}
                </Link>
            }
            {hasBeenReleased && isOnContainer && 
            <Typography>
                /
            </Typography>
            }
            {isOnContainer && 
                <Link component={RouterLink} to={containerStatusUrl} >
                {tm.Get("Container Status")}
            </Link>            
            }
        </Stack>
    </>;

}

export default ProductionStatusLink;