import React, { useMemo } from "react"
import { TextField, Typography, Accordion, AccordionDetails, Grid } from "@mui/material"

import SelectAddress from "components/Quotes/SelectFields/SelectAddress";
import SelectShipVia from "components/Quotes/SelectFields/SelectShipVia";
import SelectIsAddressSameAsBilling from "components/Quotes/SelectFields/SelectIsAddressSameAsBilling";
import SelectDropShip from "components/Quotes/SelectFields/SelectDropShip";
import AccordionSummarySlim from "components/Common/AccordionSummarySlim";

import { useFormContext } from "react-hook-form";
import useTranslations from "helpers/hooks/useTranslations";

import Constants from "helpers/constants";
import useUserInfo from "helpers/context/User/useUserInfo";
import { CompanyPermissionEnum, RoleEnum } from "helpers/enums";
import { IQuotePermissions } from "helpers/interfaces";

interface IProps {
    parentCustomerID: string,
    expanded: boolean,
    permissions?: IQuotePermissions
    onExpandedChange: (event: React.SyntheticEvent, expanded: boolean) => void,
}

function Shipping({ parentCustomerID, expanded, permissions, onExpandedChange }: IProps) {

    const tm = useTranslations();
    const user = useUserInfo();
    const { register, watch } = useFormContext();
    const watchSameAsBilling = watch("shipping.isAddressSameAsBilling");
    const dropShipVisible = React.useMemo<boolean>(() => {
        return user.HasRole(RoleEnum.CSR) || user.HasCompanyPermission(CompanyPermissionEnum.DropShip);
    }, [user]);

    const isAddressReadOnly = useMemo(() => {

        if ((permissions?.shippingAddress ?? false) === false) {
            return true;
        }
        else if ((watchSameAsBilling) === true) {
            return true;
        }

        return false;
    }, [permissions, watchSameAsBilling]);

    return <>
        <Accordion expanded={expanded} onChange={onExpandedChange} disableGutters >
            <AccordionSummarySlim>
                <Typography color="secondary.contrastText">
                    {tm.Get("Shipping Address")}
                </Typography>
            </AccordionSummarySlim>
            <AccordionDetails>
                <Grid container spacing={2} padding={0}>
                    <Grid item xs={12} sm={6}>
                        <SelectIsAddressSameAsBilling permissions={permissions} />
                    </Grid>
                    {!watchSameAsBilling &&
                        <Grid item xs={12}>
                            <SelectAddress
                                addressPrefix="shipping"
                                readOnly={isAddressReadOnly}
                            />
                        </Grid>
                    }
                    <Grid item xs={12} sm={6}>
                        {/* Ship Via */}
                        <SelectShipVia
                            parentCustomerID={parentCustomerID}
                            permissions={permissions}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {/* Drop Ship */}
                        {dropShipVisible &&
                            <SelectDropShip permissions={permissions} />
                        }
                    </Grid>
                    <Grid item xs={12}>
                        {/* Ship Comment */}
                        <TextField
                            id="shipComment"
                            inputProps={{ maxLength: Constants.MaxLength.ShipComment }}
                            label={tm.Get("Shipping Comment")}
                            placeholder={tm.Get("Shipping Comment")}
                            multiline
                            minRows={2} maxRows={5}
                            disabled={!permissions?.shippingComment ?? true}
                            fullWidth
                            {...register("shipping.shippingComment")}
                        />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    </>
}

export default Shipping;