import * as React from "react";

import { ISubLineItemInfo } from "../interfaces";
import Format, { ICallsizeArgs, ImperialFormatModeEnum } from "helpers/fv.format";
import useTranslations from "helpers/hooks/useTranslations";
import { Container, Box, Typography, Grid, FormControl, Autocomplete, Stack, TextField } from "@mui/material";
import Translation from "components/Common/Translation";
import { IPartCallSize } from "helpers/interfaces";
import useDimensionValue from "components/OptionsWizard/ItemProperties/useDimensionValue";
import useFormatHelper from "helpers/hooks/useFormatHelper";
import useWizardState from "components/OptionsWizard/WebDesigner/useWizardState";
import useMeasurementTypes from "helpers/context/SelectionValues/useMeaurementTypes";
import { IItemInformation } from "components/OptionsWizard/ItemProperties/ItemProperties";
import useWizardInteractions from "../useWizardInteractions";
import { useEffectOnLoad } from "helpers/hooks/useEffectOnLoad";

interface IProps {
    sliInfo: ISubLineItemInfo;
    itemInfo: IItemInformation;
}

const SectionSizing: React.FC<IProps> = ({itemInfo}: IProps) => {

    const wizardState = useWizardState();
    const tm = useTranslations();
    const formatMethods = useFormatHelper();

    const partCallSizePresets = itemInfo.callSizes;

    const measurementTypes = useMeasurementTypes();
    const wizardInteractions = useWizardInteractions();

    const [prevDimensions, setPrevDimensions] = React.useState<{ width: number, height: number, callSize: string } | undefined>(undefined);

    const quoteMeasurementType = React.useCallback(() => {
        if (itemInfo && measurementTypes) {
            return measurementTypes.find((m) => m.setID === wizardState.itemInfo?.unitSet);
        }
    }, [wizardState.itemInfo, itemInfo, measurementTypes]);

    const measurementType = quoteMeasurementType();

    const sizingModel = wizardState.altWizInfo?.sectionSizingModel;

    const callSizeEnabled = () => {
        let callSizeEnableTmp: boolean = false

        if (sizingModel) {
            callSizeEnableTmp = !sizingModel.pinned;

            let widthEnabled: boolean = !sizingModel.widthFixed;
            let heightEnabled: boolean = !sizingModel.heightFixed;

            if (!widthEnabled && !heightEnabled) {
                callSizeEnableTmp = false;
            }
        }

        return callSizeEnableTmp;
    }

    const [callSize, setCallsize] = React.useState<string>(() => {


        let callSizes = itemInfo.callSizes;

        let callSize: string = wizardState?.itemInfo?.callSize ?? "";

        if (callSizes && callSizes.callSizes.length > 0) {
            if (!wizardState.itemInfo?.callSize) {
                let defaultCallsize = callSizes.callSizes.filter(c => c.default);
                if (defaultCallsize.length > 0) {
                    callSize = defaultCallsize[0].callSize;
                } else {
                    if (callSizes.locked) {
                        callSize = callSizes.callSizes[0].callSize;
                    } else {
                        callSize = "";
                    }
                }
            }
        }

        return callSize;

    });
    const [displayCallSize, setDisplayCallSize] = React.useState<string>("");

    const callSizeSelectChosen = React.useMemo(() => {
        return partCallSizePresets !== null && partCallSizePresets.callSizes.length > 0 && callSize !== "";
    }, [callSize, partCallSizePresets]);

    React.useEffect(() => {
        setDisplayCallSize(callSize);
    }, [callSize]);

    const handleCallSizeTextChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setDisplayCallSize(e.target.value);
    };

    const handleCallSizeTextBlur = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        
        if (!measurementType)
            return;

        let newCallSize: string = e.target.value;
        if (newCallSize === callSize) {
            return;
        }

        const currentWidth = width;
        const currentHeight = height;

        let newWidth = currentWidth;
        let newHeight = currentHeight;

        let callsizeArgs: ICallsizeArgs = {
            callSize: newCallSize,
            width: currentWidth,
            height: currentHeight,
        };

        if (Format.formatCallSize(callsizeArgs)) {
            //valid
            newWidth = callsizeArgs.width;
            newHeight = callsizeArgs.height;
        } else {
            //Invalid
            newCallSize = "";
            newWidth = 0;
            newHeight = 0;
        }


        const newFormattedWidth = formatMethods.formatDimensionText(newWidth, measurementType.setID, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false);
        const newFormattedHeight = formatMethods.formatDimensionText(newHeight, measurementType.setID, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false);

        setWidth(newWidth);
        setWidthString(newFormattedWidth);
        setHeight(newHeight);
        setHeightString(newFormattedHeight);

        setCallsize(newCallSize);
        setDisplayCallSize(newCallSize);
        updateSectionSize(newWidth, newHeight, newCallSize);
    };

    const handleCallSizeSelectChange = (event: any, data: IPartCallSize | null) => {

        if (!measurementType)
            return;

        const newCallSize: string = data?.callSize ?? "";

        const currentWidth = width;
        const currentHeight = height;

        let newWidth = currentWidth;
        let newHeight = currentHeight;

        if (newCallSize) {
            if (itemInfo?.callSizes) {
                const selectedCallSize = itemInfo.callSizes.callSizes.filter(cs => cs.callSize === newCallSize);
                if (selectedCallSize.length > 0) {
                    newWidth = selectedCallSize[0].width;
                    newHeight = selectedCallSize[0].height;
                }
            }
        } else {
            newWidth = 0;
            newHeight = 0;
        }

        const newFormattedWidth = formatMethods.formatDimensionText(newWidth, measurementType.setID, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false);
        const newFormattedHeight = formatMethods.formatDimensionText(newHeight, measurementType.setID, ImperialFormatModeEnum.SHOW_DECIMAL_IF_NOT_CLEAN, false);


        setWidth(newWidth);
        setWidthString(newFormattedWidth);
        setHeight(newHeight);
        setHeightString(newFormattedHeight);
        setCallsize(newCallSize);
        updateSectionSize(newWidth, newHeight, newCallSize);

    };


    const callSizeLocked = React.useMemo<boolean>(() => {
        if (partCallSizePresets && partCallSizePresets.callSizes.length > 0 && partCallSizePresets.locked) {
            return true;
        } else {
            return false;
        }
    }, [partCallSizePresets]);

    const selectedCallSize = React.useCallback(() => {
        if (partCallSizePresets && partCallSizePresets.callSizes && partCallSizePresets.callSizes.length > 0) {
            if (callSize) {
                const item = partCallSizePresets.callSizes.find(cs => cs.callSize === callSize);
                return item ?? null;
            } else {
                return null;
            }

        }
        return null;
    }, [callSize, partCallSizePresets]);

    const [width, widthString, handleWidthChange, handleWidthBlur, setWidth, setWidthString]
        = useDimensionValue(sizingModel?.decimalWidth ?? 0, measurementType?.setID ?? 1, formatMethods, setCallsize, onWidthChanged);

    const [height, heightString, handleHeightChange, handleHeightBlur, setHeight, setHeightString]
        = useDimensionValue(sizingModel?.decimalHeight ?? 0, measurementType?.setID ?? 1, formatMethods, setCallsize, onHeightChanged);

    function onWidthChanged(newValue: number) {
        updateSectionSize(newValue, height, "");
    }

    function onHeightChanged(newValue: number) {
        updateSectionSize(width, newValue, "");
    }    

    function updateSectionSize(width: number, height: number, callSize: string) {
        if (!prevDimensions ||
            prevDimensions.width !== width ||
            prevDimensions.height !== height ||
            prevDimensions.callSize !== callSize) {
            setPrevDimensions({ width, height, callSize });
            wizardInteractions.ChangeSectionSizingAsync(width, height, callSize);
        }
    };

    let measurementLabel: string;

    if (measurementType?.setID === 1)
        measurementLabel = tm.Get("inches");
    else
        measurementLabel = tm.Get("mm");

    useEffectOnLoad(() => {
        if (callSizeSelectChosen) {
            handleCallSizeSelectChange(undefined, partCallSizePresets?.callSizes.find((c) => c.callSize === callSize) ?? null);
        }
    });

    return <>
        <Container >

            <Box display="flex" flexDirection="column" p={1} gap={1}>
                <Typography variant="h2"><Translation>Section Sizing</Translation></Typography>
                <Grid container direction="column" rowSpacing={1} justifyItems="stretch">

                    {callSizeEnabled() &&
                        <Grid item  >
                            <FormControl id="callsize" fullWidth>
                                {partCallSizePresets && partCallSizePresets.callSizes.length > 0 ?
                                    <Autocomplete
                                        autoComplete
                                        disableClearable={callSizeLocked}
                                        autoSelect
                                        autoHighlight
                                        selectOnFocus
                                        handleHomeEndKeys
                                        options={partCallSizePresets.callSizes}
                                        getOptionLabel={(cs: IPartCallSize) => cs.callSize}
                                        isOptionEqualToValue={(cs: IPartCallSize, value: IPartCallSize) => cs.callSize === value.callSize}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={tm.Get("Call Size")}
                                                InputProps={{
                                                    ...params.InputProps,
                                                }}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    inputMode: wizardState.userPreferences.enableMobileKeyboard ? "text" : "none"
                                                }}
                                            />
                                        )}
                                        onChange={handleCallSizeSelectChange}
                                        value={selectedCallSize()}
                                    />
                                    :
                                    <TextField label={tm.Get("Call Size")} value={displayCallSize} onChange={handleCallSizeTextChange} onBlur={handleCallSizeTextBlur} fullWidth />
                                }
                            </FormControl>

                        </Grid>
                    }

                    <Grid item>
                        <Stack direction={"row"} alignItems="center" justifyContent="left" spacing={1}  >
                            <TextField label={tm.Get("Width")} value={widthString} onChange={handleWidthChange} onBlur={handleWidthBlur} fullWidth
                                disabled={callSizeLocked || callSizeSelectChosen}
                                InputProps={{ endAdornment: <Typography>{measurementLabel}</Typography> }}
                            />

                        </Stack>
                    </Grid>

                    <Grid item>
                        <Stack direction={"row"} alignItems="center" justifyContent="left" spacing={1}  >
                            <TextField label={tm.Get("Height")} value={heightString} onChange={handleHeightChange} onBlur={handleHeightBlur} fullWidth
                                disabled={callSizeLocked || callSizeSelectChosen}
                                InputProps={{ endAdornment: <Typography>{measurementLabel}</Typography> }}
                            />
                        </Stack>
                    </Grid>

                </Grid>
            </Box>
        </Container>
    </>;
}

export default SectionSizing;


