import useWindowTitle from "helpers/context/Title/useWindowTitle";
import useTranslations from "helpers/hooks/useTranslations";
import React from 'react';
import { IWebCustomerSummary } from "models/IWebCustomer";
import CustomerAPI from "./CustomerAPI";
import useUserInfo from "helpers/context/User/useUserInfo";
import AddCustomerDialog from "./AddCustomerDialog";
import { Alert, AlertColor, Container, Snackbar, Stack, TextField } from "@mui/material";
import useIsMobile from "helpers/hooks/useIsMobile";
import { IActionButton } from "helpers/context/Page/PageContext";
import { MaintenanceFilterEnum, RoleEnum, ThemeColorEnum } from "helpers/enums";
import useActionButtons from "helpers/context/Page/useActionButtons";
import useMfgCustomersAndSites from "helpers/context/SelectionValues/useMfgCustomersAndSites";
import { useForm, FormProvider } from "react-hook-form";
import { useEffectOnLoad } from "helpers/hooks/useEffectOnLoad";
import useWait from "helpers/context/Page/useWait";
import useSelectionValuesActions from "helpers/context/SelectionValues/useSelectionValuesActions";
import { searchObjectStringProperties } from "helpers/objects";
import CustomersGrid from "./CustomersGrid";
import MaintenanceFilter from "components/Common/MaintenanceFilter";
import { useNavigate } from "react-router-dom";

const Customers: React.FC = () => {

    const tm = useTranslations();
    useWindowTitle(tm.Get("Customers"));
    const user = useUserInfo();
    const wait = useWait();
    const navigate = useNavigate();
    const isMobile = useIsMobile();
    const actionButtons = useActionButtons();
    const mfgCustomerSites = useMfgCustomersAndSites();
    const formMethods = useForm({ mode: 'onChange', reValidateMode: 'onChange' });
    const { handleSubmit } = formMethods;

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isPosting, setIsPosting] = React.useState<boolean>(false);
    const [customerList, setCustomerList] = React.useState<IWebCustomerSummary[] | null>(null);
    const [customerFilter, setCustomerFilter] = React.useState<MaintenanceFilterEnum>(MaintenanceFilterEnum.All);
    const [searchText, setSearchText] = React.useState<string | null>(null);
    const [addCustomerOpen, setAddCustomerOpen] = React.useState<boolean>(false);
    const [alert, setAlert] = React.useState<[text: string, alertType: AlertColor] | null>(null);
    const selectionValuesActions = useSelectionValuesActions();

    const mfgSites = React.useMemo(() => {
        if (mfgCustomerSites && !user.HasRole(RoleEnum.Contractor)) {
            return mfgCustomerSites.GetMfgSitesFromMfgCustomerID(user.mfgCustomerID);
        }
        return null;
    }, [mfgCustomerSites, user]);

    useEffectOnLoad(() => {
        const addCustomerButton: IActionButton = {
            text: tm.GetWithParams("Add {0}", tm.Get("Customer")),
            color: ThemeColorEnum.Secondary,
            disabled: (isLoading || isPosting),
            onClick: (() => setAddCustomerOpen(true))
        };
        actionButtons.Set(0, addCustomerButton);
    })

    React.useEffect(() => {
        wait.Show(isLoading || isPosting);
    }, [isLoading, isPosting, wait])

    const getCustomerSummaryList = async () => {
        setIsLoading(true);
        try {
            let result = user.HasRole(RoleEnum.Contractor) ?
                await CustomerAPI.GetWebContractorCustomerSummaryList(user.parentCustomerID) :
                await CustomerAPI.GetWebCustomerSummaryList(user.mfgCustomerID);
            setCustomerList(result);
        } finally {
            setIsLoading(false);
        }
    }

    function clearCache() {
        selectionValuesActions.ClearCustomersCache();
    }

    useEffectOnLoad(() => {
        if (!customerList) {
            getCustomerSummaryList();
        }
    });

    const filteredCustomerList = React.useMemo(() => {
        if (!customerList) return null;

        let filtered = customerList;
        if (customerFilter !== MaintenanceFilterEnum.All) {
            filtered = filtered.filter(customer => customerFilter === MaintenanceFilterEnum.Active ? !customer.inactive : customer.inactive)
        }
        if (searchText && searchText !== "") {
            filtered = filtered.filter(customer => searchObjectStringProperties(searchText, customer, ["customerID", "parentSiteName", "parentSiteID", "siteID", "salesPersonName"]))
        }
        return filtered;
    }, [customerFilter, searchText, customerList])


    const handleAddCustomer = async (formData: any, e: any) => {
        let customerName: string = formData["customer-name"].trim();

        setIsPosting(true);
        try {
            if (user.HasRole(RoleEnum.Contractor)) {
                const customerID = await CustomerAPI.AddNewWebContractorCustomerWithDefaults(customerName);
                clearCache();
                const url = `edit/${encodeURIComponent(customerID)}`;
                navigate(url);
            } else {
                let mfgSiteID: string | undefined = undefined;
                if (user.isMultiSite) {
                    /*Need to get siteID from form if multisite*/
                    mfgSiteID = formData["select-mfg-site"].siteID;
                }
                const customerID = await CustomerAPI.AddNewWebCustomerWithDefaults(customerName, mfgSiteID);

                clearCache();
                const url = `edit/${encodeURIComponent(customerID)}`;
                navigate(url);
            }
        } finally {
            setIsPosting(false);
        }
    }

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway')
            return;
        setAlert(null);
    }

    return <>
        <Snackbar open={alert !== null} autoHideDuration={5000} onClose={handleClose} >
            <Alert onClose={handleClose} severity={alert ? alert[1] : "info"} variant='filled' sx={{ width: '100%', fontWeight: 'bold' }}>{alert ? alert[0] : ""}</Alert>
        </Snackbar>

        <FormProvider {...formMethods}>
            <form id="add-customer" onSubmit={handleSubmit(handleAddCustomer)}>
                <AddCustomerDialog
                    isOpen={addCustomerOpen}
                    onClose={() => setAddCustomerOpen(false)}
                    mfgSites={mfgSites}
                    mfgSitesVisible={user.isMultiSite}
                    customerNames={customerList ? customerList.map(customer => customer.name) : []}
                />
            </form>
        </FormProvider>

        <Container disableGutters={!isMobile} maxWidth={isMobile ? "md" : "xl"} >
            <Stack direction="column" m={1} spacing={1}>
                <Stack direction={isMobile ? "column" : "row"} spacing={isMobile ? 1 : 3}>
                    <MaintenanceFilter
                        currentFilter={customerFilter}
                        setFilter={setCustomerFilter}
                    />
                    <TextField
                        value={searchText ?? ""}
                        onChange={(e) => setSearchText(e.target.value)}
                        placeholder={tm.Get("Search")}
                        label={tm.Get("Search")}
                    />
                </Stack>
                <CustomersGrid
                    getCustomerSummaryList={getCustomerSummaryList}
                    setAlert={setAlert}
                    filteredCustomerList={filteredCustomerList}
                    isMfgSiteVisible={user.isMultiSite}
                />
            </Stack>
        </Container>
    </>;
}

export default Customers;