import { useState, useEffect, useMemo } from 'react';
import { Grid, Autocomplete, TextField} from '@mui/material'

import { useController, useFormContext } from 'react-hook-form';
import useTranslations from 'helpers/hooks/useTranslations';
import useMfgSecondaryShipTos from 'helpers/context/SelectionValues/useMfgSecondaryShipTos';

import { IMfgSecondaryShipTo } from 'helpers/interfaces';

import { IQuotePermissions } from "helpers/interfaces";

interface IProps {
    mfgCustomerID : string,
    mfgSiteID : string,
    defaultMfgShipToGUID : string,
    permissions?: IQuotePermissions
}

function SelectMfgShippingAddress ({ mfgCustomerID, mfgSiteID, defaultMfgShipToGUID, permissions, ...rest }: IProps) {

    const tm = useTranslations();
    const defaultShipTo : IMfgSecondaryShipTo = useMemo(() => {
        return {
            shipToBusinessEntityGUID : "",
            shipToName : "{" + tm.Get("Default") + "}"
        };
    }, [tm]);
    const [selectedMfgShippingAddress, setSelectedMfgShippingAddress] = useState<IMfgSecondaryShipTo>(defaultShipTo);
    
    const mfgShippingAddresses = useMfgSecondaryShipTos(mfgCustomerID, mfgSiteID);

    const { control, watch } = useFormContext();
    const { field } = useController({
        name: "shipping.mfgShipToGUID", 
        control,
        defaultValue: defaultMfgShipToGUID,
        rules: { required: false },
      });

    const watchMfgShipToGUID = watch("shipping.mfgShipToGUID", null); 

    useEffect(() => {
        if (watchMfgShipToGUID)
        {
            let mfgShipTo = mfgShippingAddresses?.find((e)=> e.shipToBusinessEntityGUID === watchMfgShipToGUID);
            setSelectedMfgShippingAddress(mfgShipTo ?? defaultShipTo);
        }
        else
        {
            setSelectedMfgShippingAddress(defaultShipTo);
        }
    }, [watchMfgShipToGUID, mfgShippingAddresses, defaultShipTo]);

    const selectionValues = useMemo(() => {
        if (defaultShipTo && mfgShippingAddresses)
            return [defaultShipTo, ...mfgShippingAddresses];
        else
            return [defaultShipTo];
    }, [defaultShipTo, mfgShippingAddresses]);

    return (  
        <Grid item xs>
            <Autocomplete 
                id="mfgShipTo"
                autoComplete
                autoSelect
                autoHighlight
                selectOnFocus
                handleHomeEndKeys
                disableClearable
                options={selectionValues} 
                disabled={!permissions?.mfgShipTo ?? true}
                getOptionLabel={(option) => (option?.shipToName || "")}
                renderInput={(params) => <TextField variant="outlined" {...params} placeholder={tm.Get("Ship To")} />}
                onChange = {(_, value) => {
                    field.onChange(value?.shipToBusinessEntityGUID);
                }}
                value = {selectedMfgShippingAddress}   
                ref = {field.ref}
                {...rest}
            />
        </Grid>
    );
}
 
export default SelectMfgShippingAddress;

