import React from 'react';
import { Button, Table, TableBody, Box, DialogContent, DialogActions } from "@mui/material";

import SurchargeHeader from "components/Quotes/QuoteEntry/Surcharges/SurchargeHeader";
import SurchargeDetail, { ISurchargeDetail } from "components/Quotes/QuoteEntry/Surcharges/SurchargeDetail";
import useTranslations from "helpers/hooks/useTranslations";
import { FormProvider, useForm } from "react-hook-form";
import useMessageBox from "helpers/context/Page/useMessageBox";
import { FindAllNestedProps } from "helpers/objects";
import useQuoteData from "components/Quotes/useQuoteData";
import useCurrencyFormatter from "helpers/hooks/useCurrencyFormatter";
import useOrderData from "components/Orders/useOrderData";
import OKCancelControl from "components/Common/OKCancelControl";
import { ThemeColorEnum } from "helpers/enums";

export interface ISurchargeInfo {
    details: ISurchargeDetail[];
}

interface ISurchargeFormFields {
    surcharge: { unitPrice: string }[];
}

interface IProps {
    initialState: ISurchargeInfo,
    isReadOnly: boolean,
    viewCost: boolean,
    onCancel: () => void,
    onSubmit: (originalState: ISurchargeInfo, newState: ISurchargeInfo) => void;
};

const Surcharges: React.FC<IProps> = ({ initialState, isReadOnly, viewCost, onCancel, onSubmit }: IProps) => {

    const { quote } = useQuoteData();
    const { order } = useOrderData();
    const formDefault: ISurchargeFormFields = { surcharge: [] };
    const tm = useTranslations();
    const cf = useCurrencyFormatter(quote?.currencyCulture ?? order?.currencyCulture);
    const formMethods = useForm({ mode: "onChange", defaultValues: formDefault });
    const { handleSubmit } = formMethods;
    const messageBox = useMessageBox();

    const onSubmitInternal = React.useCallback((formData: ISurchargeFormFields, e: any) => {

        const fields = formData.surcharge;

        const newState: ISurchargeInfo = {
            details: [],
        };

        initialState.details.forEach((sd, index) => {

            let newPrice = 0;
            let priceChanged = false;

            let newValueString = fields[index]?.unitPrice;
            if (newValueString) {
                const newValue = cf.Parse(newValueString);
                newPrice = newValue;
                priceChanged = (newPrice !== sd.unitPrice);
            }
            else {
                newPrice = sd.unitPrice;
                priceChanged = false;
            }

            newState.details.push({ ...sd, unitPrice: newPrice, priceChanged: priceChanged });
        });

        onSubmit(initialState, newState);

    }, [initialState, cf, onSubmit]);

    const onError = React.useCallback((errors: { [x: string]: any }, e: any) => {

        const messages = FindAllNestedProps(errors, "message");
        const formattedMessage = messages.join("\n");

        if (formattedMessage) {
            messageBox.Show({ message: formattedMessage, title: tm.Get("Please correct before saving.") });
        }
    }, [messageBox, tm]);

    return <>
        <FormProvider {...formMethods}>
            <form id="surcharges" onSubmit={handleSubmit(onSubmitInternal, onError)}>
                <DialogContent>
                    <Box display="flex" flexDirection="column">
                        <Table>
                            <SurchargeHeader viewCost={viewCost} />
                            <TableBody>
                                {initialState.details.map((sd) => (
                                    <SurchargeDetail initialState={sd} key={sd.index} isReadOnly={isReadOnly} viewCost={viewCost} cf={cf} />
                                )
                                )}
                            </TableBody>
                        </Table>
                    </Box>
                </DialogContent>
                <DialogActions>
                    {isReadOnly ?
                        <Button variant="contained" onClick={onCancel} color={ThemeColorEnum.Secondary} sx={{marginRight: "16px"}}>{tm.Get("OK")}</Button>
                        :
                        <OKCancelControl cancelCommand={onCancel} okText={tm.Get("Submit")} okButtonType={"submit"} />
                    }
                </DialogActions>
            </form>
        </FormProvider>
    </>;
}

export default Surcharges;