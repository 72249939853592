import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Container, useTheme } from "@mui/material";
import { GridCallbackDetails, GridRowIdGetter, GridRowModel } from "@mui/x-data-grid-pro";
import { DateTime } from "luxon";

import CustomDataGridPro from "components/Common/CustomDataGridPro";
import ContainerDetail from "./ContainerDetail";
import DataGridColumnGenerator from "components/Common/DataGridColumnGenerator";

import useOrderActions from "components/Orders/useOrderActions";
import useWindowTitle from "helpers/context/Title/useWindowTitle";
import useTranslations from "helpers/hooks/useTranslations";
import useOrderData from "components/Orders/useOrderData";
import useIsMobile from "helpers/hooks/useIsMobile";
import useFormatHelper from "helpers/hooks/useFormatHelper";
import useWait from "helpers/context/Page/useWait";

import { IContainer } from "helpers/interfaces";
import useActionButtons from "helpers/context/Page/useActionButtons";
import useRowsPerPage from "helpers/hooks/useRowsPerPage";

const ContainerStatus: React.FC<any> = () => {

    const navigate = useNavigate();
    const query = new URLSearchParams(useLocation().search);
    const oKeyString = query.get("oKey") ?? undefined;

    const [containerDialogVisible, setContainerDialogVisible] = useState<boolean>(false);

    const orderActions = useOrderActions();
    const { order, containers } = useOrderData();
    const tm = useTranslations();
    const theme = useTheme();
    const wait = useWait();
    const isMobile = useIsMobile();
    const formatMethods = useFormatHelper();
    const actionButtons = useActionButtons();
    const rowsPerPage = useRowsPerPage("ContainerStatus");

    if (!oKeyString) {
        //No okey 
        navigate(-1);
    }

    const oKey = oKeyString ? parseInt(oKeyString) : 0;

    const title = useMemo(() => {
        if (order) {
            return `${tm.Get("Order Shipping Containers")} - #${order?.orderNumber}`;
        }
        return "";
    }, [tm, order]);

    useWindowTitle(title);

    useEffect(() => {
        if (oKey) {
            orderActions.LoadOrderAsync(oKey);
            orderActions.LoadContainersAsync(oKey);
        }
    }, [oKey, orderActions]);

    useEffect( () => {
        actionButtons.SetBackButton(0, `/orders?oKey=${oKey}`)
    }, [oKey, actionButtons])    

    const formatCode = useCallback((statusCode: string) => {
        return formatMethods.FormatStatusCode(statusCode);
    }, [formatMethods]);

    const onContainerClick = useCallback(async (row: IContainer) => {
        if (oKey && row.containerKey) {
            wait.Show(true);
            await orderActions.LoadContainerDetailAsync(oKey, row);
            setContainerDialogVisible(true);
            wait.Show(false);
        }
    }, [oKey, wait, orderActions]);

    const generator = useMemo(() => {
        const columns = new DataGridColumnGenerator(tm, containers, theme, isMobile);
        columns.TryAddLinkButtonColumn("containerID", "Container", onContainerClick, { hideInMobile: false });
        columns.TryAddTextColumn("routeName", "Route", { hideInMobile: true });
        columns.TryAddTextColumn("stationID", "Station", { hideInMobile: true });
        columns.TryAddDateTimeColumn("dateTime", tm.Get("Date"), DateTime.DATETIME_SHORT_WITH_SECONDS, {});
        columns.TryAddFormattedTextColumn("code", "Status", formatCode, { hideInMobile: false });

        return columns;
    }, [tm, containers, theme, isMobile, formatCode, onContainerClick]);

    const getRowId: GridRowIdGetter = (row: GridRowModel) => {
        const container = row as IContainer;
        return container.containerKey;
    };

    const onPageSizeChange = (pageSize: number, details: GridCallbackDetails) => rowsPerPage.setPageSize(pageSize);

    return <>
        <Container sx={{ marginTop: 1 }}>
            <CustomDataGridPro
                getRowId={getRowId}
                columns={generator.GetColumns()}
                rows={containers}
                onPageSizeChange={onPageSizeChange}
                pageSize={rowsPerPage.pageSize}
                rowsPerPageOptions={rowsPerPage.pageSizeOptions}
            />

        </Container>

        <ContainerDetail
            dialogVisible={containerDialogVisible}
            onClose={() => setContainerDialogVisible(false)}
        />
    </>;

};

export default ContainerStatus;
