import React from "react";
import { MoreHoriz } from "@mui/icons-material";
import { GridActionsCellItem, GridEnrichedColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid-pro";
import { DateTime } from "luxon";

import DataGridColumnGenerator from "components/Common/DataGridColumnGenerator";
import useTranslations, { ITranslationManager } from "helpers/hooks/useTranslations";
import { ITransLogHeader } from "helpers/interfaces";
import { TransLogHeaderTypeEnum } from "models/ITransLogHeader";
import { Typography } from "@mui/material";

type ViewDetailsActionHandler = (transLogHeader: ITransLogHeader) => void;

interface ICommandProps {
    params: GridRowParams;
    actionHandler: ViewDetailsActionHandler
}

const ViewDetailsCommand: React.FC<ICommandProps> = ({ params, actionHandler }: ICommandProps) => {
    const tm = useTranslations();
    const transLogHeader = params.row as ITransLogHeader;

    const handlerShowDialog = React.useCallback(() => {
        actionHandler(transLogHeader);
    }, [actionHandler, transLogHeader]);

    return <><GridActionsCellItem
        icon={<MoreHoriz />}
        color="primary"
        label={tm.Get("View Details")}
        onClick={handlerShowDialog}
    />
    </>
};

const commandsColumn = (viewDetailsHandler: ViewDetailsActionHandler, title: string) => {

    const minWidth = 46;

    const col = {
        field: "commands",
        type: "actions",
        minWidth: minWidth,
        headerName: title,
        filterable: false,
        sortable: false,
        getActions: (params: GridRowParams) => {
            const actions: Array<JSX.Element> = [];
            actions.push(<ViewDetailsCommand params={params} actionHandler={viewDetailsHandler} />);
            return actions;
        },
    } as GridEnrichedColDef;

    return col;
}

const textColumn = (fieldName: string, title: string, isMobile: boolean) => {

    const col = {
        field: fieldName,
        minWidth: isMobile ? 90 : 130,
        headerName: title,
        filterable: true,
        sortable: true,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          return <Typography variant="body2">{params.value}</Typography>;
        },
    } as GridEnrichedColDef

    return col
}

const transLogTypeColumn = (fieldName: string, title: string, isMobile: boolean, tm: ITranslationManager) => {

    const typeNameMap = new Map<number, string>();
    typeNameMap.set(TransLogHeaderTypeEnum.Quote as number, tm.Get("Quote"));
    typeNameMap.set(TransLogHeaderTypeEnum.LineItem as number, tm.Get("Line Item"));
    typeNameMap.set(TransLogHeaderTypeEnum.LineItemOption as number, tm.Get("Line Item Option"));

    const col = {
        field: fieldName,
        minWidth: isMobile ? 90 : 130,
        headerName: title,
        hideable: false,
        filterable: true,
        sortable: true,
        flex: 1,
        renderCell: (params) => {
            const transLogHeader = params.row as ITransLogHeader;
            return <Typography variant="body2">{typeNameMap.get(transLogHeader.type)}</Typography>;
        }
    } as GridEnrichedColDef

    return col
}

export function AddColumns(generator: DataGridColumnGenerator, isMobile: boolean, viewDetailsHandler: ViewDetailsActionHandler): void {
    const tm = useTranslations();
    generator.AddColumn(transLogTypeColumn("type", tm.Get("Type"), isMobile, tm));
    generator.TryAddDateTimeColumn("date", tm.Get("Date"), DateTime.DATETIME_SHORT_WITH_SECONDS, { hideInMobile: false });
    generator.AddColumn(textColumn("user", tm.Get("User"), isMobile));
    generator.AddColumn(textColumn("quoteNumber", tm.Get("Quote Number"), isMobile));
    generator.AddColumn(textColumn("lineItem", tm.Get("Line Item"), isMobile));
    generator.AddColumn(textColumn("question", tm.Get("Question"), isMobile));
    generator.AddColumn(textColumn("code", tm.Get("Code"), isMobile));
    generator.AddColumn(commandsColumn(viewDetailsHandler, tm.Get("Details")));
}
