import React, { useState, useCallback } from "react"
import { Typography, Dialog, DialogContent, DialogActions, TextField, Stack } from "@mui/material"

import useTranslations from "helpers/hooks/useTranslations";
import DialogTitleWithClose from "components/Dialog/DialogTitleWithClose";
import ErrorAdornment from "components/Common/ErrorAdornment";
import OKCancelControl from "components/Common/OKCancelControl";

interface IProps {
    open: boolean;
    close: () => void;
    handleAdd: (name: string) => void;
}

const AddSchedule: React.FC<IProps> = ({open, close, handleAdd}) => {
    const tm = useTranslations();
    const message = tm.Get("Create a new schedule by specifying a name.");

    const [name, setName] = useState<string>("");
    const [errorText, setErrorText] = useState<string>("");

    const handleNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    }, []);

    const validate = useCallback((name: string) => {
        if (name.trim() === "") {
            return tm.Get("The schedule name cannot be an empty string.")
        }
        return "";
    }, [tm]);

    const handleNameBlur = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
        setErrorText(validate(e.target.value));
    }, [validate]);

    const trySubmit = useCallback((name: string) => {
        let error = validate(name);
        if (error === "") {
            handleAdd(name);
        } else {
            setErrorText(error);
        }
    }, [handleAdd, validate]);

    return <>
        <Dialog fullWidth
            onClose={close}
            open={open}
        >
            <DialogTitleWithClose onClose={close}>
                {tm.GetWithParams("Add {0}", tm.Get("Schedule"))}
            </DialogTitleWithClose>
            <DialogContent>
                <Stack direction="column" alignItems="flex-start" justifyItems="stretch" spacing={2}>
                    <Typography whiteSpace="pre-wrap">
                        {message}
                    </Typography>
                    <TextField size="small" label={tm.Get("Schedule Name")} value={name} onChange={handleNameChange} onBlur={handleNameBlur} fullWidth
                        error={errorText !== ""}
                        InputProps={{
                            endAdornment: errorText !== "" ? 
                                <ErrorAdornment validationError={errorText}/> : null
                        }}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <OKCancelControl okCommand={() => trySubmit(name.trim())} okText={tm.Get("Add")} cancelCommand={close} />
            </DialogActions>
        </Dialog>
    </>
}

export default AddSchedule;