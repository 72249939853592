import { Stack, Box, Button, useTheme, Snackbar, Alert, SnackbarCloseReason, Container } from "@mui/material";
import { GridCallbackDetails, GridRowIdGetter, GridRowModel, GridRowParams, GridSelectionModel } from "@mui/x-data-grid-pro";
import CustomDataGridPro from "components/Common/CustomDataGridPro";
import DataGridColumnGenerator from "components/Common/DataGridColumnGenerator";
import MaintenanceAPI from "components/Maintenance/MaintenanceAPI";
import { AddColumnsForType } from "components/Maintenance/MaintenanceColumns";
import MaintenanceQuoteEdit from "components/Maintenance/MaintenanceQuoteEdit";
import UploadMessageBox from "components/Maintenance/UploadMessageBox";
import { MaintenanceActionEnum, MaintenanceData, useMaintenanceData } from "components/Maintenance/useMaintenanceData";
import ReportSelectionDialog from "components/Reports/ReportSelectionDialog";
import useApplicationInfo from "helpers/context/Application/useApplicationInfo";
import useDefaultActionButtons from "helpers/context/Page/useDefaultActionButtons";
import useMessageBox from "helpers/context/Page/useMessageBox";
import useWait from "helpers/context/Page/useWait";
import useReports, { IReportSelection } from "helpers/context/SelectionValues/useReports";
import useWindowTitle from "helpers/context/Title/useWindowTitle";
import useUserInfo from "helpers/context/User/useUserInfo";
import { ClassificationTypeEnum, CompanyPermissionEnum, RoleEnum, UserPermissionTypes } from "helpers/enums";
import useCurrencyFormatter from "helpers/hooks/useCurrencyFormatter";
import useFileDownload from "helpers/hooks/useFileDownload";
import useIsMobile from "helpers/hooks/useIsMobile";
import useRowsPerPage from "helpers/hooks/useRowsPerPage";
import useTranslations from "helpers/hooks/useTranslations";
import { IMaintenanceEditQuoteSubmit } from "helpers/interfaces";
import { IMaintenanceQuote } from "models/IMaintenanceQuote";
import { IUploadedOrder } from "models/IUploadedOrder";
import React, { useEffect, useCallback, useMemo } from "react";
import { useNavigate } from "react-router";
import SelectStatusType from "./SelectStatusType";
import useSelectionValuesData from "helpers/context/SelectionValues/useSelectionValuesData";

const Maintenance: React.FC<any> = () => {

    const user = useUserInfo();
    const theme = useTheme();
    const isMobile = useIsMobile();
    const tm = useTranslations();
    const cf = useCurrencyFormatter();
    const appInfo = useApplicationInfo();
    const navigate = useNavigate();
    const messageBox = useMessageBox();
    const rowsPerPage = useRowsPerPage("Maintenance");
    const wait = useWait();

    useWindowTitle(tm.Get("Quote Maintenance"));
    useDefaultActionButtons();
    const { maintenanceClassification } = useSelectionValuesData();

    const [mfgCustomerName, setMfgCustomerName] = React.useState<string | null>(null);
    const [mfgSiteName, setMfgSiteName] = React.useState<string | null>(null);

    const [pagedData, dataDispatch] = useMaintenanceData(maintenanceClassification, rowsPerPage.pageSize);

    const [editDialogOKey, setEditDialogOKey] = React.useState<number | null>(null);

    const [submitVisible, setSubmitVisible] = React.useState<boolean>(false);
    const [uploadVisible, setUploadVisible] = React.useState<boolean>(false);
    const [multiDeleteVisible, setMultiDeleteVisible] = React.useState<boolean>(false);
    const [selectedRows, setSelectedRows] = React.useState<number[]>([]);
    const [uploadClicked, setUploadClicked] = React.useState<boolean>(false);

    const [isReportSelectionVisible, setIsReportSelectionVisible] = React.useState<boolean>(false);
    const [okeyToPrint, setOkeyToPrint] = React.useState<number | null>(null);
    const [alertText, setAlertText] = React.useState<string | undefined>(undefined);

    const { reportSelections, getReportDownloadAPI } = useReports("web", "quote");
    const { reportSelections: reportSelectionsOrder, getReportDownloadAPI: getReportDownloadAPIOrder } = useReports("core", "order");

    const canCreateModifyQuotes = useMemo(() => {
        return user.HasPermission(UserPermissionTypes.CreateModifyQuotes);
    }, [user]);

    const canDeleteQuotes = useMemo(() => {
        return user.HasPermission(UserPermissionTypes.DeleteQuotes);
    }, [user]);

    const handleChangeMfgCustomerName = (customerName: string | null, siteName: string | null) => {
        setMfgCustomerName(customerName);
        setMfgSiteName(siteName);
    };

    const fileDownload = useFileDownload({
        preDownload: () =>  wait.Show(true),
        postDownload: () =>  wait.Show(false),
        onError: () => handlePrintError(),
    });

    const handlePrintError = () => {
        wait.Show(false);
        setAlertText(tm.Get("An unknown error has occurred."));
    };

    const isUploadClassification = useMemo(() => {
        return pagedData?.data.some((p: IMaintenanceQuote | IUploadedOrder) => (p as IUploadedOrder).oKeyQuote);
    }, [pagedData]);

    const submitMessage = tm.Get("You are about to submit the selected quotes. You are responsible for the content of each quote. Do you want to proceed?");
    const multiDeleteMessage = tm.Get("Are you sure you want to delete the selected quotes?");

    const getRowId: GridRowIdGetter = (row: GridRowModel) => row["oKey"] + "|" + Date.now().toString();
    const onPageSizeChange = (pageSize: number, details: GridCallbackDetails) => rowsPerPage.setPageSize(pageSize);

    const ShouldIncludeQuote = useCallback((quote: any) => {
        if (mfgCustomerName) {

            //Default to like search on the customer name
            let filterValue = mfgCustomerName;

            if (mfgSiteName) {
                if (mfgSiteName === mfgCustomerName) {
                    //Match the exact string for the 'default' site
                    filterValue = `^${mfgCustomerName}$`
                } else {
                    //Match the exact customer site name 
                    filterValue = `${mfgCustomerName}\\[${mfgSiteName}\\]`;
                }
            }

            const quoteValue = quote["mfgSiteName"];
            let compare = new RegExp(filterValue, "i"); //i for ignore case
            return compare.test(quoteValue);
        }

        return true;

    }, [mfgCustomerName, mfgSiteName]);

    useEffect(() => {
        if (maintenanceClassification !== null && (maintenanceClassification.classificationType === ClassificationTypeEnum.Available || maintenanceClassification.classificationType === ClassificationTypeEnum.AvailableBeforeUpload) &&
            user.HasRole(RoleEnum.Dealer)) {
            setUploadVisible(user.HasPermission(UserPermissionTypes.UploadQuotes) && user.HasCompanyPermission(CompanyPermissionEnum.UploadQuotes));
        } else {
            setUploadVisible(false);
        }

        if (maintenanceClassification !== null && (maintenanceClassification.classificationType === ClassificationTypeEnum.Available || maintenanceClassification.classificationType === ClassificationTypeEnum.AvailableBeforeUpload) &&
            user.HasRole(RoleEnum.Contractor)) {
            setSubmitVisible(user.HasPermission(UserPermissionTypes.SubmitQuotes));
        } else {
            setSubmitVisible(false);
        }

        if (maintenanceClassification !== null && maintenanceClassification.classificationType === ClassificationTypeEnum.Expired) {
            setMultiDeleteVisible(user.HasPermission(UserPermissionTypes.DeleteQuotes));
        } else {
            setMultiDeleteVisible(false);
        }
    }, [maintenanceClassification, user]);

    const quotes = React.useMemo(() => {
        if (pagedData) {
            //Casting as any is required to work around 
            // https://github.com/microsoft/TypeScript/issues/44373
            const anyData = pagedData.data as any[];
            const filtered = anyData.filter(q => ShouldIncludeQuote(q));
            return filtered;
        } else {
            return null;
        }
    }, [pagedData, ShouldIncludeQuote]);


    const copyQuoteHandler = useCallback((okey: number) => {
        wait.Show(true);
        MaintenanceAPI.CopyQuote(okey).then((newOkey) => {
            navigate(`/quotes/?oKey=${newOkey}`);
        }).finally(() => {
            wait.Show(false);
        });
    }, [wait, navigate]);

    const deleteQuoteHandler = useCallback((okey: number) => {
        wait.Show(true);
        MaintenanceAPI.DeleteQuote(okey).then(() => {
            //remove this guy from quotes 
            if (pagedData && maintenanceClassification) {
                const anyData = pagedData.data as any[];
                const newData = anyData.filter((row: IUploadedOrder | IMaintenanceQuote) => row.oKey !== okey) as IMaintenanceQuote[];
                const newPagedData: MaintenanceData = {
                    data: newData,
                    totalRecords: newData.length
                };
                dataDispatch({ type: MaintenanceActionEnum.AddOrUpdateData, value: [maintenanceClassification.classificationID, newPagedData] });
            }
        }).finally(() => {
            wait.Show(false);
        });
    }, [wait, pagedData, maintenanceClassification, dataDispatch]);

    const editQuoteHandler = useCallback((okey: number) => {
        setEditDialogOKey(okey);
    }, []);

    const dialogCloseHandler = useCallback((newState: IMaintenanceEditQuoteSubmit | null) => {

        if (newState) {
            // Update the current record with new values
            if (pagedData && maintenanceClassification) {
                const anyData = pagedData.data as any[];

                //Add new distach to update single row and look up 
                const row = anyData.find((d) => d.oKey === newState.okey);

                if (row) {

                    const rowIndex = anyData.indexOf(row);

                    const quote: IMaintenanceQuote = { ...row } as IMaintenanceQuote;
                    quote.poNumber = newState.poNumber;
                    quote.customerRef = newState.customerRef;
                    quote.requestDate = newState.requestDate?.toISOString();

                    const newData = anyData.slice(0);
                    newData[rowIndex] = quote;

                    const newPagedData: MaintenanceData = {
                        data: newData,
                        totalRecords: newData.length
                    };
                    dataDispatch({ type: MaintenanceActionEnum.AddOrUpdateData, value: [maintenanceClassification.classificationID, newPagedData] });

                }

            }

        }

        setEditDialogOKey(null);

    }, [pagedData, maintenanceClassification, setEditDialogOKey, dataDispatch]);

    const printHandler = useCallback((okey: number) => {
        setOkeyToPrint(okey);
        setIsReportSelectionVisible(true);
    }, []);

    const selectionModelChangeHandler = useCallback((selectedRows: GridSelectionModel) => {
        var newSet: number[] = [];
        selectedRows.forEach((row) => newSet.push(Number(row.valueOf().toString().split("|")[0])));
        newSet.sort();
        setSelectedRows(newSet);
    }, []);

    const isQuoteSelectable = useCallback((quote: GridRowParams) => {
        if (submitVisible) {
            return true;
        } else if (uploadVisible) {
            if (user.HasPermission(UserPermissionTypes.UploadQuotesWithExceptions) && user.HasCompanyPermission(CompanyPermissionEnum.UploadQuotesWithExceptions)) {
                return true;
            } else {
                return !quote.row.hasExceptions;
            }
        } else if (multiDeleteVisible) {
            return true;
        } else {
            return false;
        }
    }, [submitVisible, uploadVisible, multiDeleteVisible, user]);

    const handleUploadClick = useCallback(() => {
        if (selectedRows.length > 0) {
            setUploadClicked(true);
        } else {
            messageBox.Show({ message: tm.Get("No quotes were selected"), title: tm.Get("Quote Maintenance") });
        }
    }, [selectedRows, messageBox, tm]);

    const handleUpload = useCallback(() => {
        setUploadClicked(false);
        wait.Show(true);
        MaintenanceAPI.UploadQuotes(selectedRows).then((quotes) => {
            navigate("/maintenance/confirmation", { state: quotes });
        }).finally(() => {
            wait.Show(false);
        });
    }, [wait, selectedRows, navigate]);

    const handleSubmitClick = useCallback(() => {
        if (selectedRows.length > 0) {
            messageBox.Show({
                message: submitMessage, title: tm.Get("Quote Maintenance"), yesNoPrompt: true,
                callback: (dialogResult?) => {
                    if (dialogResult) {
                        wait.Show(true);
                        MaintenanceAPI.SubmitQuotes(selectedRows).then((quotes) => {
                            navigate("/maintenance/confirmation", { state: quotes });
                        });
                    }
                }
            });
        } else {
            messageBox.Show({ message: tm.Get("No quotes were selected"), title: tm.Get("Quote Maintenance") });
        }
    }, [wait, selectedRows, messageBox, submitMessage, tm, navigate]);

    const handleMultiDeleteClick = useCallback(() => {
        if (selectedRows.length > 0) {
            messageBox.Show({
                message: multiDeleteMessage, title: tm.Get("Quote Maintenance"), yesNoPrompt: true,
                callback: (dialogResult?) => {
                    if (dialogResult) {
                        wait.Show(true);
                        MaintenanceAPI.MultiDeleteQuotes(selectedRows).then(() => {
                            //remove these guys from quotes 
                            if (pagedData && maintenanceClassification) {
                                let anyData = pagedData.data as any[];
                                selectedRows.forEach((okey: number) => anyData = anyData.filter((row: IUploadedOrder | IMaintenanceQuote) => row.oKey !== okey));
                                const newPagedData: MaintenanceData = {
                                    data: anyData as IMaintenanceQuote[],
                                    totalRecords: anyData.length
                                };
                                dataDispatch({ type: MaintenanceActionEnum.AddOrUpdateData, value: [maintenanceClassification.classificationID, newPagedData] });
                            }
                        }).finally(() => {
                            wait.Show(false);
                        });
                    }
                }
            });
        } else {
            messageBox.Show({ message: tm.Get("No quotes were selected"), title: tm.Get("Quote Maintenance") });
        }
    }, [wait, selectedRows, pagedData, maintenanceClassification, messageBox, multiDeleteMessage, tm, dataDispatch]);

    const generator = new DataGridColumnGenerator(tm, quotes, theme, isMobile, cf);
    AddColumnsForType(maintenanceClassification?.classificationType, generator, appInfo, user, quotes, printHandler,
        canCreateModifyQuotes ? copyQuoteHandler : null,
        canDeleteQuotes ? deleteQuoteHandler : null,
        canCreateModifyQuotes ? editQuoteHandler : null);

    const showRequestDate: boolean = React.useMemo(() => {
        if (pagedData && pagedData.data.length > 0) {
            const firstRecord = pagedData.data[0];
            return firstRecord.hasOwnProperty("requestDate"); //FUTURE: there doesn't seem to be a nameof operator in typescript.  Investigate a way to reference IMaintetanceEditQuote.requestDate instead of using a string.
        }

        return false;

    }, [pagedData]);

    const onReportSelected = useCallback((selectedReport: IReportSelection): void => {
        setIsReportSelectionVisible(false);
        if (okeyToPrint) {
            if (isUploadClassification) {
                fileDownload.download({ apiCall: () => getReportDownloadAPIOrder(okeyToPrint, selectedReport.reportID, "PDF") });
            } else {
                fileDownload.download({ apiCall: () => getReportDownloadAPI(okeyToPrint, selectedReport.reportID, "PDF") });
            }
        }
    }, [okeyToPrint, isUploadClassification, fileDownload, getReportDownloadAPI, getReportDownloadAPIOrder]);

    const onAlertClose = useCallback((event: React.SyntheticEvent<Element, Event>) => {
        setAlertText(undefined);
    }, []);

    const onAlertCloseSnackbar = useCallback((event: Event | React.SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => {
        if (reason === 'clickaway')
            return;

        setAlertText(undefined);
    }, []);

    return <Container maxWidth="xl" disableGutters>

        <ReportSelectionDialog
            title={tm.Get("Print")}
            dialogVisible={isReportSelectionVisible}
            reports={isUploadClassification ? reportSelectionsOrder : reportSelections}
            onSubmit={onReportSelected}
            onCancel={() => setIsReportSelectionVisible(false)}
        />

        <Snackbar open={alertText ? true : false} autoHideDuration={5000} onClose={onAlertCloseSnackbar} >
            <Alert onClose={onAlertClose} severity='error' variant='filled' sx={{ width: '100%', fontWeight: 'bold' }}>{alertText}</Alert>
        </Snackbar>

        {/* Hidden link to host file downloads */}
        <a hidden ref={fileDownload.ref} />

        <Stack direction="column" m={1} spacing={1}>

            <SelectStatusType
                handleChangeMfgCustomerName={handleChangeMfgCustomerName}
            />

            <CustomDataGridPro
                onSelectionModelChange={selectionModelChangeHandler}
                isRowSelectable={isQuoteSelectable}
                onPageSizeChange={onPageSizeChange}
                pageSize={rowsPerPage.pageSize}
                rowsPerPageOptions={rowsPerPage.pageSizeOptions}
                getRowId={getRowId}
                columns={generator.GetColumns()}
                rows={quotes}
                checkboxSelection={uploadVisible || submitVisible || multiDeleteVisible}
            />

            {uploadVisible &&
                <Box display="flex" alignItems="center" justifyContent="center">
                    <Button color="secondary" onClick={handleUploadClick} variant="contained">{tm.Get("Upload Selected Quotes To Manufacturer")}</Button>
                </Box>
            }

            {submitVisible &&
                <Box display="flex" alignItems="center" justifyContent="center">
                    <Button color="secondary" onClick={handleSubmitClick} variant="contained">{tm.Get("Submit Selected Quotes")}</Button>
                </Box>
            }

            {multiDeleteVisible &&
                <Box display="flex" alignItems="center" justifyContent="center">
                    <Button color="secondary" onClick={handleMultiDeleteClick} variant="contained">{tm.GetWithParams("Delete {0}", tm.Get("Quotes"))}</Button>
                </Box>
            }
        </Stack>

        {editDialogOKey &&
            <MaintenanceQuoteEdit
                okey={editDialogOKey ?? 0}
                open={(editDialogOKey != null)}
                close={dialogCloseHandler}
                showRequestDate={showRequestDate}
            />
        }

        {uploadClicked &&
            <UploadMessageBox
                open={uploadClicked}
                close={() => setUploadClicked(false)}
                handleUpload={handleUpload}
            />
        }

    </Container>;

}



export default Maintenance;
