import React, { useState, useCallback } from 'react';
import { TableCell, TableRow, TextField } from "@mui/material";
import { useFormContext } from "react-hook-form";
import Constants from "helpers/constants";
import useTranslations from "helpers/hooks/useTranslations";
import ErrorAdornment from "components/Common/ErrorAdornment";
import useLocaleNumberFormatter from "helpers/hooks/useLocaleNumberFormatter";

export interface ITaxDetailProps {
    index: number;
    title: string;
    percent: number;
    percentChanged: boolean;
}

interface IProps {
    initialState: ITaxDetailProps;
    isReadOnly: boolean;
}

const TaxDetail: React.FC<IProps> = (props: IProps) => {

    return <>
        <TableRow>
            <TableCell>
                <span style={{ fontWeight: "bold" }}>
                    {props.initialState.title}
                </span>
            </TableCell>
            <TableCell align="right">
                <TaxRateCell {...props} />
            </TableCell>
        </TableRow>
    </>;
};

const TaxRateCell: React.FC<IProps> = (props: IProps) => {

    const tm = useTranslations();
    const lnf = useLocaleNumberFormatter({style: "decimal", useGrouping: false, minimumFractionDigits: 4, maximumFractionDigits: 4});
    const { register } = useFormContext();
    const fieldName = `taxes.${props.initialState.index}.percent`;
    const [validationError, setValidationError] = useState<string>("");

    const validateValue = useCallback((value: string): string | boolean => {

        if (!value) {
            const message = tm.Get("Required");
            return message;
        }
        else {
            const floatValue = lnf.Parse(value);
            const minValue = Constants.Min.TaxRate;
            const maxValue = Constants.Max.TaxRate;

            if (isNaN(floatValue)) {
                const message = tm.Get("Invalid value");
                return message;
            }
            else {
                if (floatValue > maxValue || floatValue < minValue) {
                    const message = tm.Get("Invalid value");
                    return message;
                }
            }
        }

        return true;
    }, [tm, lnf]);

    const onTextChanged = useCallback((e: any) => {
        let result = validateValue(e.target.value);
        if (typeof result === 'string') {
            setValidationError(result);
        }
        else {
            setValidationError("");
        }
    }, [validateValue]);

    const { onChange, ...restOfRegister } = register(fieldName, { validate: validateValue });

    const handleOnChange = useCallback((e: any) => {
        onChange(e);
        onTextChanged(e);
    }, [onChange, onTextChanged]);

    return <>
        {props.isReadOnly ?
            lnf.Format(props.initialState.percent)
            :
            <TextField
                variant="standard"
                inputProps={{ style: { textAlign: "right" } }}
                {...restOfRegister}
                defaultValue={lnf.Format(props.initialState.percent)}
                error={validationError ? true : false}
                onChange={handleOnChange}
                InputProps={{
                    endAdornment:
                        validationError ? <>% <ErrorAdornment validationError={validationError} /></> : "%"
                }}
            />
        }
    </>;
};

export default TaxDetail;