import { useMemo } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { Error } from "@mui/icons-material";

import { ThemeColorEnum } from "helpers/enums";
import useTranslations from "helpers/hooks/useTranslations";
import { IGlobalItem } from "helpers/interfaces";

interface IProps {
    globalItem: IGlobalItem,
    onClick: (globalItem: IGlobalItem) => void,
}

const ExceptionIcon: React.FC<IProps> = ({ globalItem, onClick }: IProps) => {

    // Prevent any of the links in this section from acting as "submit"
    const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();
    const tm = useTranslations();

    const tooltip = useMemo(() => {
        return tm.Get("Selection Required")
    }, [tm]);

    return <div onClick={preventDefault}>
        <Tooltip title={tooltip}>
            <IconButton sx={{ padding: 0 }} color={ThemeColorEnum.Error} onClick={(_) => onClick(globalItem)}>
                <Error />
            </IconButton>
        </Tooltip>
    </div>;

};

export default ExceptionIcon;


