import { Accordion, AccordionDetails, Button, Grid, Stack, Typography } from "@mui/material";
import AccordionSummarySlim from "components/Common/AccordionSummarySlim";
import { ThemeColorEnum } from "helpers/enums";
import useTranslations from "helpers/hooks/useTranslations";
import { ResizeBase64Image } from "helpers/ImageHelper";
import React, { useRef } from 'react';
import CompanyLogoImage from "./CompanyLogoImage";

interface IProps {
    readOnly: boolean,
    isLoading: boolean,
    companyLogoBase64: string | null,
    localLogoFileBase64: string | null,
    setLocalLogoFileBase64: (value: string | null) => void,
    logoCleared: boolean,
    setLogoCleared: (value: boolean) => void
}

const validImageExtensions = ".APNG, .AVIF, .GIF, .JPEG, .PNG, .SVG, .WebP";

const CompanyLogo: React.FC<IProps> = (props: IProps) => {

    const tm = useTranslations();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const resizeImageWidth: number = 125;
    const resizeImageHeight: number = 125;
    
    const [isExpanded, setIsEpanded] = React.useState<boolean>(true);
    const [logoFileName, setLogoFileName] = React.useState<string | null>(null);

    const handleLogoSelect = () => {
        if (!fileInputRef.current?.files) return;
        const imageFile = fileInputRef.current.files[0];
        let reader = new FileReader();
        reader.onload = async (e) => {
            if (reader.DONE && typeof reader.result === "string") {
                let scaledLogo: string = await ResizeBase64Image(reader.result, resizeImageWidth, resizeImageHeight);
                props.setLogoCleared(false);
                props.setLocalLogoFileBase64(scaledLogo);
                setLogoFileName(imageFile.name);
            } else {
                handleClearLogo();
            }
        }
        reader.readAsDataURL(imageFile)
    }

    const handleClearLogo = () => {
        props.setLogoCleared(true);
        props.setLocalLogoFileBase64(null);
        setLogoFileName(null);
        if (fileInputRef?.current?.value)
            fileInputRef.current.value = "";
    }

    const prepareImageSource = React.useMemo(() => {
        //If we have a local file, it means the user just selected it and we want it to show
        if (props.localLogoFileBase64 !== null) {
            return props.localLogoFileBase64;
        //If the logo has been cleared, don't show the db image
        } else if (props.logoCleared) {
            return null;
        //If we don't have a local file selected, check if the database returned a logo and append a mime type to it
        } else if (props.companyLogoBase64 !== null) {
            return `data:image;base64,${props.companyLogoBase64}`;
        } else {
            return null;
        }
    }, [props.companyLogoBase64, props.localLogoFileBase64, props.logoCleared])

    return <>
        <Accordion expanded={isExpanded} onChange={() => setIsEpanded((prev) => !prev)} disableGutters >
            <AccordionSummarySlim>
                <Typography>
                    {tm.Get("Logo")}
                </Typography>
            </AccordionSummarySlim>
            <AccordionDetails>
                <Grid container mt={1}>
                    <Grid item xs={12} sm="auto">
                        <CompanyLogoImage imageSrc={prepareImageSource} isLoading={props.isLoading} />
                    </Grid>
                    <Grid item xs={12} sm sx={{ marginLeft: { xs: 0, sm: 3 } }}>
                        {!props.readOnly &&
                            <Stack direction="column" spacing={1} width="fit-content">
                                {prepareImageSource ?
                                    <Button variant="contained" color={ThemeColorEnum.Primary} onClick={handleClearLogo} size="small" sx={{minWidth: resizeImageWidth}}>
                                        {tm.Get("Clear Logo")}
                                    </Button> :
                                    <Button variant="contained" size="small" component="label" sx={{minWidth: resizeImageWidth}} color={ThemeColorEnum.Secondary}>
                                        {tm.Get("Choose File")}
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            hidden
                                            onChange={handleLogoSelect}
                                            accept={validImageExtensions}
                                        />
                                    </Button>
                                }
                                {logoFileName && <Typography>{logoFileName.length > 30 ? `${logoFileName.substring(0, 30)}...` : logoFileName}</Typography>}
                            </Stack>
                        }
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    </>
}

export default CompanyLogo;