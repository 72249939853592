import React, { useEffect } from 'react';
import useWindowTitle from "../../helpers/context/Title/useWindowTitle";
import useTranslations from "../../helpers/hooks/useTranslations";
import useActionButtons from '../../helpers/context/Page/useActionButtons';
import { IActionButton } from "../../helpers/context/Page/PageContext";
import { SecurityLevelEnum, ThemeColorEnum } from "../../helpers/enums";
import UserAPI from "./UserAPI";
import { IProfileInfo, IProfileUpdate } from "../../helpers/interfaces";
import ProfileInfo from "./ProfileInfo";
import ChangePassword from "./ChangePassword";
import { useForm, FormProvider } from "react-hook-form";
import useMessageBox from "../../helpers/context/Page/useMessageBox";
import useUserInfo from "../../helpers/context/User/useUserInfo";
import useWait from "../../helpers/context/Page/useWait";
import { Snackbar, Alert, AlertColor, Container } from "@mui/material";
import { useEffectOnLoad } from "helpers/hooks/useEffectOnLoad";
import useNavigationBlocker from "helpers/context/Application/useNavigationBlocker";
import useNavigationBlockerActions from "helpers/context/Application/useNavigationBlockerActions";

enum Mode {
    info = 0,
    edit = 1,
    password = 2
};

const emptyProfile: IProfileInfo = {
    userName: "",
    authenticationType: SecurityLevelEnum.Standard,
    firstName: "",
    lastName: "",
    emailAddress: ""
}

const Profile: React.FC = () => {

    const tm = useTranslations();
    useWindowTitle(tm.Get("Profile"));
    const messageBox = useMessageBox();
    const wait = useWait();
    const user = useUserInfo();
    const actionButtons = useActionButtons();
    const formMethods = useForm({ mode: 'onChange', defaultValues: emptyProfile });
    const { handleSubmit, reset } = formMethods;
    const navBlockerActions = useNavigationBlockerActions();

    const [profileInfo, setProfileInfo] = React.useState<IProfileInfo | null>(null);
    const [mode, setMode] = React.useState<Mode>(Mode.info);
    const [alert, setAlert] = React.useState<[text: string, alertType: AlertColor] | null>(null);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isPosting, setIsPosting] = React.useState<boolean>(false);

    useNavigationBlocker((mode === Mode.edit) && formMethods.formState.isDirty);

    const onProfileSubmit = async (formData: any, e: any) => {
        if (formData["confirmPassword"]) {
            const newPassword = formData["confirmPassword"];
            setIsPosting(true);
            let result = await UserAPI.UpdateUserPassword(user.employeeID, newPassword)
            try {
                if (result.message) {
                    const translated = tm.Get(result);
                    setAlert([translated, "error"]);
                } else {
                    setAlert([tm.Get("Your password has been updated."), "success"]);
                    setMode(Mode.info);
                }
            } finally {
                setIsPosting(false);
            }
        } else {
            const newInfo: IProfileUpdate = {
                employeeID: user.employeeID,
                firstName: formData["firstName"],
                lastName: formData["lastName"],
                emailAddress: formData["emailAddress"],
                password: null
            };
            try {
                setIsPosting(true);
                await UserAPI.UpdateProfileInfo(newInfo);
                await getProfileInfo();
                if (mode === Mode.password) {
                    setAlert([tm.Get("Your password has been updated."), "success"]);
                } else {
                    setAlert([tm.Get("Changes have been saved."), "success"]);
                }
                setMode(Mode.info);
            } finally {
                setIsPosting(false);
            }
        }
    }

    const onError = (errors: { [x: string]: any }, e: any) => {
        console.log(errors, e);
        let messages: any[] = [];
        for (const key in errors) {
            messages.push(errors[key].message);
        }
        const formattedMessage = messages.join("\n");

        if (formattedMessage) {
            messageBox.Show({ message: formattedMessage, title: tm.Get("Please correct before saving.") });
        }
    }

    const getProfileInfo = async () => {
        try {
            setIsLoading(true);
            let result = await UserAPI.GetProfileInfo();
            if (result) {
                setProfileInfo(result);
                reset(result);
            } else {
                setAlert([tm.Get("An unknown error has occurred."), "error"]);
            }
        } finally {
            setIsLoading(false);
        }
    }

    useEffectOnLoad(() => {
        getProfileInfo();
    });

    useEffect(() => {
        wait.Show(isLoading || isPosting);
    }, [isLoading, isPosting, wait])

    useEffect(() => {
        actionButtons.SetBackButton(0, "/dashboard");
        if (mode === Mode.info) {
            const editButton: IActionButton = {
                text: tm.Get("Edit"),
                color: ThemeColorEnum.Secondary,
                disabled: (profileInfo === null || isLoading || isPosting),
                onClick: (() => setMode(Mode.edit))
            };
            const passwordButton: IActionButton = {
                text: tm.Get("Change Password"),
                color: ThemeColorEnum.Secondary,
                disabled: (profileInfo === null || isLoading || isPosting),
                onClick: (() => setMode(Mode.password))
            }
            actionButtons.Set(1, editButton);
            actionButtons.Set(2, passwordButton);

        }
        else {
            const saveButton: IActionButton = {
                text: tm.Get("Save"),
                color: ThemeColorEnum.Secondary,
                disabled: (isLoading || isPosting) ? true : false,
                type: "submit",
                form: "profileForm",
                onClick: (() => navBlockerActions.Unblock())
            };
            const cancelButton: IActionButton = {
                text: tm.Get("Cancel"),
                color: ThemeColorEnum.Primary,
                disabled: (isLoading || isPosting) ? true : false,
                onClick: () => {
                    reset();
                    setMode(Mode.info);
                    navBlockerActions.Unblock();
                }
            };
            actionButtons.Set(1, saveButton);
            actionButtons.Set(2, cancelButton);
        }
    }, [profileInfo, mode, isLoading, isPosting, navBlockerActions, actionButtons, reset, tm]);

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway')
            return;
        setAlert(null);
    }

    return <>
        <Snackbar open={alert !== null} autoHideDuration={5000} onClose={handleClose} >
            <Alert onClose={handleClose} severity={alert ? alert[1] : "info"} variant='filled' sx={{ width: '100%', fontWeight: 'bold' }}>{alert ? alert[0] : ""}</Alert>
        </Snackbar>
        {profileInfo &&
            <Container>
                <FormProvider {...formMethods}>
                    <form id="profileForm" onSubmit={handleSubmit(onProfileSubmit, onError)}>
                        {mode === Mode.password ? <ChangePassword /> : <ProfileInfo profileInfo={profileInfo} isEditMode={mode === Mode.edit} />}
                    </form>
                </FormProvider>
            </Container>
        }
    </>
}

export default Profile;