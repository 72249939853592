import { Autocomplete, TextField } from "@mui/material";
import useUserInfo from "helpers/context/User/useUserInfo";
import { RoleEnum } from "helpers/enums";
import useTranslations from "helpers/hooks/useTranslations";
import { IWebCustomer } from "models/IWebCustomer";
import React from 'react';
import useUserSetupActions from "./useUserSetupActions";

interface IProps {
    customers: IWebCustomer[] | null,
    setSelectedCustomer: (customer: IWebCustomer) => void
}

const CustomerSelect: React.FC<IProps> = (props: IProps) => {

    const tm = useTranslations();
    const user = useUserInfo();
    const userActions = useUserSetupActions();

    const companyCustomer: IWebCustomer = {
        customerGUID: "-1",
        customerID: "-1",
        siteID: "-1",
        name: tm.Get("{Company}"),
        isDefault: false,
        isInactive: false
    }

    let dataSource: IWebCustomer[] = props.customers ? [companyCustomer, ...props.customers] : [];

    return <Autocomplete
        id="select-customer"
        autoComplete
        autoSelect
        autoHighlight
        selectOnFocus
        handleHomeEndKeys
        options={dataSource}
        disabled={dataSource === null}
        isOptionEqualToValue={(option, value) => option.customerGUID === value.customerGUID}
        getOptionLabel={(option: IWebCustomer) => option ? option.name : ""}
        renderInput={(params) => (
            <TextField
                variant="outlined"
                {...params}
                InputProps={{ ...params.InputProps }}
            />
        )}
        onChange={(_, data: IWebCustomer | null) => {
            if (data) {
                userActions.SetIsDealerCreatingAdminContractor(!user.HasRole(RoleEnum.Contractor) && data.customerGUID !== "-1");
                props.setSelectedCustomer(data);
            }
        }}
        defaultValue={dataSource ? companyCustomer : undefined}
    />
}

export default CustomerSelect;