import { Grid } from '@mui/material'
import useTranslations from '../../../helpers/hooks/useTranslations';
import Constants from 'helpers/constants';
import TextFieldForm from "components/Common/TextFieldForm";

interface IProps {
    addressPrefix: string,
    readOnly: boolean,
}

const showCountry: boolean = false;

function SelectAddress({ addressPrefix, readOnly }: IProps) {

    const tm = useTranslations();

    return <>
        <Grid container spacing={2} padding={0}>
            <Grid item xs={12}>
                <TextFieldForm
                    fieldName={addressPrefix + ".name"}
                    readOnly={false}
                    id="name"
                    variant="standard"
                    inputProps={{ readOnly: readOnly, maxLength: Constants.MaxLength.AddressName }}
                    placeholder={tm.Get("Name")}
                    label={tm.Get("Name")}
                    disabled={readOnly}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextFieldForm
                    fieldName={addressPrefix + ".address.address1"}
                    readOnly={false}
                    id="address1"
                    variant="standard"
                    inputProps={{ readOnly: readOnly, maxLength: Constants.MaxLength.Address1 }}
                    label={tm.Get("Address 1")}
                    disabled={readOnly}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextFieldForm
                    fieldName={addressPrefix + ".address.address2"}
                    readOnly={false}
                    id="address2"
                    variant="standard"
                    inputProps={{ readOnly: readOnly, maxLength: Constants.MaxLength.Address2 }}
                    label={tm.Get("Address 2")}
                    disabled={readOnly}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextFieldForm
                    fieldName={addressPrefix + ".address.city"}
                    readOnly={false}
                    id="city"
                    variant="standard"
                    inputProps={{ readOnly: readOnly, maxLength: Constants.MaxLength.City }}
                    placeholder={tm.Get("City")}
                    label={tm.Get("City")}
                    disabled={readOnly}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={2}>
                <TextFieldForm
                    fieldName={addressPrefix + ".address.state"}
                    readOnly={false}
                    id="state"
                    variant="standard"
                    inputProps={{ readOnly: readOnly, maxLength: Constants.MaxLength.State }}
                    placeholder={tm.Get("State")}
                    label={tm.Get("State")}
                    disabled={readOnly}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextFieldForm
                    fieldName={addressPrefix + ".address.zipCode"}
                    readOnly={false}
                    id="zipCode"
                    variant="standard"
                    inputProps={{ readOnly: readOnly, maxLength: Constants.MaxLength.ZipCode }}
                    placeholder={tm.Get("Zip Code")}
                    label={tm.Get("Zip Code")}
                    disabled={readOnly}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextFieldForm
                    fieldName={addressPrefix + ".phone"}
                    readOnly={false}
                    id="phone"
                    variant="standard"
                    inputProps={{ readOnly: readOnly, maxLength: Constants.MaxLength.Phone }}
                    placeholder={tm.Get("Phone")}
                    label={tm.Get("Phone")}
                    disabled={readOnly}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextFieldForm
                    fieldName={addressPrefix + ".fax"}
                    readOnly={false}
                    id="fax"
                    variant="standard"
                    inputProps={{ readOnly: readOnly, maxLength: Constants.MaxLength.Fax }}
                    placeholder={tm.Get("Fax")}
                    label={tm.Get("Fax")}
                    disabled={readOnly}
                    fullWidth
                />
            </Grid>
            {showCountry &&
                <Grid item xs={12} sm={6}>
                    <TextFieldForm
                        fieldName={addressPrefix + ".address.country"}
                        readOnly={false}
                        id="country"
                        variant="standard"
                        inputProps={{ readOnly: readOnly, maxLength: Constants.MaxLength.Country }}
                        placeholder={tm.Get("Country")}
                        label={tm.Get("Country")}
                        disabled={readOnly}
                        fullWidth
                    />
                </Grid>
            }
        </Grid>
    </>
}

export default SelectAddress;