import { FormControlLabel, Checkbox } from "@mui/material";
import { useFormContext, useController } from "react-hook-form";

import useTranslations from "helpers/hooks/useTranslations";

import { IQuotePermissions } from "helpers/interfaces";

interface IProps {
    permissions?: IQuotePermissions
}

function SelectIsAddressSameAsBilling({ permissions } : IProps) {

    const tm = useTranslations();
    const name ="shipping.isAddressSameAsBilling"
    const { control } = useFormContext();
    const { field } = useController({
        name: name,
        control: control,
     });

    return <>
        <FormControlLabel 
            control={
                <Checkbox 
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    ref={field.ref}
                    checked={field.value}
                    disabled={!permissions?.shippingAddress ?? true}
                />} 
            label={tm.Get("Same as billing address")} 
        />    
    </>

}

export default SelectIsAddressSameAsBilling;