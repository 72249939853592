import { TableContainer, Table, TableBody } from "@mui/material";
import React, { useCallback, useMemo } from "react";

import ResultHeader from "./Rows/ResultHeader";
import useGlobalOptions from "../useGlobalOptions";
import ResultRow from "./Rows/ResultRow";
import ResultColumn from "./Columns/ResultColumn";
import TextResultColumn from "./Columns/TextResultColumn";
import useTranslations from "helpers/hooks/useTranslations";
import NewValueColumn from "./Columns/NewValueColumn";
import Format from "helpers/fv.format"
import SelectResultColumn from "./Columns/SelectResultColumn";
import { IGlobalItem } from "models/GlobalOptions/IGlobalItem";
import { IGlobalItemChangeInfo } from "models/GlobalOptions/IGlobalItemChangeInfo";
import ExceptionIconColumn from "./Columns/ExceptionIconColumn";
import useMessageBox from "helpers/context/Page/useMessageBox";

export enum ResultsModeEnum {
    Savable,
    Unsavable
}

interface IProps {
    mode: ResultsModeEnum,
}

const ResultsGrid: React.FC<any> = ({ mode }: IProps) => {

    const globalOptions = useGlobalOptions();
    const { savableItems, unsavableItems, onGlobalItemSelected, isGlobalItemSelected, isGlobalItemEditEnabled } = globalOptions;
    const tm = useTranslations();
    const messageBox = useMessageBox();

    const results = useMemo(() => {
        switch (mode) {
            case ResultsModeEnum.Savable:
                return savableItems;
            case ResultsModeEnum.Unsavable:
                return unsavableItems;
        }

    }, [savableItems, unsavableItems, mode]);

    const onItemErrorClicked = useCallback((globalItem: IGlobalItem) => {
        let message: string;
        if (isGlobalItemEditEnabled(globalItem)) {
            message = tm.GetWithParams("A value must be selected for the option '{0}'", globalItem.changeInfo.newDescription);
        }
        else {
            message = tm.GetWithParams("An option must be selected for the required question '{0}'", globalItem.changeInfo.questionDescription);
        }
        messageBox.Show({ message: message });
    }, [messageBox, tm, isGlobalItemEditEnabled]);

    const columns = useMemo(() => {
        let cols: ResultColumn[] = [];

        if (mode === ResultsModeEnum.Savable) {
            cols.push(new SelectResultColumn("colSelect", "", "", "center", isGlobalItemSelected, onGlobalItemSelected));
        }
        else if (mode === ResultsModeEnum.Unsavable) {
            cols.push(new ExceptionIconColumn("colSelect", "", "", onItemErrorClicked));
        }
        cols.push(new TextResultColumn("colItem", tm.Get("Item"), "", "left", (li) => Format.FormatLineItem(li.lineItem, li.subLineItem)));
        cols.push(new TextResultColumn("colPart", tm.Get("Part"), "20%", "left", (li) => li.part));
        cols.push(new TextResultColumn("colQuestion", tm.Get("Question"), "15%", "left", (li) => li.changeInfo.questionDescription));
        cols.push(new TextResultColumn("colOldOption", tm.Get("Old Option"), "10%", "left", (li) => li.changeInfo.oldDescription));
        cols.push(new TextResultColumn("colOldValue", tm.Get("Old Value"), "10%", "left", (li) => li.changeInfo.oldValueDescription));
        cols.push(new TextResultColumn("colNewOption", tm.Get("New Option"), "10%", "left", (li) => li.changeInfo.newDescription));
        cols.push(new NewValueColumn("colNewValue", tm.Get("New Value"), "10%", "left", (li) => li.changeInfo.newValueDescription));
        return cols;
    }, [mode, tm, onItemErrorClicked, isGlobalItemSelected, onGlobalItemSelected]);

    const getChangeInfoHash = useCallback((changeInfo: IGlobalItemChangeInfo) => {
        return `${changeInfo.question}|${changeInfo.oldCode}:${changeInfo.oldValue}`;
    }, []);

    const getItemHash = useCallback((globalItem: IGlobalItem) => {
        return `${globalItem.lineItem}.${globalItem.subLineItem}:${getChangeInfoHash(globalItem.changeInfo)}`;
    }, [getChangeInfoHash]);


    return <>
        <TableContainer>
            <Table sx={{ width: "100%", tableLayout: "auto" }} size="small" padding="none">
                <ResultHeader columns={columns} />
                <TableBody>
                    {results?.map((li, index) => (
                        <ResultRow key={getItemHash(li)} globalOptions={globalOptions} columns={columns} globalItem={li} previousItem={results[index - 1]} />
                    )
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    </>;
}

export default ResultsGrid;
