import { GridColumns } from "@mui/x-data-grid-pro";
import { ITranslationManager } from "helpers/hooks/useTranslations";
import { IOrderHistoryItem } from "helpers/interfaces";
import { Theme } from "@mui/material";
import DataGridColumnGenerator from "components/Common/DataGridColumnGenerator";
import { ICurrencyFormatter } from "helpers/hooks/useCurrencyFormatter";
import ApiUser from "models/ApiUser";

export const generateOrderHistoryColumns = (tm: ITranslationManager, data: IOrderHistoryItem[] | null, theme: Theme, isMobile: boolean, cf: ICurrencyFormatter, canViewCost: boolean, user: ApiUser) => {

  if (!data || data.length === 0) return [] as GridColumns;

  const generator = new DataGridColumnGenerator(tm, data, theme, isMobile, cf);

  generator.TryAddDocumentNumberColumn("oKey", "orderNumber", "Order", "/orders", { hideable: false });
  generator.TryAddTextColumn("typeDesc", "Type", { hideInMobile: false });
  generator.TryAddDateColumn("date", "Date", { hideInMobile: false })
  generator.TryAddTextColumn("poNumber", "PO Number", { hideInMobile: true });
  generator.TryAddTextColumn("customerRef", "Customer Ref", { hideInMobile: true });
  if (canViewCost) {
    generator.TryAddTotalPriceColumn({});
  }
  if (user.isMultiSite) {
    generator.TryAddTextColumn("siteName", "Mfg Customer", { hideInMobile: false });
  }

  return generator.GetColumns();
}
