import React, { useCallback } from 'react';
import { Autocomplete, TextField, CircularProgress } from '@mui/material';

import { useController, useFormContext } from 'react-hook-form';
import useTranslations from 'helpers/hooks/useTranslations';

import useSalespersons from 'helpers/context/SelectionValues/useSalespersons';

import { IQuotePermissions } from "helpers/interfaces";

interface IProps {
    parentCustomerID: string,
    parentSiteID: string,
    permissions?: IQuotePermissions,
    [x: string] : any  // this should always be the last prop, to support any other props that will be passed along down the component chain
}

function SelectSalesperson ({ parentCustomerID, parentSiteID, permissions, ...rest }: IProps)
{

    const tm = useTranslations();
    
    const salespersonList = useSalespersons(parentCustomerID, parentSiteID);
    
    const { control } = useFormContext();
    const { field } = useController({
        name: "salespersonID", 
        control,
        rules: { required: false },
      });

    const selectedSalesperson = useCallback(() => {
        return salespersonList?.find((sp) => field.value && sp.employeeID === field.value) ?? null
    }, [salespersonList, field]);


    return <>
        <Autocomplete 
            id="salesperson"
            autoComplete
            autoSelect
            autoHighlight
            selectOnFocus
            handleHomeEndKeys
            options={salespersonList ?? []} 
            getOptionLabel={(option) => (option?.fullName || "")}
            disabled={!permissions?.salesperson ?? true}
            renderInput={(params) => (
                <TextField 
                    variant="outlined" 
                    {...params} 
                    label={tm.Get("Salesperson")} 
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                            {(salespersonList === undefined) && <CircularProgress color="inherit" size={20} />}
                            {params.InputProps.endAdornment}
                            </>
                        ),
                    }}                      
                />
            )}
            onChange = {(_, value) => {
                field.onChange(value?.employeeID ?? 0);
            }}
            value = {selectedSalesperson()}   
            ref = {field.ref}
            {...rest}
        /> 
    </>

}

export default SelectSalesperson;