import * as React from "react";
import OWQuestions from "./OWQuestions";
import { IQuestionGroup } from "../models";
import { ILineItemInfo, IUserPreferences } from "../interfaces";
import WizardHelper from "../WizardHelper";
import useTranslations from "helpers/hooks/useTranslations";
import { Flag } from "@mui/icons-material";
import { TabContext, TabPanel } from "@mui/lab";
import { Grid, Tab } from "@mui/material";
import ItemSize from "components/OptionsWizard/MainWizard/ItemSize";
import CustomTabList from "components/Common/CustomTabList";

export interface IOWTabsProps {
    questionGroups: Array<IQuestionGroup>;
    preferences: IUserPreferences;
    readOnlyMode: boolean;
    sizingAsGroup: boolean;
    itemInfo: ILineItemInfo | null;
}

const OWTabs: React.FC<IOWTabsProps> = (props: IOWTabsProps) => {

    const tm = useTranslations();
    const [currentTab, setCurrentTab] = React.useState<string>(props.questionGroups[0].groupName);

    return <TabContext value={currentTab} >

        <CustomTabList variant="scrollable" scrollButtons="auto" onChange={(e, newValue) => setCurrentTab(newValue)} sx={{ position: "sticky", top: "40px", zIndex: 40, maxWidth: "1000px" }}    >
            {props.sizingAsGroup &&
                <Tab
                    key={"TAB_SIZE"}
                    value={"TAB_SIZE"}
                    label={tm.Get("Size")}
                />}

            {props.questionGroups.map((qg: IQuestionGroup) =>
                <Tab key={"TBL_" + qg.groupName}
                    value={qg.groupName}
                    label={<Grid container>
                        {(WizardHelper.RecursivelyIsThereARequiredQuestionOrCode(qg.questions)) && <Grid item xs="auto"><Flag sx={{ width: "16px", height: "16px" }} color="warning" /></Grid>}
                        {<Grid item xs>{qg.groupName}</Grid>}
                    </Grid>}
                />
            )}
        </CustomTabList>

        {props.sizingAsGroup &&
            <TabPanel sx={{ padding: 0, marginTop: 1 }}
                key={"TAB_SIZE_PANEL"}
                value={"TAB_SIZE"}
            >
                <ItemSize
                    key="GRPS_SIZE"
                />
            </TabPanel>
        }

        {props.questionGroups.map((qg: IQuestionGroup) => (
            <TabPanel key={"TBPL_" + qg.groupName} value={qg.groupName} sx={{ padding: 0, marginTop: 1 }}>
                <OWQuestions
                    preferences={props.preferences}
                    questionGroup={qg}
                    readOnlyMode={props.readOnlyMode}
                />
            </TabPanel>
        ))}

    </TabContext>;

};

export default OWTabs;
