import React from "react";
import { ThemeProvider } from '@mui/material/styles';
import useFeneVisionTheme from 'helpers/context/Theme/useFeneVisionTheme';
import { TitleContextProvider } from 'helpers/context/Title/TitleContext';
import { FeneVisionThemeContextProvider } from 'helpers/context/Theme/FeneVisionThemeContext';
import { TranslationContextProvider } from "helpers/context/Translations/TranslationContext";
import { HomeAddressContextProvider } from "./HomeAddress/HomeAddressContext";

const NonAuthenticatingProviders: React.FC<React.PropsWithChildren<any>> = (props: React.PropsWithChildren<any>) => {

    return <FeneVisionThemeContextProvider>
        <TranslationContextProvider>
                <TitleContextProvider>
                    <HomeAddressContextProvider>
                        <InnerThemeProviders>
                            {props.children}
                        </InnerThemeProviders>
                    </HomeAddressContextProvider>
                </TitleContextProvider>
        </TranslationContextProvider>
    </FeneVisionThemeContextProvider >;
}

const InnerThemeProviders: React.FC<React.PropsWithChildren<any>> = (props: React.PropsWithChildren<any>) => {

    const [themeContext] = useFeneVisionTheme();

    return <>
        {themeContext.theme &&
            <ThemeProvider theme={themeContext.theme}>
                {props.children}
            </ThemeProvider>
        }
    </>;
}


export default NonAuthenticatingProviders;
