import { OptionSequenceTypeEnum, ViewTypeEnum } from "./enum";

import { IQuestionGroup } from "./models";
import { ICode, IQuestion, ISysInfo, ISectionRegion, IDesignerViewRegions, IDropDownCode } from "./interfaces";
import { ITranslationManager } from "helpers/hooks/useTranslations";
import Format from "helpers/fv.format";


export default class WizardHelper {

    private static _sysInfo: ISysInfo;


    public static setSysInfo(si: ISysInfo) { WizardHelper._sysInfo = si; }
    public static getOptionValueDelimiter(): string { return WizardHelper._sysInfo.delimiter; }
    public static getMaximizeOptionImage(): boolean { return WizardHelper._sysInfo && WizardHelper._sysInfo.maximizeOptionImage; }


    public static CodeHasValueList(c: ICode): boolean {
        if (c.selectItems) {
            if (c.selectItems.length > 0)
                return true;
            else
                return false;
        } else {
            return false;
        }
    }

    public static SelectedCodes(q: IQuestion): Array<ICode> {
        if (q && q.codes) {
            return q.codes.filter((c: ICode) => c.selected);
        } else {
            return new Array<ICode>();
        }
    }

    //https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript-jquery
    public static GenerateStringHash(str: string): number {
        let hash: number = 0, i: number, chr: number;
        if (!str) return hash;
        if (str.length === 0) return hash;
        for (i = 0; i < str.length; i++) {
            chr = str.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }


    public static RecursivelyIsThereARequiredQuestionOrCode(iseqs: Array<ICode | IQuestion>): boolean {
        for (var i = 0; i < iseqs.length; i++) {
            if (iseqs[i].sequenceType === OptionSequenceTypeEnum.Question) {
                let q: IQuestion = (iseqs[i] as IQuestion);
                if (this.IsQuestionOrStandaloneRequired(q)) {
                    return true;
                }

                if (q.nestedQuestions) {
                    if (this.RecursivelyIsThereARequiredQuestionOrCode(q.nestedQuestions)) {
                        return true;
                    }
                }
            } else {
                let c: ICode = (iseqs[i] as ICode);

                if (this.IsQuestionOrStandaloneRequired(c)) {
                    return true;
                }

                if (c.nestedQuestions) {
                    if (this.RecursivelyIsThereARequiredQuestionOrCode(c.nestedQuestions)) {
                        return true;
                    }
                }
            }
        }

        return false;
    }

    public static ValueSplit(codeValue: string, delimiter: string): Array<HeaderValuePair> | null {
        //We have to add an extra slash before the s because javascript is treating the single \ as a string escape 
        // before it gets passed into the regexp object.  
        let regxValidate: RegExp = RegExp("^[^=\\s]+=.*");
        let splittedValues: HeaderValuePair[] = [];
        let lbl: string = "label";
        let val: string = "string";

        if (this.IsNullOrWhiteSpace(codeValue)) {
            return null;
        }
        else {
            let splittedList: string[] = codeValue.split(delimiter);
            if (this.IsNullOrWhiteSpace(splittedList[splittedList.length - 1])) {
                splittedList.pop();
            }

            let booleanShapeParameters: string[] = [];

            for (let subValue of splittedList) {

                if (subValue.trim().match(regxValidate)) {
                    //capture the valid value and get the header from the origianl string length - the length of the value in the string

                    //rightmost parsing
                    //splitedValues.Add(New HeaderValuePair(subValue.TrimEnd.Substring(0, subValue.TrimEnd.Length - Regex.Match(subValue.TrimEnd, "[^=]*$").ToString.Length - 1), Regex.Match(subValue.TrimEnd, "[^=]*$").ToString))

                    //leftmost parsing 

                    const matches = subValue.match("^[^=]*");

                    if (matches) {
                        lbl = matches[0];
                        val = subValue.substr(lbl.length + 1).trim();
                        lbl = lbl.trim();
                        splittedValues.push(new HeaderValuePair(lbl, val));
                    }

                }
                else {
                    if (subValue.trim().length === 1)
                        booleanShapeParameters.push(subValue.trim());
                    else
                        return null;
                }
            }

            if (booleanShapeParameters.length > 0) {
                if (splittedValues.length > 0) {
                    //if there is at least one other parameter of the form X={}, we will assume that this is a shape parameter and it is valid to have a single boolean value in the list
                    // ex.  "M, W=10, H=5" is a valid shape string while "M,W" is not.
                    for (let booleanValue of booleanShapeParameters) {
                        splittedValues.push(new HeaderValuePair(booleanValue, "1"));
                    }
                }
                else {
                    //'If an option value was of the form "A,B", then it is not a valid split value
                    return null;
                }
            }
        }

        if (splittedValues.length === 0)
            return null;
        else
            return splittedValues;
    }

    public static ValueCombine(splitHeaderValues: Array<HeaderValuePair>): string {
        let newInputString: string = "";

        for (let i in splitHeaderValues) {
            let hvp = splitHeaderValues[i];

            newInputString += hvp.header + "=" + hvp.value;
            if (hvp !== splitHeaderValues[splitHeaderValues.length - 1]) {
                newInputString += ", ";
            }
        }
        return newInputString;
    }

    static IsNullOrWhiteSpace(str: string): boolean {
        return str === null || str.match(/^ *$/) !== null;
    }

    public static GetPageHeader(tm: ITranslationManager, odkey: number, partNo: string, partNoSuffix: string, orderNumber: string, lineItem: number, isMobile: boolean): string {

        let mainHeader: string;
        let subHeader: string;

        if (odkey <= 0) {
            mainHeader = tm.Get("Add Quote Item")
            subHeader = Format.FormatPartDescription(partNo, partNoSuffix) + ` - #` + orderNumber
        } else {
            mainHeader = tm.Get("Edit Quote Item");
            subHeader = Format.FormatPartDescription(partNo, partNoSuffix) + ` - #` + orderNumber + '-' + lineItem;
        }

        if (isMobile)
            return Format.FormatPartDescription(partNo, partNoSuffix);
        else
            return mainHeader + " - " + subHeader;

    }

    public static ConvertStructureToGroups(structure: Array<ICode | IQuestion>, tm: ITranslationManager): Array<IQuestionGroup> | null {

        if (!structure) { return null; }


        let questionGroups: Array<IQuestionGroup> = new Array<IQuestionGroup>();

        let currentGroup: IQuestionGroup | null = null;

        for (let i = 0; i < structure.length; i++) {
            let tmpSeqObj: IQuestion | ICode = structure[i];
            if (this.QuestionBranchVisible(tmpSeqObj)) {
                if ((!currentGroup) || currentGroup.groupName !== tmpSeqObj.questionGroup) {
                    currentGroup = {
                        groupName: tmpSeqObj.questionGroup,
                        questions: new Array<ICode | IQuestion>()
                    };
                    questionGroups.push(currentGroup);
                }
                currentGroup.questions.push(tmpSeqObj);
            }
        }

        if (questionGroups.length > 1) {
            //If we get an empty option structure with other named groups, it will be set to 'OTHER'
            for (let i = 0; i < questionGroups.length; i++) {

                if (questionGroups[i] && questionGroups[i].groupName === "") {
                    questionGroups[i].groupName = tm.Get("OTHER");
                }
            }
        }
        return questionGroups;
    }

    public static QuestionBranchVisible(qorc: IQuestion | ICode): boolean {
        if (qorc.visible) return true;

        if (qorc.nestedQuestions) {
            for (let nq of qorc.nestedQuestions) {
                if (this.QuestionBranchVisible(nq)) return true;
            }
        }

        return false;
    }


    public static IsQuestionOrStandaloneRequired(iseq: ICode | IQuestion): boolean {
        let actionRequired: boolean = false;

        if (iseq.sequenceType === OptionSequenceTypeEnum.Question) {
            let q: IQuestion = (iseq as IQuestion);

            if (q.visible && !q.locked) {
                let selectedCodes: Array<ICode> = q.codes.filter((c: ICode) => c.selected);
                let inputRequired: boolean = selectedCodes.some((c: ICode) => c.userInput && c.value.length === 0);

                let questionSelected: boolean = selectedCodes.length > 0;

                actionRequired = (q.required && !questionSelected) || inputRequired;

                if (actionRequired)
                    return true;
            }

        } else {

            let c: ICode = (iseq as ICode);

            if (c.visible) {
                let codeInputRequired: boolean = (c.userInput && c.value.length === 0);
                let codeSelected: boolean = c.selected;

                actionRequired = (codeSelected && codeInputRequired);

                if (actionRequired)
                    return true;
            }

        }

        return false;

    }

    public static GetClickableRegions(regionEntries: IDesignerViewRegions, displayViewType: ViewTypeEnum): ISectionRegion[] | null {
        if (regionEntries && regionEntries[displayViewType]) {
            return regionEntries[displayViewType];
        }

        return null;
    }

    public static AreSubLineItemTabsEnabled(requiredSubLineItems: Array<number>, currentSLI: number): boolean {
        if ((requiredSubLineItems.indexOf(currentSLI) > -1)) {
            return false;
        }
        else {
            return true;
        }
    }

    public static GetOptionCodeDescription(c: IDropDownCode, hideOptionCodes: boolean): string {
        if (c.description === "") {
            return c.code;
        }
        else if (hideOptionCodes || c.code === "") {
            return c.description;
        }
        else {
            return c.code + " - " + c.description
        }
    }

}

export class HeaderValuePair {
    header: string;
    value: string;

    constructor(head: string, val: string) {
        this.header = head;
        this.value = val;
    }

    public Header() {
        return this.header;
    }

    public Value() {
        return this.value;
    }
}
