import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectProps } from '@mui/material';
import { useFormContext, useController, Validate } from 'react-hook-form';
import useTranslations from 'helpers/hooks/useTranslations';
import LabeledText from "./LabeledText";
import React from "react";

interface IProps {
    fieldName: string,
    dataSource: any,
    keyField: string,
    valueField: string,
    displayField: string,
    //If function, returned boolean determines if displayField should be translated
    //The parameter will be the value in the object's "display"
    translateDisplayField: boolean | ((value: any) => boolean),
    label: string,
    readOnly: boolean,
    requiredRule?: { value: boolean, message: string },
    //If supplied, the parameter will be the value in the object's "valueField"
    renderDisplayText?: (value: any) => string,
    validateValue?: Validate<any> | Record<string, Validate<any>>
}

type SelectFormProps = SelectProps & IProps;

const SelectForm = ({ fieldName, dataSource, keyField, valueField, displayField, translateDisplayField, label, readOnly, renderDisplayText, requiredRule, validateValue, onChange, onBlur, ...rest }: SelectFormProps) => {

    const tm = useTranslations();

    const { control } = useFormContext();
    const { field, formState: { errors } } = useController({
        name: fieldName,
        control: control,
        rules: {
            required: { value: requiredRule?.value ?? false, message: requiredRule?.message ?? "" },
            validate: validateValue
        }
    });

    const getValue = React.useMemo(() => {
        if (field.value === null || !dataSource || field.value === -1) {
            return "";
        }
        if (dataSource.find((item: any) => item[valueField] === field.value) === undefined) {
            return "";
        }
        return field.value
    }, [field.value, dataSource, valueField]);

    const getDisplayText = React.useCallback((item: any) => {
        if (!item) return "";
        let displayText = (renderDisplayText !== undefined) ? renderDisplayText(item[valueField]) : item[displayField];
        if (typeof translateDisplayField === "boolean") {
            return translateDisplayField ? tm.Get(displayText) : displayText;
        } else {
            return translateDisplayField(item[displayField]) ? tm.Get(displayText) : displayText;
        }
    }, [renderDisplayText, valueField, displayField, translateDisplayField, tm]);

    if (readOnly) {
        return <LabeledText
            label={label}
            text={getValue !== "" ? getDisplayText(dataSource.find(item => item[valueField] === field.value)) : ""}
            isLoading={!dataSource}
            showSkeletonWhenEmpty={true}
        />
    }

    function handleOnChange(e: any) {
        if (!readOnly) {
            field.onChange(e);
            if (onChange) onChange(e, field.value);
        }
    }

    function handleOnBlur(e: any) {
        field.onBlur();
        if (onBlur) onBlur(e);
    }

    return <>
        <FormControl variant="outlined" error={errors[fieldName] ? true : false} fullWidth>
            <InputLabel>{label}</InputLabel>
            <Select
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                inputRef={field.ref}
                value={getValue}
                label={label}
                {...rest}
            >
                {dataSource?.map((item) => {
                    return <MenuItem key={item[keyField]} value={item[valueField]}>{getDisplayText(item)}</MenuItem>
                })}
            </Select>
            <FormHelperText>{errors[fieldName]?.message}</FormHelperText>
        </FormControl>
    </>
}

export default SelectForm;