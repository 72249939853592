export default class OrderNavigation {

    public static OrdersURL(oKey: number) {
        return `/orders?oKey=${oKey}`;
    }

    public static LineItemPriceBreakdownURL(oKey: number, odKey: number) {
        return `/orders/pricebreakdown?oKey=${oKey}&odKey=${odKey}`;
    }

}