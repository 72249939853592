import { Chip } from "@mui/material";

import useQuoteClassifications from "helpers/context/SelectionValues/useQuoteClassifications";
import { OrderStatusEnum, ThemeColorEnum } from "helpers/enums";
import useQuoteData from "components/Quotes/useQuoteData";
import { useCallback } from "react";
import useTranslations from "helpers/hooks/useTranslations";
import Constants from "helpers/constants";

function StatusChip() {

    const tm = useTranslations();
    const quoteClassifications = useQuoteClassifications();
    const { quote } = useQuoteData();

    const getColor = useCallback(() : any => {

        if (quote)
        {
            switch (quote.status)
            {
                case OrderStatusEnum.OnHold:
                    return ThemeColorEnum.Warning;
                case OrderStatusEnum.Uploaded:
                    return ThemeColorEnum.Primary;
                default:
                    return ThemeColorEnum.Success;
            }
        }
        
        return ThemeColorEnum.Primary;
    }, [quote]);


    if (quote?.classificationID !== undefined && quote?.status !== undefined && quoteClassifications) {
        const classificationDescription = quoteClassifications.GetQuoteClassificationDescription(quote.classificationID, quote.status);

        return <Chip
            color={getColor()}
            label={Constants.Strings.Classifications.includes(classificationDescription) ? tm.Get(classificationDescription) : classificationDescription }
        />
    } else {
        return <></>
    }
}

export default StatusChip;